import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../common/api/api.base";
import {
	GET_STATES_DIRECTORY_REQUEST,
	getStatesDirectorySuccess,
	getStatesDirectoryError,
} from "../../actions/directories/states.directory.actions";

function* workerGetStatesDirectory() {
	try {
		const result = yield call(ApiBase.get, `/api/enumerations/states`);
		if (result.length) {
			yield put(getStatesDirectorySuccess(result));
		} else {
			yield put(getStatesDirectoryError("Failed to get states!"));
		}
	} catch (error) {
		yield put(getStatesDirectoryError(error));
	}
}

export default function* watchStatesDirectorySaga() {
	yield takeEvery(GET_STATES_DIRECTORY_REQUEST, workerGetStatesDirectory);
}
