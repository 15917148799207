import {
	SEARCH_FOR_MESSAGES,
	SEARCH_FOR_MESSAGES_SUCCESS,
	SEARCH_FOR_MESSAGES_ERROR,
} from "./actions";

const initialState = {
	loading: false,
	options: [],
	error: null,
};

export default function messagesSearchReducer(state = initialState, action) {
	switch (action.type) {
		case SEARCH_FOR_MESSAGES:
			return {
				...state,
				loading: true,
				error: null,
			};
		case SEARCH_FOR_MESSAGES_SUCCESS:
			return {
				...state,
				loading: false,
				error: null,
				options: action.payload,
			};
		case SEARCH_FOR_MESSAGES_ERROR:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
