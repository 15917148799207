export const GET_SERVICE_TYPE_BY_ID = "GET_SERVICE_TYPE_BY_ID";
export const GET_SERVICE_TYPE_BY_ID_SUCCESS = "GET_SERVICE_TYPE_BY_ID_SUCCESS";
export const GET_SERVICE_TYPE_BY_ID_ERROR = "GET_SERVICE_TYPE_BY_ID_ERROR";

export function getServiceTypeById(payload) {
	return {
		type: GET_SERVICE_TYPE_BY_ID,
		payload,
	};
}

export function getServiceTypeByIdSuccess(payload) {
	return {
		type: GET_SERVICE_TYPE_BY_ID_SUCCESS,
		payload,
	};
}

export function getServiceTypeByIdError(error) {
	return {
		type: GET_SERVICE_TYPE_BY_ID_ERROR,
		error,
	};
}

export const CREATE_SERVICE_PLAN_ITEM = "CREATE_SERVICE_PLAN_ITEM";
export const CREATE_SERVICE_PLAN_ITEM_SUCCES =
	"CREATE_SERVICE_PLAN_ITEM_SUCCESS";
export const CREATE_SERVICE_PLAN_ITEM_ERROR = "CREATE_SERVICE_PLAN_ITEM_ERROR";

export function createServicePlanItem(payload) {
	return {
		type: CREATE_SERVICE_PLAN_ITEM,
		payload,
	};
}

export function createServicePlanItemSuccess(payload) {
	return {
		type: CREATE_SERVICE_PLAN_ITEM_SUCCES,
		payload,
	};
}

export function createServicePlanItemError(error) {
	return {
		type: CREATE_SERVICE_PLAN_ITEM_ERROR,
		error,
	};
}

export const UPDATE_SERVICE_PLAN_ITEM = "UPDATE_SERVICE_PLAN_ITEM";
export const UPDATE_SERVICE_PLAN_ITEM_SUCCES =
	"UPDATE_SERVICE_PLAN_ITEM_SUCCESS";
export const UPDATE_SERVICE_PLAN_ITEM_ERROR = "UPDATE_SERVICE_PLAN_ITEM_ERROR";

export function updateServicePlanItem(payload) {
	return {
		type: UPDATE_SERVICE_PLAN_ITEM,
		payload,
	};
}

export function updateServicePlanItemSuccess(payload) {
	return {
		type: UPDATE_SERVICE_PLAN_ITEM_SUCCES,
		payload,
	};
}

export function updateServicePlanItemError(error) {
	return {
		type: UPDATE_SERVICE_PLAN_ITEM_ERROR,
		error,
	};
}

export const DELETE_SERVICE_PLAN_ITEM = "DELETE_SERVICE_PLAN_ITEM";
export const DELETE_SERVICE_PLAN_ITEM_SUCCES =
	"DELETE_SERVICE_PLAN_ITEM_SUCCESS";
export const DELETE_SERVICE_PLAN_ITEM_ERROR = "DELETE_SERVICE_PLAN_ITEM_ERROR";

export function deleteServicePlanItem(payload) {
	return {
		type: DELETE_SERVICE_PLAN_ITEM,
		payload,
	};
}

export function deleteServicePlanItemSuccess(payload) {
	return {
		type: DELETE_SERVICE_PLAN_ITEM_SUCCES,
		payload,
	};
}

export function deleteServicePlanItemError(error) {
	return {
		type: DELETE_SERVICE_PLAN_ITEM_ERROR,
		error,
	};
}

export const GET_SERVICE_PLAN_ITEM = "GET_SERVICE_PLAN_ITEM";
export const GET_SERVICE_PLAN_ITEM_SUCCES = "GET_SERVICE_PLAN_ITEM_SUCCESS";
export const GET_SERVICE_PLAN_ITEM_ERROR = "GET_SERVICE_PLAN_ITEM_ERROR";

export function getServicePlanItem(payload) {
	return {
		type: GET_SERVICE_PLAN_ITEM,
		payload,
	};
}

export function getServicePlanItemSuccess(payload) {
	return {
		type: GET_SERVICE_PLAN_ITEM_SUCCES,
		payload,
	};
}

export function getServicePlanItemError(error) {
	return {
		type: GET_SERVICE_PLAN_ITEM_ERROR,
		error,
	};
}

export const CREATE_SERVICE_TYPE_NOTE = "CREATE_SERVICE_TYPE_NOTE";
export const CREATE_SERVICE_TYPE_NOTE_SUCCES =
	"CREATE_SERVICE_TYPE_NOTE_SUCCESS";
export const CREATE_SERVICE_TYPE_NOTE_ERROR = "CREATE_SERVICE_TYPE_NOTE_ERROR";

export function createServiceTypeNote(payload) {
	return {
		type: CREATE_SERVICE_TYPE_NOTE,
		payload,
	};
}

export function createServiceTypeNoteSuccess(payload) {
	return {
		type: CREATE_SERVICE_TYPE_NOTE_SUCCES,
		payload,
	};
}

export function createServiceTypeNoteError(error) {
	return {
		type: CREATE_SERVICE_TYPE_NOTE_ERROR,
		error,
	};
}

export const UPDATE_SERVICE_TYPE_NOTE = "UPDATE_SERVICE_TYPE_NOTE";
export const UPDATE_SERVICE_TYPE_NOTE_SUCCES =
	"UPDATE_SERVICE_TYPE_NOTE_SUCCESS";
export const UPDATE_SERVICE_TYPE_NOTE_ERROR = "UPDATE_SERVICE_TYPE_NOTE_ERROR";

export function updateServiceTypeNote(payload) {
	return {
		type: UPDATE_SERVICE_TYPE_NOTE,
		payload,
	};
}

export function updateServiceTypeNoteSuccess(payload) {
	return {
		type: UPDATE_SERVICE_TYPE_NOTE_SUCCES,
		payload,
	};
}

export function updateServiceTypeNoteError(error) {
	return {
		type: UPDATE_SERVICE_TYPE_NOTE_ERROR,
		error,
	};
}

export const DELETE_SERVICE_TYPE_NOTE = "DELETE_SERVICE_TYPE_NOTE";
export const DELETE_SERVICE_TYPE_NOTE_SUCCES =
	"DELETE_SERVICE_TYPE_NOTE_SUCCESS";
export const DELETE_SERVICE_TYPE_NOTE_ERROR = "DELETE_SERVICE_TYPE_NOTE_ERROR";

export function deleteServiceTypeNote(payload) {
	return {
		type: DELETE_SERVICE_TYPE_NOTE,
		payload,
	};
}

export function deleteServiceTypeNoteSuccess(payload) {
	return {
		type: DELETE_SERVICE_TYPE_NOTE_SUCCES,
		payload,
	};
}

export function deleteServiceTypeNoteError(error) {
	return {
		type: DELETE_SERVICE_TYPE_NOTE_ERROR,
		error,
	};
}

export const CREATE_SERVICE_TYPE_ATTACHMENT = "CREATE_SERVICE_TYPE_ATTACHMENT";
export const CREATE_SERVICE_TYPE_ATTACHMENT_SUCCES =
	"CREATE_SERVICE_TYPE_ATTACHMENT_SUCCESS";
export const CREATE_SERVICE_TYPE_ATTACHMENT_ERROR =
	"CREATE_SERVICE_TYPE_ATTACHMENT_ERROR";

export function createServiceTypeAttachment(payload) {
	return {
		type: CREATE_SERVICE_TYPE_ATTACHMENT,
		payload,
	};
}

export function createServiceTypeAttachmentSuccess(payload) {
	return {
		type: CREATE_SERVICE_TYPE_ATTACHMENT_SUCCES,
		payload,
	};
}

export function createServiceTypeAttachmentError(error) {
	return {
		type: CREATE_SERVICE_TYPE_ATTACHMENT_ERROR,
		error,
	};
}

export const CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE =
	"CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE";
export const CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE_SUCCESS =
	"CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE_SUCCESS";
export const CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE_ERROR =
	"CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE_ERROR";

export function createServiceTypeAttachmentMultiple(payload) {
	return {
		type: CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE,
		payload,
	};
}

export function createServiceTypeAttachmentMultipleSuccess(payload) {
	return {
		type: CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE_SUCCESS,
		payload,
	};
}

export function createServiceTypeAttachmentMultipleError(error) {
	return {
		type: CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE_ERROR,
		error,
	};
}

export const UPDATE_SERVICE_TYPE_ATTACHMENT = "UPDATE_SERVICE_TYPE_ATTACHMENT";
export const UPDATE_SERVICE_TYPE_ATTACHMENT_SUCCES =
	"UPDATE_SERVICE_TYPE_ATTACHMENT_SUCCESS";
export const UPDATE_SERVICE_TYPE_ATTACHMENT_ERROR =
	"UPDATE_SERVICE_TYPE_ATTACHMENT_ERROR";

export function updateServiceTypeAttachment(payload) {
	return {
		type: UPDATE_SERVICE_TYPE_ATTACHMENT,
		payload,
	};
}

export function updateServiceTypeAttachmentSuccess(payload) {
	return {
		type: UPDATE_SERVICE_TYPE_ATTACHMENT_SUCCES,
		payload,
	};
}

export function updateServiceTypeAttachmentError(error) {
	return {
		type: UPDATE_SERVICE_TYPE_ATTACHMENT_ERROR,
		error,
	};
}

export const DELETE_SERVICE_TYPE_ATTACHMENT = "DELETE_SERVICE_TYPE_ATTACHMENT";
export const DELETE_SERVICE_TYPE_ATTACHMENT_SUCCES =
	"DELETE_SERVICE_TYPE_ATTACHMENT_SUCCESS";
export const DELETE_SERVICE_TYPE_ATTACHMENT_ERROR =
	"DELETE_SERVICE_TYPE_ATTACHMENT_ERROR";

export function deleteServiceTypeAttachment(payload) {
	return {
		type: DELETE_SERVICE_TYPE_ATTACHMENT,
		payload,
	};
}

export function deleteServiceTypeAttachmentSuccess(payload) {
	return {
		type: DELETE_SERVICE_TYPE_ATTACHMENT_SUCCES,
		payload,
	};
}

export function deleteServiceTypeAttachmentError(error) {
	return {
		type: DELETE_SERVICE_TYPE_ATTACHMENT_ERROR,
		error,
	};
}

export const CLEAR_PLAN = "CLEAR_PLAN";

export function clearPlan(payload) {
	return {
		type: CLEAR_PLAN,
		payload,
	};
}

export const PRINT_SERVICE_PLAN_PDF = "PRINT_SERVICE_PLAN_PDF";
export const PRINT_SERVICE_PLAN_PDF_SUCCESS = "PRINT_SERVICE_PLAN_PDF_SUCCESS";
export const PRINT_SERVICE_PLAN_PDF_ERROR = "PRINT_SERVICE_PLAN_PDF_ERROR";

export function printServicePlanPdf(payload){
	return{
		type:PRINT_SERVICE_PLAN_PDF,
		payload
	}
}

export function printServicePlanPdfSuccess(payload){
	return{
		type:PRINT_SERVICE_PLAN_PDF_SUCCESS,
		payload
	}
}

export function printServicePlanPdfError(error){
	return{
		type:PRINT_SERVICE_PLAN_PDF_ERROR,
		error
	}
}

export const PRINT_SERVICE_PLAN_HTML = "PRINT_SERVICE_PLAN_HTML";
export const PRINT_SERVICE_PLAN_HTML_SUCCESS = "PRINT_SERVICE_PLAN_HTML_SUCCESS";
export const PRINT_SERVICE_PLAN_HTML_ERROR = "PRINT_SERVICE_PLAN_HTML_ERROR";

export function printServicePlanHtml(payload){
	return{
		type:PRINT_SERVICE_PLAN_HTML,
		payload
	}
}

export function printServicePlanHtmlSuccess(payload){
	return{
		type:PRINT_SERVICE_PLAN_HTML_SUCCESS,
		payload
	}
}

export function printServicePlanHtmlError(error){
	return{
		type:PRINT_SERVICE_PLAN_HTML_ERROR,
		error
	}
}
