import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from '../../../common/api/api.base';
import {
    REGISTER_USER,   
    registerUserError,
    registerUserSuccess
} from './actions';

function* registerUser(action) {
    try {
      const result = yield call(ApiBase.post, `/api/account/register`, action.payload);
      if (!result.error) {
        yield put(registerUserSuccess(result));         
      } else {
        yield put(registerUserError("Failed to sign up!"));
      }
    } catch (e) {
      yield put(registerUserError(e.message));
    }
  }

  export default function* watchRegisterSaga() {
    yield takeEvery(REGISTER_USER, registerUser);
   }