export const CREATE_POST = "CREATE_POST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_ERROR = "CREATE_POST_ERROR";

export function createPost(payload) {
    return {
        type: CREATE_POST,
        payload
    }
}

export function createPostSuccess(payload) {
    return {
        type: CREATE_POST_SUCCESS,
        payload
    }
}

export function createPostError(error) {
    return {
        type: CREATE_POST_ERROR,
        error
    }
}

export const DELETE_POST = "DELETE_POST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_ERROR = "DELETE_POST_ERROR";

export function deletePost(payload) {
    return {
        type: DELETE_POST,
        payload
    }
}

export function deletePostSuccess(payload) {
    return {
        type: DELETE_POST_SUCCESS,
        payload
    }
}

export function deletePostError(error) {
    return {
        type: DELETE_POST_ERROR,
        error
    }
}

export const GET_DASHBOARD_POSTS = "GET_DASHBOARD_POSTS";
export const GET_DASHBOARD_POSTS_SUCCESS="GET_DASHBOARD_POSTS_SUCCESS";
export const GET_DASHBOARD_POSTS_ERROR = "GET_DASHBOARD_POSTS_ERROR";

export function getDashboardPosts(payload){
    return{
        type:GET_DASHBOARD_POSTS,
        payload
    }
}

export function getDashboardPostsSuccess(payload){
    return{
        type:GET_DASHBOARD_POSTS_SUCCESS,
        payload
    }
}

export function getDashboardPostsError(error){
    return{
        type:GET_DASHBOARD_POSTS_ERROR,
        error
    }
}

export const GET_EDITABLE_POST = "GET_EDITABLE_POST";
export const GET_EDITABLE_POST_SUCCESS="GET_EDITABLE_POST_SUCCESS";
export const GET_EDITABLE_POST_ERROR = "GET_EDITABLE_POST_ERROR";

export function getEditablePost(payload){
    return{
        type:GET_EDITABLE_POST,
        payload
    }
}

export function getEditablePostSuccess(payload){
    return{
        type:GET_EDITABLE_POST_SUCCESS,
        payload
    }
}

export function getEditablePostError(error){
    return{
        type:GET_EDITABLE_POST_ERROR,
        error
    }
}

export const UPDATE_POST = "UPDATE_POST";
export const UPDATE_POST_SUCCESS ="UPDATE_POST_SUCCESS";
export const UPDATE_POST_ERROR ="UPDATE_POST_ERROR";

export function updatePost(payload) {
    return {
        type: UPDATE_POST,
        payload
    }
}

export function updatePostSuccess(payload) {
    return {
        type: UPDATE_POST_SUCCESS,
        payload
    }
}

export function updatePostError(error) {
    return {
        type: UPDATE_POST_ERROR,
        error
    }
}