import {
    DELETE_COMMENT,
    DELETE_COMMENT_SUCCESS,
    DELETE_COMMENT_ERROR,
    GET_ALL_COMMENTS,
    GET_ALL_COMMENTS_SUCCESS,
    GET_ALL_COMMENTS_ERROR
} from './actions';

const initialCommentsState = {
    error: null,
    loading: false,
    loaded: true,
    actionLoading:false,
    comments: {
        count: 0,
        entities: []
    }
}

function dashboardCommentsReducer(state = initialCommentsState, action) {
    switch (action.type) {
        case DELETE_COMMENT:
            return { ...state, actionLoading: true, loaded: false, error: null };
        case DELETE_COMMENT_SUCCESS:
            return { ...state, actionLoading: false, loaded: true, error: null };
        case DELETE_COMMENT_ERROR:
            return { ...state, actionLoading: false, loaded: false, error: action.error }
        case GET_ALL_COMMENTS:           
            return { ...state, loading: true, loaded: false, error: null };
        case GET_ALL_COMMENTS_SUCCESS:
            return { ...state, loading: false, loaded: true, error: null, comments: action.payload }
        case GET_ALL_COMMENTS_ERROR:
            return { ...state, loading: false, loaded: false, error: action.error, comments: { count: 0, entities: [] } };
        default:
            return state
    }
}

export default dashboardCommentsReducer;