import { call, put, takeEvery, take } from "redux-saga/effects";
import {
	GET_UPCOMING_EVENTS,
	SEARCH_EVENTS,
	GET_LATEST_EVENTS,
	getUpcomingEventsError,
	getUpcomingEventsSuccess,
	getLatestEventsSuccess,
	getLatestEventsError,
	searchForEventsSuccess,
	searchForEventsError,
} from "./actions";

import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";

function* workerGetUpcomingEvents(action) {
	try {
		const result = yield call(ApiBase.get, "/api/events/upcoming");
		if (Array.isArray(result)) {
			yield put(getUpcomingEventsSuccess(result));
		} else {
			yield put(getUpcomingEventsError(result));
		}
	} catch (error) {
		yield put(getUpcomingEventsError(error));
	}
}

function* workerGetLatestEvents(action) {
	try {
		const result = yield call(ApiBase.get, "/api/events/latest");
		if (Array.isArray(result)) {
			yield put(getLatestEventsSuccess(result));
		} else {
			yield put(getLatestEventsError(result));
		}
	} catch (error) {
		yield put(getLatestEventsError(error));
	}
}

function* workerSearchForEvents(action) {
	try {
		const result = yield call(ApiBase.get, "/api/events", action.payload);
		if (!result.error) {
			if(result.length==0){
				toast.info("No events match these criteria");
			}
			yield put(searchForEventsSuccess(result));
		} else {
			yield put(searchForEventsError(result.error));
		}
	} catch (error) {
		yield put(searchForEventsError(error));
	}
}

export default function* watchEventsSaga() {
	yield takeEvery(GET_UPCOMING_EVENTS, workerGetUpcomingEvents);
	yield takeEvery(GET_LATEST_EVENTS, workerGetLatestEvents);
	yield takeEvery(SEARCH_EVENTS, workerSearchForEvents);
}
