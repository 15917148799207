import {
	GET_DASHBOARD_MEMBERS,
	GET_DASHBOARD_MEMBERS_SUCCESS,
	GET_DASHBOARD_MEMBERS_ERROR,
	SEARCH_DASHBOARD_MEMBERS,
	SEARCH_DASHBOARD_MEMBERS_SUCCESS,
	SEARCH_DASHBOARD_MEMBERS_ERROR,
	GET_ROLES_SHORT_INFO,
	GET_ROLES_SHORT_INFO_SUCCESS,
	GET_ROLES_SHORT_INFO_ERROR,
	DELETE_MEMBERS,
	DELETE_MEMBERS_SUCCESS,
	DELETE_MEMBERS_ERROR,
	UPDATE_MEMBER_ROLE,
	UPDATE_MEMBER_ROLE_SUCCESS,
	UPDATE_MEMBER_ROLE_ERROR
} from "./actions";

const initialState = {
	loading: false,
	loaded: false,	
	error: null,
	members: {
		entities: [],
		count: 0
	},
	roles: [],
	deleteMembers: {
		loading: false,
		loaded: false,
		error: null
	},
	updateRole: {
		loading: false,
		loaded: false,
		error: null
	}
};

export default function dashboardMembersReducer(state = initialState, action) {
	switch (action.type) {
		case GET_DASHBOARD_MEMBERS:
			return { ...state, loading: true, loaded: false };
		case GET_DASHBOARD_MEMBERS_SUCCESS:
			return { ...state, loading: false, loaded: true, error: null, members: action.payload };
		case GET_DASHBOARD_MEMBERS_ERROR:
			return { ...state, loading: false, loaded: false, error: action.error };
		case SEARCH_DASHBOARD_MEMBERS:
			return { ...state, loading: true, loaded: false };
		case SEARCH_DASHBOARD_MEMBERS_SUCCESS:
			return { ...state, loading: false, loaded: true, error: null };
		case SEARCH_DASHBOARD_MEMBERS_ERROR:
			return { ...state, loading: false, loaded: false, error: action.error };
		case GET_ROLES_SHORT_INFO:
			return { ...state, loading: true, loaded: false };
		case GET_ROLES_SHORT_INFO_SUCCESS:
			return { ...state, loading: false, loaded: true, error: null, roles: action.payload };
		case GET_ROLES_SHORT_INFO_ERROR:
			return { ...state, loading: false, loaded: false, error: action.error };
		case DELETE_MEMBERS:
			return {
				...state, deleteMembers: {
					...state.deleteMembers,
					loading: true,
					loaded: false
				}
			};
		case DELETE_MEMBERS_SUCCESS:
			return {
				...state, deleteMembers: {
					...state.deleteMembers,
					loading: false,
					loaded: true,
					error: null
				}
			};
		case DELETE_MEMBERS_ERROR:
			return {
				...state, deleteMembers: {
					...state.deleteMembers,
					loading: false,
					loaded: true,
					error: action.error
				}
			};
		case UPDATE_MEMBER_ROLE:
			return {
				...state, updateRole: {
					...state.updateRole,
					loading: true,
					loaded: false
				}
			};
		case UPDATE_MEMBER_ROLE_SUCCESS:
			return {
				...state, updateRole: {
					...state.updateRole,
					loading: false,
					loaded: true,
					error: null
				},
				members:{
					count:state.members.count,
					entities:updateMemberRoleInList(state.members.entities,action.payload)
				}
			};
		case UPDATE_MEMBER_ROLE_ERROR:
			return {
				...state, updateRole: {
					...state.updateRole,
					loading: false,
					loaded: false,
					error: action.error
				}
			};
		default:
			return state;
	}
}

function updateMemberRoleInList(members, payload){	
	const memberIdx = members.findIndex(m=>m.id===payload.id);
	if(memberIdx===-1){
		return members;
	}

	return[
		...members.slice(0, memberIdx),
		payload,
		...members.slice(memberIdx+1)
	];
}