import { call, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
    GET_VIDEO,
    getVideoSuccess,
    getVideoError
} from './actions';

import ApiBase from '../../../common/api/api.base';
import { toast } from "react-toastify";

function* workerGetVideo(action) {
    try {
        const result = yield call(ApiBase.get, `/api/videos/${action.payload}`);
        if (!result.error) {
            yield put(getVideoSuccess(result));
        } else {
            yield put(getVideoError(`Failed to get video!`));
        }
    } catch (e) {
        const { status } = e.response;
        if (status === 404) {
            yield put(getVideoError({status}));
        }
        else{
            yield put(getVideoError(`Failed to get video!`));
            yield put(push("/videos"));
            toast.error(`Failed to get video!`);  
        }
    }
}

export default function* watchVideoSaga() {
    yield takeEvery(GET_VIDEO, workerGetVideo);
}