export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS="REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR ="REGISTER_USER_ERROR";

export function registerUser(payload){
    return{
        type:REGISTER_USER,
        payload
    }
}

export function registerUserSuccess(payload){
    return{
        type:REGISTER_USER_SUCCESS,
        payload
    }
}

export function registerUserError(error){
    return{
        type:REGISTER_USER_ERROR,
        error
    }
}