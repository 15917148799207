import React from "react";
import { Link } from "react-router-dom";
import { parseHtmlToReact } from "../../../../../common/helpers/html.parser";

const HomePostsListItem = ({ post }) => {
	return (
		<>
			<h3 className="post-item__title / title  title_size_h3">
				{post.heading}
			</h3>

			{post.titlePictureUrl && (
				<img
					className="post-item__img"
					src={post.titlePictureUrl}
					alt={post.heading}
				/>
			)}
			<div className="post-item__text">{parseHtmlToReact(post.text)}</div>

			<Link className={"link"} to={`/posts/${post.id}`}>
				Read more &gt;
			</Link>
		</>
	);
};

export default HomePostsListItem;
