import React, { useEffect } from "react";
import HomeVideos from "./HomeVideos";
import { useDispatch, useSelector } from "react-redux";
import { getHomeVideos } from "./store/actions";
import { getCurrentUser } from "../../../../common/helpers/auth.helper";

const HomeVideosContainer = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getHomeVideos());
	}, []);

	const videos = useSelector((x) => x.homeVideos.videos);

	useSelector((x) => x.auth.user);
	const currentUser = getCurrentUser();
	const isSignedIn = currentUser.isSignedIn;
	const isAdmin = currentUser.roles.some((r) => r === "Admin");

	return (
		<HomeVideos videos={videos} isAdmin={isAdmin} isSignedIn={isSignedIn} />
	);
};

export default HomeVideosContainer;
