import React, { useEffect } from "react";
import Giving from "./Giving";
import { useDispatch, useSelector } from "react-redux";
import {
	getAllGivingTypes,
	createOneTimeGiving,
	getAllGivingPlans,
	getDataForSubscription,
	createAnonymousGiving
} from "./store/actions";
import {
	createAnonymousGivingPaymentIntent,
	createOneTimeGivingPaymentIntent,
	getUserPaymentMethods,
	resetPaymentIntent,
} from "../../redux/actions/payment.actions";

const GivingContainer = ({ isSignedIn }) => {
	const dispatch = useDispatch();

	const fetchSelectsData = () => {
		dispatch(getAllGivingTypes());
		dispatch(getAllGivingPlans());
	};

	const givingTypes = useSelector((x) => x.givings.givingTypes);
	const givingPlans = useSelector((x) => x.givings.givingPlans);

	const oneTimeGiving = useSelector((x) => x.givings.oneTimeGiving);
	const creationLoading = useSelector((x) => x.givings.givingCreationLoading);

	const dataForSubscription = useSelector(
		(x) => x.givings.dataForSubscription
	);
	const subscriptionLoading = useSelector(
		(x) => x.givings.subscriptionLoading
	);

	const paymentMethods = useSelector((x) => x.payments.paymentMethods);

	const onOneTimeGivingCreate = (payload) =>
		dispatch(createOneTimeGiving(payload));

	const onAnonymousGivingCreate = (payload)=>
		dispatch(createAnonymousGiving(payload));	

	const onGetDataForSubscription = (payload) =>
		dispatch(getDataForSubscription(payload));
	const onGetPaymentMethods = () => dispatch(getUserPaymentMethods());

	const oneTimePaymentIntentData = useSelector(
		(x) => x.payments.oneTimePaymentIntentData
	);
	const onOneTimeGivingPaymentIntentCreate = (payload) =>
		dispatch(createOneTimeGivingPaymentIntent(payload));

	const onAnonymousGivingPaymentIntentCreate = (payload)=>
		dispatch(createAnonymousGivingPaymentIntent(payload));

	const onResetPaymentIntent = () => dispatch(resetPaymentIntent());

	return (
		<Giving
			fetchSelectsData={fetchSelectsData}
			isSignedIn={isSignedIn}
			givingPlans={givingPlans}
			givingTypes={givingTypes}
			createOneTimeGiving={onOneTimeGivingCreate}
			createAnonymousGiving={onAnonymousGivingCreate}
			oneTimeGiving={oneTimeGiving}
			givingCreationLoading={creationLoading}
			oneTimeGivingPaymentIntentData={oneTimePaymentIntentData}
			resetPaymentIntent={onResetPaymentIntent}
			createOneTimeGivingPaymentIntent={
				onOneTimeGivingPaymentIntentCreate
			}
			createAnonymousGivingPaymentIntent={onAnonymousGivingPaymentIntentCreate}
			dataForSubscription={dataForSubscription}
			subscriptionLoading={subscriptionLoading}
			getDataForSubscription={onGetDataForSubscription}
			paymentMethods={paymentMethods}
			getPaymentMethods={onGetPaymentMethods}
		/>
	);
};

export default GivingContainer;
