import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getCurrentUser } from "../auth.helper";

function PrivateRoute({
	component: Component,
	roles,
	allowedActions,
	...rest
}) {
	const user = getCurrentUser();
	const isAllowed =
		user.isSignedIn &&
		(roles ? user.roles.some((r) => roles.includes(r)) : true);
	// || (allowedActions
	// 		? user.allowedActions.some((a) => allowedActions.includes(a))
	// 		: true));

	return (
		<Route
			{...rest}
			render={(props) => {
				if (isAllowed) {
					return <Component {...props} />;
				} else {
					return (
						<Redirect
							to={{
								pathname: "/",
								state: { from: props.location },
							}}
						/>
					);
				}
			}}
		/>
	);
}

export default PrivateRoute;
