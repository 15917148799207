import React from "react";
import PrivateRoute from "../common/helpers/routing/PrivateRoute";
import { Route, Switch } from "react-router-dom";
import NotFound from "./NotFound/NotFound";

import Account from "./Account/Account";
import Dashboard from "./Dashboard/Dashboard";

const Video = React.lazy(() => import("./Video/Video.container"));
const Videos = React.lazy(() => import("./Videos/Videos.container"));
const Posts = React.lazy(() => import("./Posts/Posts.container"));
const Post = React.lazy(() => import("./Post/Post.container"));
const ResetPassword = React.lazy(() =>
	import("./ResetPassword/ResetPassword.container")
);
const AboutUs = React.lazy(() => import("./AboutUs/AboutUs"));
const Events = React.lazy(() => import("./Events/Events.Container"));

const AppRouting = ({ onLogout }) => {
	return (
		<Switch>
			<PrivateRoute
				path="/dashboard"
				roles={["Admin"]}
				component={(props) => (
					<Dashboard onLogout={onLogout} {...props} />
				)}
			/>
			<PrivateRoute
				path="/account"
				component={(props) => (
					<Account onLogout={onLogout} {...props} />
				)}
			/>
			<Route path="/events" exact component={Events} />
			<Route path="/videos" exact component={Videos} />
			<Route path="/videos/:id" component={Video} />
			<Route path="/posts" exact component={Posts} />
			<Route path="/posts/:id" exact component={Post} />

			<Route path="/reset-password" exact component={ResetPassword} />
			<Route path="/about-us" exact component={AboutUs} />
			<Route component={NotFound} />
		</Switch>
	);
};

export default AppRouting;
