import React from "react";
import { ReactComponent as CloseIcon } from "../../../assets/img/icons/close.svg";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";

const ResendConfirmationModal = ({
	show,
	handleClose,
	loading,
	sendConfirmationEmail,
}) => {
	const { register, handleSubmit, errors } = useForm();

	return (
		<Modal isOpen={show} toggle={handleClose} className="modal-compact">
			<div className="modal-header / align-items-center">
				<h5
					className="modal-title / title  title_size_h2"
					id="login_modal_title">
					Resend confirmation email
				</h5>
				<button
					className="close"
					type="button"
					aria-label="Close"
					onClick={handleClose}>
					<CloseIcon />
				</button>
			</div>
			<form onSubmit={handleSubmit(sendConfirmationEmail)}>
				<ModalBody className="wide-modal-body-without-shadow">
					<div className="form-group">
						<label
							className="form-label  form-label_required"
							htmlFor="resend_modal_email">
							Email
						</label>
						<input
							className="form-control"
							type="email"
							id="resend_modal_email"
							name="email"
							placeholder="Email"
							ref={register({ required: true })}
						/>
						{errors.email && (
							<span className="form-validation-error">
								Email is required
							</span>
						)}
					</div>
				</ModalBody>
				<ModalFooter className="wide-modal-body-without-shadow">
					<button disabled={loading} className="btn" type="submit">
						Resend confirmation email
						{loading && <Spinner size="sm" />}
					</button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default ResendConfirmationModal;
