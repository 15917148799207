import {
    GET_VIDEO,
    GET_VIDEO_ERROR,
    GET_VIDEO_SUCCESS
} from './actions';

const initialVideoState = {
    error: null,
    video:{},
    loading: false,
    loaded: false
};

function videoReducer(state = initialVideoState, action) {
    switch (action.type) {
        case GET_VIDEO:
            return { ...state, loading: true, loaded: false };
        case GET_VIDEO_SUCCESS:                      
            return { ...state, loading: false, loaded: true, video: action.payload };
        case GET_VIDEO_ERROR:
            return { ...state, videos:{}, loaded: false, loading: false, error: action.error };       
        default:
            return state;
    }
}

export default videoReducer;