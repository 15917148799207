import { 
SEARCH_POSTS,
SEARCH_POSTS_SUCCESS,
SEARCH_POSTS_ERROR,
SEARCH_VIDEOS,
SEARCH_VIDEOS_SUCCESS,
SEARCH_VIDEOS_ERROR,
SEARCH_FILTER_CHANGED
} from "./actions";

const initialSearchState = {
    loading: false,
    error: null,
    options: []
}

function searchReducer(state = initialSearchState, action) {
    switch (action.type) {
        case SEARCH_POSTS:
            return { ...state, loading: true, error: null };
        case SEARCH_POSTS_SUCCESS:
            return { loading: false, options: state.options.concat(action.payload) };
        case SEARCH_POSTS_ERROR:
            return { ...state, loading: false, error: action.error };
        case SEARCH_VIDEOS:
            return { ...state, loading: true, error: null };
        case SEARCH_VIDEOS_SUCCESS:          
            return { loading: false, options: state.options.concat(action.payload) };
        case SEARCH_VIDEOS_ERROR:
            return { ...state, loading: false, error: action.error };
        case SEARCH_FILTER_CHANGED:           
            return initialSearchState;
        default:
            return state;
    }
}

export default searchReducer;