export const UPLOAD_VIDEO = "UPLOAD_VIDEO";
export const UPLOAD_VIDEO_SUCCESS = "UPLOAD_VIDEO_SUCCESS";
export const UPLOAD_VIDEO_ERROR = "UPLOAD_VIDEO_ERROR";

export function uploadVideo(payload) {
	return {
		type: UPLOAD_VIDEO,
		payload,
	};
}

export function uploadVideoSuccess(payload) {
	return {
		type: UPLOAD_VIDEO_SUCCESS,
		payload,
	};
}

export function uploadVideoError(error) {
	return {
		type: UPLOAD_VIDEO_ERROR,
		error,
	};
}

export const DELETE_VIDEO = "DELETE_VIDEO";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const DELETE_VIDEO_ERROR = "DELETE_VIDEO_ERROR";

export function deleteVideo(payload) {
	return {
		type: DELETE_VIDEO,
		payload,
	};
}

export function deleteVideoSuccess(payload) {
	return {
		type: DELETE_VIDEO_SUCCESS,
		payload,
	};
}

export function deleteVideoError(error) {
	return {
		type: DELETE_VIDEO_ERROR,
		error,
	};
}

export const GET_DASHBOARD_VIDEOS = "GET_DASHBOARD_VIDEOS";
export const GET_DASHBOARD_VIDEOS_SUCCESS = "GET_DASHBOARD_VIDEOS_SUCCESS";
export const GET_DASHBOARD_VIDEOS_ERROR = "GET_DASHBOARD_VIDEOS_ERROR";

export function getDashboardVideos(payload) {
	return {
		type: GET_DASHBOARD_VIDEOS,
		payload,
	};
}

export function getDashboardVideosSuccess(payload) {
	return {
		type: GET_DASHBOARD_VIDEOS_SUCCESS,
		payload,
	};
}

export function getDashboardVideosError(error) {
	return {
		type: GET_DASHBOARD_VIDEOS_ERROR,
		error,
	};
}

export const SET_DASHBOARD_VIDEO_UPLOAD_PROGRESS =
	"SET_DASHBOARD_VIDEO_UPLOAD_PROGRESS";

export const setDashboardVideoUploadProgress = (progress) => ({
	type: SET_DASHBOARD_VIDEO_UPLOAD_PROGRESS,
	payload: progress,
});

export const UPLOAD_EMBEDED_VIDEO_REQUEST = "UPLOAD_EMBEDED_VIDEO_REQUEST";
export const UPLOAD_EMBEDED_VIDEO_SUCCESS = "UPLOAD_EMBEDED_VIDEO_SUCCESS";
export const UPLOAD_EMBEDED_VIDEO_ERROR = "UPLOAD_EMBEDED_VIDEO_ERROR";

export const uploadEmbededVideoRequest = (payload) => ({
	type: UPLOAD_EMBEDED_VIDEO_REQUEST,
	payload,
});

export const uploadEmbededVideoSuccess = (payload) => ({
	type: UPLOAD_EMBEDED_VIDEO_SUCCESS,
	payload,
});

export const uploadEmbededVideoError = (error) => ({
	type: UPLOAD_EMBEDED_VIDEO_ERROR,
	error,
});

export const CANCEL_VIDEO_UPLOAD = "CANCEL_VIDEO_UPLOAD";

export const cancelVideoUpload = () => ({
	type: CANCEL_VIDEO_UPLOAD,
});


export const TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE = "TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE";
export const TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE_SUCCESS = "TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE_SUCCESS";
export const TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE_ERROR = "TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE_ERROR";

export function toggleVisibilityOnHomePage(payload){
	return{
		type:TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE,
		payload
	}
}

export function toggleVisibilityOnHomePageSuccess(payload){
	return{
		type:TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE_SUCCESS,
		payload
	}
}

export function toggleVisibilityOnHomePageError(error){
	return{
		type:TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE_ERROR,
		error
	}
}