export const GET_HOME_VIDEOS = "GET_HOME_VIDEOS";
export const GET_HOME_VIDEOS_SUCCESS="GET_HOME_VIDEOS_SUCCESS";
export const GET_HOME_VIDEOS_ERROR = "GET_HOME_VIDEOS_ERROR";

export function getHomeVideos(payload){
    return{
        type:GET_HOME_VIDEOS,
        payload
    }
}

export function getHomeVideosSuccess(payload){
    return{
        type:GET_HOME_VIDEOS_SUCCESS,
        payload
    }
}

export function getHomeVideosError(error){
    return{
        type:GET_HOME_VIDEOS_ERROR,
        error
    }
}