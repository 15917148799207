import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import rootReducer from "../reducers/root.reducer";
import rootSaga from "../sagas/root.saga";
import { routerMiddleware } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import persistConfig from "./persistConfig";

export const history = createBrowserHistory();

export default function (initialState = {}) {
	const sagaMiddleware = createSagaMiddleware();
	const middlewares = [sagaMiddleware, routerMiddleware(history)];
	const persistedReducer = persistReducer(
		persistConfig,
		rootReducer(history)
	);

	const store = createStore(
		persistedReducer,
		initialState,
		compose(applyMiddleware(...middlewares))
	);

	let persistor = persistStore(store);

	sagaMiddleware.run(rootSaga);

	return { store, persistor };
}
