import {
    GET_HOME_VIDEOS,
    GET_HOME_VIDEOS_ERROR,
    GET_HOME_VIDEOS_SUCCESS
} from './actions';

const initialHomeVideosState = {
    error: null,
    videos: [],
    loading: false,
    loaded: false
};

function homeVideosReducer(state = initialHomeVideosState, action) {
    switch (action.type) {
        case GET_HOME_VIDEOS:
            return { ...state, loading: true, loaded: false };
        case GET_HOME_VIDEOS_SUCCESS:            
            return { ...state, loading: false, loaded: true, videos: action.payload };
        case GET_HOME_VIDEOS_ERROR:
            return { ...state, videos:[], loaded: false, loading: false, error: action.error };       
        default:
            return state;
    }
}

export default homeVideosReducer;