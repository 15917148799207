import React from "react";
import { Link } from "react-router-dom";

const HomeArticlesList = ({ posts }) => {
	const firstPost = posts.find((x) => x.rowPosition === 1) || {};
	const secondPost = posts.find((x) => x.rowPosition === 2) || {};
	const thirdPost = posts.find((x) => x.rowPosition === 3) || {};

	return (
		<section className="articles">
			<div className="container">
				<div className="articles__list / row">
					<div className="articles__item  / col-lg-5">
						<div
							className="articles__inner"
							style={{
								backgroundImage:
									"url(" + firstPost.titlePictureUrl + ")",
							}}>
							<h2 className="artilces__name">
								{firstPost.heading}
							</h2>

							<Link
								className="link  link_theme_white"
								to={`/posts/${firstPost.id}`}>
								Read more &gt;
							</Link>
						</div>
					</div>

					<div className="articles__item  col-md-6 col-lg-3">
						<div
							className="articles__inner"
							style={{
								backgroundImage:
									"url(" + secondPost.titlePictureUrl + ")",
							}}>
							<h2 className="artilces__name">
								{secondPost.heading}
							</h2>

							<Link
								className="link  link_theme_white"
								to={`/posts/${secondPost.id}`}>
								Read more &gt;
							</Link>
						</div>
					</div>

					<div className="articles__item / col-md-6 col-lg-4">
						<div
							className="articles__inner"
							style={{
								backgroundImage:
									"url(" + thirdPost.titlePictureUrl + ")",
							}}>
							<h2 className="artilces__name">
								{thirdPost.heading}
							</h2>

							<Link
								className="link  link_theme_white"
								to={`/posts/${thirdPost.id}`}>
								Read more &gt;
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HomeArticlesList;
