import {
    CREATE_EVENT,
    createEventSuccess,
    createEventError,
    SEARCH_SERVICE_TYPES,
    searchServiceTypesError,
    searchServiceTypesSuccess,
    SEARCH_GROUPS,
    searchGroupsError,
    searchGroupsSuccess,
    updateEventError,
    updateEventSuccess,
    getDashboardEventsError,
    getDashboardEventsSuccess,
    deleteEventError,
    deleteEventSuccess,
    getDashboardEvents,
    GET_DASHBOARD_EVENTS,
    DELETE_EVENT,
    UPDATE_EVENT,
    searchTemplatesSuccess,
    searchTemplatesError,
    SEARCH_TEMPLATES
} from "./actions";

import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../../../common/api/api.base";
import { toast } from "react-toastify";
import pageSizes from "../../../../../common/constants/pageSizes";
import { getEventTemplates } from "../tabs/EventTemplates/store/actions";

function* workerCreateEvent(action) {
    try {
        const {eventsQuery, templatesQuery, body} = action.payload;
        const result = yield call(ApiBase.post, '/api/events', body);
        if (!result.error) {
            yield put(createEventSuccess(result));
            toast.success("Event successfully created");

            yield put(getDashboardEvents({
                query:eventsQuery, 
                skip: 0, 
                take: pageSizes.DASHBOARD_EVENTS_TAKE 
            }));

            if(body.saveAsTemplate){
                yield put(getEventTemplates({
                    query:templatesQuery, 
                    skip: 0, 
                    take: pageSizes.EVENT_TEMPLATES_TAKE
                }));
            }
        }
        else {
            yield put(createEventError(result.error));
        }
    }
    catch (e) {
        yield put(createEventError(e.message));
    }
}

function* workerUpdateEvent(action) {
    try {
        const { eventsQuery, data } = action.payload;
        const result = yield call(ApiBase.put, '/api/events/' + data.id, data.body);
        if (!result.error) {
            yield put(updateEventSuccess(result));
            yield put(getDashboardEvents({ 
                query: eventsQuery,
                skip: 0, 
                take: pageSizes.DASHBOARD_EVENTS_TAKE }));
        }
        else {
            yield put(updateEventError(result.error));
        }
    }
    catch (e) {
        yield put(updateEventError(e.message));
    }
}

function* workerGetDashboardEvents(action) {
    try {
        const result = yield call(ApiBase.get, '/api/events/detailed', action.payload);
        if (!result.error) {
            yield put(getDashboardEventsSuccess(result));
        }
        else {
            yield put(getDashboardEventsError(result.error));
        }
    }
    catch (e) {
        yield put(getDashboardEventsError(e.message));
    }
}

function* workerDeleteEvent(action) {
    try {
        const {id, eventsQuery} = action.payload;
        const result = yield call(ApiBase.delete, '/api/events/' + id);
        if (!result.error) {
            yield put(deleteEventSuccess(result));
            yield put(getDashboardEvents({
                query: eventsQuery,
                skip: 0, 
                take: pageSizes.DASHBOARD_EVENTS_TAKE
            }));
        }
        else {
            yield put(deleteEventError(result.error));
        }
    }
    catch (e) {
        yield put(deleteEventError(e.message));
    }
}

function* workerSearchGroups(action) {
    try {
        const result = yield call(ApiBase.get, '/api/groups', action.payload);
        if (!result.error) {
            yield put(searchGroupsSuccess(result));
        }
        else {
            yield put(searchGroupsError(result.error));
        }
    }
    catch (e) {
        yield put(searchGroupsError(e.message));
    }
}

function* workerSearchServiceTypes(action) {
    try {
        const result = yield call(ApiBase.get, '/api/service-types', action.payload);
        if (!result.error) {
            yield put(searchServiceTypesSuccess(result));
        }
        else {
            yield put(searchServiceTypesError(result.error));
        }
    }
    catch (e) {
        yield put(searchServiceTypesError(e.message));
    }
}

function* workerSearchTemplates(action) {
    try {
        const result = yield call(ApiBase.get, '/api/event-templates', action.payload);
        if (!result.error) {
            yield put(searchTemplatesSuccess(result));
        }
        else {
            yield put(searchTemplatesError(result.error));
        }
    }
    catch (e) {
        yield put(searchTemplatesError(e.message));
    }
}

export default function* watchDashboardEventsSaga() {
    yield takeEvery(CREATE_EVENT, workerCreateEvent);
    yield takeEvery(SEARCH_GROUPS, workerSearchGroups);
    yield takeEvery(SEARCH_SERVICE_TYPES, workerSearchServiceTypes);
    yield takeEvery(GET_DASHBOARD_EVENTS, workerGetDashboardEvents);
    yield takeEvery(DELETE_EVENT, workerDeleteEvent);
    yield takeEvery(UPDATE_EVENT, workerUpdateEvent);
    yield takeEvery(SEARCH_TEMPLATES, workerSearchTemplates);
}