import {
	CREATE_GIVING_TYPE,
	CREATE_GIVING_TYPE_ERROR,
	CREATE_GIVING_TYPE_SUCCESS,
	UPDATE_GIVING_TYPE,
	UPDATE_GIVING_TYPE_SUCCESS,
	UPDATE_GIVING_TYPE_ERROR,
	DELETE_GIVING_TYPE,
	DELETE_GIVING_TYPE_SUCCESS,
	DELETE_GIVING_TYPE_ERROR,
	GET_GIVING_TYPES,
	GET_GIVING_TYPES_SUCCESS,
	GET_GIVING_TYPES_ERROR,
	GET_GIVINGS_HISTORY,
	GET_GIVINGS_HISTORY_ERROR,
	GET_GIVINGS_HISTORY_SUCCESS,
	SEARCH_FOR_USERS,
	SEARCH_FOR_USERS_SUCCESS,
	SEARCH_FOR_USERS_ERROR,
	CREATE_OFFLINE_GIVING,
	CREATE_OFFLINE_GIVING_SUCCESS,
	CREATE_OFFLINE_GIVING_ERROR,
	DELETE_OFFLINE_GIVING,
	DELETE_OFFLINE_GIVING_SUCCESS,
	DELETE_OFFLINE_GIVING_ERROR,
} from "./actions";

const initialGivingsState = {
	error: null,
	loading: false,
	actionLoading:false,
	loaded: true,
	givingTypes: {
		count: 0,
		entities: [],
	},
	givings: {
		entities: [],
		count: 0,
	},
	userSearch: {
		options: [],
		loading: false,
		error: null,
	},
	offlineGiving: {
		loading: false,
		error: null,
		loaded: false
	},
	deleteOfflineGiving: {
		loading: false,
		error: null,
		loaded: false
	}
};

function dashboardGivingsReducer(state = initialGivingsState, action) {
	switch (action.type) {
		case CREATE_GIVING_TYPE:
			return { ...state, actionLoading: true, loaded: false, error: null };
		case CREATE_GIVING_TYPE_SUCCESS:
			return { ...state, actionLoading: false, loaded: true, error: null };
		case CREATE_GIVING_TYPE_ERROR:
			return {
				...state,
				actionLoading: false,
				loaded: false,
				error: action.error,
			};
		case UPDATE_GIVING_TYPE:
			return { ...state, actionLoading: true, loaded: false, error: null };
		case UPDATE_GIVING_TYPE_SUCCESS:
			return {
				...state,
				actionLoading: false,
				loaded: true,
				error: null,
				givingTypes: updateGivingTypeInList(state, action.payload),
			};
		case UPDATE_GIVING_TYPE_ERROR:
			return {
				...state,
				actionLoading: false,
				loaded: false,
				error: action.error,
			};
		case DELETE_GIVING_TYPE:
			return { ...state, actionLoading: true, loaded: false, error: null };
		case DELETE_GIVING_TYPE_SUCCESS:
			return { ...state, actionLoading: false, loaded: true, error: null };
		case DELETE_GIVING_TYPE_ERROR:
			return {
				...state,
				actionLoading: false,
				loaded: false,
				error: action.error,
			};
		case GET_GIVING_TYPES:
			return { ...state, loading: true, loaded: false, error: null };
		case GET_GIVING_TYPES_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				givingTypes: action.payload,
			};
		case GET_GIVING_TYPES_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
				givingTypes: { count: 0, entities: [] },
			};
		case GET_GIVINGS_HISTORY:
			return { ...state, loading: true, loaded: false, error: null };
		case GET_GIVINGS_HISTORY_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				givings: action.payload,
			};
		case GET_GIVINGS_HISTORY_ERROR:
			return {
				...state,
				loading: false,
				loaded: true,
				error: action.error,
			};
		case SEARCH_FOR_USERS:
			return {
				...state,
				userSearch: {
					...state.userSearch,
					loading: true,
					error: null,
				},
			};
		case SEARCH_FOR_USERS_SUCCESS:
			return {
				...state,
				userSearch: {
					...state.userSearch,
					loading: false,
					error: null,
					options: action.payload,
				},
			};
		case SEARCH_FOR_USERS_ERROR:
			return {
				...state,
				userSearch: {
					...state.userSearch,
					loading: false,
					error: action.payload,
				},
			};
		case CREATE_OFFLINE_GIVING:
			return {
				...state,
				offlineGiving: {
					loading: true, loaded: false, error: null
				}
			};
		case CREATE_OFFLINE_GIVING_SUCCESS:
			return {
				...state,
				offlineGiving: {
					loading: false, loaded: true, error: null
				}
			};
		case CREATE_OFFLINE_GIVING_ERROR:
			return {
				...state,
				offlineGiving: {
					loading: false, loaded: false, error: action.error
				}
			};
		case DELETE_OFFLINE_GIVING:
			return {
				...state,
				deleteOfflineGiving: {
					loading: true, loaded: false, error: null
				}
			};
		case DELETE_OFFLINE_GIVING_SUCCESS:
			return {
				...state,
				deleteOfflineGiving: {
					loading: false, loaded: true, error: null
				}
			};
		case DELETE_OFFLINE_GIVING_ERROR:
			return {
				...state,
				deleteOfflineGiving: {
					loading: false, loaded: false, error: action.error
				}
			};
		default:
			return state;
	}
}

function updateGivingTypeInList(state, payload) {
	const { entities, count } = state.givingTypes;

	const givingTypeIdx = entities.findIndex((g) => g.id === payload.id);

	return {
		count,
		entities: [
			...entities.slice(0, givingTypeIdx),
			payload,
			...entities.slice(givingTypeIdx + 1),
		],
	};
}

export default dashboardGivingsReducer;
