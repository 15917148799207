export const RUN_EVENTS_PDF_REPORT = "RUN_EVENTS_PDF_REPORT";
export const RUN_EVENTS_PDF_REPORT_SUCCESS = "RUN_EVENTS_PDF_REPORT_SUCCESS";
export const RUN_EVENTS_PDF_REPORT_ERROR = "RUN_EVENTS_PDF_REPORT_ERROR";

export function runEventsPdfReport(payload){
    return{
        type:RUN_EVENTS_PDF_REPORT,
        payload
    }
}

export function runEventsPdfReportSuccess(payload){
    return{
        type:RUN_EVENTS_PDF_REPORT_SUCCESS,
        payload
    }
}

export function runEventsPdfReportError(error){
    return{
        type:RUN_EVENTS_PDF_REPORT_ERROR,
        error
    }
}

export const RUN_EVENTS_HTML_REPORT = "RUN_EVENTS_HTML_REPORT";
export const RUN_EVENTS_HTML_REPORT_SUCCESS = "RUN_EVENTS_HTML_REPORT_SUCCESS";
export const RUN_EVENTS_HTML_REPORT_ERROR = "RUN_EVENTS_HTML_REPORT_ERROR";

export function runEventsHtmlReport(payload){
    return{
        type:RUN_EVENTS_HTML_REPORT,
        payload
    }
}

export function runEventsHtmlReportSuccess(payload){
    return{
        type:RUN_EVENTS_HTML_REPORT_SUCCESS,
        payload
    }
}

export function runEventsHtmlReportError(error){
    return{
        type:RUN_EVENTS_HTML_REPORT_ERROR,
        error
    }
}

export const GET_GIVINGS_REPORT_DETAILS = "GET_GIVINGS_REPORT_DETAILS";
export const GET_GIVINGS_REPORT_DETAILS_SUCCESS = "GET_GIVINGS_REPORT_DETAILS_SUCCESS";
export const GET_GIVINGS_REPORT_DETAILS_ERROR = "GET_GIVINGS_REPORT_DETAILS_ERROR";

export function getGivingsReportDetails(payload){
    return{
        type:GET_GIVINGS_REPORT_DETAILS,
        payload
    }
}

export function getGivingsReportDetailsSuccess(payload){
    return{
        type:GET_GIVINGS_REPORT_DETAILS_SUCCESS,
        payload
    }
}

export function getGivingsReportDetailsError(error){
    return{
        type:GET_GIVINGS_REPORT_DETAILS_ERROR,
        error
    }
}

export const CLEAR_GIVINGS_SEARCH_RESULT = "CLEAR_GIVINGS_SEARCH_RESULT";

export function clearGivingsSearchResult(){
    return{
        type:CLEAR_GIVINGS_SEARCH_RESULT
    }
}

export const RUN_GIVINGS_PDF_REPORT = "RUN_GIVINGS_PDF_REPORT";
export const RUN_GIVINGS_PDF_REPORT_SUCCESS = "RUN_GIVINGS_PDF_REPORT_SUCCESS";
export const RUN_GIVINGS_PDF_REPORT_ERROR = "RUN_GIVINGS_PDF_REPORT_ERROR";

export function runGivingsPdfReport(payload){
    return{
        type:RUN_GIVINGS_PDF_REPORT,
        payload
    }
}

export function runGivingsPdfReportSuccess(payload){
    return{
        type:RUN_GIVINGS_PDF_REPORT_SUCCESS,
        payload
    }
}

export function runGivingsPdfReportError(error){
    return{
        type:RUN_GIVINGS_PDF_REPORT_ERROR,
        error
    }
}

export const RUN_GIVINGS_HTML_REPORT = "RUN_GIVINGS_HTML_REPORT";
export const RUN_GIVINGS_HTML_REPORT_SUCCESS = "RUN_GIVINGS_HTML_REPORT_SUCCESS";
export const RUN_GIVINGS_HTML_REPORT_ERROR = "RUN_GIVINGS_HTML_REPORT_ERROR";

export function runGivingsHtmlReport(payload){
    return{
        type:RUN_GIVINGS_HTML_REPORT,
        payload
    }
}

export function runGivingsHtmlReportSuccess(payload){
    return{
        type:RUN_GIVINGS_HTML_REPORT_SUCCESS,
        payload
    }
}

export function runGivingsHtmlReportError(error){
    return{
        type:RUN_GIVINGS_HTML_REPORT_ERROR,
        error
    }
}

export const SEND_TAX_RECEIPT = "SEND_TAX_RECEIPT";
export const SEND_TAX_RECEIPT_SUCCESS = "SEND_TAX_RECEIPT_SUCCESS";
export const SEND_TAX_RECEIPT_ERROR = "SEND_TAX_RECEIPT_ERROR";

export function sendTaxReceipt(payload){
    return{
        type:SEND_TAX_RECEIPT,
        payload
    }
}

export function sendTaxReceiptSuccess(payload){
    return{
        type:SEND_TAX_RECEIPT_SUCCESS,
        payload
    }
}

export function sendTaxReceiptError(error){
    return{
        type:SEND_TAX_RECEIPT_ERROR,
        error
    }
}