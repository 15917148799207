export const GET_POSTS = "GET_POSTS";
export const GET_POSTS_SUCCESS="GET_POSTS_SUCCESS";
export const GET_POSTS_ERROR = "GET_POSTS_ERROR";

export function getPosts(payload){
    return{
        type:GET_POSTS,
        payload
    }
}

export function getPostsSuccess(payload){
    return{
        type:GET_POSTS_SUCCESS,
        payload
    }
}

export function getPostsError(error){
    return{
        type:GET_POSTS_ERROR,
        error
    }
}