export const SEARCH_FOR_MESSAGES = "SEARCH_FOR_MESSAGES";
export const SEARCH_FOR_MESSAGES_SUCCESS = "SEARCH_FOR_MESSAGES_SUCCESS";
export const SEARCH_FOR_MESSAGES_ERROR = "SEARCH_FOR_MESSAGES_ERROR";

export const searchForMessages = (query) => ({
	type: SEARCH_FOR_MESSAGES,
	payload: query,
});

export const searchForMessagesSuccess = (data) => ({
	type: SEARCH_FOR_MESSAGES_SUCCESS,
	payload: data,
});

export const searchForMessagesError = (error) => ({
	type: SEARCH_FOR_MESSAGES_ERROR,
	payload: error,
});
