import {
    GET_USER_GIVINGS_HISTORY,
    GET_USER_GIVINGS_HISTORY_SUCCESS,
    GET_USER_GIVINGS_HISTORY_ERROR,
    REFUND,
    REFUND_SUCCESS,
    REFUND_ERROR
} from './actions';

const initialUserGivingsState = {
    loading: false,
    loaded: false,
    givings: {
        entities: [],
        count: 0
    },
    error: null,
    refund: {
        loading: false,
        loaded: false,
        error: null
    }
};

function userGivingsReducer(state = initialUserGivingsState, action) {
    switch (action.type) {
        case GET_USER_GIVINGS_HISTORY:
            return { ...state, loading: true, loaded: false, error: null };
        case GET_USER_GIVINGS_HISTORY_SUCCESS:
            return { ...state, loading: false, loaded: true, givings: action.payload };
        case GET_USER_GIVINGS_HISTORY_ERROR:
            return { ...state, loading: false, loaded: true, error: action.error };
        case REFUND:
            return {
                ...state, refund: {
                    loading: true, loaded: false, error: null
                }
            };
        case REFUND_SUCCESS:
            return {
                ...state, givings: updateGivingInList(state, action.payload),
                refund: {
                    ...state.refund,
                    loading: false,
                    loaded: true
                }
            };
        case REFUND_ERROR:
            return {
                ...state, refund: {
                    loading: false, loaded: true, error: action.error
                }
            };
        default:
            return state;
    }
}

function updateGivingInList(state, payload) {
    const { entities, count } = state.givings;

    const givingIdx = entities.findIndex(g => g.id === payload);
    const giving = entities[givingIdx];
    giving.isRefunded = true;

    return {
        count,
        entities: [
            ...entities.slice(0, givingIdx),
            giving,
            ...entities.slice(givingIdx + 1),
        ],
    };
}

export default userGivingsReducer;