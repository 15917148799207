import {
	GET_DASHBOARD_REQUESTS,
	GET_DASHBOARD_REQUESTS_ERROR,
	GET_DASHBOARD_REQUESTS_SUCCESS,
	RESET_DASHBOARD_REQUESTS,
	RESET_DASHBOARD_NEW_MESSAGES_COUNTER,
	MOVE_DASHBOARD_REQUEST_TO_TOP,
	SEARCH_ADMIN_PRAYER_REQUESTS,
	SEARCH_ADMIN_PRAYER_REQUESTS_SUCCESS,
	SEARCH_ADMIN_PRAYER_REQUESTS_ERROR,
	GET_DASHBOARD_REQUESTS_STEALTH,
	GET_DASHBOARD_REQUESTS_SUCCESS_STEALTH,
	GET_DASHBOARD_REQUESTS_ERROR_STEALTH,
} from "./actions";

import { SET_LAST_MESSAGE_EMITTER } from "../../../../../common/Messages/store/actions";
import {
	concatRequests,
	resetMessagesCounter,
	moveToTop,
	replaceCreator,
} from "../../../../../common/helpers/prayer.requests.reducer.utils";

const initialDashboardRequestsState = {
	error: null,
	requests: {
		entities: [],
		hasMore: false,
	},
	loading: false,
	loaded: false,
	searchLoading: false,
	page: 0,
};

function dashboardRequestsReducer(
	state = initialDashboardRequestsState,
	action
) {
	switch (action.type) {
		case RESET_DASHBOARD_REQUESTS:
			return { ...initialDashboardRequestsState, loading: true };
		case GET_DASHBOARD_REQUESTS:
			return { ...state, loading: true, loaded: false };
		case GET_DASHBOARD_REQUESTS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				requests: concatRequests(state, action.payload),
				page: state.page + 1,
			};
		case GET_DASHBOARD_REQUESTS_ERROR:
			return {
				...state,
				requests: { hasMore: false, entities: [] },
				loaded: false,
				loading: false,
				error: action.error,
			};
		case RESET_DASHBOARD_NEW_MESSAGES_COUNTER:
			return {
				...state,
				requests: resetMessagesCounter(state, action.payload),
			};
		case MOVE_DASHBOARD_REQUEST_TO_TOP:
			return { ...state, requests: moveToTop(state, action.payload) };
		case SET_LAST_MESSAGE_EMITTER:
			return {
				...state,
				requests: {
					...state.requests,
					entities: replaceCreator(state, action.payload),
				},
			};
		case SEARCH_ADMIN_PRAYER_REQUESTS:
			return {
				...state,
				searchLoading: true,
			};
		case SEARCH_ADMIN_PRAYER_REQUESTS_SUCCESS:
			return {
				...state,
				searchLoading: false,
				requests: { ...action.payload },
			};
		case SEARCH_ADMIN_PRAYER_REQUESTS_ERROR:
			return {
				...state,
				searchLoading: false,
				error: action.payload,
			};
		case GET_DASHBOARD_REQUESTS_SUCCESS_STEALTH:
			return {
				...state,
				requests: action.payload,
				// requests: concatRequests(state, action.payload),
				page: 1,
			};
		case GET_DASHBOARD_REQUESTS_ERROR_STEALTH:
			return {
				...state,
				requests: { hasMore: false, entities: [] },
				error: action.error,
			};
		default:
			return state;
	}
}

export default dashboardRequestsReducer;
