import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../api/api.base";
import {
	SEARCH_FOR_MESSAGES,
	searchForMessagesSuccess,
	searchForMessagesError,
} from "./actions";

function* workerSearchMessages(action) {
	console.log(action, "workerSearchMessages");
}

export default function* watchMessagesSearchSaga() {
	yield takeEvery(SEARCH_FOR_MESSAGES, workerSearchMessages);
}
