import React from "react";
import FullLayout from "./pages/App.container";
import { ToastContainer } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const promise = loadStripe("pk_live_51HOi8NGjjbePflpA0MdcCQfgX1MEhHOAOloIhLuvzN4PWfhTAo4cVaujeSK9nftm5QPMw5vi7mOYlM9XV1P7Ntvw00qKNX5ksa");

export default function App() {
	return (
		<>
			<Elements stripe={promise}>
				<FullLayout />
			</Elements>
			<ToastContainer autoClose={2000} />
		</>
	);
}
