import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import authReducer from "./auth.reducer";
import paymentsReducer from "./payments.reducer";

import registrationReducer from "../../modals/SignUpModal/store/reducer";
import dashboardVideosReducer from "../../pages/Dashboard/pages/DashboardVideoSettings/store/reducer";
import homeVideosReducer from "../../pages/Home/modules/HomeVideos/store/reducer";
import videosReducer from "../../pages/Videos/store/reducer";
import moreVideosReducer from "../../pages/Video/modules/VideoMoreVideos/store/reducer";
import videoReducer from "../../pages/Video/store/reducer";
import videoCommentsReducer from "../../pages/Video/modules/VideoCommentsSection/store/reducer";
import dashboardCommentsReducer from "../../pages/Dashboard/pages/DashboardComments/store/reducer";
import dashboardPostsReducer from "../../pages/Dashboard/pages/DashboardPosts/store/reducer";
import postsReducer from "../../pages/Posts/store/reducer";
import postReducer from "../../pages/Post/store/reducer";
import morePostsReducer from "../../pages/Post/modules/PostMorePosts/store/reducer";
import homePostsReducer from "../../pages/Home/modules/HomePosts/store/reducer";
import requestsReducer from "../../modals/RequestModal/store/reducer";
import dashboardRequestsReducer from "../../pages/Dashboard/pages/DashboardPrayerRequests/store/reducer";
import messagesReducer from "../../common/Messages/store/reducer";
import accountRequestsReducer from "../../pages/Account/pages/AccountRequests/store/reducer";
import homeHighlightedPostsReducer from "../../pages/Home/modules/HomeArticles/store/reducer";
import dashboardGivingsReducer from "../../pages/Dashboard/pages/DashboardGivings/store/reducer";
import accountUserInfoReducer from "../../pages/Account/pages/AccountSettings/store/reducer";
import searchReducer from "../../common/blocks/SearchBar/store/reducer";
import givingModalsReducer from "../../pages/Giving/store/modals/reducer";
import givingsReducer from "../../pages/Giving/store/reducer";
import directoryReducer from "./directories/directories.reducer";
import paymentSettingsRecuder from "../../pages/Account/pages/AccountPaymentSettings/store/reducer";
import userGivingsReducer from "../../pages/Account/pages/AccountGivingsHistory/store/reducer";
import messagesSearchReducer from "../../common/blocks/RequestBlockToolbar/store/reducer";
import dashboardMembersReducer from "../../pages/Dashboard/pages/DashboardMembers/store/reducer";
import eventsReducer from "../../pages/Events/store/reducer";
import dashboardRolesReducer from "../../pages/Dashboard/pages/DashboardRoles/store/reducer";
import usersReducer from "./users.reducer";
import dashboardsStatsRecuder from "../../pages/Dashboard/pages/Charts/store/reducer";
import createEventReducer from "../../pages/Dashboard/pages/CreateEvent/store/reducer";
import dashboardServiceTypesReducer from "../../pages/Dashboard/pages/DashboardPlans/store/reducer";
import dashboardPlanReducer from "../../pages/Dashboard/pages/DashboardPlanPage/store/reducer";
import dashboardGroupTypesReducer from "../../pages/Dashboard/pages/DashboardGroupTypes/store/reducer";
import songsReducer from "../../modals/AddSongModal/store/reducer";
import dashboardEventsReducer from "../../pages/Dashboard/pages/DashboardEvents/store/reducer";
import eventTemplatesReducer from "../../pages/Dashboard/pages/DashboardEvents/tabs/EventTemplates/store/reducer";
import dashboardReportsReducer from "../../pages/Dashboard/pages/DashboardReports/store/reducer";

const reducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		auth: authReducer,
		registration: registrationReducer,
		dashboardVideos: dashboardVideosReducer,
		dashboardComments: dashboardCommentsReducer,
		dashboardPosts: dashboardPostsReducer,
		dashboardRequests: dashboardRequestsReducer,
		dashboardGivings: dashboardGivingsReducer,
		dashboardMembers: dashboardMembersReducer,
		dashboardRoles: dashboardRolesReducer,
		dashboardPlan: dashboardPlanReducer,
		dashboardStats: dashboardsStatsRecuder,
		dashboardServiceTypes: dashboardServiceTypesReducer,
		dashboardGroupTypes: dashboardGroupTypesReducer,
		accountRequests: accountRequestsReducer,
		accountUserInfo: accountUserInfoReducer,
		homeVideos: homeVideosReducer,
		videos: videosReducer,
		moreVideos: moreVideosReducer,
		video: videoReducer,
		videoComments: videoCommentsReducer,
		posts: postsReducer,
		post: postReducer,
		morePosts: morePostsReducer,
		homePosts: homePostsReducer,
		requests: requestsReducer,
		messages: messagesReducer,
		homeHighlightedPosts: homeHighlightedPostsReducer,
		search: searchReducer,
		givingModals: givingModalsReducer,
		givings: givingsReducer,
		payments: paymentsReducer,
		directories: directoryReducer,
		paymentSettings: paymentSettingsRecuder,
		userGivings: userGivingsReducer,
		messagesSearch: messagesSearchReducer,
		events: eventsReducer,
		users: usersReducer,
		songs: songsReducer,
		dashboardEvents: dashboardEventsReducer,
		eventTemplates: eventTemplatesReducer,
		dashboardReports: dashboardReportsReducer,
	});

export default reducer;
