import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../../../../../common/api/api.base";
import pageSizes from "../../../../../../../common/constants/pageSizes";

import {
    GET_EVENT_TEMPLATES,
    getEventTemplatesSuccess,
    getEventTemplatesError,
    DELETE_EVENT_TEMPLATE,
    deleteEventTemplateSuccess,
    deleteEventTemplateError,
    getEventTemplates
} from './actions';

function* workerGetEventTemplates(action) {
    try {
        const result = yield call(ApiBase.get, '/api/event-templates', action.payload);
        if (!result.error) {
            yield put(getEventTemplatesSuccess(result));
        }
        else {
            yield put(getEventTemplatesError(result.error));
        }
    }
    catch (e) {
        yield put(getEventTemplatesError(e.message));
    }
}

function* workerDeleteEventTemplate(action) {
    try {
        const { id, query } = action.payload;
        const result = yield call(ApiBase.delete, '/api/event-templates/' + id);
        if (!result.error) {
            yield put(deleteEventTemplateSuccess(result));
            yield put(getEventTemplates({
                query,
                skip: 0,
                take: pageSizes.EVENT_TEMPLATES_TAKE
            }));
        }
        else {
            yield put(deleteEventTemplateError(result.error));
        }
    }
    catch (e) {
        yield put(deleteEventTemplateError(e.message));
    }
}

export default function* watchEventTemplatesSaga() {
    yield takeEvery(DELETE_EVENT_TEMPLATE, workerDeleteEventTemplate);
    yield takeEvery(GET_EVENT_TEMPLATES, workerGetEventTemplates);
}