import { useState, useEffect } from "react"

export const useScrollHandler = (scrollRange) => {
    const [scroll, setScroll] = useState(false);

    useEffect(() => {      
        const onScroll = () => {
            const scrollCheck = window.scrollY >= scrollRange
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck)
            }
        }

        document.addEventListener("scroll", onScroll)

        return () => {
            document.removeEventListener("scroll", onScroll)
        }
    }, [scroll, setScroll, scrollRange])

    return scroll

}