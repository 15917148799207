export const SEARCH_POSTS = "SEARCH_POSTS";
export const SEARCH_POSTS_SUCCESS="SEARCH_POSTS_SUCCESS";
export const SEARCH_POSTS_ERROR="SEARCH_POSTS_ERROR";

export function searchPosts(payload){
    return{
        type:SEARCH_POSTS,
        payload
    }
}

export function searchPostsSuccess(payload){
    return{
        type:SEARCH_POSTS_SUCCESS,
        payload
    }
}

export function searchPostsError(error){
    return{
        type:SEARCH_POSTS_ERROR,
        error
    }
}

export const SEARCH_VIDEOS = "SEARCH_VIDEOS";
export const SEARCH_VIDEOS_SUCCESS="SEARCH_VIDEOS_SUCCESS";
export const SEARCH_VIDEOS_ERROR="SEARCH_VIDEOS_ERROR";

export function searchVideos(payload){
    return{
        type:SEARCH_VIDEOS,
        payload
    }
}

export function searchVideosSuccess(payload){
    return{
        type:SEARCH_VIDEOS_SUCCESS,
        payload
    }
}

export function searchVideosError(error){
    return{
        type:SEARCH_VIDEOS_ERROR,
        error
    }
}

export const SEARCH_FILTER_CHANGED = "SEARCH_FILTER_CHANGED";

export function searchFilterChanged(payload={}){
    return {
        type:SEARCH_FILTER_CHANGED,
        payload
    }
}

