import { call, put, takeEvery, select } from "redux-saga/effects";

import {
	GET_SERVICE_TYPE_BY_ID,
	getServiceTypeByIdSuccess,
	getServiceTypeByIdError,
	CREATE_SERVICE_PLAN_ITEM,
	createServicePlanItemSuccess,
	createServicePlanItemError,
	UPDATE_SERVICE_PLAN_ITEM,
	updateServicePlanItemSuccess,
	updateServicePlanItemError,
	DELETE_SERVICE_PLAN_ITEM,
	deleteServicePlanItemSuccess,
	deleteServicePlanItemError,
	GET_SERVICE_PLAN_ITEM,
	getServicePlanItemSuccess,
	getServicePlanItemError,
	CREATE_SERVICE_TYPE_NOTE,
	createServiceTypeNoteSuccess,
	createServiceTypeNoteError,
	UPDATE_SERVICE_TYPE_NOTE,
	updateServiceTypeNoteSuccess,
	updateServiceTypeNoteError,
	DELETE_SERVICE_TYPE_NOTE,
	deleteServiceTypeNoteSuccess,
	deleteServiceTypeNoteError,
	CREATE_SERVICE_TYPE_ATTACHMENT,
	createServiceTypeAttachmentSuccess,
	createServiceTypeAttachmentError,
	UPDATE_SERVICE_TYPE_ATTACHMENT,
	updateServiceTypeAttachmentSuccess,
	updateServiceTypeAttachmentError,
	DELETE_SERVICE_TYPE_ATTACHMENT,
	deleteServiceTypeAttachmentSuccess,
	deleteServiceTypeAttachmentError,
	CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE,
	createServiceTypeAttachmentMultipleSuccess,
	createServiceTypeAttachmentMultipleError,
	printServicePlanPdfSuccess,
	printServicePlanPdfError,
	printServicePlanHtmlSuccess,
	printServicePlanHtmlError,
	PRINT_SERVICE_PLAN_PDF,
	PRINT_SERVICE_PLAN_HTML,
} from "./actions";
import { toast } from "react-toastify";

import ApiBase from "../../../../../common/api/api.base";
import fileDownload from "js-file-download";

function* workerGetServiceTypeById(action) {
	try {
		var result = yield call(
			ApiBase.get,
			"/api/service-types/" + action.payload
		);
		if (!result.error) {
			yield put(getServiceTypeByIdSuccess(result));
		} else {
			yield put(getServiceTypeByIdError(result.error));
		}
	} catch (e) {
		yield put(getServiceTypeByIdError(e.message));
	}
}

function* workerCreateServicePlanItem(action) {
	try {
		var result = yield call(
			ApiBase.post,
			"/api/service-types/plan-items",
			action.payload
		);
		if (!result.error) {
			const serviceTypeId = yield select(
				(state) => state.dashboardPlan.id
			);
			yield put(createServicePlanItemSuccess(result));
			yield workerGetServiceTypeById({ payload: serviceTypeId });
		} else {
			yield put(createServicePlanItemError(result.error));
			toast.error("Error while creating service plan item");
		}
	} catch (e) {
		yield put(createServicePlanItemError(e.message));
	}
}

function* workerUpdateServicePlanItem(action) {
	try {
		var result = yield call(
			ApiBase.put,
			"/api/service-types/plan-items/" + action.payload.id,
			action.payload.body
		);
		if (!result.error) {
			const serviceTypeId = yield select(
				(state) => state.dashboardPlan.id
			);
			yield put(updateServicePlanItemSuccess(result));
			yield workerGetServiceTypeById({ payload: serviceTypeId });
		} else {
			yield put(updateServicePlanItemError(result.error));
			toast.error("Error while updating service plan item");
		}
	} catch (e) {
		yield put(updateServicePlanItemError(e.message));
	}
}

function* workerDeleteServicePlanItem(action) {
	try {
		var result = yield call(
			ApiBase.delete,
			"/api/service-types/plan-items/" + action.payload
		);
		if (!result.error) {
			const serviceTypeId = yield select(
				(state) => state.dashboardPlan.id
			);
			yield put(deleteServicePlanItemSuccess(result));
			yield workerGetServiceTypeById({ payload: serviceTypeId });
		} else {
			yield put(deleteServicePlanItemError(result.error));
			toast.error("Error while deleting service plan item");
		}
	} catch (e) {
		yield put(deleteServicePlanItemError(e.message));
	}
}

function* workerGetServicePlanItem(action) {
	try {
		var result = yield call(
			ApiBase.get,
			"/api/service-types/plan-items/" + action.payload
		);
		if (!result.error) {
			yield put(getServicePlanItemSuccess(result));
		} else {
			yield put(getServicePlanItemError(result.error));
		}
	} catch (e) {
		yield put(getServicePlanItemError(e.message));
	}
}

function* workerCreateServiceTypeNote(action) {
	try {
		const result = yield call(
			ApiBase.post,
			"/api/service-types/notes",
			action.payload
		);
		if (!result.error) {
			yield put(createServiceTypeNoteSuccess(result));
		} else {
			yield put(createServiceTypeNoteError(result.error));
		}
	} catch (e) {
		yield put(createServiceTypeNoteError(e.message));
	}
}

function* workerUpdateServiceTypeNote(action) {
	try {
		const result = yield call(
			ApiBase.put,
			"/api/service-types/notes/" + action.payload.id,
			action.payload.body
		);
		if (!result.error) {
			yield put(updateServiceTypeNoteSuccess(result));
		} else {
			yield put(updateServiceTypeNoteError(result.error));
		}
	} catch (e) {
		yield put(updateServiceTypeNoteError(e.message));
	}
}

function* workerDeleteServiceTypeNote(action) {
	try {
		const result = yield call(
			ApiBase.delete,
			"/api/service-types/notes/" + action.payload
		);
		if (!result.error) {
			yield put(
				deleteServiceTypeNoteSuccess({ ...result, id: action.payload })
			);
		} else {
			yield put(deleteServiceTypeNoteError(result.error));
		}
	} catch (e) {
		yield put(deleteServiceTypeNoteError(e.message));
	}
}

function* workerCreateServiceTypeAttachment(action) {
	try {
		const result = yield call(
			ApiBase.post,
			"/api/service-types/attachments",
			action.payload
		);
		if (!result.error) {
			yield put(createServiceTypeAttachmentSuccess(result));
		} else {
			yield put(createServiceTypeAttachmentError(result.error));
		}
	} catch (e) {
		yield put(createServiceTypeAttachmentError(e.message));
	}
}

function* workerUpdateServiceTypeAttachment(action) {
	try {
		const result = yield call(
			ApiBase.put,
			"/api/service-types/attachments/" + action.payload.id,
			action.payload.body
		);
		if (!result.error) {
			yield put(updateServiceTypeAttachmentSuccess(result));
		} else {
			yield put(updateServiceTypeAttachmentError(result.error));
		}
	} catch (e) {
		yield put(updateServiceTypeAttachmentError(e.message));
	}
}

function* workerDeleteServiceTypeAttachment(action) {
	try {
		const result = yield call(
			ApiBase.delete,
			"/api/service-types/attachments/" + action.payload
		);
		if (!result.error) {
			yield put(
				deleteServiceTypeAttachmentSuccess({
					...result,
					id: action.payload,
				})
			);
		} else {
			yield put(deleteServiceTypeAttachmentError(result.error));
		}
	} catch (e) {
		yield put(deleteServiceTypeAttachmentError(e.message));
	}
}

function* workerCreateServiceTypeAttachmentMultiple(action) {
	try {
		// yield action.payload.forEach((elem) => {
		// 	call(ApiBase.file, "/api/files", elem);
		// });
		let backUrls = [];
		for (const i in action.payload.files) {
			if (action.payload.files.hasOwnProperty(i)) {
				const result = yield call(
					ApiBase.file,
					"/api/files",
					action.payload.files[i]
				);
				backUrls.push(result);
			}
		}
		if (backUrls.length === action.payload.files.length) {
			const attachmentsResult = yield call(
				ApiBase.post,
				"/api/service-types/attachments/multiple",
				{
					serviceTypeId: action.payload.serviceTypeId,
					attachments: backUrls.map((elem, index) => ({
						name: action.payload.files[index].name,
						url: elem,
						isUploadedFile: true,
					})),
				}
			);
			yield put(
				createServiceTypeAttachmentMultipleSuccess(attachmentsResult)
			);
		} else {
			yield put(
				createServiceTypeAttachmentMultipleError(
					"Error while uploading file"
				)
			);
			toast.error("Error while uploading file");
		}
	} catch (error) {
		toast.error("Error while uploading file");
		yield put(
			createServiceTypeAttachmentMultipleError(
				"Error while uploading file"
			)
		);
	}
}

function* workerPrintServicePlanPdf(action) {
	try {
		const result = yield call(
			ApiBase.downloadFile,
			"/api/service-types/print-pdf",
			action.payload.body
		);
		if (result.status === 200) {
			yield put(printServicePlanPdfSuccess(result.data));
			fileDownload(result.data, `${action.payload.name}.pdf`);
		} else {
			toast.error("Failed to print service plan");
			yield put(printServicePlanPdfError("Failed to print service plan"));
		}
	} catch (e) {
		yield put(printServicePlanPdfError(e.message));
	}
}

function* workerPrintServicePlanHtml(action) {
	try {
		const result = yield call(
			ApiBase.downloadFile,
			"/api/service-types/print-html",
			action.payload.body
		);
		if (result.status === 200) {
			yield put(printServicePlanHtmlSuccess(result.data));
			fileDownload(result.data, `${action.payload.name}.html`);
		} else {
			toast.error("Failed to print service plan");
			yield put(
				printServicePlanHtmlError("Failed to print service plan")
			);
		}
	} catch (e) {
		yield put(printServicePlanHtmlError(e.message));
	}
}

export function* watchDashboardServicePlanSaga() {
	yield takeEvery(GET_SERVICE_TYPE_BY_ID, workerGetServiceTypeById);
	yield takeEvery(CREATE_SERVICE_PLAN_ITEM, workerCreateServicePlanItem);
	yield takeEvery(UPDATE_SERVICE_PLAN_ITEM, workerUpdateServicePlanItem);
	yield takeEvery(DELETE_SERVICE_PLAN_ITEM, workerDeleteServicePlanItem);
	yield takeEvery(GET_SERVICE_PLAN_ITEM, workerGetServicePlanItem);
	yield takeEvery(CREATE_SERVICE_TYPE_NOTE, workerCreateServiceTypeNote);
	yield takeEvery(UPDATE_SERVICE_TYPE_NOTE, workerUpdateServiceTypeNote);
	yield takeEvery(DELETE_SERVICE_TYPE_NOTE, workerDeleteServiceTypeNote);
	yield takeEvery(
		CREATE_SERVICE_TYPE_ATTACHMENT,
		workerCreateServiceTypeAttachment
	);
	yield takeEvery(
		UPDATE_SERVICE_TYPE_ATTACHMENT,
		workerUpdateServiceTypeAttachment
	);
	yield takeEvery(
		DELETE_SERVICE_TYPE_ATTACHMENT,
		workerDeleteServiceTypeAttachment
	);
	yield takeEvery(
		CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE,
		workerCreateServiceTypeAttachmentMultiple
	);
	yield takeEvery(PRINT_SERVICE_PLAN_PDF, workerPrintServicePlanPdf);
	yield takeEvery(PRINT_SERVICE_PLAN_HTML, workerPrintServicePlanHtml);
}
