export const GET_MEMBERS_CHART_DATA = "GET_MEMBERS_CHART_DATA";
export const GET_MEMBERS_CHART_DATA_SUCCESS = "GET_MEMBERS_CHART_DATA_SUCCESS";
export const GET_MEMBERS_CHART_DATA_ERROR = "GET_MEMBERS_CHART_DATA_ERROR";

export function getMembersChartData(payload){
	return{
		type:GET_MEMBERS_CHART_DATA,
		payload
	}
}

export function getMembersChartDataSuccess(payload){
	return{
		type:GET_MEMBERS_CHART_DATA_SUCCESS,
		payload
	}
}

export function getMembersChartDataError(error){
	return{
		type:GET_MEMBERS_CHART_DATA_ERROR,
		error
	}
}

export const GET_GIVINGS_CHART_DATA = "GET_GIVINGS_CHART_DATA";
export const GET_GIVINGS_CHART_DATA_SUCCESS = "GET_GIVINGS_CHART_DATA_SUCCESS";
export const GET_GIVINGS_CHART_DATA_ERROR = "GET_GIVINGS_CHART_DATA_ERROR";

export function getGivingsChartData(payload){
	return{
		type:GET_GIVINGS_CHART_DATA,
		payload
	}
}

export function getGivingsChartDataSuccess(payload){
	return{
		type:GET_GIVINGS_CHART_DATA_SUCCESS,
		payload
	}
}

export function getGivingsChartDataError(error){
	return{
		type:GET_GIVINGS_CHART_DATA_ERROR,
		error
	}
}

export const GET_REQUESTS_CHART_DATA = "GET_REQUESTS_CHART_DATA";
export const GET_REQUESTS_CHART_DATA_SUCCESS = "GET_REQUESTS_CHART_DATA_SUCCESS";
export const GET_REQUESTS_CHART_DATA_ERROR = "GET_REQUESTS_CHART_DATA_ERROR";

export function getRequestsChartData(payload){
	return{
		type:GET_REQUESTS_CHART_DATA,
		payload
	}
}

export function getRequestsChartDataSuccess(payload){
	return{
		type:GET_REQUESTS_CHART_DATA_SUCCESS,
		payload
	}
}

export function getRequestsChartDataError(error){
	return{
		type:GET_REQUESTS_CHART_DATA_ERROR,
		error
	}
}

export const GET_COMMENTS_CHART_DATA = "GET_COMMENTS_CHART_DATA";
export const GET_COMMENTS_CHART_DATA_SUCCESS = "GET_COMMENTS_CHART_DATA_SUCCESS";
export const GET_COMMENTS_CHART_DATA_ERROR = "GET_COMMENTS_CHART_DATA_ERROR";

export function getCommentsChartData(payload){
	return{
		type:GET_COMMENTS_CHART_DATA,
		payload
	}
}

export function getCommentsChartDataSuccess(payload){
	return{
		type:GET_COMMENTS_CHART_DATA_SUCCESS,
		payload
	}
}

export function getCommentsChartDataError(error){
	return{
		type:GET_COMMENTS_CHART_DATA_ERROR,
		error
	}
}

export const RESET_ACTIVITIES = "RESET_ACTIVITIES";
export const GET_ACTIVITIES = "GET_ACTIVITIES";
export const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS";
export const GET_ACTIVITIES_ERROR = "GET_ACTIVITIES_ERROR";

export function resetActivities(){
	return{
		type:RESET_ACTIVITIES		
	}
}

export function getActivities(payload){
	return{
		type:GET_ACTIVITIES,
		payload
	}
}

export function getActivitiesSuccess(payload){
	return{
		type:GET_ACTIVITIES_SUCCESS,
		payload
	}
}

export function getActivitiesError(error){
	return{
		type:GET_ACTIVITIES_ERROR,
		error
	}
}

export const DELETE_ACTIVITY = "DELETE_ACTIVITY";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_ERROR = "DELETE_ACTIVITY_ERROR";

export function deleteActivity(payload){
	return{
		type:DELETE_ACTIVITY,
		payload
	}
}

export function deleteActivitySuccess(payload){
	return{
		type:DELETE_ACTIVITY_SUCCESS,
		payload
	}
}

export function deleteActivityError(error){
	return{
		type:DELETE_ACTIVITY_ERROR,
		error
	}
}

export const DELETE_ACTIVITY_FROM_LIST = "DELETE_ACTIVITY_FROM_LIST";

export function deleteActivityFromList(payload){
	return{
		type:DELETE_ACTIVITY_FROM_LIST,
		payload
	}
}