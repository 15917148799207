const pageSizes = {
	ACCOUNT_REQUESTS_TAKE: 20,
	MESSAGES_TAKE: 6,
	ACCOUNT_GIVINGS_HISTORY_TAKE: 6,
	DASHBOARD_COMMENTS_TAKE: 5,
	DASHBOARD_MEMBERS_TAKE: 12,
	DASHBOARD_POSTS_TAKE: 6,
	DASHBOARD_REQUESTS_TAKE: 20,
	DAHSBOARD_VIDEO_SETTINGS_TAKE: 6,
	DASHBOARD_SERVICE_TYPES_TAKE: 10,
	DASHBOARD_EVENTS_TAKE: 8,
	EVENT_TEMPLATES_TAKE: 8,
	POSTS_TAKE: 6,
	VIDEO_COMMENTS_TAKE: 5,
	VIDEOS_TAKE: 6,
	EVENTS_TAKE: 6,
	GIVINGS_HISTORY_TAKE: 5,
	GIVING_TYPES_TAKE: 5,
	USER_SEARCH_LIMIT: 20,
	HOME_HIGHLIGHTED_POSTS_TAKE: 3,
	HOME_POSTS_TAKE: 3,
	HOME_VIDEOS_TAKE: 3,
	MORE_VIDEOS_TAKE: 4,
	ACTIVITIES_TAKE: 8,
	EVENTS_SEARCH_TAKE: 5,
	EVENT_GROUPS_SEARCH_LIMIT: 5,
	EVENT_TEMPLATES_SEARCH_LIMIT: 5,
	EVENT_SERVICE_TYPES_SEARCH_LIMIT: 5,
	GROUP_TYPES_TAKE: 5,
	SEARCH_SONGS_TAKE: 10,
	POPULAR_SONGS_TAKE: 10,
	GIVINGS_REPORT_TAKE: 8,
};

export default pageSizes;
