export const CREATE_EVENT = "CREATE_EVENT";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_ERROR = "CREATE_EVENT_ERROR";

export function createEvent(payload) {
	return {
		type: CREATE_EVENT,
		payload,
	};
}

export function createEventSuccess(payload) {
	return {
		type: CREATE_EVENT_SUCCESS,
		payload,
	};
}

export function createEventError(error) {
	return {
		type: CREATE_EVENT_ERROR,
		error,
	};
}

export const SEARCH_GROUPS = "SEARCH_GROUPS";
export const SEARCH_GROUPS_SUCCESS = "SEARCH_GROUPS_SUCCESS";
export const SEARCH_GROUPS_ERROR = "SEARCH_GROUPS_ERROR";

export function searchGroups(payload) {
	return {
		type: SEARCH_GROUPS,
		payload,
	};
}

export function searchGroupsSuccess(payload) {
	return {
		type: SEARCH_GROUPS_SUCCESS,
		payload,
	};
}

export function searchGroupsError(error) {
	return {
		type: SEARCH_GROUPS_ERROR,
		error,
	};
}

export const CLEAR_FINDED_GROUPS = "CLEAR_FINDED_GROUPS";
export function clearFindedGroups(payload) {
	return {
		type: CLEAR_FINDED_GROUPS,
		payload,
	};
}

export const SEARCH_SERVICE_TYPES = "SEARCH_SERVICE_TYPES";
export const SEARCH_SERVICE_TYPES_SUCCESS = "SEARCH_SERVICE_TYPES_SUCCESS";
export const SEARCH_SERVICE_TYPES_ERROR = "SEARCH_SERVICE_TYPES_ERROR";

export function searchServiceTypes(payload) {
	return {
		type: SEARCH_SERVICE_TYPES,
		payload,
	};
}

export function searchServiceTypesSuccess(payload) {
	return {
		type: SEARCH_SERVICE_TYPES_SUCCESS,
		payload,
	};
}

export function searchServiceTypesError(error) {
	return {
		type: SEARCH_SERVICE_TYPES_ERROR,
		error,
	};
}

export const CLEAR_FINDED_SERVICE_TYPES = "CLEAR_FINDED_SERVICE_TYPES";
export function clearFindedServiceTypes(payload) {
	return {
		type: CLEAR_FINDED_SERVICE_TYPES,
		payload,
	};
}

export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";

export function deleteEvent(payload) {
	return {
		type: DELETE_EVENT,
		payload,
	};
}

export function deleteEventSuccess(payload) {
	return {
		type: DELETE_EVENT_SUCCESS,
		payload,
	};
}

export function deleteEventError(error) {
	return {
		type: DELETE_EVENT_ERROR,
		error,
	};
}

export const GET_DASHBOARD_EVENTS = "GET_DASHBOARD_EVENTS";
export const GET_DASHBOARD_EVENTS_SUCCESS = "GET_DASHBOARD_EVENTS_SUCCESS";
export const GET_DASHBOARD_EVENTS_ERROR = "GET_DASHBOARD_EVENTS_ERROR";

export function getDashboardEvents(payload) {
	return {
		type: GET_DASHBOARD_EVENTS,
		payload,
	};
}

export function getDashboardEventsSuccess(payload) {
	return {
		type: GET_DASHBOARD_EVENTS_SUCCESS,
		payload,
	};
}

export function getDashboardEventsError(error) {
	return {
		type: GET_DASHBOARD_EVENTS_ERROR,
		error,
	};
}

export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_ERROR = "UPDATE_EVENT_ERROR";

export function updateEvent(payload) {
	return {
		type: UPDATE_EVENT,
		payload,
	};
}

export function updateEventSuccess(payload) {
	return {
		type: UPDATE_EVENT_SUCCESS,
		payload,
	};
}

export function updateEventError(error) {
	return {
		type: UPDATE_EVENT_ERROR,
		error,
	};
}

export const SEARCH_TEMPLATES = "SEARCH_TEMPLATES";
export const SEARCH_TEMPLATES_SUCCESS = "SEARCH_TEMPLATES_SUCCESS";
export const SEARCH_TEMPLATES_ERROR = "SEARCH_TEMPLATES_ERROR";

export function searchTemplates(payload) {
	return {
		type: SEARCH_TEMPLATES,
		payload,
	};
}

export function searchTemplatesSuccess(payload) {
	return {
		type: SEARCH_TEMPLATES_SUCCESS,
		payload,
	};
}

export function searchTemplatesError(error) {
	return {
		type: SEARCH_TEMPLATES_ERROR,
		error,
	};
}

export const CLEAR_FINDED_TEMPLATES = "CLEAR_FINDED_TEMPLATES";
export function clearFindedTemplates(){
	return{
		type:CLEAR_FINDED_TEMPLATES		
	}
}
