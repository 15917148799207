import {
    GET_DASHBOARD_POSTS,
    GET_DASHBOARD_POSTS_ERROR,
    GET_DASHBOARD_POSTS_SUCCESS,
    CREATE_POST,
    CREATE_POST_ERROR,
    CREATE_POST_SUCCESS,
    DELETE_POST,
    DELETE_POST_SUCCESS,
    DELETE_POST_ERROR,
    GET_EDITABLE_POST,
    GET_EDITABLE_POST_ERROR,
    GET_EDITABLE_POST_SUCCESS,
    UPDATE_POST,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_ERROR
} from './actions';

const initialDashboardPostsState = {
    error: null,
    post: {},
    posts: {
        count: 0,
        entities: []
    },
    loading: false,
    loaded: false,
    updateLoading:false
};

function dashboardPostsReducer(state = initialDashboardPostsState, action) {
    switch (action.type) {
        case GET_DASHBOARD_POSTS:
            return { ...state, loading: true, loaded: false };
        case GET_DASHBOARD_POSTS_SUCCESS:
            return { ...state, loading: false, loaded: true, posts: action.payload };
        case GET_DASHBOARD_POSTS_ERROR:
            return { ...state, posts: { count: 0, entities: [] }, loaded: false, loading: false, error: action.error };
        case CREATE_POST:
            return { ...state, loading: true, loaded: false };
        case CREATE_POST_SUCCESS:
            return { ...state, error: false, loading: false, loaded: true };
        case CREATE_POST_ERROR:
            return { ...state, error: action.error, loading: false, loaded: true };
        case DELETE_POST:
            return { ...state, loading: true, loaded: false };
        case DELETE_POST_SUCCESS:
            return { ...state, error: false, loading: false, loaded: true };
        case DELETE_POST_ERROR:
            return { ...state, error: action.error, loading: false, loaded: true };
        case GET_EDITABLE_POST:
            return { ...state, loading: true, loaded: false };
        case GET_EDITABLE_POST_SUCCESS:         
            return { ...state, loading: false, loaded: true, post: action.payload };
        case GET_EDITABLE_POST_ERROR:
            return { ...state, posts: {}, loaded: false, loading: false, error: action.error };
        case UPDATE_POST:
            return { ...state, updateLoading: true, loaded: false };
        case UPDATE_POST_SUCCESS:           
            return { ...state, error: false, updateLoading: false, loaded: true };
        case UPDATE_POST_ERROR:
            return { ...state, error: action.error, updateLoading: false, loaded: true };
        default:
            return state;
    }
}


export default dashboardPostsReducer;