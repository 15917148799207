import {
	LOGIN_USER,
	LOGIN_USER_ERROR,
	LOGIN_USER_SUCCESS,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
	LOGOUT_USER_ERROR,
	REFRESH_TOKEN,
	REFRESH_TOKEN_SUCCESS,
	REFRESH_TOKEN_ERROR,
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_ERROR,
	SEND_RESET_PASSWORD,
	SEND_RESET_PASSWORD_SUCCESS,
	SEND_RESET_PASSWORD_ERROR,
	SEND_CONFIRMATION_EMAIL,
	SEND_CONFIRMATION_EMAIL_SUCCESS,
	SEND_CONFIRMATION_EMAIL_ERROR,
} from "../actions/auth.actions";
import {
	getCurrentUser,
	setCurrentUser,
	clearCurrentUser,
	updateToken,
} from "../../common/helpers/auth.helper";

const initialLoginParameters = {
	error: null,
	user: {
		userId: "",
		accessToken: "",
		expiresIn: "",
		refreshToken: "",
		roles: [],
		isSignedIn: false,
	},
	loading: false,
	loaded: false,
	emailSending: false,
	emailSent: false,
};

function authReducer(state = initialLoginParameters, action) {
	switch (action.type) {
		case LOGIN_USER:
			return { ...state, loading: true, loaded: false };
		case LOGIN_USER_SUCCESS:
			setCurrentUser(action.payload);
			return {
				...state,
				user: getCurrentUser(),
				loading: false,
				loaded: true,
			};
		case LOGIN_USER_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case LOGOUT_USER:
			return { ...state, loading: true, loaded: false };
		case LOGOUT_USER_SUCCESS:
			clearCurrentUser();
			return { ...state, user: null, loading: false, loaded: true };
		case LOGOUT_USER_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case REFRESH_TOKEN:
			return { ...state, loading: true, loaded: false };
		case REFRESH_TOKEN_SUCCESS:
			return {
				...state,
				user: getCurrentUser(),
				loading: false,
				loaded: true,
			};
		case REFRESH_TOKEN_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case RESET_PASSWORD:
			return { ...state, loading: true, loaded: false, error: null };
		case RESET_PASSWORD_SUCCESS:
			return { ...state, loading: false, loaded: true, error: null };
		case RESET_PASSWORD_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case SEND_RESET_PASSWORD:
			return {
				...state,
				emailSending: true,
				error: null,
				emailSent: false,
			};
		case SEND_RESET_PASSWORD_SUCCESS:
			return {
				...state,
				emailSending: false,
				error: null,
				emailSent: true,
			};
		case SEND_RESET_PASSWORD_ERROR:
			return {
				...state,
				emailSending: false,
				error: action.error,
				emailSent: false,
			};
		case SEND_CONFIRMATION_EMAIL:
			return {
				...state,
				emailSending: true,
				error: null,
				emailSent: false,
			};
		case SEND_CONFIRMATION_EMAIL_SUCCESS:
			return {
				...state,
				emailSending: false,
				error: null,
				emailSent: true,
			};
		case SEND_CONFIRMATION_EMAIL_ERROR:
			return {
				...state,
				emailSending: false,
				error: action.error,
				emailSent: false,
			};
		default:
			return state;
	}
}

export default authReducer;
