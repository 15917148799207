export const CREATE_GROUP_TYPE = "CREATE_GROUP_TYPE";
export const CREATE_GROUP_TYPE_SUCCESS = "CREATE_GROUP_TYPE_SUCCESS";
export const CREATE_GROUP_TYPE_ERROR = "CREATE_GROUP_TYPE_ERROR";

export function createGroupType(payload) {
	return {
		type: CREATE_GROUP_TYPE,
		payload,
	};
}

export function createGroupTypeSuccess(payload) {
	return {
		type: CREATE_GROUP_TYPE_SUCCESS,
		payload,
	};
}

export function createGroupTypeError(error) {
	return {
		type: CREATE_GROUP_TYPE_ERROR,
		error,
	};
}

export const UPDATE_GROUP_TYPE = "UPDATE_GROUP_TYPE";
export const UPDATE_GROUP_TYPE_SUCCESS = "UPDATE_GROUP_TYPE_SUCCESS";
export const UPDATE_GROUP_TYPE_ERROR = "UPDATE_GROUP_TYPE_ERROR";

export function updateGroupType(payload) {
	return {
		type: UPDATE_GROUP_TYPE,
		payload,
	};
}

export function updateGroupTypeSuccess(payload) {
	return {
		type: UPDATE_GROUP_TYPE_SUCCESS,
		payload,
	};
}

export function updateGroupTypeError(error) {
	return {
		type: UPDATE_GROUP_TYPE_ERROR,
		error,
	};
}

export const DELETE_GROUP_TYPE = "DELETE_GROUP_TYPE";
export const DELETE_GROUP_TYPE_SUCCESS = "DELETE_GROUP_TYPE_SUCCESS";
export const DELETE_GROUP_TYPE_ERROR = "DELETE_GROUP_TYPE_ERROR";

export function deleteGroupType(payload) {
	return {
		type: DELETE_GROUP_TYPE,
		payload,
	};
}

export function deleteGroupTypeSuccess(payload) {
	return {
		type: DELETE_GROUP_TYPE_SUCCESS,
		payload,
	};
}

export function deleteGroupTypeError(error) {
	return {
		type: DELETE_GROUP_TYPE_ERROR,
		error,
	};
}

export const GET_GROUP_TYPES = "GET_GROUP_TYPES";
export const GET_GROUP_TYPES_SUCCESS = "GET_GROUP_TYPES_SUCCESS";
export const GET_GROUP_TYPES_ERROR = "GET_GROUP_TYPES_ERROR";

export function getGroupTypes(payload) {
	return {
		type: GET_GROUP_TYPES,
		payload,
	};
}

export function getGroupTypesSuccess(payload) {
	return {
		type: GET_GROUP_TYPES_SUCCESS,
		payload,
	};
}

export function getGroupTypesError(error) {
	return {
		type: GET_GROUP_TYPES_ERROR,
		error,
	};
}