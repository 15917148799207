export const GET_DASHBOARD_REQUESTS = "GET_DASHBOARD_REQUESTS";
export const GET_DASHBOARD_REQUESTS_SUCCESS = "GET_DASHBOARD_REQUESTS_SUCCESS";
export const GET_DASHBOARD_REQUESTS_ERROR = "GET_DASHBOARD_REQUESTS_ERROR";

export function getRequests(payload) {
	return {
		type: GET_DASHBOARD_REQUESTS,
		payload,
	};
}

export function getRequestsSuccess(payload) {
	return {
		type: GET_DASHBOARD_REQUESTS_SUCCESS,
		payload,
	};
}

export function getRequestsError(error) {
	return {
		type: GET_DASHBOARD_REQUESTS_ERROR,
		error,
	};
}

export const GET_DASHBOARD_REQUESTS_STEALTH = "GET_DASHBOARD_REQUESTS_STEALTH";
export const GET_DASHBOARD_REQUESTS_SUCCESS_STEALTH =
	"GET_DASHBOARD_REQUESTS_SUCCESS_STEALTH";
export const GET_DASHBOARD_REQUESTS_ERROR_STEALTH =
	"GET_DASHBOARD_REQUESTS_ERROR_STEALTH";

export function getRequestsStealth(payload) {
	return {
		type: GET_DASHBOARD_REQUESTS_STEALTH,
		payload,
	};
}

export function getRequestsSuccessStealth(payload) {
	return {
		type: GET_DASHBOARD_REQUESTS_SUCCESS_STEALTH,
		payload,
	};
}

export function getRequestsErrorStealth(error) {
	return {
		type: GET_DASHBOARD_REQUESTS_ERROR_STEALTH,
		error,
	};
}

export const RESET_DASHBOARD_REQUESTS = "RESET_DASHBOARD_REQUESTS";
export function resetDashboardRequests() {
	return {
		type: RESET_DASHBOARD_REQUESTS,
	};
}

export const RESET_DASHBOARD_NEW_MESSAGES_COUNTER =
	"RESET_DASHBOARD_NEW_MESSAGES_COUNTER";
export function resetDashboardNewMessagesCounter(payload) {
	return {
		type: RESET_DASHBOARD_NEW_MESSAGES_COUNTER,
		payload,
	};
}

export const MOVE_DASHBOARD_REQUEST_TO_TOP = "MOVE_DASHBOARD_REQUEST_TO_TOP";

export function moveDashboardRequestToTop(payload) {
	return {
		type: MOVE_DASHBOARD_REQUEST_TO_TOP,
		payload,
	};
}

export const SEARCH_ADMIN_PRAYER_REQUESTS = "SEARCH_ADMIN_PRAYER_REQUESTS";
export const SEARCH_ADMIN_PRAYER_REQUESTS_SUCCESS =
	"SEARCH_ADMIN_PRAYER_REQUESTS_SUCCESS";
export const SEARCH_ADMIN_PRAYER_REQUESTS_ERROR =
	"SEARCH_ADMIN_PRAYER_REQUESTS_ERROR";

export function searchAdminPrayerRequests(payload) {
	return {
		type: SEARCH_ADMIN_PRAYER_REQUESTS,
		payload,
	};
}

export function searchAdminPrayerRequestsSuccess(payload) {
	return {
		type: SEARCH_ADMIN_PRAYER_REQUESTS_SUCCESS,
		payload,
	};
}

export function searchAdminPrayerRequestsError(error) {
	return {
		type: SEARCH_ADMIN_PRAYER_REQUESTS_ERROR,
		payload: error,
	};
}
