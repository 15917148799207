export const GET_DASHBOARD_MEMBERS = "GET_DASHBOARD_MEMBERS";
export const GET_DASHBOARD_MEMBERS_SUCCESS = "GET_DASHBOARD_MEMBERS_SUCCESS";
export const GET_DASHBOARD_MEMBERS_ERROR = "GET_DASHBOARD_MEMBERS_ERROR";

export const getDashboardMembers = (payload) => ({
	type: GET_DASHBOARD_MEMBERS,
	payload,
});

export const getDashboardMembersSuccess = (payload) => ({
	type: GET_DASHBOARD_MEMBERS_SUCCESS,
	payload,
});

export const getDashboardMembersError = (error) => ({
	type: GET_DASHBOARD_MEMBERS_ERROR,
	error,
});

export const SEARCH_DASHBOARD_MEMBERS = "SEARCH_DASHBOARD_MEMBERS";
export const SEARCH_DASHBOARD_MEMBERS_SUCCESS =
	"SEARCH_DASHBOARD_MEMBERS_SUCCESS";
export const SEARCH_DASHBOARD_MEMBERS_ERROR = "SEARCH_DASHBOARD_MEMBERS_ERROR";

export const searchDashboardMembers = (payload) => ({
	type: SEARCH_DASHBOARD_MEMBERS,
	payload,
});

export const searchDashboardMembersSuccess = (payload) => ({
	type: SEARCH_DASHBOARD_MEMBERS_SUCCESS,
	payload,
});

export const searchDashboardMembersError = (error) => ({
	type: SEARCH_DASHBOARD_MEMBERS_ERROR,
	error,
});


export const GET_ROLES_SHORT_INFO = "GET_ROLES_SHORT_INFO";
export const GET_ROLES_SHORT_INFO_SUCCESS = "GET_ROLES_SHORT_INFO_SUCCESS";
export const GET_ROLES_SHORT_INFO_ERROR = "GET_ROLES_SHORT_INFO_ERROR";

export function getRolesShortInfo(){
	return{
		type:GET_ROLES_SHORT_INFO
	}
}

export function getRolesShortInfoSuccess(payload){
	return{
		type:GET_ROLES_SHORT_INFO_SUCCESS,
		payload
	}
}

export function getRolesShortInfoError(error){
	return{
		type:GET_ROLES_SHORT_INFO_ERROR,
		error
	}
}

export const DELETE_MEMBERS = "DELETE_MEMBERS";
export const DELETE_MEMBERS_SUCCESS = "DELETE_MEMBERS_SUCCESS";
export const DELETE_MEMBERS_ERROR = "DELETE_MEMBERS_ERROR";

export function deleteMembers(payload){
	return{
		type:DELETE_MEMBERS,
		payload
	}
}

export function deleteMembersSuccess(payload){
	return{
		type:DELETE_MEMBERS_SUCCESS,
		payload
	}
}

export function deleteMembersError(error){
	return{
		type:DELETE_MEMBERS_ERROR,
		error
	}
}

export const UPDATE_MEMBER_ROLE = "UPDATE_MEMBER_ROLE";
export const UPDATE_MEMBER_ROLE_SUCCESS = "UPDATE_MEMBER_ROLE_SUCCESS";
export const UPDATE_MEMBER_ROLE_ERROR = "UPDATE_MEMBER_ROLE_ERROR";

export function updateMemberRole(payload){
	return{
		type:UPDATE_MEMBER_ROLE,
		payload
	}
}

export function updateMemberRoleSuccess(payload){
	return{
		type:UPDATE_MEMBER_ROLE_SUCCESS,
		payload
	}
}

export function updateMemberRoleError(error){
	return{
		type:UPDATE_MEMBER_ROLE_ERROR,
		error
	}
}