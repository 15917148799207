export const CREATE_GIVING_TYPE = "CREATE_GIVING_TYPE";
export const CREATE_GIVING_TYPE_SUCCESS = "CREATE_GIVING_TYPE_SUCCESS";
export const CREATE_GIVING_TYPE_ERROR = "CREATE_GIVING_TYPE_ERROR";

export function createGivingType(payload) {
	return {
		type: CREATE_GIVING_TYPE,
		payload,
	};
}

export function createGivingTypeSuccess(payload) {
	return {
		type: CREATE_GIVING_TYPE_SUCCESS,
		payload,
	};
}

export function createGivingTypeError(error) {
	return {
		type: CREATE_GIVING_TYPE_ERROR,
		error,
	};
}

export const UPDATE_GIVING_TYPE = "UPDATE_GIVING_TYPE";
export const UPDATE_GIVING_TYPE_SUCCESS = "UPDATE_GIVING_TYPE_SUCCESS";
export const UPDATE_GIVING_TYPE_ERROR = "UPDATE_GIVING_TYPE_ERROR";

export function updateGivingType(payload) {
	return {
		type: UPDATE_GIVING_TYPE,
		payload,
	};
}

export function updateGivingTypeSuccess(payload) {
	return {
		type: UPDATE_GIVING_TYPE_SUCCESS,
		payload,
	};
}

export function updateGivingTypeError(error) {
	return {
		type: UPDATE_GIVING_TYPE_ERROR,
		error,
	};
}

export const DELETE_GIVING_TYPE = "DELETE_GIVING_TYPE";
export const DELETE_GIVING_TYPE_SUCCESS = "DELETE_GIVING_TYPE_SUCCESS";
export const DELETE_GIVING_TYPE_ERROR = "DELETE_GIVING_TYPE_ERROR";

export function deleteGivingType(payload) {
	return {
		type: DELETE_GIVING_TYPE,
		payload,
	};
}

export function deleteGivingTypeSuccess(payload) {
	return {
		type: DELETE_GIVING_TYPE_SUCCESS,
		payload,
	};
}

export function deleteGivingTypeError(error) {
	return {
		type: DELETE_GIVING_TYPE_ERROR,
		error,
	};
}

export const GET_GIVING_TYPES = "GET_GIVING_TYPES";
export const GET_GIVING_TYPES_SUCCESS = "GET_GIVING_TYPES_SUCCESS";
export const GET_GIVING_TYPES_ERROR = "GET_GIVING_TYPES_ERROR";

export function getGivingTypes(payload) {
	return {
		type: GET_GIVING_TYPES,
		payload,
	};
}

export function getGivingTypesSuccess(payload) {
	return {
		type: GET_GIVING_TYPES_SUCCESS,
		payload,
	};
}

export function getGivingTypesError(error) {
	return {
		type: GET_GIVING_TYPES_ERROR,
		error,
	};
}

export const GET_GIVINGS_HISTORY = "GET_GIVINGS_HISTORY";
export const GET_GIVINGS_HISTORY_SUCCESS = "GET_GIVINGS_HISTORY_SUCCESS";
export const GET_GIVINGS_HISTORY_ERROR = "GET_GIVINGS_HISTORY_ERROR";

export function getGivingsHistory(payload) {
	return {
		type: GET_GIVINGS_HISTORY,
		payload,
	};
}

export function getGivingsHistorySuccess(payload) {
	return {
		type: GET_GIVINGS_HISTORY_SUCCESS,
		payload,
	};
}

export function getGivingsHistoryError(error) {
	return {
		type: GET_GIVINGS_HISTORY_SUCCESS,
		error,
	};
}

export const SEARCH_FOR_USERS = "SEARCH_FOR_USERS";
export const SEARCH_FOR_USERS_SUCCESS = "SEARCH_FOR_USERS_SUCCESS";
export const SEARCH_FOR_USERS_ERROR = "SEARCH_FOR_USERS_ERROR";

export function searchForUsers(payload) {
	return {
		type: SEARCH_FOR_USERS,
		payload,
	};
}

export function searchForUsersSuccess(payload) {
	return {
		type: SEARCH_FOR_USERS_SUCCESS,
		payload,
	};
}

export function searchForUsersError(error) {
	return {
		type: SEARCH_FOR_USERS_ERROR,
		payload: error,
	};
}


export const CREATE_OFFLINE_GIVING = "CREATE_OFFLINE_GIVING";
export const CREATE_OFFLINE_GIVING_SUCCESS = "CREATE_OFFLINE_GIVING_SUCCESS";
export const CREATE_OFFLINE_GIVING_ERROR = "CREATE_OFFLINE_GIVING_ERROR";

export function createOfflineGiving(payload){
	return{
		type:CREATE_OFFLINE_GIVING,
		payload
	}
}

export function createOfflineGivingSuccess(payload){
	return{
		type:CREATE_OFFLINE_GIVING_SUCCESS,
		payload
	}
}

export function createOfflineGivingError(error){
	return{
		type:CREATE_GIVING_TYPE_ERROR,
		error
	}
}

export const DELETE_OFFLINE_GIVING = "DELETE_OFFLINE_GIVING";
export const DELETE_OFFLINE_GIVING_SUCCESS = "DELETE_OFFILE_GIVING_SUCCESS";
export const DELETE_OFFLINE_GIVING_ERROR = "DELETE_OFFLINE_GIVING_ERROR";

export function deleteOfflineGiving(payload){
	return{
		type:DELETE_OFFLINE_GIVING,
		payload
	}
}

export function deleteOfflineGivingSuccess(payload){
	return{
		type:DELETE_OFFLINE_GIVING_SUCCESS,
		payload
	}
}

export function deleteOfflineGivingError(error){
	return{
		type:DELETE_OFFLINE_GIVING_ERROR,
		error
	}
}