import {
	GET_MEMBERS_CHART_DATA,
	GET_MEMBERS_CHART_DATA_SUCCESS,
	GET_MEMBERS_CHART_DATA_ERROR,
	GET_GIVINGS_CHART_DATA,
	GET_GIVINGS_CHART_DATA_SUCCESS,
	GET_GIVINGS_CHART_DATA_ERROR,
	GET_COMMENTS_CHART_DATA,
	GET_COMMENTS_CHART_DATA_SUCCESS,
	GET_COMMENTS_CHART_DATA_ERROR,
	GET_REQUESTS_CHART_DATA,
	GET_REQUESTS_CHART_DATA_SUCCESS,
	GET_REQUESTS_CHART_DATA_ERROR,
	RESET_ACTIVITIES,
	GET_ACTIVITIES,
	GET_ACTIVITIES_SUCCESS,
	GET_ACTIVITIES_ERROR,
	DELETE_ACTIVITY,
	DELETE_ACTIVITY_SUCCESS,
	DELETE_ACTIVITY_ERROR,
	DELETE_ACTIVITY_FROM_LIST,
} from "./actions";

const initialDashboardStatsState = {
	members: {
		loading: false,
		error: null,
		data: {},
	},
	givings: {
		loading: false,
		error: null,
		data: {},
	},
	requests: {
		loading: false,
		error: null,
		data: {},
	},
	comments: {
		loading: false,
		error: null,
		data: {},
	},
	activities: {
		loading: false,
		error: null,
		deleteLoading: false,
		deleteError: null,
		data: {
			hasMore: false,
			entities: [],
		},
		additionalSkip: 0,
	},
};

let activityToDelete = null;

function dashboardStatsReducer(state = initialDashboardStatsState, action) {
	switch (action.type) {
		case GET_MEMBERS_CHART_DATA:
			return {
				...state,
				members: {
					...state.members,
					loading: true,
				},
			};
		case GET_MEMBERS_CHART_DATA_SUCCESS:
			return {
				...state,
				members: {
					loading: false,
					data: action.payload,
					error: null,
				},
			};
		case GET_MEMBERS_CHART_DATA_ERROR:
			return {
				...state,
				members: {
					loading: false,
					data: {},
					error: action.error,
				},
			};
		case GET_GIVINGS_CHART_DATA:
			return {
				...state,
				givings: {
					...state.givings,
					loading: true,
				},
			};
		case GET_GIVINGS_CHART_DATA_SUCCESS:
			return {
				...state,
				givings: {
					loading: false,
					data: action.payload,
					error: null,
				},
			};
		case GET_GIVINGS_CHART_DATA_ERROR:
			return {
				...state,
				givings: {
					loading: false,
					data: {},
					error: action.error,
				},
			};
		case GET_COMMENTS_CHART_DATA:
			return {
				...state,
				comments: {
					...state.comments,
					loading: true,
				},
			};
		case GET_COMMENTS_CHART_DATA_SUCCESS:
			return {
				...state,
				comments: {
					loading: false,
					data: action.payload,
					error: null,
				},
			};
		case GET_COMMENTS_CHART_DATA_ERROR:
			return {
				...state,
				comments: {
					loading: false,
					data: {},
					error: action.error,
				},
			};
		case GET_REQUESTS_CHART_DATA:
			return {
				...state,
				requests: {
					...state.requests,
					loading: true,
				},
			};
		case GET_REQUESTS_CHART_DATA_SUCCESS:
			return {
				...state,
				requests: {
					loading: false,
					data: action.payload,
					error: null,
				},
			};
		case GET_REQUESTS_CHART_DATA_ERROR:
			return {
				...state,
				requests: {
					loading: false,
					data: {},
					error: action.error,
				},
			};

		case RESET_ACTIVITIES:
			return {
				...state,
				activities: {
					loading: false,
					error: null,
					data: {
						hasMore: false,
						entities: [],
					},
					additionalSkip: 0,
				},
			};
		case GET_ACTIVITIES:
			return {
				...state,
				activities: {
					...state.activities,
					loading: true,
					error: null,
				},
			};
		case GET_ACTIVITIES_SUCCESS:
			return {
				...state,
				activities: {
					...state.activities,
					loading: false,
					data: concatActivities(state, action.payload),
				},
			};
		case GET_ACTIVITIES_ERROR:
			return {
				...state,
				activities: {
					...state.activities,
					loading: false,
					error: action.error,
					data: {
						hasMore: false,
						entities: [],
					},
				},
			};
		case DELETE_ACTIVITY:
			return {
				...state,
				activities: {
					...state.activities,
					deleteLoading: true,
				},
			};
		case DELETE_ACTIVITY_SUCCESS:
			return {
				...state,
				activities: {
					...state.activities,
					deleteLoading: false,
					deleteError: null,
				},
			};
		case DELETE_ACTIVITY_ERROR:
			return {
				...state,
				activities: {
					...state.activities,
					deleteLoading: false,
					deleteError: action.error,
				},
			};
		case DELETE_ACTIVITY_FROM_LIST: {
			return {
				...state,
				activities: {
					...state.activities,
					data: deleteActivity(state, action.payload),
					additionalSkip: state.activities.additionalSkip + 1,
				},
			};
		}

		default:
			return state;
	}
}

function concatActivities(state, payload) {
	const entities = state.activities.data.entities;
	return {
		hasMore: payload.hasMore,
		entities: [...entities, ...payload.entities],
	};
}

function deleteActivity(state, id) {
	const { entities, hasMore } = state.activities.data;
	const activityIdx = entities.findIndex((a) => a.id === id);

	return {
		hasMore,
		entities: [
			...entities.slice(0, activityIdx),
			...entities.slice(activityIdx + 1),
		],
	};
}

export default dashboardStatsReducer;
