import {
    CREATE_ONE_TIME_GIVING_PAYMENT_INTENT,
    CREATE_ONE_TIME_GIVING_PAYMENT_INTENT_SUCCESS,
    CREATE_ONE_TIME_GIVING_PAYMENT_INTENT_ERROR,
    CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT,
    CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT_ERROR,
    CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT_SUCCESS,
    RESET_PAYMENT_INTENT,
    GET_USER_PAYMENT_METHODS,
    GET_USER_PAYMENT_METHODS_SUCCESS,
    GET_USER_PAYMENT_METHODS_ERROR,
    SET_PAYMENT_METHOD_DEFAULT,
    SET_PAYMENT_METHOD_DEFAULT_ERROR,
    SET_PAYMENT_METHOD_DEFAULT_SUCCESS,
    DELETE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD_ERROR,
    DELETE_PAYMENT_METHOD_SUCCESS,
    ADD_PAYMENT_METHOD,
    ADD_PAYMENT_METHOD_ERROR,
    ADD_PAYMENT_METHOD_SUCCESS
} from "../actions/payment.actions";

const initialPaymentsState = {
    loading: false,
    loaded: false,
    error: null,
    oneTimePaymentIntentData: null,
    paymentMethodsLoading: false,
    paymentMethods: [],
    paymentMethodActionLoading: false,
    paymentMethodActionLoaded: false,
    paymentMethodActionError: null
}

function paymentsReducer(state = initialPaymentsState, action) {
    switch (action.type) {
        case CREATE_ONE_TIME_GIVING_PAYMENT_INTENT:
            return { ...state, loading: true, loaded: false, error: null };
        case CREATE_ONE_TIME_GIVING_PAYMENT_INTENT_SUCCESS:
            return { ...state, loading: false, loaded: true, oneTimePaymentIntentData: action.payload };
        case CREATE_ONE_TIME_GIVING_PAYMENT_INTENT_ERROR:
            return { ...state, loading: false, loaded: false, oneTimePaymentIntentData: null, error: action.error };
        case CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT:
            return { ...state, loading: true, loaded: false, error: null };
        case CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT_SUCCESS:
            return { ...state, loading: false, loaded: true, oneTimePaymentIntentData: action.payload };
        case CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT_ERROR:
            return { ...state, loading: false, loaded: false, oneTimePaymentIntentData: null, error: action.error };
        case RESET_PAYMENT_INTENT:
            return { ...state, oneTimePaymentIntentData: null };
        case GET_USER_PAYMENT_METHODS:
            return { ...state, paymentMethodsLoading: true, loaded: false };
        case GET_USER_PAYMENT_METHODS_SUCCESS:
            return { ...state, paymentMethodsLoading: false, loaded: true, paymentMethods: action.payload };
        case GET_USER_PAYMENT_METHODS_ERROR:
            return { ...state, paymentMethodsLoading: false, loaded: false, paymentMethods: [], error: action.error };
        case SET_PAYMENT_METHOD_DEFAULT:
            return { ...state, paymentMethodActionLoading: true, paymentMethodActionLoaded: false };
        case SET_PAYMENT_METHOD_DEFAULT_SUCCESS:
            return {
                ...state, paymentMethodActionLoading: false,
                paymentMethodActionLoaded: true, paymentMethods: updateDefaultPaymentMethod(state, action.payload),
                paymentMethodActionError: null
            };
        case SET_PAYMENT_METHOD_DEFAULT_ERROR:
            return { ...state, paymentMethodActionLoading: false, paymentMethodActionLoaded: false, paymentMethodActionError: action.error };
        case DELETE_PAYMENT_METHOD:
            return { ...state, paymentMethodActionLoading: true, paymentMethodActionLoaded: false };
        case DELETE_PAYMENT_METHOD_SUCCESS:
            return { ...state, paymentMethodActionLoading: false, paymentMethodActionLoaded: true, paymentMethodActionError: null };
        case DELETE_PAYMENT_METHOD_ERROR:
            return { ...state, paymentMethodActionLoading: false, paymentMethodActionLoaded: false, paymentMethodActionError: action.error };
        case ADD_PAYMENT_METHOD:
            return { ...state, paymentMethodActionLoading: true, paymentMethodActionLoaded: false };
        case ADD_PAYMENT_METHOD_SUCCESS:
            return { ...state, paymentMethodActionLoading: false, paymentMethodActionLoaded: true, paymentMethodActionError: null };
        case ADD_PAYMENT_METHOD_ERROR:
            return { ...state, paymentMethodActionLoading: false, paymentMethodActionLoaded: false, paymentMethodActionError: action.error };
        default:
            return state;
    }
}

const updateDefaultPaymentMethod = (state, newDefault) => {
    const paymentMethods = state.paymentMethods;

    const oldDefault = paymentMethods.find(pm => pm.isDefault);
    oldDefault.isDefault = false;

    const oldDefaultIdx = paymentMethods.findIndex(pm => pm.id === oldDefault.id);

    const withUpdatedOldDefault = [
        ...paymentMethods.slice(0, oldDefaultIdx),
        oldDefault,
        ...paymentMethods.slice(oldDefaultIdx + 1)
    ];

    const newDefaultIdx = paymentMethods.findIndex(pm => pm.id === newDefault.id);

    return [
        ...withUpdatedOldDefault.slice(0, newDefaultIdx),
        newDefault,
        ...withUpdatedOldDefault.slice(newDefaultIdx + 1)
    ];
}

export default paymentsReducer;