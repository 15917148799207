import {
	GET_MESSAGES,
	GET_MESSAGES_ERROR,
	GET_MESSAGES_SUCCESS,
	RESET_MESSAGES,
	RECEIVE_MESSAGE,
} from "./actions";

const initialMessagesState = {
	error: null,
	messages: {
		entities: [],
		hasMore: false,
	},
	loading: false,
	loaded: false,
};

function messagesReducer(state = initialMessagesState, action) {
	switch (action.type) {
		case RESET_MESSAGES:
			return initialMessagesState;
		case GET_MESSAGES:
			return { ...state, loading: true, loaded: false };
		case GET_MESSAGES_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				messages: concatMessages(state, action.payload),
			};
		case GET_MESSAGES_ERROR:
			return {
				...state,
				messages: { hasMore: false, entities: [] },
				loaded: false,
				loading: false,
				error: action.error,
			};
		case RECEIVE_MESSAGE:
			return { ...state, messages: addMessage(state, action.payload) };
		default:
			return state;
	}
}

function concatMessages(state, payload) {
	const entities = state.messages.entities;
	return {
		hasMore: payload.hasMore,
		entities: [...payload.entities.reverse(), ...entities],
	};
}

function addMessage(state, payload) {
	return {
		hasMore: state.messages.hasMore,
		entities: state.messages.entities.concat(payload),
	};
}

export default messagesReducer;
