import React from "react";

const CompleteSignUpInfo = () => {
	return (
		<div className="modal-inner / text-center">
			<p className="modal-subtitle">Thank you!</p>
			<p className="m-0">
				You have been sent a letter to the indicated mail to confirm the
				password.
			</p>
		</div>
	);
};

export default CompleteSignUpInfo;
