import React, { useEffect, useState } from "react";
import SignUpModal from "./SignUpModal";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "./store/actions";
import { getStatesDirectory } from "../../redux/actions/directories/states.directory.actions";

const SignUpModalContainer = ({ show, handleClose, setShowLogin }) => {
	const [signUpComplete, setSignUpComplete] = useState(false);

	const closeModalAndResetComplete = () => {
		setSignUpComplete(false);
		handleClose();
	};

	const dispatch = useDispatch();
	const onSignUp = (payload) => dispatch(registerUser(payload));

	const loading = useSelector((x) => x.registration.loading);
	const loaded = useSelector((x) => x.registration.loaded);
	const states = useSelector((state) => state.directories.states.states);

	useEffect(() => {
		dispatch(getStatesDirectory());
	}, []);

	useEffect(() => {
		setSignUpComplete(true);
	}, [loaded]);

	return (
		<SignUpModal
			registerUser={onSignUp}
			loading={loading}
			signUpComplete={signUpComplete && loaded}
			show={show}
			handleClose={closeModalAndResetComplete}
			setShowLogin={setShowLogin}
			states={states}
		/>
	);
};

export default SignUpModalContainer;
