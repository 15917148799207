export const GET_VIDEO = "GET_VIDEO";
export const GET_VIDEO_SUCCESS="GET_VIDEO_SUCCESS";
export const GET_VIDEO_ERROR = "GET_VIDEO_ERROR";

export function getVideo(payload){
    return{
        type:GET_VIDEO,
        payload
    }
}

export function getVideoSuccess(payload){
    return{
        type:GET_VIDEO_SUCCESS,
        payload
    }
}

export function getVideoError(error){
    return{
        type:GET_VIDEO_ERROR,
        error
    }
}