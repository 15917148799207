import {
    GET_MORE_POSTS,
    GET_MORE_POSTS_ERROR,
    GET_MORE_POSTS_SUCCESS
} from './actions';

const initialMorePostsState = {
    error: null,
    posts: [],
    loading: false,
    loaded: false
};

function morePostsReducer(state = initialMorePostsState, action) {
    switch (action.type) {
        case GET_MORE_POSTS:
            return { ...state, loading: true, loaded: false };
        case GET_MORE_POSTS_SUCCESS:            
            return { ...state, loading: false, loaded: true, posts: action.payload };
        case GET_MORE_POSTS_ERROR:
            return { ...state, posts:[], loaded: false, loading: false, error: action.error };       
        default:
            return state;
    }
}

export default morePostsReducer;