import {
	GET_UPCOMING_EVENTS,
	GET_UPCOMING_EVENTS_SUCCESS,
	GET_UPCOMING_EVENTS_ERROR,
	GET_LATEST_EVENTS,
	GET_LATEST_EVENTS_SUCCESS,
	GET_LATEST_EVENTS_ERROR,
	SEARCH_EVENTS,
	SEARCH_EVENTS_SUCCESS,
	SEARCH_EVENTS_ERROR,
	CLEAR_EVENTS_SEARCH_RESULT,
} from "./actions";

const initialState = {
	upcomingLoading: false,
	upcomingEvents: [],
	latestLoading: false,
	latestEvents: [],
	searchLoading: false,
	searchResults: [],
	error: null,
};

export default function eventsReducer(state = initialState, action) {
	switch (action.type) {
		case GET_UPCOMING_EVENTS:
			return {
				...state,
				upcomingLoading: true,
			};
		case GET_UPCOMING_EVENTS_SUCCESS:
			return {
				...state,
				upcomingLoading: false,
				upcomingEvents: action.payload,
			};
		case GET_UPCOMING_EVENTS_ERROR:
			return {
				...state,
				upcomingLoading: false,
				error: action.payload,
			};
		case GET_LATEST_EVENTS:
			return {
				...state,
				latestLoading: true,
			};
		case GET_LATEST_EVENTS_SUCCESS:
			return {
				...state,
				latestLoading: false,
				latestEvents: action.payload,
			};
		case GET_LATEST_EVENTS_ERROR:
			return {
				...state,
				latestLoading: false,
				error: action.payload,
			};
		case SEARCH_EVENTS:			
			return {
				...state,
				searchLoading: true,
			};
		case SEARCH_EVENTS_SUCCESS:		
			return {
				...state,
				searchLoading: false,
				searchResults: action.payload,
			};
		case SEARCH_EVENTS_ERROR:
			return {
				...state,
				searchLoading: false,
				error: action.payload,
			};
		case CLEAR_EVENTS_SEARCH_RESULT:
			return {
				...state,
				searchResults: [],
				searchLoading: false,
			};
		default:
			return state;
	}
}
