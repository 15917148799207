import { call, put, takeEvery } from "redux-saga/effects";
import {
	DELETE_COMMENT,
	deleteCommentSuccess,
	deleteCommentError,
	GET_ALL_COMMENTS,
	getAllComments,
	getAllCommentsSuccess,
	getAllCommentsError,
} from "./actions";
import pageSizes from "../../../../../common/constants/pageSizes";
import ApiBase from "../../../../../common/api/api.base";

function* workerDeleteComment(action) {
	try {
		const {query, comment} = action.payload;
		const result = yield call(
			ApiBase.delete,
			`/api/comments/${comment}`
		);
		if (!result.error) {
			yield put(deleteCommentSuccess(result));
			yield put(
				getAllComments({
					query,
					skip: 0,
					take: pageSizes.DASHBOARD_COMMENTS_TAKE,
				})
			);
		} else {
			yield put(deleteCommentError("Failed to delete comment!"));
		}
	} catch (e) {
		yield put(deleteCommentError(e.message));
	}
}

function* workerGetAllComments(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/paginated-comments-all`,
			action.payload
		);
		if (!result.error) {
			yield put(getAllCommentsSuccess(result));
		} else {
			yield put(getAllCommentsError("Failed to get comments!"));
		}
	} catch (e) {
		yield put(getAllCommentsError(e.message));
	}
}

export default function* watchDashboardCommentsSaga() {
	yield takeEvery(DELETE_COMMENT, workerDeleteComment);
	yield takeEvery(GET_ALL_COMMENTS, workerGetAllComments);
}
