import React from "react";
import HomePostsListItem from "./HomePostsListItem";

const HomePostsList = ({ posts }) => {
	return (
		<ul className="next__items">
			{posts.map((post) => {
				return (
					<li key={post.id} className="next__item / post-item">
						<HomePostsListItem post={post} />
					</li>
				);
			})}
		</ul>
	);
};

export default HomePostsList;
