import {
	GET_SERVICE_TYPES,
	GET_SERVICE_TYPES_ERROR,
	GET_SERVICE_TYPES_SUCCESS,
	CREATE_SERVICE_TYPE,
	CREATE_SERVICE_TYPE_SUCCESS,
	CREATE_SERVICE_TYPE_ERROR,
	DELETE_SERVICE_TYPE,
	DELETE_SERVICE_TYPE_ERROR,
	DELETE_SERVICE_TYPE_SUCCESS,
	UPDATE_SERVICE_TYPE,
	UPDATE_SERVICE_TYPE_ERROR,
	UPDATE_SERVICE_TYPE_SUCCESS,
	GET_MASTER_CALENDAR_EVENTS_REQUEST,
	GET_MASTER_CALENDAR_EVENTS_SUCCESS,
	GET_MASTER_CALENDAR_EVENTS_ERROR,
} from "./actions";

const initialDashboardServiceTypesState = {
	loading: false,
	loaded: false,
	error: null,
	serviceTypes: {
		count: 0,
		entities: [],
	},
	calendarEventsLoading: false,
	calendarEvents: [],
};

function dashboardServiceTypesReducer(
	state = initialDashboardServiceTypesState,
	action
) {
	switch (action.type) {
		case GET_SERVICE_TYPES:
			return { ...state, loading: true, loaded: false };
		case GET_SERVICE_TYPES_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				serviceTypes: action.payload,
			};
		case GET_SERVICE_TYPES_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case CREATE_SERVICE_TYPE:
			return { ...state, loading: true, loaded: false };
		case CREATE_SERVICE_TYPE_SUCCESS:
			return { ...state, loading: false, loaded: true };
		case CREATE_SERVICE_TYPE_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case DELETE_SERVICE_TYPE:
			return { ...state, loading: true, loaded: false };
		case DELETE_SERVICE_TYPE_SUCCESS:
			return { ...state, loading: false, loaded: true };
		case DELETE_SERVICE_TYPE_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case UPDATE_SERVICE_TYPE:
			return { ...state, loading: true, loaded: false };
		case UPDATE_SERVICE_TYPE_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				serviceTypes: {
					...state.serviceTypes,
					entities: state.serviceTypes.entities.map((elem) => {
						if (elem.id === action.payload.id) {
							return action.payload;
						} else {
							return elem;
						}
					}),
				},
			};
		case UPDATE_SERVICE_TYPE_ERROR:
			return {
				...state,
				loading: false,
				loaded: true,
				error: action.error,
			};
		case GET_MASTER_CALENDAR_EVENTS_REQUEST:
			return {
				...state,
				calendarEventsLoading: true,
			};
		case GET_MASTER_CALENDAR_EVENTS_SUCCESS:
			return {
				...state,
				calendarEventsLoading: false,
				calendarEvents: action.payload,
			};
		case GET_MASTER_CALENDAR_EVENTS_ERROR:
			return {
				...state,
				calendarEventsLoading: false,
				error: action.payload,
			};
		default:
			return state;
	}
}

export default dashboardServiceTypesReducer;
