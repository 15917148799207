import { all } from "redux-saga/effects";

import watchAuthSaga from "./auth.saga";
import watchPaymentsSaga from "./payments.saga";

import watchRegisterSaga from "../../modals/SignUpModal/store/sagas";
import watchDashboardVideosSaga from "../../pages/Dashboard/pages/DashboardVideoSettings/store/sagas";
import watchHomeVideosSaga from "../../pages/Home/modules/HomeVideos/store/sagas";
import watchVideosSaga from "../../pages/Videos/store/sagas";
import watchMoreVideosSaga from "../../pages/Video/modules/VideoMoreVideos/store/sagas";
import watchVideoSaga from "../../pages/Video/store/sagas";
import watchVideoCommentsSaga from "../../pages/Video/modules/VideoCommentsSection/store/sagas";
import watchDashboardCommentsSaga from "../../pages/Dashboard/pages/DashboardComments/store/sagas";
import watchDashboardPostsSaga from "../../pages/Dashboard/pages/DashboardPosts/store/sagas";
import watchPostsSaga from "../../pages/Posts/store/sagas";
import watchPostSaga from "../../pages/Post/store/sagas";
import watchMorePostsSaga from "../../pages/Post/modules/PostMorePosts/store/sagas";
import watchHomePostsSaga from "../../pages/Home/modules/HomePosts/store/sagas";
import watchRequestsSaga from "../../modals/RequestModal/store/sagas";
import watchDashboardRequestsSaga from "../../pages/Dashboard/pages/DashboardPrayerRequests/store/sagas";
import watchMessagesSaga from "../../common/Messages/store/sagas";
import watchAccountRequestsSaga from "../../pages/Account/pages/AccountRequests/store/sagas";
import watchHomeHighlightedPostsSaga from "../../pages/Home/modules/HomeArticles/store/sagas";
import watchDashboardGivingTypesSaga from "../../pages/Dashboard/pages/DashboardGivings/store/sagas";
import watchAccountUserInfoSaga from "../../pages/Account/pages/AccountSettings/store/sagas";
import watchSearchSaga from "../../common/blocks/SearchBar/store/sagas";
import watchGivingsSaga from "../../pages/Giving/store/sagas";
import watchDirectoriesSaga from "./directories/directories.saga";
import watchPaymentSettingsSaga from "../../pages/Account/pages/AccountPaymentSettings/store/sagas";
import watchUserGivingsSaga from "../../pages/Account/pages/AccountGivingsHistory/store/sagas";
import watchMessagesSearchSaga from "../../common/blocks/RequestBlockToolbar/store/sagas";
import watchDashboardMembersSaga from "../../pages/Dashboard/pages/DashboardMembers/store/sagas";
import watchEventsSaga from "../../pages/Events/store/sagas";
import watchRolesSaga from "../../pages/Dashboard/pages/DashboardRoles/store/sagas";
import watchUsersSaga from "./users.saga";
import watchDashboardStatsSaga from "../../pages/Dashboard/pages/Charts/store/sagas";
import watchDashboardServiceTypesSaga from "../../pages/Dashboard/pages/DashboardPlans/store/sagas";
import { watchDashboardServicePlanSaga } from "../../pages/Dashboard/pages/DashboardPlanPage/store/sagas";
import watchDashboardGroupTypesSaga from "../../pages/Dashboard/pages/DashboardGroupTypes/store/sagas";
import watchSongsSaga from "../../modals/AddSongModal/store/sagas";
import watchDashboardEventsSaga from "../../pages/Dashboard/pages/DashboardEvents/store/sagas";
import watchEventTemplatesSaga from "../../pages/Dashboard/pages/DashboardEvents/tabs/EventTemplates/store/sagas";
import watchDashboardReportsSaga from "../../pages/Dashboard/pages/DashboardReports/store/sagas";

export default function* rootSaga() {
	yield all([
		watchAuthSaga(),
		watchRegisterSaga(),
		watchDashboardVideosSaga(),
		watchHomeVideosSaga(),
		watchVideosSaga(),
		watchMoreVideosSaga(),
		watchVideoSaga(),
		watchVideoCommentsSaga(),
		watchDashboardCommentsSaga(),
		watchDashboardPostsSaga(),
		watchPostsSaga(),
		watchPostSaga(),
		watchMorePostsSaga(),
		watchHomePostsSaga(),
		watchRequestsSaga(),
		watchDashboardRequestsSaga(),
		watchMessagesSaga(),
		watchAccountRequestsSaga(),
		watchHomeHighlightedPostsSaga(),
		watchDashboardGivingTypesSaga(),
		watchAccountUserInfoSaga(),
		watchSearchSaga(),
		watchGivingsSaga(),
		watchPaymentsSaga(),
		watchDirectoriesSaga(),
		watchPaymentSettingsSaga(),
		watchUserGivingsSaga(),
		watchMessagesSearchSaga(),
		watchDashboardMembersSaga(),
		watchEventsSaga(),
		watchRolesSaga(),
		watchUsersSaga(),
		watchDashboardStatsSaga(),
		watchDashboardServiceTypesSaga(),
		watchDashboardServicePlanSaga(),
		watchDashboardGroupTypesSaga(),
		watchSongsSaga(),
		watchDashboardEventsSaga(),
		watchEventTemplatesSaga(),
		watchDashboardReportsSaga(),
	]);
}
