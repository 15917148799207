import { call, put, takeEvery } from "redux-saga/effects";
import {
	GET_DASHBOARD_REQUESTS,
	getRequestsSuccess,
	getRequestsError,
	SEARCH_ADMIN_PRAYER_REQUESTS,
	searchAdminPrayerRequestsSuccess,
	searchAdminPrayerRequestsError,
	moveDashboardRequestToTop,
	GET_DASHBOARD_REQUESTS_STEALTH,
	getRequestsSuccessStealth,
	getRequestsErrorStealth,
} from "./actions";
import ApiBase from "../../../../../common/api/api.base";
import { RECEIVE_MESSAGE } from "../../../../../common/Messages/store/actions";

function* workerGetRequests(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/requests/all`,
			action.payload
		);
		if (!result.error) {
			yield put(getRequestsSuccess(result));
		} else {
			yield put(getRequestsError(`Failed to get requests!`));
		}
	} catch (e) {
		yield put(getRequestsError(e.message));
	}
}

function* workerSearchAdminRequests(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/requests/all`,
			action.payload
		);
		if (!result.error) {
			yield put(searchAdminPrayerRequestsSuccess(result));
		} else {
			yield put(
				searchAdminPrayerRequestsError(`Failed to get requests!`)
			);
		}
	} catch (e) {
		yield put(searchAdminPrayerRequestsError(e.message));
	}
}

function* workerGetRequestsStealth(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/requests/all`,
			action.payload
		);
		if (!result.error) {
			yield put(getRequestsSuccessStealth(result));
		} else {
			yield put(getRequestsErrorStealth(`Failed to get requests!`));
		}
	} catch (e) {
		yield put(getRequestsErrorStealth(e.message));
	}
}

function* workerReceiveDashboardMessage(action) {
	yield put(moveDashboardRequestToTop(action.payload));
}

export default function* watchDashboardRequestsSaga() {
	yield takeEvery(GET_DASHBOARD_REQUESTS, workerGetRequests);
	yield takeEvery(SEARCH_ADMIN_PRAYER_REQUESTS, workerSearchAdminRequests);
	yield takeEvery(RECEIVE_MESSAGE, workerReceiveDashboardMessage);
	yield takeEvery(GET_DASHBOARD_REQUESTS_STEALTH, workerGetRequestsStealth);
}
