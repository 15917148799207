import React, { useEffect } from "react";
import HomeArticles from "./HomeArticles";
import { useDispatch, useSelector } from "react-redux";
import { getHomeHighlightedPosts } from "./store/actions";
import pageSizes from "../../../../common/constants/pageSizes";

const HomeArticlesContainer = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			getHomeHighlightedPosts({
				skip: 0,
				take: pageSizes.HOME_HIGHLIGHTED_POSTS_TAKE,
			})
		);
	}, []);

	const posts = useSelector((x) => x.homeHighlightedPosts.posts);

	return <HomeArticles posts={posts} />;
};

export default HomeArticlesContainer;
