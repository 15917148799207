import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_POSTS,
    getPostsSuccess,
    getPostsError
} from './actions';
import ApiBase from '../../../common/api/api.base';

function* workerGetPosts(action) {
    try {
        const result = yield call(ApiBase.get, `/api/paginated-posts`, action.payload);
        if (!result.error) {
            yield put(getPostsSuccess(result));
        } else {
            yield put(getPostsError(`Failed to get posts!`));
        }
    } catch (e) {
        yield put(getPostsError(e.message));
    }
}

export default function* watchPostsSaga() {
    yield takeEvery(GET_POSTS, workerGetPosts);    
}