export const getCardBrandFullName = (stripeBrand)=>{
    switch(stripeBrand){
        case 'visa':
            return 'Visa';
        case 'mastercard':
            return 'Mastercard';
        case 'amex':
            return 'AmEx';
        case 'discover':
            return 'Discover'
        case 'diners':
            return 'Diners';
        case 'jcb':
            return 'JCB';
        case 'unionpay':
            return 'UnionPay';
        default:
            return 'Unknown brand';
    }
}