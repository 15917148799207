import { call, put, takeEvery } from "redux-saga/effects";
import {
    RUN_EVENTS_PDF_REPORT,
    RUN_EVENTS_HTML_REPORT,
    runEventsPdfReportSuccess,
    runEventsPdfReportError,
    runEventsHtmlReportSuccess,
    runEventsHtmlReportError,
    RUN_GIVINGS_PDF_REPORT,
    RUN_GIVINGS_HTML_REPORT,
    runGivingsHtmlReportSuccess,
    runGivingsHtmlReportError,
    runGivingsPdfReportSuccess,
    runGivingsPdfReportError,
    GET_GIVINGS_REPORT_DETAILS,
    getGivingsReportDetailsError,
    getGivingsReportDetailsSuccess,
    sendTaxReceiptSuccess,
    sendTaxReceiptError,
    SEND_TAX_RECEIPT
} from './actions';

import { toast } from "react-toastify";

import ApiBase from "../../../../../common/api/api.base";
import fileDownload from "js-file-download";
import { formatStringDateToMMDDYYYY } from "../../../../../common/helpers/date.utils";

function* workerRunEventsPdfReport(action) {
    try {
        const result = yield call(ApiBase.downloadFile, '/api/events/report-pdf', action.payload);
        if (result.status === 200) {
            yield put(runEventsPdfReportSuccess(result.data));
            const {startDate, endDate} = action.payload;
            fileDownload(result.data, `Events report ${formatStringDateToMMDDYYYY(startDate)}-${formatStringDateToMMDDYYYY(endDate)}.pdf`);
        }
        else{
            toast.error('Failed to generate pdf report');
            yield put(runEventsPdfReportError('Failed to generate report'));
        }
    }
    catch (e) {
        yield put(runEventsPdfReportError(e.message));
    }
}

function* workerRunEventsHtmlReport(action) {
    try {
        const result = yield call(ApiBase.post, '/api/events/report-html', action.payload);
        if(!result.error){
            yield put(runEventsHtmlReportSuccess(result));
            const {startDate, endDate} = action.payload;
            fileDownload(result, `Events report ${formatStringDateToMMDDYYYY(startDate)}-${formatStringDateToMMDDYYYY(endDate)}.html`);
        }
        else{
            yield put(runEventsPdfReportError(result.error));
        }
    }
    catch (e) {
        yield put(runEventsHtmlReportError(e.message));
    }
}

function* workerRunGivingsPdfReport(action) {
    try {
        const result = yield call(ApiBase.downloadFile, '/api/givings/report-pdf', action.payload);
        if (result.status === 200) {
            yield put(runGivingsPdfReportSuccess(result.data));
            const {startDate, endDate} = action.payload;
            fileDownload(result.data, `Givings report ${formatStringDateToMMDDYYYY(startDate)}-${formatStringDateToMMDDYYYY(endDate)}.pdf`);
        }
        else{
            toast.error('Failed to generate pdf report');
            yield put(runGivingsPdfReportError('Failed to generate report'));
        }
    }
    catch (e) {
        yield put(runGivingsPdfReportError(e.message));
    }
}

function* workerRunGivingsHtmlReport(action) {
    try {
        const result = yield call(ApiBase.post, '/api/givings/report-html', action.payload);
        if(!result.error){
            yield put(runGivingsHtmlReportSuccess(result));
            const {startDate, endDate} = action.payload;
            fileDownload(result, `Givings report ${formatStringDateToMMDDYYYY(startDate)}-${formatStringDateToMMDDYYYY(endDate)}.html`);
        }
        else{
            yield put(runGivingsPdfReportError(result.error));
        }
    }
    catch (e) {
        yield put(runGivingsHtmlReportError(e.message));
    }
}

function* workerGetGivingReportDetails(action){
    try {
        const result = yield call(ApiBase.post, '/api/givings/report-details', action.payload);
        if(!result.error){
            yield put(getGivingsReportDetailsSuccess(result));           
        }
        else{
            yield put(getGivingsReportDetailsError(result.error));
        }
    }
    catch (e) {
        yield put(getGivingsReportDetailsError(e.message));
    }
}

function* workerSendTaxReceipt(action){
    try{
        const result = yield call(ApiBase.post, '/api/givings/tax-receipt', action.payload);
        if(!result.error){
            yield put(sendTaxReceiptSuccess(result));
            toast.success("Tax receipt email has been delivered to user!");
        }
        else{
            yield put(sendTaxReceiptError(result.error));
        }
    }
    catch(e){
        yield put(sendTaxReceiptSuccess(e.message));
    }
}

export default function* watchDashboardReportsSaga() {
    yield takeEvery(RUN_EVENTS_PDF_REPORT, workerRunEventsPdfReport);
    yield takeEvery(RUN_EVENTS_HTML_REPORT, workerRunEventsHtmlReport);
    yield takeEvery(RUN_GIVINGS_HTML_REPORT, workerRunGivingsHtmlReport);
    yield takeEvery(RUN_GIVINGS_PDF_REPORT, workerRunGivingsPdfReport);
    yield takeEvery(GET_GIVINGS_REPORT_DETAILS, workerGetGivingReportDetails);
    yield takeEvery(SEND_TAX_RECEIPT, workerSendTaxReceipt);
}