import React from "react";
import { Link } from "react-router-dom";
import HomePostsList from "./components/HomePostsList";

const HomePosts = ({ posts }) => {
	return (
		<section className="next">
			<div className="next__header">
				<h2 className="next__title / title  title_size_h2">Up next:</h2>

				<Link className="next__view-all / link" to={"/posts"}>
					View all posts &gt;
				</Link>
			</div>
			<HomePostsList posts={posts} />
		</section>
	);
};

export default HomePosts;
