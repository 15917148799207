import {
	GET_STATES_DIRECTORY_REQUEST,
	GET_STATES_DIRECTORY_SUCCESS,
	GET_STATES_DIRECTORY_ERROR,
} from "../../actions/directories/states.directory.actions";

const initialState = {
	error: null,
	states: [],
	loaded: false,
	loading: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_STATES_DIRECTORY_REQUEST:
			return { ...state, loading: true, loaded: false };
		case GET_STATES_DIRECTORY_SUCCESS:
			return {
				...state,
				states: action.payload,
				loading: false,
				loaded: true,
			};
		case GET_STATES_DIRECTORY_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
