import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as TwitterIcon } from "../../../../../assets/img/icons/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../../../../assets/img/icons/facebook.svg";
import { ReactComponent as PlayIcon } from "../../../../../assets/img/icons/play.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { formatStringDateToMMDDYYYY } from "../../../../../common/helpers/date.utils";
import { dispatch } from "../../../../../redux/root.store";
import { openGivingModal } from "../../../../Giving/store/modals/actions";
import ReactPlayer from "react-player";

const HomeVideosListItem = ({ video, showRequestModal }) => {
	const [showPlayIcon, setShowPlayIcon] = useState(true);
	const [playing, setPlay] = useState(false);
	const [showControls, setShowControls] = useState(
		!/petersrock-dev-storage/.test(video.videoUrl)
	);

	const play = () => {
		setShowPlayIcon(false);
		setPlay(true);
		setShowControls(true);
	};

	const onGivingClick = () => {
		dispatch(openGivingModal("giving"));
	};

	const onReady = () => {
		if (document.querySelector(".fb-video")) {
			document.querySelectorAll(".fb-video").forEach((elem) => {
				// If video is vertical then set width based on 16/9 aspect ratio coefficient
				if (
					elem?.children[0]?.clientWidth <
					elem?.children[0]?.clientHeight
				) {
					elem.style = `height: 415px; width: ${415 / 1.77}px`;
				}
			});
		}
	};

	const origin = window.location.origin;

	return (
		<>
			<div className="video__content">
				<div className="react-player-wrapper">
					<ReactPlayer
						url={video.videoUrl}
						// url="https://www.facebook.com/sbullis77/videos/10223632496742413/"
						width="100%"
						className="react-player"
						playing={playing}
						controls={showControls}
						height="100%"
						onReady={(props) => {
							onReady();
						}}
					/>
				</div>
				{showPlayIcon && /petersrock-dev-storage/.test(video.videoUrl) && (
					<button
						className="video__button  video__button_overlay  video__button_play"
						type="button"
						aria-label="Play video"
						onClick={play}>
						<PlayIcon />
					</button>
				)}
			</div>

			<div className="video__info / row / justify-content-between">
				<div className="col-auto">
					{/* <a href="" className="video__name">{video.name}</a> */}
					<Link className="video__name" to={`/videos/${video.id}`}>
						{video.name}
					</Link>
					{/* <p className="video__name">video.name</p> */}
				</div>

				<div className="col-auto">
					<span>
						{formatStringDateToMMDDYYYY(video.creationDate)}
					</span>
				</div>
			</div>

			<div className="video__actions / row / no-gutters">
				<div className="video__action / col-auto">
					<button
						className="btn"
						type="button"
						onClick={showRequestModal}>
						Prayer Request
					</button>
				</div>

				<div className="video__action / col-auto">
					<button
						className="btn"
						onClick={onGivingClick}
						type="button">
						Give
					</button>
				</div>

				<div className="video__action / col-auto">
					<Link className="btn" to={`/videos/${video.id}`}>
						Comment
					</Link>
				</div>

				<div className="video__social / col-12 col-lg-auto / ml-xl-auto">
					<span>Share</span>

					<TwitterShareButton
						resetButtonStyle={false}
						className="video__share  video__share_tw"
						url={`${origin}/videos/${video.id}`}
						title="Share via Twitter">
						<TwitterIcon />
					</TwitterShareButton>

					<FacebookShareButton
						resetButtonStyle={false}
						className="video__share  video__share_fb"
						url={`${origin}/videos/${video.id}`}
						title="Share via Facebook">
						<FacebookIcon />
					</FacebookShareButton>
				</div>
			</div>
		</>
	);
};

export default HomeVideosListItem;
