import React from "react";
import { useForm } from "react-hook-form";
import { ReactComponent as CloseIcon } from "../../assets/img/icons/close.svg";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

const RequestModal = ({ createRequest, loading, show, handleClose, page }) => {
	const { register, handleSubmit, errors, reset } = useForm();

	const onSubmit = (data) => {
		createRequest({ ...data, page });
		reset();
		handleClose();
	};

	const closeModal = () => {
		handleClose();
	};

	return (
		<Modal
			isOpen={show}
			toggle={closeModal}
			centered
			className="modal-compact">
			<div className="modal-header / align-items-center">
				<h5
					className="modal-title / title  title_size_h2"
					id="login_modal_title">
					Prayer request
				</h5>
				<button
					className="close"
					type="button"
					aria-label="Close"
					onClick={closeModal}>
					<CloseIcon />
				</button>
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody className="wide-modal-body-without-shadow">
					<div className="form-group">
						<label
							className="form-label  form-label_required"
							htmlFor="prayer_request">
							My Prayer Request
						</label>
						<textarea
							className="form-control"
							id="prayer_request"
							name="requestText"
							cols="30"
							rows="5"
							placeholder="Type your request"
							required
							ref={register({
								required: true,
								maxLength: 4096,
							})}
						/>
						{errors.requestText?.type === "required" && (
							<span className="form-validation-error">
								Please, type your answer
							</span>
						)}
						{errors.requestText?.type === "maxLength" && (
							<span className="form-validation-error">
								Max length is 4096 characters
							</span>
						)}
					</div>
				</ModalBody>
				<ModalFooter className="wide-modal-body-without-shadow">
					<button disabled={loading} className="btn" type="submit">
						Send
						{loading && <Spinner size="sm" />}
					</button>
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default RequestModal;
