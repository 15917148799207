import { call, put, takeEvery } from "redux-saga/effects";

import {
    GET_ALL_GIVING_TYPES,
    getAllGivingTypesSuccess,
    getAllGivingTypesError,
    createOneTimeGivingSuccess,
    createOneTimeGivingError,
    CREATE_ONE_TIME_GIVING,
    GET_ALL_GIVING_PLANS,
    getAllGivingPlansSuccess,
    getAllGivingPlansError,
    GET_DATA_FOR_SUBSCRIPTION,
    getDataForSubscriptionSuccess,
    getDataForSubscriptionError, createAnonymousGivingSuccess, createAnonymousGivingError, CREATE_ANONYMOUS_GIVING
} from './actions';

import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";

function* workerGetGivingTypes(action){
    try {
        const result = yield call(ApiBase.get, '/api/givingTypes');
        if (!result.error) {
            yield put(getAllGivingTypesSuccess(result));
        } else {
            yield put(getAllGivingTypesError('Failed to fetch giving types'));
        }
    } catch (e) {
        yield put(getAllGivingTypesError(e.message));
    }
}

function* workerGetGivingPlans(action){
    try {
        const result = yield call(ApiBase.get, '/api/giving-plans');
        if (!result.error) {
            yield put(getAllGivingPlansSuccess(result));
        } else {
            yield put(getAllGivingPlansError('Failed to fetch giving plans'));
        }
    } catch (e) {
        yield put(getAllGivingPlansError(e.message));
    }
}

function* workerCreateOneTimeGiving(action){
    try{
        const result = yield call(ApiBase.post,'/api/givings/one-time-giving', {designations:action.payload});
        if(!result.error){
            yield put(createOneTimeGivingSuccess(result));
        }else{
            toast.error('Failed to confirm gift, please try to reload page');
            yield put(createOneTimeGivingError('Failed to confirm gift, please try to reload page'));
        }        
    }
    catch(e){
        yield put(createOneTimeGivingError(e.message))
    }
}

function* workerCreateAnonymousGiving(action){
    try{
        const result = yield call(ApiBase.post,'/api/givings/anonymous-giving', action.payload);
        if(!result.error){
            yield put(createAnonymousGivingSuccess(result));
        }else{
            toast.error('Failed to confirm gift, please try to reload page');
            yield put(createAnonymousGivingError('Failed to confirm gift, please try to reload page'));
        }        
    }
    catch(e){
        yield put(createAnonymousGivingError(e.message));
    }
}

function* workerGetDataForSubscription(action){
    try{
        const {givingPlanId, givingTypeId} = action.payload;

        const result = yield call(ApiBase.post, 
            `/api/givings/subscription-subject?givingPlanId=${givingPlanId}&givingTypeId=${givingTypeId}`);
        if(!result.error){
            yield put(getDataForSubscriptionSuccess(result));
        }
        else{
            toast.error('Failed to load data for recurring, plese try to reload page');
            yield put(getDataForSubscriptionError('Failed to load data for recurring, plese try to reload page'));
        }        
    }
    catch(e){
        yield put(getDataForSubscriptionError(e.message));
    }
}

export default function* watchGivingsSaga(){
    yield takeEvery(GET_ALL_GIVING_PLANS, workerGetGivingPlans);
    yield takeEvery(GET_ALL_GIVING_TYPES, workerGetGivingTypes);
    yield takeEvery(CREATE_ONE_TIME_GIVING, workerCreateOneTimeGiving);
    yield takeEvery(GET_DATA_FOR_SUBSCRIPTION, workerGetDataForSubscription);
    yield takeEvery(CREATE_ANONYMOUS_GIVING, workerCreateAnonymousGiving);
}