import {
    CREATE_EVENT,
    CREATE_EVENT_SUCCESS,
    CREATE_EVENT_ERROR,
    SEARCH_GROUPS,
    SEARCH_GROUPS_SUCCESS,
    SEARCH_GROUPS_ERROR,
    CLEAR_FINDED_GROUPS,
    SEARCH_SERVICE_TYPES,
    SEARCH_SERVICE_TYPES_SUCCESS,
    SEARCH_SERVICE_TYPES_ERROR,
    CLEAR_FINDED_SERVICE_TYPES,
    GET_DASHBOARD_EVENTS,
    GET_DASHBOARD_EVENTS_SUCCESS,
    GET_DASHBOARD_EVENTS_ERROR,
    DELETE_EVENT,
    DELETE_EVENT_SUCCESS,
    DELETE_EVENT_ERROR,
    UPDATE_EVENT,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_ERROR,
    SEARCH_TEMPLATES,
    SEARCH_TEMPLATES_SUCCESS,
    SEARCH_TEMPLATES_ERROR,
    CLEAR_FINDED_TEMPLATES
} from "./actions";

const initialCreateEventState = {
    createEvent: {
        loading: false,
        loaded: false,
        error: null,
        groups: {
            loading: false,
            error: null,
            loaded: false,
            entities: []
        },
        serviceTypes: {
            loading: false,
            error: null,
            loaded: false,
            entities: []
        }
    },
    eventsList: {
        loading: false,
        loaded: false,
        error: null,
        events: {
            count: 0,
            entities: []
        }
    },
    deleteEvent: {
        loading: false,
        loaded: false,
        error: null
    },
    createEvent: {
        loading: false,
        loaded: false,
        error: null,
        groups: {
            loading: false,
            error: null,
            loaded: false,
            entities: []
        },
        serviceTypes: {
            loading: false,
            error: null,
            loaded: false,
            entities: []
        },
        templates: {
            loading: false,
            error: null,
            loaded: false,
            entities: []
        }
    },
    updateEvent: {
        loading: false,
        loaded: false,
        error: null,
        groups: {
            loading: false,
            error: null,
            loaded: false,
            entities: []
        },
        serviceTypes: {
            loading: false,
            error: null,
            loaded: false,
            entities: []
        }
    }
};

function dashboardEventsReducer(state = initialCreateEventState, action) {
    switch (action.type) {
        case GET_DASHBOARD_EVENTS:
            return {
                ...state,
                eventsList: {
                    ...state.eventsList,
                    loading: true,
                    loaded: false
                }
            };
        case GET_DASHBOARD_EVENTS_SUCCESS:
            return {
                ...state,
                eventsList: {
                    ...state.eventsList,
                    loading: false,
                    loaded: true,
                    error: null,
                    events: action.payload
                }
            };
        case GET_DASHBOARD_EVENTS_ERROR:
            return {
                ...state,
                eventsList: {
                    ...state.eventsList,
                    loading: false,
                    loaded: false,
                    error: action.error
                }
            };
        case DELETE_EVENT:
            return {
                ...state,
                deleteEvent: {
                    ...state.deleteEvent,
                    loading: true,
                    loaded: false
                }
            };
        case DELETE_EVENT_SUCCESS:
            return {
                ...state,
                deleteEvent: {
                    ...state.deleteEvent,
                    loading: false,
                    loaded: true,
                    error: null
                }
            };
        case DELETE_EVENT_ERROR:
            return {
                ...state,
                deleteEvent: {
                    ...state.deleteEvent,
                    loading: false,
                    loaded: false,
                    error: action.error
                }
            };
        case UPDATE_EVENT:
            return {
                ...state,
                updateEvent: {
                    ...state.updateEvent,
                    loading: true, loaded: false
                }
            };
        case UPDATE_EVENT_SUCCESS:
            return {
                ...state,
                updateEvent: {
                    ...state.updateEvent, loading: false, loaded: true, error: null
                }
            };
        case UPDATE_EVENT_ERROR:
            return {
                ...state,
                updateEvent: {
                    ...state.updateEvent, loading: false, loaded: false, error: action.error
                }
            };
        case CREATE_EVENT:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    loading: true, loaded: false
                }
            };
        case CREATE_EVENT_SUCCESS:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent, loading: false, loaded: true, error: null
                }
            };
        case CREATE_EVENT_ERROR:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent, loading: false, loaded: false, error: action.error
                }
            };
        case SEARCH_GROUPS:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    groups: {
                        ...state.groups,
                        loading: true,
                        loaded: false
                    }
                },
                updateEvent: {
                    ...state.updateEvent,
                    groups: {
                        ...state.groups,
                        loading: true,
                        loaded: false
                    }
                }
            };
        case SEARCH_GROUPS_SUCCESS:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    groups: {
                        loading: false,
                        loaded: true,
                        error: null,
                        entities: action.payload
                    }
                },
                updateEvent: {
                    ...state.updateEvent,
                    groups: {
                        loading: false,
                        loaded: true,
                        error: null,
                        entities: action.payload
                    }
                }
            };
        case SEARCH_GROUPS_ERROR:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    groups: {
                        ...state.groups,
                        loading: false,
                        loaded: false,
                        error: action.error
                    }
                },
                updateEvent: {
                    ...state.updateEvent,
                    groups: {
                        ...state.groups,
                        loading: false,
                        loaded: false,
                        error: action.error
                    }
                }
            };
        case CLEAR_FINDED_GROUPS:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    groups: {
                        loading: false,
                        error: null,
                        loaded: false,
                        entities: []
                    }
                }
            };
        case SEARCH_TEMPLATES:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    templates: {
                        ...state.templates,
                        loading: true,
                        loaded: false
                    }
                }
            };
        case SEARCH_TEMPLATES_SUCCESS:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    templates: {
                        loading: false,
                        loaded: true,
                        error: null,
                        entities: action.payload.entities
                    }
                }
            };
        case SEARCH_TEMPLATES_ERROR:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    templates: {
                        ...state.templates,
                        loading: false,
                        loaded: false,
                        error: action.error
                    }
                }
            };
        case CLEAR_FINDED_TEMPLATES:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    templates: {
                        loading: false,
                        error: null,
                        loaded: false,
                        entities: []
                    }
                }
            };
        case SEARCH_SERVICE_TYPES:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    serviceTypes: {
                        ...state.serviceTypes,
                        loading: true,
                        loaded: false
                    }
                },
                updateEvent: {
                    ...state.updateEvent,
                    serviceTypes: {
                        ...state.serviceTypes,
                        loading: true,
                        loaded: false
                    }
                }
            };
        case SEARCH_SERVICE_TYPES_SUCCESS:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    serviceTypes: {
                        loading: false,
                        loaded: true,
                        error: null,
                        entities: action.payload
                    }
                }
            };
        case SEARCH_SERVICE_TYPES_ERROR:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    serviceTypes: {
                        ...state.serviceTypes,
                        loading: false,
                        loaded: false,
                        error: action.error
                    }
                }
            };
        case CLEAR_FINDED_SERVICE_TYPES:
            return {
                ...state,
                createEvent: {
                    ...state.createEvent,
                    serviceTypes: {
                        loading: false,
                        error: null,
                        loaded: false,
                        entities: []
                    }
                }
            };
        default:
            return state;
    }
}

export default dashboardEventsReducer;