import { call, put, takeEvery } from "redux-saga/effects";
import {
	SEARCH_FOR_SONGS_REQUEST,
	searchForSongsSuccess,
	searchForSongsError,
	SELECT_SONG_REQUEST,
	selectSongSuccess,
	selectSongError,
	GET_POPULAR_SONGS_REQUEST,
	getPopularSongsSuccess,
	getPopularSongsError,
	SEARCH_FOR_POPULAR_SONGS_REQUEST,
	searchForPopularSongsSuccess,
	searchForPopularSongsError,
} from "./actions";
import ApiBase from "../../../common/api/api.base";

function* workerSearchForSongs(action) {
	try {
		const {skip, take, searchType, query} = action.payload;

		const result = yield call(
			ApiBase.get, '/api/songs/search', {skip, take, searchType, query}
		);
		// This JSON monstrocity needed because response has unwanted "callback(" at
		// start and ";" at the end		
		if (!result.error) {
			yield put(
				searchForSongsSuccess({
					total: JSON.parse(
						result.substring(9, result.length - 2)
					).message.header.available,
					items: JSON.parse(
						result.substring(9, result.length - 2)
					).message.body.track_list,
				})
			);
		} else {
			yield put(searchForSongsError("Failed to find songs"));
		}
	} catch (error) {
		yield put(searchForSongsError("Failed to get songs"));
	}
}

function* workerSelectSong(action) {
	try {
		const result = yield call(
			ApiBase.get, '/api/songs/select',{songId:action.payload.track_id}
		);
		if (!result.error) {
			yield put(
				selectSongSuccess({
					...action.payload,
					...JSON.parse(
						result.substring(9, result.length - 2)
					).message.body,
				})
			);
		} else {
			yield put(selectSongError("Failed to fetch song data"));
		}
	} catch (error) {
		yield put(selectSongError("Failed to get song data"));
	}
}

function* workerGetPopularSongs(action) {
	try {
		const {skip, take} = action.payload;
		const result = yield call(
			ApiBase.get,
			'/api/songs/popular', {skip,take}
		);
		if (!result.error) {
			yield put(
				getPopularSongsSuccess({
					total: JSON.parse(
						result.substring(9, result.length - 2)
					).message.header.available,
					items: JSON.parse(
						result.substring(9, result.length - 2)
					).message.body.track_list,
				})
			);
		} else {
			yield put(getPopularSongsError("Failed to fetch popular songs"));
		}
	} catch (error) {
		yield put(getPopularSongsError("Failed to get popular songs"));
	}
}

function* workerSearchForPopularSongs(action) {
	try {
		const {skip, take, searchType, query} = action.payload;

		const result = yield call(
			ApiBase.get, '/api/songs/popular/search', {skip, take, searchType, query}
		);
		if (!result.error) {
			yield put(
				searchForPopularSongsSuccess({
					total: JSON.parse(
						result.substring(9, result.length - 2)
					).message.header.available,
					items: JSON.parse(
						result.substring(9, result.length - 2)
					).message.body.track_list,
				})
			);
		} else {
			yield put(
				searchForPopularSongsError("Failed to fetch popular songs")
			);
		}
	} catch (error) {
		yield put(searchForPopularSongsError(error));
	}
}

export default function* watchSongsSaga() {
	yield takeEvery(SEARCH_FOR_SONGS_REQUEST, workerSearchForSongs);
	yield takeEvery(SELECT_SONG_REQUEST, workerSelectSong);
	yield takeEvery(GET_POPULAR_SONGS_REQUEST, workerGetPopularSongs);
	yield takeEvery(
		SEARCH_FOR_POPULAR_SONGS_REQUEST,
		workerSearchForPopularSongs
	);
}
