export const CREATE_ONE_TIME_GIVING_PAYMENT_INTENT = "CREATE_ONE_TIME_GIVING_PAYMENT_INTENT";
export const CREATE_ONE_TIME_GIVING_PAYMENT_INTENT_SUCCESS = "CREATE_ONE_TIME_GIVING_PAYMENT_INTENT_SUCCESS";
export const CREATE_ONE_TIME_GIVING_PAYMENT_INTENT_ERROR = "CREATE_ONE_TIME_GIVING_PAYMENT_INTENT_ERROR";

export function createOneTimeGivingPaymentIntent(payload){
    return{
        type:CREATE_ONE_TIME_GIVING_PAYMENT_INTENT,
        payload
    }
}

export function createOneTimeGivingPaymentIntentSuccess(payload){
    return{
        type:CREATE_ONE_TIME_GIVING_PAYMENT_INTENT_SUCCESS,
        payload
    }
}

export function createOneTimeGivingPaymentIntentError(error){
    return{
        type:CREATE_ONE_TIME_GIVING_PAYMENT_INTENT_ERROR,
        error
    }
}

export const CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT = "CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT";
export const CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT_SUCCESS = "CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT_SUCCESS";
export const CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT_ERROR = "CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT_ERROR";

export function createAnonymousGivingPaymentIntent(payload){
    return{
        type:CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT,
        payload
    }
}

export function createAnonymousGivingPaymentIntentSuccess(payload){
    return{
        type:CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT_SUCCESS,
        payload
    }
}

export function createAnonymousGivingPaymentIntentError(error){
    return{
        type:CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT_ERROR,
        error
    }
}

export const RESET_PAYMENT_INTENT="RESET_PAYMENT_INTENT";
export function resetPaymentIntent(){
    return{
        type:RESET_PAYMENT_INTENT
    }
}

export const GET_USER_PAYMENT_METHODS="GET_USER_PAYMENTS_METHODS";
export const GET_USER_PAYMENT_METHODS_SUCCESS="GET_USER_PAYMENTS_METHODS_SUCCESS";
export const GET_USER_PAYMENT_METHODS_ERROR="GET_USER_PAYMENTS_METHODS_ERROR";

export function getUserPaymentMethods(payload={}){
    return{
        type:GET_USER_PAYMENT_METHODS,
        payload
    }
}

export function getUserPaymentMethodsSuccess(payload){
    return{
        type:GET_USER_PAYMENT_METHODS_SUCCESS,
        payload
    }
}

export function getUserPaymentMethodsError(error){
    return{
        type:GET_USER_PAYMENT_METHODS_ERROR,
        error
    }
}

export const SET_PAYMENT_METHOD_DEFAULT = "SET_PAYMENT_METHOD_DEFAULT";
export const SET_PAYMENT_METHOD_DEFAULT_SUCCESS = "SET_PAYMENT_METHOD_DEFAULT_SUCCESS";
export const SET_PAYMENT_METHOD_DEFAULT_ERROR = "SET_PAYMENT_METHOD_DEFAULT_ERROR";

export function setPaymentMethodDefault(payload){
    return{
        type:SET_PAYMENT_METHOD_DEFAULT,
        payload
    }
}

export function setPaymentMethodDefaultSuccess(payload){
    return{
        type:SET_PAYMENT_METHOD_DEFAULT_SUCCESS,
        payload
    }
}

export function setPaymentMethodDefaultError(error){
    return{
        type:SET_PAYMENT_METHOD_DEFAULT_ERROR,
        error
    }
}

export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const DELETE_PAYMENT_METHOD_SUCCESS="DELETE_PAYMENT_METHOD_SUCCESS";
export const DELETE_PAYMENT_METHOD_ERROR = "DELETE_PAYMENT_METHOD_ERROR";

export function deletePaymentMethod(payload){
    return{
        type:DELETE_PAYMENT_METHOD,
        payload
    }
}

export function deletePaymentMethodSuccess(payload){
    return{
        type:DELETE_PAYMENT_METHOD_SUCCESS,
        payload
    }
}

export function deletePaymentMethodError(error){
    return{
        type:DELETE_PAYMENT_METHOD_ERROR,
        error
    }
}

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const ADD_PAYMENT_METHOD_ERROR = "ADD_PAYMENT_METHOD_ERROR";
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS";

export function addPaymentMethod(payload){
    return{
        type:ADD_PAYMENT_METHOD,
        payload
    }
}

export function addPaymentMethodSuccess(payload){
    return{
        type:ADD_PAYMENT_METHOD_SUCCESS,
        payload
    }
}

export function addPaymentMethodError(error){
    return{
        type:ADD_PAYMENT_METHOD,
        error
    }
}