import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../common/api/api.base";
import {
    SEARCH_USERS,
    searchUsersSuccess,
    searchUsersError
} from "../actions/users.actions";

function* workerSearchUsers(action) {
    try {
        const result = yield call(
            ApiBase.get,
            `/api/users`,
            action.payload
        );
        if (!result.error) {
            yield put(searchUsersSuccess(result));
        } else {
            yield put(searchUsersError('Failed to fetch users'));
        }
    } catch (e) {
        yield put(searchUsersError(e.message));
    }
}

export default function* watchUsersSaga() {
    yield takeEvery(SEARCH_USERS, workerSearchUsers);
}