export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_ERROR = "GET_ROLES_ERROR";

export const getRoles = (payload) => ({
	type: GET_ROLES,
	payload,
});

export const getRolesSuccess = (payload) => ({
	type: GET_ROLES_SUCCESS,
	payload,
});

export const getRolesError = (error) => ({
	type: GET_ROLES_ERROR,
	payload: error,
});

export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_ERROR = "CREATE_ROLE_ERROR";

export function createRole(payload){
	return{
		type:CREATE_ROLE,
		payload
	}
}

export function createRoleSuccess(payload){
	return{
		type:CREATE_ROLE_SUCCESS,
		payload
	}
}

export function createRoleError(error){
	return{
		type:CREATE_ROLE_ERROR,
		error
	}
}

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR";

export function updateRole(payload){
	return{
		type:UPDATE_ROLE,
		payload
	}
}

export function updateRoleSuccess(payload){
	return{
		type:UPDATE_ROLE_SUCCESS,
		payload
	}
}

export function updateRoleError(error){
	return{
		type:UPDATE_ROLE_ERROR,
		error
	}
}

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS="DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_ERROR="DELETE_ROLE_ERROR";

export function deleteRole(payload){
	return{
		type:DELETE_ROLE,
		payload
	}
}

export function deleteRoleSuccess(payload){
	return{
		type:DELETE_ROLE_SUCCESS,
		payload
	}
}

export function deleteRoleError(error){
	return{
		type:DELETE_ROLE_ERROR,
		error
	}
}

export const UPDATE_ROLES_RANGE = "UPDATE_ROLES_RANGE";
export const UPDATE_ROLES_RANGE_SUCCESS = "UPDATE_ROLES_RANGE_SUCCESS";
export const UPDATE_ROLES_RANGE_ERROR = "UPDATE_ROLES_RANGE_ERROR";

export function updateRolesRange(payload){
	return{
		type:UPDATE_ROLES_RANGE,
		payload
	}
}

export function updateRolesRangeSuccess(payload){
	return{
		type:UPDATE_ROLES_RANGE_SUCCESS,
		payload
	}
}

export function updateRolesRangeError(error){
	return{
		type:UPDATE_ROLES_RANGE_ERROR,
		error
	}
}

export const GET_ALLOWED_ACTIONS = "GET_ALLOWED_ACTIONS";
export const GET_ALLOWED_ACTIONS_SUCCESS = "GET_ALLOWED_ACTIONS_SUCCESS";
export const GET_ALLOWED_ACTIONS_ERROR = "GET_ALLOWED_ACTIONS_ERROR";

export function getAllowedActions(payload){
	return{
		type:GET_ALLOWED_ACTIONS,
		payload
	}
}

export function getAllowedActionsSuccess(payload){
	return{
		type:GET_ALLOWED_ACTIONS_SUCCESS,
		payload
	}
}

export function getAllowedActionsError(error){
	return{
		type:GET_ALLOWED_ACTIONS_ERROR,
		error
	}
}

export const UPDATE_ROLE_ACTION = "UPDATE_ROLE_ACTION";

export function updateRoleAction(payload){
	return{
		type:UPDATE_ROLE_ACTION,
		payload
	}
}