import {
	CREATE_GROUP_TYPE,
	CREATE_GROUP_TYPE_ERROR,
	CREATE_GROUP_TYPE_SUCCESS,
	UPDATE_GROUP_TYPE,
	UPDATE_GROUP_TYPE_SUCCESS,
	UPDATE_GROUP_TYPE_ERROR,
	DELETE_GROUP_TYPE,
	DELETE_GROUP_TYPE_SUCCESS,
	DELETE_GROUP_TYPE_ERROR,
	GET_GROUP_TYPES,
	GET_GROUP_TYPES_SUCCESS,
	GET_GROUP_TYPES_ERROR	
} from "./actions";

const initialGroupTypesState = {
	error: null,
	loading: false,
	loaded: true,
	groupTypes: {
		count: 0,
		entities: [],
	}	
};

function dashboardGroupTypesReducer(state = initialGroupTypesState, action) {
	switch (action.type) {
		case CREATE_GROUP_TYPE:
			return { ...state, loading: true, loaded: false, error: null };
		case CREATE_GROUP_TYPE_SUCCESS:
			return { ...state, loading: false, loaded: true, error: null };
		case CREATE_GROUP_TYPE_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case UPDATE_GROUP_TYPE:
			return { ...state, loading: true, loaded: false, error: null };
		case UPDATE_GROUP_TYPE_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				groupTypes: updateGroupTypeInList(state, action.payload),
			};
		case UPDATE_GROUP_TYPE_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case DELETE_GROUP_TYPE:
			return { ...state, loading: true, loaded: false, error: null };
		case DELETE_GROUP_TYPE_SUCCESS:
			return { ...state, loading: false, loaded: true, error: null };
		case DELETE_GROUP_TYPE_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case GET_GROUP_TYPES:
			return { ...state, loading: true, loaded: false, error: null };
		case GET_GROUP_TYPES_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				groupTypes: action.payload,
			};
		case GET_GROUP_TYPES_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
				groupTypes: { count: 0, entities: [] },
			};		
		default:
			return state;
	}
}

function updateGroupTypeInList(state, payload) {
	const { entities, count } = state.groupTypes;

	const groupTypeIdx = entities.findIndex((g) => g.id === payload.id);

	return {
		count,
		entities: [
			...entities.slice(0, groupTypeIdx),
			payload,
			...entities.slice(groupTypeIdx + 1),
		],
	};
}

export default dashboardGroupTypesReducer;
