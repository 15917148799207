import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../../../common/api/api.base";

import{
    GET_MEMBERS_CHART_DATA,
    getMembersChartDataSuccess,
    getMembersChartDataError,
    GET_REQUESTS_CHART_DATA,
    getRequestsChartDataSuccess,
    getRequestsChartDataError,
    GET_COMMENTS_CHART_DATA,
    getCommentsChartDataSuccess,
    getCommentsChartDataError,
    getGivingsChartDataSuccess,
    getGivingsChartDataError,
    GET_GIVINGS_CHART_DATA,
    getActivitiesSuccess,
    getActivitiesError,
    GET_ACTIVITIES,
    deleteActivitySuccess,
    deleteActivityError,
    DELETE_ACTIVITY,
    deleteActivityFromList
} from './actions';

function* workerGetMembersChartData(action){
    try{
        var result = yield call(ApiBase.get, '/api/users/statistic',{period:action.payload});
        if(!result.error){
            yield put(getMembersChartDataSuccess(result));
        }
        else{
            yield put(getMembersChartDataError(result.error));
            displayChartLoadingError("members");
        }
    }
    catch(e){
        yield put(getMembersChartDataError(e.message));
        displayChartLoadingError("members");
    }
}

function* workerGetRequestsChartData(action){
    try{
        var result = yield call(ApiBase.get, '/api/requests/statistic',{period:action.payload});
        if(!result.error){
            yield put(getRequestsChartDataSuccess(result));
        }
        else{
            yield put(getRequestsChartDataError(result.error));
            displayChartLoadingError("prayer requests");
        }
    }
    catch(e){
        yield put(getRequestsChartDataError(e.message));
        displayChartLoadingError("prayer requests");
    }
}

function* workerGetCommentsChartData(action){
    try{
        var result = yield call(ApiBase.get, '/api/comments/statistic',{period:action.payload});
        if(!result.error){
            yield put(getCommentsChartDataSuccess(result));
        }
        else{
            yield put(getCommentsChartDataError(result.error));
            displayChartLoadingError("comments");
        }
    }
    catch(e){
        yield put(getCommentsChartDataError(e.message));
        displayChartLoadingError("comments");
    }
}

function* workerGetGivingsChartData(action){
    try{
        var result = yield call(ApiBase.get, '/api/givings/statistic',{period:action.payload});
        if(!result.error){
            yield put(getGivingsChartDataSuccess(result));
        }
        else{
            yield put(getGivingsChartDataError(result.error));
            displayChartLoadingError("givings");
        }
    }
    catch(e){
        yield put(getGivingsChartDataError(e.message));
        displayChartLoadingError("givings");
    }
}

function* workerGetActivities(action){
    try{
        var result = yield call(ApiBase.get, '/api/activities/for-period',action.payload);
        if(!result.error){
            yield put(getActivitiesSuccess(result));
        }
        else{
            yield put(getActivitiesError(result.error));
            toast.error(`An error occured while fetching data for recent activities table`);
        }
    }
    catch(e){
        yield put(getActivitiesError(e.message));
        toast.error(`An error occured while fetching data for recent activities table`);
    }
}

function* workerDeleteActivity(action){
    try{
        var result = yield call(ApiBase.delete, '/api/activities/'+action.payload);
        if(!result.error){
            yield put(deleteActivitySuccess(result));
            yield put(deleteActivityFromList(action.payload));
        }
        else{
            yield put(deleteActivityError(result.error));            
        }
    }
    catch(e){
        yield put(getActivitiesError(e.message));
        toast.error(`An error occured while deleting activity`);
    }
}

function displayChartLoadingError(chartName){
    toast.error(`An error occured while fetching data for ${chartName} chart`);
}

export default function* watchDashboardStatsSaga(){
    yield takeEvery(GET_MEMBERS_CHART_DATA, workerGetMembersChartData);
    yield takeEvery(GET_REQUESTS_CHART_DATA, workerGetRequestsChartData);
    yield takeEvery(GET_COMMENTS_CHART_DATA, workerGetCommentsChartData);
    yield takeEvery(GET_GIVINGS_CHART_DATA, workerGetGivingsChartData);
    yield takeEvery(GET_ACTIVITIES, workerGetActivities);
    yield takeEvery(DELETE_ACTIVITY, workerDeleteActivity);
}