import React, { Suspense } from "react";

import DashboardRouting from "./Dashboard.routing";
import DashboardLayout from "./components/DashboardLayout";
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import { Helmet } from "react-helmet";

function Dashboard({ onLogout }) {
	return (
		<>
			<Helmet>
				<title>Dashboard – Peter's Rock Ministries</title>
			</Helmet>
			<DashboardLayout onLogout={onLogout}>
				<Suspense
					fallback={
						<div className="center-loading">
							<LoadingSpinner />
						</div>
					}>
					{/* <Switch> */}
					<DashboardRouting />
					{/* </Switch> */}
				</Suspense>
			</DashboardLayout>
		</>
	);
}

export default Dashboard;
