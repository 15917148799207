export const GET_UPCOMING_EVENTS = "GET_UPCOMING_EVENTS";
export const GET_UPCOMING_EVENTS_SUCCESS = "GET_UPCOMING_EVENTS_SUCCESS";
export const GET_UPCOMING_EVENTS_ERROR = "GET_UPCOMING_EVENTS_ERROR";

export const getUpcomingEvents = (payload) => ({
	type: GET_UPCOMING_EVENTS,
	payload,
});

export const getUpcomingEventsSuccess = (payload) => ({
	type: GET_UPCOMING_EVENTS_SUCCESS,
	payload,
});

export const getUpcomingEventsError = (error) => ({
	type: GET_UPCOMING_EVENTS_ERROR,
	payload: error,
});

export const GET_LATEST_EVENTS = "GET_LATEST_EVENTS";
export const GET_LATEST_EVENTS_SUCCESS = "GET_LATEST_EVENTS_SUCCESS";
export const GET_LATEST_EVENTS_ERROR = "GET_LATEST_EVENTS_ERROR";

export const getLatestEvents = (payload) => ({
	type: GET_LATEST_EVENTS,
	payload,
});

export const getLatestEventsSuccess = (payload) => ({
	type: GET_LATEST_EVENTS_SUCCESS,
	payload,
});

export const getLatestEventsError = (error) => ({
	type: GET_LATEST_EVENTS_ERROR,
	payload: error,
});

export const SEARCH_EVENTS = "SEARCH_EVENTS";
export const SEARCH_EVENTS_SUCCESS = "SEARCH_EVENTS_SUCCESS";
export const SEARCH_EVENTS_ERROR = "SEARCH_EVENTS_ERROR";

export const searchForEvents = (payload) => ({
	type: SEARCH_EVENTS,
	payload,
});

export const searchForEventsSuccess = (payload) => ({
	type: SEARCH_EVENTS_SUCCESS,
	payload,
});

export const searchForEventsError = (error) => ({
	type: SEARCH_EVENTS_ERROR,
	payload: error,
});

export const CLEAR_EVENTS_SEARCH_RESULT = "CLEAR_EVENTS_SEARCH_RESULT";

export const clearEventsSearchResult = () => ({
	type: CLEAR_EVENTS_SEARCH_RESULT,
});
