import {
    CREATE_REQUEST,
    CREATE_REQUEST_SUCCESS,
    CREATE_REQUEST_ERROR
} from './actions';

const initialRequestsState = {
    error: null,
    loading: false,
    loaded: true    
}

function requestsReducer(state = initialRequestsState, action) {
    switch (action.type) {
        case CREATE_REQUEST:
            return { ...state, loading: true, loaded: false, error: null };
        case CREATE_REQUEST_SUCCESS:
            return { ...state, loading: false, loaded: true, error: null };
        case CREATE_REQUEST_ERROR:
            return { ...state, loading: false, loaded: false, error: action.error }        
        default:
            return state
    }
}

export default requestsReducer;