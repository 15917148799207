export const GET_USER_GIVINGS_HISTORY = "GET_USER_GIVINGS_HISTORY";
export const GET_USER_GIVINGS_HISTORY_SUCCESS = "GET_USER_GIVINGS_HISTORY_SUCCESS";
export const GET_USER_GIVINGS_HISTORY_ERROR = "GET_USER_GIVINGS_HISTORY_ERROR";

export function getUserGivingsHistory(payload){
    return{
        type:GET_USER_GIVINGS_HISTORY,
        payload
    }
}

export function getUserGivingsHistorySuccess(payload){
    return{
        type:GET_USER_GIVINGS_HISTORY_SUCCESS,
        payload
    }
}

export function getUserGivingsHistoryError(error){
    return{
        type:GET_USER_GIVINGS_HISTORY_SUCCESS,
        error
    }
}

export const REFUND = "REFUND";
export const REFUND_SUCCESS="REFUND_SUCCESS";
export const REFUND_ERROR="REFUND_ERROR";

export function refund(payload){
    return{
        type:REFUND,
        payload
    }
}

export function refundSuccess(payload){
    return{
        type:REFUND_SUCCESS,
        payload
    }
}

export function refundError(error){
    return{
        type:REFUND_ERROR,
        error
    }
}

