import {
    CREATE_EVENT,
    CREATE_EVENT_SUCCESS,
    CREATE_EVENT_ERROR,
    SEARCH_GROUPS,
    SEARCH_GROUPS_SUCCESS,
    SEARCH_GROUPS_ERROR,
    CLEAR_FINDED_GROUPS,
    SEARCH_SERVICE_TYPES,
    SEARCH_SERVICE_TYPES_SUCCESS,
    SEARCH_SERVICE_TYPES_ERROR,
    CLEAR_FINDED_SERVICE_TYPES
} from "./actions";

const initialCreateEventState = {
    loading: false,
    loaded: false,
    error: null,
    groups: {
        loading: false,
        error: null,
        loaded: false,
        entities: []
    },
    serviceTypes: {
        loading: false,
        error: null,
        loaded: false,
        entities: []
    }
};

function createEventReducer(state = initialCreateEventState, action) {
    switch (action.type) {
        case CREATE_EVENT:
            return { ...state, loading: true, loaded: false };
        case CREATE_EVENT_SUCCESS:
            return { ...state,loading: false, loaded: true, error: null };
        case CREATE_EVENT_ERROR:
            return { ...state,loading: false, loaded: false, error: action.error };
        case SEARCH_GROUPS:
            return {
                ...state,
                groups: {
                    ...state.groups,
                    loading: true,
                    loaded: false
                }
            };
        case SEARCH_GROUPS_SUCCESS:
            return {
                ...state,
                groups: {
                    loading: false,
                    loaded: true,
                    error: null,
                    entities: action.payload
                }
            };
        case SEARCH_GROUPS_ERROR:
            return {
                ...state,
                groups: {
                    ...state.groups,
                    loading: false,
                    loaded: false,
                    error: action.error
                }
            };
        case CLEAR_FINDED_GROUPS:
            return {
                ...state,
                groups: {
                    loading: false,
                    error: null,
                    loaded: false,
                    entities: []
                }
            };
        case SEARCH_SERVICE_TYPES:
            return {
                ...state,
                serviceTypes: {
                    ...state.serviceTypes,
                    loading: true,
                    loaded: false
                }
            };
        case SEARCH_SERVICE_TYPES_SUCCESS:
            return {
                ...state,
                serviceTypes: {
                    loading: false,
                    loaded: true,
                    error: null,
                    entities: action.payload
                }
            };
        case SEARCH_SERVICE_TYPES_ERROR:
            return {
                ...state,
                serviceTypes: {
                    ...state.serviceTypes,
                    loading: false,
                    loaded: false,
                    error: action.error
                }
            };
        case CLEAR_FINDED_SERVICE_TYPES:
            return {
                ...state,
                serviceTypes: {
                    loading: false,
                    error: null,
                    loaded: false,
                    entities: []
                }
            };
        default:
            return state;
    }
}

export default createEventReducer;