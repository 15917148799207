import { call, put, takeEvery } from "redux-saga/effects";
import {
	GET_ROLES,
	getRolesSuccess,
	getRolesError,
	GET_ALLOWED_ACTIONS,
	getAllowedActionsSuccess,
	getAllowedActionsError,
	CREATE_ROLE,
	createRoleSuccess,
	createRoleError,
	UPDATE_ROLE,
	updateRoleSuccess,
	updateRoleError,
	DELETE_ROLE,
	deleteRoleSuccess,
	deleteRoleError,
	UPDATE_ROLES_RANGE,
	updateRolesRangeSuccess,
	updateRolesRangeError,
	getRoles,
} from "./actions";
import { toast } from "react-toastify";

import ApiBase from "../../../../../common/api/api.base";

function* workerGetRoles(_) {
	try {
		const result = yield call(ApiBase.get, "/api/roles");
		if (!result.error) {
			yield put(getRolesSuccess(result));
		} else {
			yield put(getRolesError(result.error));
		}
	} catch (e) {
		yield put(getRolesError(e.message));
	}
}

function* workerGetAllowedActions(_) {
	try {
		const result = yield call(ApiBase.get, "/api/allowed-actions");
		if (!result.error) {
			yield put(getAllowedActionsSuccess(result));
		} else {
			yield put(getAllowedActionsError(result.error));
		}
	} catch (e) {
		yield put(getAllowedActionsError(e.message));
	}
}

function* workerCreateRole(action) {
	try {
		const result = yield call(ApiBase.post, "/api/roles", action.payload);
		if (!result.error) {
			yield put(createRoleSuccess(result));
		} else {
			yield put(createRoleError(result.error));
		}
	} catch (e) {
		yield put(createRoleError(e.message));
	}
}

function* workerUpdateRole(action) {
	try {
		const result = yield call(
			ApiBase.put,
			"/api/roles/" + action.payload.id,
			action.payload.role
		);
		if (!result.error) {
			yield put(updateRoleSuccess(result));
			yield put(getRoles());
		} else {
			yield put(updateRoleError(result.error));
		}
	} catch (e) {
		yield put(updateRoleError(e.message));
	}
}

function* workerUpdateRolesRange(action) {
	try {
		const result = yield call(
			ApiBase.put,
			"/api/roles/range",
			action.payload
		);
		if (!result.error) {
			yield put(updateRolesRangeSuccess(result));
			toast.success("Changes saved successfully!");
		} else {
			yield put(updateRolesRangeError(result.error));
		}
	} catch (e) {
		yield put(updateRolesRangeError(e.message));
	}
}

function* workerDeleteRole(action) {
	try {
		const result = yield call(
			ApiBase.delete,
			"/api/roles/" + action.payload
		);
		if (!result.error) {
			yield put(deleteRoleSuccess({ ...result, roleId: action.payload }));
		} else {
			yield put(deleteRoleError(result.error));
		}
	} catch (e) {
		yield put(deleteRoleError(e.message));
	}
}

export default function* watchRolesSaga() {
	yield takeEvery(GET_ROLES, workerGetRoles);
	yield takeEvery(UPDATE_ROLE, workerUpdateRole);
	yield takeEvery(CREATE_ROLE, workerCreateRole);
	yield takeEvery(UPDATE_ROLES_RANGE, workerUpdateRolesRange);
	yield takeEvery(DELETE_ROLE, workerDeleteRole);
	yield takeEvery(GET_ALLOWED_ACTIONS, workerGetAllowedActions);
}
