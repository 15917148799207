import { call, put, takeEvery } from "redux-saga/effects";
import{
    GET_HOME_VIDEOS,
    getHomeVideosSuccess,
    getHomeVideosError
} from './actions';

import ApiBase from '../../../../../common/api/api.base';

function* workerGetHomeVideos(_action) {
    try {
        const result = yield call(ApiBase.get, '/api/videos/home');
        if (!result.error) {
            yield put(getHomeVideosSuccess(result));
        } else {
            yield put(getHomeVideosError('Failed to get videos!'));
        }
    } catch (e) {
        yield put(getHomeVideosError(e.message));
    }
}

export default function* watchHomeVideosSaga(){
    yield takeEvery(GET_HOME_VIDEOS, workerGetHomeVideos);
}