import {
    RUN_EVENTS_PDF_REPORT,
    RUN_EVENTS_PDF_REPORT_SUCCESS,
    RUN_EVENTS_PDF_REPORT_ERROR,
    RUN_EVENTS_HTML_REPORT,
    RUN_EVENTS_HTML_REPORT_SUCCESS,
    RUN_EVENTS_HTML_REPORT_ERROR,
    GET_GIVINGS_REPORT_DETAILS,
    GET_GIVINGS_REPORT_DETAILS_SUCCESS,
    GET_GIVINGS_REPORT_DETAILS_ERROR,
    RUN_GIVINGS_HTML_REPORT,
    RUN_GIVINGS_HTML_REPORT_SUCCESS,
    RUN_GIVINGS_HTML_REPORT_ERROR,
    RUN_GIVINGS_PDF_REPORT,
    RUN_GIVINGS_PDF_REPORT_SUCCESS,
    RUN_GIVINGS_PDF_REPORT_ERROR,
    CLEAR_GIVINGS_SEARCH_RESULT,
    SEND_TAX_RECEIPT,
    SEND_TAX_RECEIPT_SUCCESS,
    SEND_TAX_RECEIPT_ERROR
} from './actions';

const initialDashboardReportsState = {
    events: {
        loading: false,
        loaded: false,
        error: null,
        pdfData: null,
        htmlData: null
    },
    givingsList: {
        loading: false,
        loaded: false,
        error: null,
        givings: {
            count: 0,
            entities: []
        }
    },
    givingsReport: {
        loading: false,
        loaded: false,
        error: null,
        pdfData: null,
        htmlData: null
    },
    taxReceipt: {
        loading: false,
        loaded: false,
        error: null
    }
}

function dashboardReportsReducer(state = initialDashboardReportsState, action) {
    switch (action.type) {
        case RUN_EVENTS_PDF_REPORT:
            return {
                ...state,
                events: {
                    ...state.events,
                    loading: true,
                    loaded: false
                }
            };
        case RUN_EVENTS_PDF_REPORT_SUCCESS:
            return {
                ...state, events: {
                    ...state.events,
                    loading: false,
                    loaded: true,
                    error: null,
                    pdfData: action.payload
                }
            };
        case RUN_EVENTS_PDF_REPORT_ERROR:
            return {
                ...state, events: {
                    ...state.events,
                    loading: false,
                    loaded: false,
                    error: action.error
                }
            };
        case RUN_EVENTS_HTML_REPORT:
            return {
                ...state, events: {
                    ...state.events,
                    loading: true,
                    loaded: false
                }
            };
        case RUN_EVENTS_HTML_REPORT_SUCCESS:
            return {
                ...state, events: {
                    ...state.events,
                    loading: false,
                    loaded: true,
                    error: null,
                    htmlData: action.payload
                }
            };
        case RUN_EVENTS_HTML_REPORT_ERROR:
            return {
                ...state, events: {
                    ...state.events,
                    loading: false,
                    loaded: false,
                    error: action.error
                }
            };
        case RUN_GIVINGS_PDF_REPORT:
            return {
                ...state,
                givingsReport: {
                    ...state.givingsReport,
                    loading: true,
                    loaded: false
                }
            };
        case RUN_GIVINGS_PDF_REPORT_SUCCESS:
            return {
                ...state, givingsReport: {
                    ...state.givingsReport,
                    loading: false,
                    loaded: true,
                    error: null,
                    pdfData: action.payload
                }
            };
        case RUN_GIVINGS_PDF_REPORT_ERROR:
            return {
                ...state, givingsReport: {
                    ...state.givingsReport,
                    loading: false,
                    loaded: false,
                    error: action.error
                }
            };
        case RUN_GIVINGS_HTML_REPORT:
            return {
                ...state, givingsReport: {
                    ...state.givingsReport,
                    loading: true,
                    loaded: false
                }
            };
        case RUN_GIVINGS_HTML_REPORT_SUCCESS:
            return {
                ...state, givingsReport: {
                    ...state.givingsReport,
                    loading: false,
                    loaded: true,
                    error: null,
                    htmlData: action.payload
                }
            };
        case RUN_GIVINGS_HTML_REPORT_ERROR:
            return {
                ...state, givingsReport: {
                    ...state.givingsReport,
                    loading: false,
                    loaded: false,
                    error: action.error
                }
            };
        case GET_GIVINGS_REPORT_DETAILS:
            return {
                ...state, givingsList: {
                    ...state.givingsList,
                    loading: true,
                    loaded: false
                }
            };
        case GET_GIVINGS_REPORT_DETAILS_SUCCESS:
            return {
                ...state, givingsList: {
                    ...state.givingsList,
                    loading: false,
                    loaded: true,
                    error: null,
                    givings: action.payload
                }
            };
        case GET_GIVINGS_REPORT_DETAILS_ERROR:
            return {
                ...state, givingsList: {
                    ...state.givingsList,
                    loading: false,
                    loaded: false,
                    error: action.error
                }
            }
        case CLEAR_GIVINGS_SEARCH_RESULT:
            return {
                ...state,
                givingsList: initialDashboardReportsState.givingsList
            };
        case SEND_TAX_RECEIPT:
            return {
                ...state,
                taxReceipt: {
                    ...state.taxReceipt,
                    loading: true,
                    loaded: false
                }
            };
        case SEND_TAX_RECEIPT_SUCCESS:
            return {
                ...state,
                taxReceipt: {
                    ...state.taxReceipt,
                    loading: false,
                    loaded: true,
                    error: null
                }
            };
        case SEND_TAX_RECEIPT_ERROR:
            return {
                ...state,
                taxReceipt: {
                    ...state.taxReceipt,
                    loading: false,
                    loaded: false,
                    error: false
                }
            };
        default:
            return state;
    }
}

export default dashboardReportsReducer;