import { call, put, takeEvery } from "redux-saga/effects";

import {
	GET_SERVICE_TYPES,
	getServiceTypes,
	getServiceTypesError,
	getServiceTypesSuccess,
	CREATE_SERVICE_TYPE,
	createServiceTypeError,
	createServiceTypeSuccess,
	UPDATE_SERVICE_TYPE,
	updateServiceTypeError,
	updateServiceTypeSuccess,
	DELETE_SERVICE_TYPE,
	deleteServiceTypeSuccess,
	deleteServiceTypeError,
	GET_MASTER_CALENDAR_EVENTS_REQUEST,
	getMasterCalendarEventsSuccess,
	getMasterCalendarEventsError,
} from "./actions";

import ApiBase from "../../../../../common/api/api.base";
import pageSizes from "../../../../../common/constants/pageSizes";

function* workerGetServiceTypes(action) {
	try {
		const result = yield call(
			ApiBase.get,
			"/api/service-types/paginated",
			action.payload
		);
		if (!result.error) {
			yield put(getServiceTypesSuccess(result));
		} else {
			yield put(getServiceTypesError(result.error));
		}
	} catch (e) {
		yield put(getServiceTypesError(e.message));
	}
}

function* workerCreateServiceType(action) {
	try {
		const result = yield call(
			ApiBase.post,
			"/api/service-types",
			action.payload
		);
		if (!result.error) {
			yield put(createServiceTypeSuccess(result));
			yield put(
				getServiceTypes({
					skip: 0,
					take: pageSizes.DASHBOARD_SERVICE_TYPES_TAKE,
				})
			);
		} else {
			yield put(createServiceTypeError(result));
		}
	} catch (e) {
		yield put(createServiceTypeError(e.message));
	}
}

function* workerUpdateServiceType(action) {
	try {
		const result = yield call(
			ApiBase.put,
			"/api/service-types/" + action.payload.id,
			action.payload.body
		);
		if (!result.error) {
			yield put(updateServiceTypeSuccess(result));
			yield put(
				getServiceTypes({
					skip: 0,
					take: pageSizes.DASHBOARD_SERVICE_TYPES_TAKE,
				})
			);
		} else {
			yield put(updateServiceTypeError(result));
		}
	} catch (e) {
		yield put(updateServiceTypeError(e.message));
	}
}

function* workerDeleteServiceType(action) {
	try {
		const result = yield call(
			ApiBase.delete,
			"/api/service-types/" + action.payload
		);
		if (!result.error) {
			yield put(deleteServiceTypeSuccess(result));
			yield put(
				getServiceTypes({
					skip: 0,
					take: pageSizes.DASHBOARD_SERVICE_TYPES_TAKE,
				})
			);
		} else {
			yield put(deleteServiceTypeError(result));
		}
	} catch (e) {
		yield put(deleteServiceTypeError(e.message));
	}
}

function* workerGetMasterCalendarEvents(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/events/by-month?month=${action.payload.month}&year=${action.payload.year}`
		);
		if (!result.error) {
			yield put(getMasterCalendarEventsSuccess(result));
		} else {
			yield put(getMasterCalendarEventsError(result));
		}
	} catch (e) {
		yield put(getMasterCalendarEventsError(e));
	}
}

export default function* watchDashboardServiceTypesSaga() {
	yield takeEvery(GET_SERVICE_TYPES, workerGetServiceTypes);
	yield takeEvery(CREATE_SERVICE_TYPE, workerCreateServiceType);
	yield takeEvery(UPDATE_SERVICE_TYPE, workerUpdateServiceType);
	yield takeEvery(DELETE_SERVICE_TYPE, workerDeleteServiceType);
	yield takeEvery(
		GET_MASTER_CALENDAR_EVENTS_REQUEST,
		workerGetMasterCalendarEvents
	);
}
