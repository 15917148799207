import React from "react";
import LoginModal from "./LoginModal";
import { useDispatch, useSelector } from "react-redux";
import {
	loginUser,
	sendResetPassword,
	sendConfirmationEmail,
} from "../../redux/actions/auth.actions";

const LoginModalContainer = ({ show, handleClose, setShowSignUp }) => {
	const dispatch = useDispatch();

	const onLogin = (payload) => dispatch(loginUser(payload));
	const onSendResetPasswordEmail = (payload) =>
		dispatch(sendResetPassword(payload));
	const onSendConfirmationEmail = (payload) =>
		dispatch(sendConfirmationEmail(payload));

	const loading = useSelector((state) => state.auth.loading);
	const loaded = useSelector((state) => state.auth.loaded);
	const emailSent = useSelector((state) => state.auth.emailSent);
	const emailSending = useSelector((state) => state.auth.emailSending);

	return (
		<LoginModal
			show={show}
			handleClose={handleClose}
			emailSending={emailSending}
			loading={loading}
			emailSent={emailSent}
			loginUser={onLogin}
			sendResetPasswordEmail={onSendResetPasswordEmail}
			sendConfirmationEmail={onSendConfirmationEmail}
			setShowSignUp={setShowSignUp}
		/>
	);
};

export default LoginModalContainer;
