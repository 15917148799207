import React from "react";
import RequestModal from "./RequestModal";
import { useDispatch, useSelector } from "react-redux";
import { createRequest } from "./store/actions";

const RequestModalContainer = ({ show, handleClose, page }) => {
	const dispatch = useDispatch();

	const loading = useSelector((x) => x.requests.loading);
	const onCreate = (payload) => dispatch(createRequest(payload));

	return (
		<RequestModal
			createRequest={onCreate}
			loading={loading}
			show={show}
			handleClose={handleClose}
			page={page}
		/>
	);
};

export default RequestModalContainer;
