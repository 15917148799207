import React, { useState, useEffect } from "react";
import { ModalBody, ModalFooter, Spinner } from "reactstrap";
import { getCardBrandFullName } from "../../../../../common/helpers/card.utils";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import ApiBase from "../../../../../common/api/api.base";

const PaymentMethods = ({
	goToCheckoutForm,
	loading,
	dataForSubscription,
	paymentMethods,
	onSuccess,
}) => {
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState("");
	const stripe = useStripe();
	const elements = useElements();

	const handlePay = async (ev) => {
		ev.preventDefault();
		setProcessing(true);
		const { customerId, priceId } = dataForSubscription;

		const latestInvoicePaymentIntentStatus = localStorage.getItem(
			"latestInvoicePaymentIntentStatus"
		);

		const paymentMethodId = selectedPaymentMethod.stripePaymentMethodId;

		if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
			const invoiceId = localStorage.getItem("latestInvoiceId");
			const isPaymentRetry = true;
			// create new payment method & retry payment on invoice with new payment method
			pay(
				paymentMethodId,
				customerId,
				priceId,
				isPaymentRetry,
				invoiceId
			);
		} else {
			// create new payment method & create subscription
			pay(paymentMethodId, customerId, priceId, false, "");
		}
	};

	const pay = (
		paymentMethodId,
		customerId,
		priceId,
		isPaymentRetry,
		invoiceId
	) => {
		if (isPaymentRetry) {
			// Update the payment method and retry invoice payment
			retryInvoiceWithNewPaymentMethod({
				customerId: customerId,
				paymentMethodId: paymentMethodId,
				invoiceId: invoiceId,
				priceId: priceId,
			});
		} else {
			// Create the subscription
			createSubscription({
				customerId: customerId,
				paymentMethodId: paymentMethodId,
				priceId: priceId,
			});
		}
	};

	const createSubscription = ({ customerId, paymentMethodId, priceId }) => {
		return (
			ApiBase.post("/api/givings/create-subscription", {
				customerId: customerId,
				paymentMethodId: paymentMethodId,
				priceId: priceId,
			})
				// If the card is declined, display an error to the user.
				.then((result) => {
					if (result.error) {
						// The card had an error when trying to attach it to a customer.
						throw result;
					}
					return result;
				})
				// Normalize the result to contain the object returned by Stripe.
				// Add the addional details we need.
				.then((result) => {
					return {
						paymentMethodId: paymentMethodId,
						priceId: priceId,
						subscription: result,
					};
				})
				// Some payment methods require a customer to be on session
				// to complete the payment process. Check the status of the
				// payment intent to handle these actions.
				.then(handleCustomerActionRequired)
				// If attaching this card to a Customer object succeeds,
				// but attempts to charge the customer fail, you
				// get a requires_payment_method error.
				.then(handlePaymentMethodRequired)
				// No more actions required. Provision your service for the user.
				.then(onSubscriptionComplete)
				.catch((error) => {
					// An error has happened. Display the failure to the user here.
					// We utilize the HTML element we created.
					displayError(error);
				})
		);
	};

	const handleCustomerActionRequired = ({
		subscription,
		invoice,
		priceId,
		paymentMethodId,
		isRetry,
	}) => {
		if (subscription && subscription.status === "active") {
			// Subscription is active, no customer actions required.
			return { subscription, priceId, paymentMethodId };
		}

		// If it's a first payment attempt, the payment intent is on the subscription latest invoice.
		// If it's a retry, the payment intent will be on the invoice itself.
		let paymentIntent = invoice
			? invoice.payment_intent
			: subscription.latest_invoice.payment_intent;

		if (
			paymentIntent.status === "requires_action" ||
			(isRetry === true &&
				paymentIntent.status === "requires_payment_method")
		) {
			return stripe
				.confirmCardPayment(paymentIntent.client_secret, {
					payment_method: paymentMethodId,
					setup_future_usage: "off_session",
				})
				.then((result) => {
					if (result.error) {
						// Start code flow to handle updating the payment details.
						// Display error message in your UI.
						// The card was declined (i.e. insufficient funds, card has expired, etc).
						throw result;
					} else {
						if (result.paymentIntent.status === "succeeded") {
							// Show a success message to your customer.
							// There's a risk of the customer closing the window before the callback.
							// We recommend setting up webhook endpoints later in this guide.
							return {
								priceId: priceId,
								subscription: subscription,
								invoice: invoice,
								paymentMethodId: paymentMethodId,
							};
						}
					}
				})
				.catch((error) => {
					displayError(error);
				});
		} else {
			// No customer action needed.
			return { subscription, priceId, paymentMethodId };
		}
	};

	const handlePaymentMethodRequired = ({
		subscription,
		paymentMethodId,
		priceId,
	}) => {
		if (subscription.status === "active") {
			// subscription is active, no customer actions required.
			return { subscription, priceId, paymentMethodId };
		} else if (
			subscription.latest_invoice.payment_intent.status ===
			"requires_payment_method"
		) {
			// Using localStorage to manage the state of the retry here,
			// feel free to replace with what you prefer.
			// Store the latest invoice ID and status.
			localStorage.setItem(
				"latestInvoiceId",
				subscription.latest_invoice.id
			);
			localStorage.setItem(
				"latestInvoicePaymentIntentStatus",
				subscription.latest_invoice.payment_intent.status
			);
			throw { error: { message: "Your card was declined." } };
		} else {
			return { subscription, priceId, paymentMethodId };
		}
	};

	const retryInvoiceWithNewPaymentMethod = (
		customerId,
		paymentMethodId,
		invoiceId,
		priceId
	) => {
		return (
			ApiBase.post("/api/givings/retry-invoice", {
				customerId: customerId,
				paymentMethodId: paymentMethodId,
				invoiceId: invoiceId,
			})
				// If the card is declined, display an error to the user.
				.then((result) => {
					if (result.error) {
						// The card had an error when trying to attach it to a customer.
						throw result;
					}
					return result;
				})
				// Normalize the result to contain the object returned by Stripe.
				// Add the addional details we need.
				.then((result) => {
					return {
						// Use the Stripe 'object' property on the
						// returned result to understand what object is returned.
						invoice: result,
						paymentMethodId: paymentMethodId,
						priceId: priceId,
						isRetry: true,
					};
				})
				// Some payment methods require a customer to be on session
				// to complete the payment process. Check the status of the
				// payment intent to handle these actions.
				.then(handleCustomerActionRequired)
				// No more actions required. Provision your service for the user.
				.then(onSubscriptionComplete)
				.catch((error) => {
					// An error has happened. Display the failure to the user here.
					// We utilize the HTML element we created.
					displayError(error);
				})
		);
	};

	const onSubscriptionComplete = (result) => {
		// Payment was successful.
		if (result.subscription.status === "active") {
			// Change your UI to show a success message to your customer.
			// Call your backend to grant access to your service based on
			// `result.subscription.items.data[0].price.product` the customer subscribed to.
			onSuccess();
		}
	};

	const displayError = (event) => {
		setProcessing(false);
		if (event.error) {
			setError(event.error.message);
		} else {
			setError("");
		}
	};

	const renderPaymentMethods = () => {
		return (
			<ul className="giving-payment-methods">
				{paymentMethods.map((pm) => {
					return (
						<li
							key={pm.id}
							className={
								"row giving-payment-methods__item " +
								(pm.id === selectedPaymentMethod.id
									? "active"
									: "")
							}
							onClick={() => setSelectedPaymentMethod(pm)}>
							<span className="col-6">
								{pm.card.brand !== "unknown" ? (
									<img
										src={require(`../../../../../assets/img/cards/${pm.card.brand}.png`)}
										alt={getCardBrandFullName(
											pm.card.brand
										)}
									/>
								) : (
									<>Unknown card brand</>
								)}
							</span>
							<span className="col-6">
								**** **** **** {pm.card.last4}
							</span>
						</li>
					);
				})}
			</ul>
		);
	};

	useEffect(() => {
		const defaultPaymentMethod = paymentMethods.find((pm) => pm.isDefault);
		if (defaultPaymentMethod) {
			setSelectedPaymentMethod(defaultPaymentMethod);
		}
	}, [paymentMethods]);

	return (
		<>
			<ModalBody className="wide-modal-body-without-shadow">
				<p className="modal-subtitle / text-center / mb-4">
					Select payment method
				</p>
				{renderPaymentMethods()}
				{error && (
					<div className="card-error" role="alert">
						{error}
					</div>
				)}
			</ModalBody>
			<ModalFooter className="wide-modal-body-without-shadow">
				<button
					className="btn  btn_theme_transparent / mr-auto"
					type="button"
					disabled={processing}
					onClick={goToCheckoutForm}>
					Use another card
				</button>

				<button
					className="btn  btn_theme_white"
					disabled={processing || !selectedPaymentMethod.id}
					onClick={handlePay}
					type="button">
					Pay
					{processing && <Spinner size="sm" />}
				</button>
			</ModalFooter>
		</>
	);
};

export default PaymentMethods;
