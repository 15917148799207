export const GET_MORE_VIDEOS = "GET_MORE_VIDEOS";
export const GET_MORE_VIDEOS_SUCCESS="GET_MORE_VIDEOS_SUCCESS";
export const GET_MORE_VIDEOS_ERROR = "GET_MORE_VIDEOS_ERROR";

export function getMoreVideos(payload){
    return{
        type:GET_MORE_VIDEOS,
        payload
    }
}

export function getMoreVideosSuccess(payload){
    return{
        type:GET_MORE_VIDEOS_SUCCESS,
        payload
    }
}

export function getMoreVideosError(error){
    return{
        type:GET_MORE_VIDEOS_ERROR,
        error
    }
}