import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.scss";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { store, persistor } from "./redux/root.store";
import { history } from "./redux/config/configureStore";
import ScrollToTop from "./common/helpers/ScrollToTop";
import { PersistGate } from "redux-persist/integration/react";
import { Helmet } from "react-helmet";

ReactDOM.render(
	<>
		<Helmet>
			<meta
				name="description"
				content="House of hope"
			/>
		</Helmet>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouter history={history}>
					<ScrollToTop />
					<App />
				</ConnectedRouter>
			</PersistGate>
		</Provider>
	</>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
