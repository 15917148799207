import {
    GET_RECURRING_GIVINGS,
    GET_RECURRING_GIVINGS_SUCCESS,
    GET_RECURRING_GIVINGS_ERROR,
    CANCEL_RECURRING_GIVING,
    CANCEL_RECURRING_GIVING_SUCCESS,
    CANCEL_RECURRING_GIVING_ERROR
} from './actions';

const initialPaymentSettingsState = {
    loading: false,
    loaded: false,
    error: null,
    recurringGivings: []
};

function paymentSettingsRecuder(state = initialPaymentSettingsState, action) {   
    switch (action.type) {
        case GET_RECURRING_GIVINGS:
            return {...state, loading:true,loaded:false, error:null};
        case GET_RECURRING_GIVINGS_SUCCESS:           
            return {...state, loading:false, loaded:true, recurringGivings:action.payload};          
        case GET_RECURRING_GIVINGS_ERROR:
            return {...state, loading:false, loaded:false, error:action.error};
        case CANCEL_RECURRING_GIVING:
            return {...state, loading:true,loaded:false, error:null};
        case CANCEL_RECURRING_GIVING_SUCCESS:
            return {...state, loading:false, loaded:true};
        case CANCEL_RECURRING_GIVING_ERROR:
            return {...state, loading:false, loaded:false, error:action.error};
        default:
            return state;
    }
}

export default paymentSettingsRecuder;