import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { ReactComponent as DashboardIcon } from "../../../assets/img/icons/dashboard.svg";
import { ReactComponent as GivingsIcon } from "../../../assets/img/icons/new_givings_history.svg";
import { ReactComponent as PrayerRequestsIcon } from "../../../assets/img/icons/prayer_requests.svg";
import { ReactComponent as VideoIcon } from "../../../assets/img/icons/video_settings.svg";
import { ReactComponent as CommentsIcon } from "../../../assets/img/icons/new_comments.svg";
import { ReactComponent as PostsIcon } from "../../../assets/img/icons/new_posts.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/img/icons/logout.svg";
import { ReactComponent as ChevronRight } from "../../../assets/img/icons/chevron_right-24px.svg";
import { ReactComponent as ChevronLeft } from "../../../assets/img/icons/chevron_left-24px.svg";
import { ReactComponent as UserIcon } from "../../../assets/img/icons/men.svg";
import { ReactComponent as MembersIcon } from "../../../assets/img/icons/members.svg";
import { ReactComponent as ReportsIcon } from "../../../assets/img/icons/reports.svg";
import { ReactComponent as EventsIcon } from "../../../assets/img/icons/events.svg";
import { ReactComponent as PlansIcon } from "../../../assets/img/icons/plans.svg";
import { ReactComponent as SchedulingIcon } from "../../../assets/img/icons/scheduling.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/img/icons/settings.svg";
import MobileNav from "./MobileNav";

const DashboardLayout = ({ onLogout, children }) => {
	const logoutClick = (e) => {
		e.preventDefault();
		onLogout();
	};

	const [showMenu, setShowMenu] = useState("76px");

	return (
		<>
			<div className="dashboard__mobile_nav">
				<MobileNav logoutClick={logoutClick} />
			</div>
			<div className="dashboard__all_container">
				<div className="dashboard__tabs">
					<div
						className="dashboard__desktop_menu"
						style={{
							width: showMenu,
						}}>
						<div
							className="nav-link-alt"
							onClick={() => {
								if (showMenu === "76px") {
									setShowMenu("250px");
								} else {
									setShowMenu("76px");
								}
							}}>
							<span className="nav-icon">
								{showMenu === "76px" ? (
									<ChevronRight />
								) : (
									<ChevronLeft />
								)}
							</span>
						</div>
						<NavLink
							to={`/dashboard/charts`}
							className="nav-link-alt">
							<span className="nav-icon nav-icon_stroke nav-icon_sm">
								<label title="Dashboard">
									<DashboardIcon />
								</label>
							</span>
							<span>Dashboard</span>
						</NavLink>

						<NavLink
							to="/dashboard/account-settings"
							className="nav-link-alt">
							<span className="nav-icon nav-icon_sm">
								<label title="Account Settings">
									<UserIcon />
								</label>
							</span>
							<span>Account Settings</span>
						</NavLink>
						<NavLink
							to={`/dashboard/givings/`}
							className="nav-link-alt">
							<span className="nav-icon nav-icon_sm nav-icon_stroke">
								<label title="Givings History">
									<GivingsIcon />
								</label>
							</span>
							<span>Givings History</span>
						</NavLink>
						<NavLink
							to={`/dashboard/prayer-requests`}
							className="nav-link-alt">
							<span className="nav-icon nav-icon_sm nav-icon_stroke">
								<label title="Prayer Requests">
									<PrayerRequestsIcon />
								</label>
							</span>
							<span>Prayer Requests</span>
						</NavLink>
						<NavLink
							to={`/dashboard/video-settings`}
							className="nav-link-alt">
							<span className="nav-icon nav-icon_sm nav-icon_stroke">
								<label title="Video Settings">
									<VideoIcon />
								</label>
							</span>
							<span>Video Settings</span>
						</NavLink>
						<NavLink
							to={`/dashboard/comments`}
							className="nav-link-alt">
							<span className="nav-icon nav-icon_sm nav-icon_stroke">
								<label title="Comments">
									<CommentsIcon />
								</label>
							</span>
							<span>Comments</span>
						</NavLink>
						<NavLink
							to={`/dashboard/posts`}
							className="nav-link-alt">
							<span className="nav-icon nav-icon_stroke nav-icon_sm">
								<label title="Posts">
									<PostsIcon />
								</label>
							</span>
							<span>Posts</span>
						</NavLink>
						<NavLink
							to={`/dashboard/members`}
							className="nav-link-alt">
							<span className="nav-icon nav-icon_stroke nav-icon_sm">
								<label title="Members">
									<MembersIcon />
								</label>
							</span>
							<span>Members</span>
						</NavLink>
						<NavLink
							to={`/dashboard/reports`}
							className="nav-link-alt">
							<span className="nav-icon nav-icon_stroke nav-icon_sm">
								<label title="Reports">
									<ReportsIcon />
								</label>
							</span>
							<span>Reports</span>
						</NavLink>
						<NavLink
							to={`/dashboard/events`}
							className="nav-link-alt">
							<span className="nav-icon nav-icon_stroke nav-icon_sm">
								<label title="Events">
									<EventsIcon />
								</label>
							</span>
							<span>Events</span>
						</NavLink>
						<NavLink
							to={`/dashboard/event-plans`}
							className="nav-link-alt"
							isActive={(match, location) => {
								if (
									location.pathname ===
									"/dashboard/events-master-calendar"
								) {
									return true;
								}

								return Boolean(match);
							}}>
							<span className="nav-icon nav-icon_stroke nav-icon_sm">
								<label title="Plans">
									<PlansIcon />
								</label>
							</span>
							<span>Plans</span>
						</NavLink>
						<NavLink
							className="nav-link-alt"
							to={"/dashboard/payment-settings"}>
							<span className="nav-icon  nav-icon_sm nav-icon_stroke">
								<label title="Payment Settings">
									<SettingsIcon />
								</label>
							</span>
							<span>Payment Settings</span>
						</NavLink>
						{/* <NavLink
							to={`/dashboard/volunteer-scheduling`}
							className="nav-link-alt">
							<span className="nav-icon nav-icon_stroke nav-icon_sm">
								<label title="Scheduling">
									<SchedulingIcon />
								</label>
							</span>
							<span>Scheduling</span>
						</NavLink> */}
						<div className="logout_link">
							<a
								onClick={logoutClick}
								href="#!"
								className="nav-link-alt">
								<span className="nav-icon nav-icon_stroke nav-icon_sm">
									<label title="Log out">
										<LogoutIcon />
									</label>
								</span>

								<span>Log out</span>
							</a>
						</div>
					</div>
				</div>
				<section className="dashboard">
					<div className="dashboard__content_alt">{children}</div>
				</section>
			</div>
		</>
	);
};

export default DashboardLayout;
