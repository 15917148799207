import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../common/api/api.base";
import { toast } from "react-toastify";
import {
	createOneTimeGivingPaymentIntentError,
	createOneTimeGivingPaymentIntentSuccess,
	CREATE_ONE_TIME_GIVING_PAYMENT_INTENT,
	getUserPaymentMethodsError,
	getUserPaymentMethodsSuccess,
	GET_USER_PAYMENT_METHODS,
	setPaymentMethodDefaultError,
	setPaymentMethodDefaultSuccess,
	deletePaymentMethodSuccess,
	deletePaymentMethodError,
	addPaymentMethodSuccess,
	addPaymentMethodError,
	SET_PAYMENT_METHOD_DEFAULT,
	DELETE_PAYMENT_METHOD,
	ADD_PAYMENT_METHOD,
	getUserPaymentMethods,
	createAnonymousGivingPaymentIntentSuccess, 
	createAnonymousGivingPaymentIntentError, 
	CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT
} from "../actions/payment.actions";

function* workerCreateOneTimeGivingPaymentIntent(action) {
	try {
		const result = yield call(
			ApiBase.post,
			"/api/givings/one-time-giving-payment",
			action.payload
		);
		if (!result.error) {
			yield put(createOneTimeGivingPaymentIntentSuccess(result));
		} else {
			toast.error(
				"Failed to proceed one time gift, please reload page or try later",
				{
					autoClose: 3500,
				}
			);
			yield put(
				createOneTimeGivingPaymentIntentError(
					"Failed to proceed one time gift, please reload page or try later"
				)
			);
		}
	} catch (e) {
		yield put(createOneTimeGivingPaymentIntentError(e.message));
	}
}

function* workerCreateAnonymousGivingPaymentIntent(action) {
	try {
		const result = yield call(
			ApiBase.post,
			"/api/givings/anonymous-giving-payment",
			action.payload
		);
		if (!result.error) {
			yield put(createAnonymousGivingPaymentIntentSuccess(result));
		} else {
			toast.error(
				"Failed to proceed one time gift, please reload page or try later",
				{
					autoClose: 3500,
				}
			);
			yield put(
				createAnonymousGivingPaymentIntentError(
					"Failed to proceed one time gift, please reload page or try later"
				)
			);
		}
	} catch (e) {
		yield put(createAnonymousGivingPaymentIntentError(e.message));
	}
}

function* workerGetUserPaymentMethods(action) {
	try {
		const result = yield call(ApiBase.get, "/api/payment-methods/current");
		if (!result.error) {
			yield put(getUserPaymentMethodsSuccess(result));
		} else {
			yield put(
				getUserPaymentMethodsError(
					"Failed to get existing payment methods"
				)
			);
		}
	} catch (e) {
		yield put(getUserPaymentMethodsError(e.message));
	}
}

function* workerSetPaymentMethodDefault(action) {
	try {
		const result = yield call(
			ApiBase.put,
			`/api/payment-methods/${action.payload}/default`
		);
		if (!result.error) {
			yield put(setPaymentMethodDefaultSuccess(result));
		} else {
			yield put(
				setPaymentMethodDefaultError(
					"Failed to make payment method default"
				)
			);
		}
	} catch (e) {
		yield put(setPaymentMethodDefaultError(e.message));
	}
}

function* workerDeletePaymentMethod(action) {
	try {
		const result = yield call(
			ApiBase.delete,
			`/api/payment-methods/${action.payload}`
		);
		if (!result.error) {
			yield put(deletePaymentMethodSuccess(result));
			yield put(getUserPaymentMethods());
		} else {
			yield put(
				deletePaymentMethodError("Failed to delete payment method")
			);
		}
	} catch (e) {
		yield put(deletePaymentMethodError(e.message));
	}
}

function* workerAddPaymentMethod(action) {
	try {
		const result = yield call(
			ApiBase.post,
			"/api/payment-methods",
			action.payload
		);
		if (!result.error) {
			yield put(addPaymentMethodSuccess(result));
		} else {
			yield put(addPaymentMethodError("Failed to add payment method"));
		}
	} catch (e) {
		yield put(addPaymentMethodError(e.message));
	}
}

export default function* watchPaymentsSaga() {
	yield takeEvery(
		CREATE_ONE_TIME_GIVING_PAYMENT_INTENT,
		workerCreateOneTimeGivingPaymentIntent
	);
	yield takeEvery(GET_USER_PAYMENT_METHODS, workerGetUserPaymentMethods);
	yield takeEvery(SET_PAYMENT_METHOD_DEFAULT, workerSetPaymentMethodDefault);
	yield takeEvery(DELETE_PAYMENT_METHOD, workerDeletePaymentMethod);
	yield takeEvery(ADD_PAYMENT_METHOD, workerAddPaymentMethod);
	yield takeEvery(CREATE_ANONYMOUS_GIVING_PAYMENT_INTENT, workerCreateAnonymousGivingPaymentIntent)
}
