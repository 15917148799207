export const GET_POST = "GET_POST";
export const GET_POST_SUCCESS="GET_POST_SUCCESS";
export const GET_POST_ERROR = "GET_POST_ERROR";

export function getPost(payload){
    return{
        type:GET_POST,
        payload
    }
}

export function getPostSuccess(payload){
    return{
        type:GET_POST_SUCCESS,
        payload
    }
}

export function getPostError(error){
    return{
        type:GET_POST_ERROR,
        error
    }
}