import {
    GET_VIDEOS,
    GET_VIDEOS_ERROR,
    GET_VIDEOS_SUCCESS
} from './actions';

const initialVideosState = {
    error: null,
    videos: {
        count:0,
        entities:[]
    },
    loading: false,
    loaded: false
};

function videosReducer(state = initialVideosState, action) {
    switch (action.type) {
        case GET_VIDEOS:
            return { ...state, loading: true, loaded: false };
        case GET_VIDEOS_SUCCESS:            
            return { ...state, loading: false, loaded: true, videos: action.payload };
        case GET_VIDEOS_ERROR:
            return { ...state, videos: {count:0, entities:[]}, loaded: false, loading: false, error: action.error };        
        default:
            return state;
    }
}


export default videosReducer;