import { call, put, takeEvery } from "redux-saga/effects";
import{
    GET_MORE_VIDEOS,
    getMoreVideosSuccess,
    getMoreVideosError
} from './actions';

import ApiBase from '../../../../../common/api/api.base';

function* workerGetMoreVideos(action) {
    try {
        const result = yield call(ApiBase.get, `/api/videos`, action.payload);
        if (!result.error) {
            yield put(getMoreVideosSuccess(result));
        } else {
            yield put(getMoreVideosError(`Failed to get videos!`));
        }
    } catch (e) {
        yield put(getMoreVideosError(e.message));
    }
}

export default function* watchMoreVideosSaga(){
    yield takeEvery(GET_MORE_VIDEOS, workerGetMoreVideos);
}