import React from "react";
import CommomLayoutHeader from "./components/CommonLayoutHeader";
import CommonLayoutFooter from "./components/CommonLayoutFooter";
import Giving from "../../../pages/Giving/Giving.container";

const CommonLayout = ({
	isSignedIn,
	isAdmin,
	headerClasses,
	mainClasses,
	children,
}) => {
	return (
		<>
			<CommomLayoutHeader
				isSignedIn={isSignedIn}
				isAdmin={isAdmin}
				additionClasses={headerClasses}
			/>
			<main className={"main-content " + mainClasses}>{children}</main>
			<CommonLayoutFooter />
			<Giving isSignedIn={isSignedIn} />
		</>
	);
};

export default CommonLayout;
