import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { ModalBody, ModalFooter } from "reactstrap";
import ApiBase from "../../../../../common/api/api.base";

const CheckoutForm = ({
	onSuccess,
	dataForSubscription,
	goToPaymentMethod,
}) => {
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState("");
	const [disabled, setDisabled] = useState(true);
	const stripe = useStripe();
	const elements = useElements();

	const cardStyle = {
		style: {
			base: {
				color: "#32325d",
				fontFamily: "Arial, sans-serif",
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#32325d",
				},
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a",
			},
		},
	};

	const handleChange = async (event) => {
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};

	const handleSubmit = async (ev) => {
		ev.preventDefault();
		setProcessing(true);
		const card = elements.getElement(CardElement);
		const { customerId, priceId } = dataForSubscription;

		const latestInvoicePaymentIntentStatus = localStorage.getItem(
			"latestInvoicePaymentIntentStatus"
		);

		if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
			const invoiceId = localStorage.getItem("latestInvoiceId");
			const isPaymentRetry = true;
			// create new payment method & retry payment on invoice with new payment method
			createPaymentMethod(
				card,
				customerId,
				priceId,
				isPaymentRetry,
				invoiceId
			);
		} else {
			// create new payment method & create subscription
			createPaymentMethod(card, customerId, priceId, false, "");
		}
	};

	const createPaymentMethod = (
		cardElement,
		customerId,
		priceId,
		isPaymentRetry,
		invoiceId
	) => {
		return stripe
			.createPaymentMethod({
				type: "card",
				card: cardElement,
			})
			.then((result) => {
				if (result.error) {
					displayError(error);
				} else {
					if (isPaymentRetry) {
						// Update the payment method and retry invoice payment
						retryInvoiceWithNewPaymentMethod({
							customerId: customerId,
							paymentMethodId: result.paymentMethod.id,
							invoiceId: invoiceId,
							priceId: priceId,
						});
					} else {
						// Create the subscription
						createSubscription({
							customerId: customerId,
							paymentMethodId: result.paymentMethod.id,
							priceId: priceId,
						});
					}
				}
			});
	};

	const createSubscription = ({ customerId, paymentMethodId, priceId }) => {
		return (
			ApiBase.post("/api/givings/create-subscription", {
				customerId: customerId,
				paymentMethodId: paymentMethodId,
				priceId: priceId,
			})
				// If the card is declined, display an error to the user.
				.then((result) => {
					if (result.error) {
						// The card had an error when trying to attach it to a customer.
						throw result;
					}
					return result;
				})
				// Normalize the result to contain the object returned by Stripe.
				// Add the addional details we need.
				.then((result) => {
					return {
						paymentMethodId: paymentMethodId,
						priceId: priceId,
						subscription: result,
					};
				})
				// Some payment methods require a customer to be on session
				// to complete the payment process. Check the status of the
				// payment intent to handle these actions.
				.then(handleCustomerActionRequired)
				// If attaching this card to a Customer object succeeds,
				// but attempts to charge the customer fail, you
				// get a requires_payment_method error.
				.then(handlePaymentMethodRequired)
				// No more actions required. Provision your service for the user.
				.then(onSubscriptionComplete)
				.catch((error) => {
					// An error has happened. Display the failure to the user here.
					// We utilize the HTML element we created.
					displayError(error);
				})
		);
	};

	const handleCustomerActionRequired = ({
		subscription,
		invoice,
		priceId,
		paymentMethodId,
		isRetry,
	}) => {
		if (subscription && subscription.status === "active") {
			// Subscription is active, no customer actions required.
			return { subscription, priceId, paymentMethodId };
		}

		// If it's a first payment attempt, the payment intent is on the subscription latest invoice.
		// If it's a retry, the payment intent will be on the invoice itself.
		let paymentIntent = invoice
			? invoice.payment_intent
			: subscription.latest_invoice.payment_intent;

		if (
			paymentIntent.status === "requires_action" ||
			(isRetry === true &&
				paymentIntent.status === "requires_payment_method")
		) {
			return stripe
				.confirmCardPayment(paymentIntent.client_secret, {
					payment_method: paymentMethodId,
					setup_future_usage: "off_session",
				})
				.then((result) => {
					if (result.error) {
						// Start code flow to handle updating the payment details.
						// Display error message in your UI.
						// The card was declined (i.e. insufficient funds, card has expired, etc).
						throw result;
					} else {
						if (result.paymentIntent.status === "succeeded") {
							// Show a success message to your customer.
							// There's a risk of the customer closing the window before the callback.
							// We recommend setting up webhook endpoints later in this guide.
							return {
								priceId: priceId,
								subscription: subscription,
								invoice: invoice,
								paymentMethodId: paymentMethodId,
							};
						}
					}
				})
				.catch((error) => {
					displayError(error);
				});
		} else {
			// No customer action needed.
			return { subscription, priceId, paymentMethodId };
		}
	};

	const handlePaymentMethodRequired = ({
		subscription,
		paymentMethodId,
		priceId,
	}) => {
		if (subscription.status === "active") {
			// subscription is active, no customer actions required.
			return { subscription, priceId, paymentMethodId };
		} else if (
			subscription.latest_invoice.payment_intent.status ===
			"requires_payment_method"
		) {
			// Using localStorage to manage the state of the retry here,
			// feel free to replace with what you prefer.
			// Store the latest invoice ID and status.
			localStorage.setItem(
				"latestInvoiceId",
				subscription.latest_invoice.id
			);
			localStorage.setItem(
				"latestInvoicePaymentIntentStatus",
				subscription.latest_invoice.payment_intent.status
			);
			throw { error: { message: "Your card was declined." } };
		} else {
			return { subscription, priceId, paymentMethodId };
		}
	};

	const retryInvoiceWithNewPaymentMethod = (
		customerId,
		paymentMethodId,
		invoiceId,
		priceId
	) => {
		return (
			ApiBase.post("/api/givings/retry-invoice", {
				customerId: customerId,
				paymentMethodId: paymentMethodId,
				invoiceId: invoiceId,
			})
				// If the card is declined, display an error to the user.
				.then((result) => {
					if (result.error) {
						// The card had an error when trying to attach it to a customer.
						throw result;
					}
					return result;
				})
				// Normalize the result to contain the object returned by Stripe.
				// Add the addional details we need.
				.then((result) => {
					return {
						// Use the Stripe 'object' property on the
						// returned result to understand what object is returned.
						invoice: result,
						paymentMethodId: paymentMethodId,
						priceId: priceId,
						isRetry: true,
					};
				})
				// Some payment methods require a customer to be on session
				// to complete the payment process. Check the status of the
				// payment intent to handle these actions.
				.then(handleCustomerActionRequired)
				// No more actions required. Provision your service for the user.
				.then(onSubscriptionComplete)
				.catch((error) => {
					// An error has happened. Display the failure to the user here.
					// We utilize the HTML element we created.
					displayError(error);
				})
		);
	};

	const onSubscriptionComplete = (result) => {
		// Payment was successful.
		if (result.subscription.status === "active") {
			// Change your UI to show a success message to your customer.
			// Call your backend to grant access to your service based on
			// `result.subscription.items.data[0].price.product` the customer subscribed to.
			onSuccess();
		}
	};

	const displayError = (event) => {
		setProcessing(false);
		if (event.error) {
			setError(event.error.message);
		} else {
			setError("");
		}
	};

	return (
		<>
			<ModalBody className="wide-modal-body-without-shadow">
				<p className="modal-subtitle / text-center / mb-4">
					Pay: ${dataForSubscription.amount}
				</p>
				<form
					id="payment-form"
					className="stripe-payment-form"
					onSubmit={handleSubmit}>
					<CardElement
						id="card-element"
						options={cardStyle}
						onChange={handleChange}
					/>
					<button
						disabled={processing || disabled || succeeded}
						id="submit">
						<span id="button-text">
							{processing ? (
								<div className="spinner" id="spinner"></div>
							) : (
								"Pay"
							)}
						</span>
					</button>
					{error && (
						<div className="card-error" role="alert">
							{error}
						</div>
					)}
				</form>
			</ModalBody>
			<ModalFooter className="wide-modal-body-without-shadow">
				<button
					className="btn  btn_theme_transparent / mr-auto"
					type="button"
					disabled={processing || succeeded}
					onClick={goToPaymentMethod}>
					Use existing payment method
				</button>
			</ModalFooter>
		</>
	);
};

export default CheckoutForm;
