export const GET_CURRENT_USER_INFO = "GET_CURRENT_USER_INFO";
export const GET_CURRENT_USER_INFO_SUCCESS = "GET_CURRENT_USER_INFO_SUCCESS";
export const GET_CURRENT_USER_INFO_ERROR = "GET_CURRENT_USER_INFO_ERROR";

export function getCurrentUserInfo(payload = {}) {
	return {
		type: GET_CURRENT_USER_INFO,
		payload,
	};
}

export function getCurrentUserInfoSuccess(payload) {
	return {
		type: GET_CURRENT_USER_INFO_SUCCESS,
		payload,
	};
}

export function getCurrentUserInfoError(error) {
	return {
		type: GET_CURRENT_USER_INFO_ERROR,
		error,
	};
}

export const UPDATE_CURRENT_USER_INFO = "UPDATE_CURRENT_USER_INFO";
export const UPDATE_CURRENT_USER_INFO_SUCCESS =
	"UPDATE_CURRENT_USER_INFO_SUCCESS";
export const UPDATE_CURRENT_USER_INFO_ERROR = "UPDATE_CURRENT_USER_INFO_ERROR";

export function updateCurrentUserInfo(payload) {
	return {
		type: UPDATE_CURRENT_USER_INFO,
		payload,
	};
}

export function updateCurrentUserInfoSuccess(payload) {
	return {
		type: UPDATE_CURRENT_USER_INFO_SUCCESS,
		payload,
	};
}

export function updateCurrentUserInfoError(error) {
	return {
		type: UPDATE_CURRENT_USER_INFO_ERROR,
		error,
	};
}

export const UPDATE_CURRENT_USER_AVATAR = "UPDATE_CURRENT_USER_AVATAR";
export const UPDATE_CURRENT_USER_AVATAR_SUCCESS =
	"UPDATE_CURRENT_USER_AVATAR_SUCCESS";
export const UPDATE_CURRENT_USER_AVATAR_ERROR =
	"UPDATE_CURRENT_USER_AVATAR_ERROR";

export const updateCurrentUserAvatar = (data) => ({
	type: UPDATE_CURRENT_USER_AVATAR,
	payload: data,
});

export const updateCurrentUserAvatarSuccess = () => ({
	type: UPDATE_CURRENT_USER_AVATAR_SUCCESS,
});

export const updateCurrentUserAvatarError = () => ({
	type: UPDATE_CURRENT_USER_AVATAR_ERROR,
});

export const CLEAR_CURRENT_USER_INFO = "CLEAR_CURRENT_USER_INFO";

export const clearCurrentUserInfo = () => ({
	type: CLEAR_CURRENT_USER_INFO,
});
