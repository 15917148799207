import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_VIDEOS,
    getVideosSuccess,
    getVideosError
} from './actions';
import ApiBase from '../../../common/api/api.base';

function* workerGetVideos(action) {
    try {
        const result = yield call(ApiBase.get, `/api/paginated-videos`, action.payload);
        if (!result.error) {
            yield put(getVideosSuccess(result));
        } else {
            yield put(getVideosError(`Failed to get videos!`));
        }
    } catch (e) {
        yield put(getVideosError(e.message));
    }
}

export default function* watchVideosSaga() {
    yield takeEvery(GET_VIDEOS, workerGetVideos);    
}