import { call, put, takeEvery } from "redux-saga/effects";
import{
    GET_MORE_POSTS,
    getMorePostsSuccess,
    getMorePostsError
} from './actions';

import ApiBase from '../../../../../common/api/api.base';

function* workerGetMorePosts(action) {
    try {
        const result = yield call(ApiBase.get, `/api/posts`, action.payload);
        if (!result.error) {
            yield put(getMorePostsSuccess(result));
        } else {
            yield put(getMorePostsError(`Failed to get posts!`));
        }
    } catch (e) {
        yield put(getMorePostsError(e.message));
    }
}

export default function* watchMorePostsSaga(){
    yield takeEvery(GET_MORE_POSTS, workerGetMorePosts);
}