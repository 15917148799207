import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ModalBody, ModalFooter, Spinner } from "reactstrap";
import { STRIPE_MAX_AMOUNT } from "../../../../../common/constants/stripe.constants";

const GiftConfirmation = ({
    goBack,
    submitPayment,
    designations,
    givingTypes,
    loading,
}) => {
    const [selectedGivings, setSelectedGivings] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const { register, errors, handleSubmit } = useForm();

    useEffect(() => {
        if (designations.length && givingTypes.length) {
            const givings = designations.map((d) => {
                return {
                    amount: d.amount,
                    name: givingTypes.find((g) => g.id == d.givingTypeId).name,
                };
            });

            setTotalAmount(calculateAmount(givings));
            setSelectedGivings(givings);
        }
    }, [designations, givingTypes]);

    const mapGivings = () => {
        return selectedGivings.map((g, idx) => {
            return (
                <tr key={idx}>
                    <td>{g.name}</td>
                    <td>${g.amount}</td>
                </tr>
            );
        });
    };

    const calculateAmount = (data) => {
        return data.reduce((acc, giving) => acc + parseFloat(giving.amount), 0);
    };

    const handleConfirm = (data)=>{
        submitPayment(data);
    }

    const exceedLimit = totalAmount > STRIPE_MAX_AMOUNT;
    return (
        <>
            <form onSubmit={handleSubmit(handleConfirm)}>
            <ModalBody className="wide-modal-body-without-shadow">
                <p className="modal-subtitle / text-center / mb-4">
                    Confirm gift
				</p>
                <div className="form-group">
                    <label className="form-label form-label_required"
                        htmlFor="donorName">
                        Your name
					</label>
                    <input type="text"
                        className="form-control"
                        placeholder="Full name"
                        name="donorName"
                        ref={register({
                            required: true
                        })} />
                    {errors.donorName?.type === "required" && (
                        <span className="form-validation-error text-left">
                            Please, let us know your name
                        </span>
                    )}
                </div>
                <div className="form-group">
                    <label className="form-label form-label_required"
                        htmlFor="donorEmail">
                        Your email
					</label>
                    <input type="email"
                        className="form-control"
                        placeholder="Email"
                        name="donorEmail"
                        ref={register({
                            required: true
                        })} />
                    {errors.donorEmail?.type === "required" && (
                        <span className="form-validation-error text-left">
                            Please, let us know your email address
                        </span>
                    )}
                </div>

                <div>
                    <table className="table table-full-width">
                        <thead>
                            <tr>
                                <th>Designation</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>{mapGivings()}</tbody>
                    </table>
                </div>
                <p style={{ padding: "0.75rem" }}>Total: ${totalAmount}</p>
                {exceedLimit && (
                    <div className="alert alert-warning" role="alert">
                        Sum of the online gift can't be more than $
                        {STRIPE_MAX_AMOUNT}.
                    </div>
                )}
            </ModalBody>
            <ModalFooter className="wide-modal-body-without-shadow">
                <button
                    className="btn  btn_theme_transparent / mr-auto"
                    type="button"
                    disabled={loading}
                    onClick={goBack}>
                    &lt;Back
				</button>

                <button                    
                    className="btn  btn_theme_white"
                    disabled={loading || exceedLimit}
                    type="submit">
                    Confirm and checkout
					{loading && <Spinner size="sm" />}
                </button>
            </ModalFooter>
            </form>
        </>
    );
};

export default GiftConfirmation;
