export const CREATE_REQUEST = "CREATE_REQUEST";
export const CREATE_REQUEST_SUCCESS = "CREATE_REQUEST_SUCCESS";
export const CREATE_REQUEST_ERROR = "CREATE_REQUEST_ERROR";

export function createRequest(payload) {
    return {
        type: CREATE_REQUEST,
        payload
    }
}

export function createRequestSuccess(payload) {
    return {
        type: CREATE_REQUEST_SUCCESS,
        payload
    }
}

export function createRequestError(error) {
    return {
        type: CREATE_REQUEST_ERROR,
        error
    }
}