import React, { useEffect, useState } from "react";
import { ModalBody, ModalFooter } from "reactstrap";
import { useForm, useFieldArray } from "react-hook-form";
import {
	STRIPE_MAX_AMOUNT,
	STRIPE_MIN_AMOUNT,
} from "../../../../../common/constants/stripe.constants";

const GiftDesignations = ({
	goBack,
	goNext,
	resetTrigger,
	givingTypes,
	formFields,
	fieldsData,
}) => {
	const [fieldGroupsCount, setFieldGroupsCount] = useState(1);

	const { register, errors, control, handleSubmit, reset } = useForm({
		defaultValues: {
			givings: [
				{
					type: "number",
					name: "amount-1",
					groupIdx: 1,
					initValue: 10,
				},
				{ type: "select", name: "designation-1", groupIdx: 1 },
			],
		},
	});

	const { fields, append } = useFieldArray({
		control,
		name: "givings",
	});

	useEffect(() => {
		if (resetTrigger === false) {
			reset();
		}
	}, [resetTrigger]);

	useEffect(() => {
		if (formFields.length && fieldsData.length) {
			reset({
				givings: mapFields(fieldsData, formFields),
			});

			setFieldGroupsCount(formFields.length / 2);
		}
	}, [formFields, fieldsData]);

	const mapFields = (data, fields) => {
		return fields.map((field, idx) => {
			const initValue = data[idx][field.name];
			return {
				...field,
				initValue,
			};
		});
	};

	const appendDesignation = () => {
		append([
			{
				type: "number",
				name: "amount-" + (fieldGroupsCount + 1),
				groupIdx: fieldGroupsCount + 1,
				initValue: 10,
			},
			{
				type: "select",
				name: "designation-" + (fieldGroupsCount + 1),
				groupIdx: fieldGroupsCount + 1,
			},
		]);

		setFieldGroupsCount(fieldGroupsCount + 1);
	};

	const roundAmount = (e) => {
		e.target.value = Number.parseFloat(e.target.value).toFixed(2);
	};

	const renderFieldItem = (item, index) => {
		return (
			<div className="form-group" key={index}>
				{item.type === "number" ? (
					<>
						<label
							className="form-label  form-label_required"
							htmlFor={item.name}>
							Amount $
						</label>
						<input
							className="form-control"
							type="number"
							id={item.name}
							name={`givings[${index}].${item.name}`}
							placeholder="Enter amount"
							defaultValue={item.initValue}
							onBlur={roundAmount}
							step="0.01"
							ref={register({
								required: true,
								min: STRIPE_MIN_AMOUNT,
								max: STRIPE_MAX_AMOUNT,
							})}
						/>
						{errors.givings?.[index]?.[item.name]?.type ===
							"required" && (
							<span className="form-validation-error text-left">
								Please, enter the amount
							</span>
						)}
						{errors.givings?.[index]?.[item.name]?.type ===
							"min" && (
							<span className="form-validation-error text-left">
								Minimum amount is $0.50
							</span>
						)}
						{errors.givings?.[index]?.[item.name]?.type ===
							"max" && (
							<span className="form-validation-error text-left">
								Maximum amount is $999999.99
							</span>
						)}
					</>
				) : (
					<>
						<label className="form-label" htmlFor={item.name}>
							Designation
						</label>
						<select
							className="form-control"
							id={item.name}
							name={`givings[${index}].${item.name}`}
							defaultValue={item.initValue}
							ref={register({ required: true })}>
							{givingTypes.map((type) => {
								return (
									<option value={type.id} key={type.id}>
										{type.name}
									</option>
								);
							})}
						</select>
					</>
				)}
			</div>
		);
	};

	const onSubmit = ({ givings }) => {
		goNext(givings, fields);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody className="wide-modal-body-without-shadow">
					<p className="modal-subtitle / text-center / mb-4">
						Online-one time gift
					</p>
					<div className="modal-inner  modal-inner_step_0">
						{fields.map((item, idx) => {
							return renderFieldItem(item, idx);
						})}

						{givingTypes.length > fields.length / 2 && (
							<button
								className="btn  btn_plus  btn_theme_transparent"
								type="button"
								onClick={appendDesignation}>
								Add one more designation
							</button>
						)}
					</div>
				</ModalBody>
				<ModalFooter className="wide-modal-body-without-shadow">
					<button
						className="btn  btn_theme_transparent / mr-auto"
						type="button"
						onClick={goBack}>
						&lt;Back
					</button>

					<button
						className="btn  btn_theme_white"
						type="submit"
						disabled={fields.length === 0}>
						Next
					</button>
				</ModalFooter>
			</form>
		</>
	);
};

export default GiftDesignations;
