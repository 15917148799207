import {
    GET_POSTS,
    GET_POSTS_ERROR,
    GET_POSTS_SUCCESS
} from './actions';

const initialPostsState = {
    error: null,
    posts: {
        count:0,
        entities:[]
    },
    loading: false,
    loaded: false
};

function postsReducer(state = initialPostsState, action) {
    switch (action.type) {
        case GET_POSTS:
            return { ...state, loading: true, loaded: false };
        case GET_POSTS_SUCCESS:            
            return { ...state, loading: false, loaded: true, posts: action.payload };
        case GET_POSTS_ERROR:
            return { ...state, posts: {count:0, entities:[]}, loaded: false, loading: false, error: action.error };        
        default:
            return state;
    }
}


export default postsReducer;