import React from "react";
import { ReactComponent as TwitterIcon } from "../../../../assets/img/icons/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../../../../assets/img/icons/youtube.svg";
import { ReactComponent as FacebookIcon } from "../../../../assets/img/icons/facebook.svg";
import { ReactComponent as HeartIcon } from "../../../../assets/img/icons/heart.svg";

const CommonLayoutFooter = () => {
	return (
		<footer className="main-footer">
			<div className="container">
				<div className="row / justify-content-between / align-items-center">
					<div className="col-auto">
						<div className="row / py-2 px-3 py-md-0">
							<p>256 S 8th Street</p>
						</div>
						<div className="row / py-2 px-3 py-md-0">
							<p>Salina, KS 67401</p>
						</div>
						<div className="row / py-2 px-3 py-md-0">
							<a
								className="link  link_theme_white"
								href="tel:+17858269111">
								785-515-2019
							</a>
						</div>
						<div className="row / py-2 px-3 py-md-0">
							<a
								className="link link_theme_white"
								href="mailto:hello@petersrockminitries.com">
								hello@petersrockminitries.com
							</a>
						</div>
					</div>
					{/* <div className="row / py-2 py-md-0">
					</div> */}

					<div className="social / col-auto">
						<ul className="social__list">
							{/* <li className="social__item">
								<a
									className="social__link social__link_tw"
									title="Our Twitter"
									href="#!"
									onClick={(e) => e.preventDefault()}>
									<TwitterIcon />
								</a>
							</li> */}

							<li className="social__item">
								<a
									className="social__link  social__link_fb"
									title="Our Facebook"
									href="https://www.facebook.com/groups/petersrockmin/"
									target="_blank"
									rel="noreferrer noopener"
									// onClick={(e) => e.preventDefault()}
								>
									<FacebookIcon />
								</a>
							</li>

							<li className="social__item">
								<a
									className="social__link  social__link_yt"
									title="Our YouTube channel"
									href="https://www.youtube.com/c/PetersRockTempleChurch/featured"
									target="_blank"
									rel="noreferrer noopener"
									// onClick={(e) => e.preventDefault()}
								>
									<YoutubeIcon />
								</a>
							</li>
						</ul>
						<div
							className="row / py-2 px-3 py-md-0"
							style={{ fontSize: "14px" }}>
							<p className="row justify-content-between / align-items-center">
								Made with
								<HeartIcon className="mx-1" />
								by&nbsp;
							</p>
							<a
								className="link  link_theme_white"
								href="https://conicapps.com/">
								{` Conic Apps`}
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default CommonLayoutFooter;
