import { call, put, takeEvery } from "redux-saga/effects";
import{
    GET_HOME_POSTS,
    getHomePostsSuccess,
    getHomePostsError
} from './actions';

import ApiBase from '../../../../../common/api/api.base';

function* workerGetHomePosts(action) {
    try {
        const result = yield call(ApiBase.get, `/api/posts`, action.payload);
        if (!result.error) {
            yield put(getHomePostsSuccess(result));
        } else {
            yield put(getHomePostsError(`Failed to get posts!`));
        }
    } catch (e) {
        yield put(getHomePostsError(e.message));
    }
}

export default function* watchHomePostsSaga(){
    yield takeEvery(GET_HOME_POSTS, workerGetHomePosts);
}