import { OPEN_GIVING_MODAL, CLOSE_ALL_GIVING_MODALS } from "./actions";

const initialGivingModalsState = {
    currentModal: ''
};

function givingModalsReducer(state = initialGivingModalsState, action) {
    switch (action.type) {
        case OPEN_GIVING_MODAL:
            return { currentModal: action.payload };
        case CLOSE_ALL_GIVING_MODALS:
            return { currentModal: '' }
        default:
            return state;
    }
}

export default givingModalsReducer;