import {
    GET_POST,
    GET_POST_ERROR,
    GET_POST_SUCCESS
} from './actions';

const initialPostState = {
    error: null,
    post:{},
    loading: false,
    loaded: false
};

function postReducer(state = initialPostState, action) {
    switch (action.type) {
        case GET_POST:
            return { ...state, loading: true, loaded: false };
        case GET_POST_SUCCESS:                      
            return { ...state, loading: false, loaded: true, post: action.payload };
        case GET_POST_ERROR:
            return { ...state, posts:{}, loaded: false, loading: false, error: action.error };       
        default:
            return state;
    }
}

export default postReducer;