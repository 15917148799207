import {
    GET_HOME_HIGHLIGHTED_POSTS,
    GET_HOME_HIGHLIGHTED_POSTS_ERROR,
    GET_HOME_HIGHLIGHTED_POSTS_SUCCESS
} from './actions';

const initialHomeHighlightedPostsState = {
    error: null,
    posts: [],
    loading: false,
    loaded: false
};

function homeHighlightedPostsReducer(state = initialHomeHighlightedPostsState, action) {
    switch (action.type) {
        case GET_HOME_HIGHLIGHTED_POSTS:
            return { ...state, loading: true, loaded: false };
        case GET_HOME_HIGHLIGHTED_POSTS_SUCCESS:            
            return { ...state, loading: false, loaded: true, posts: action.payload };
        case GET_HOME_HIGHLIGHTED_POSTS_ERROR:
            return { ...state, posts:[], loaded: false, loading: false, error: action.error };       
        default:
            return state;
    }
}

export default homeHighlightedPostsReducer;