import { call, put, takeEvery } from "redux-saga/effects";
import {
	deleteGivingTypeSuccess,
	deleteGivingTypeError,
	getGivingTypes,
	getGivingTypesSuccess,
	getGivingTypesError,
	CREATE_GIVING_TYPE,
	createGivingTypeError,
	createGivingTypeSuccess,
	UPDATE_GIVING_TYPE,
	updateGivingTypeSuccess,
	updateGivingTypeError,
	DELETE_GIVING_TYPE,
	GET_GIVING_TYPES,
	getGivingsHistoryError,
	getGivingsHistorySuccess,
	GET_GIVINGS_HISTORY,
	CREATE_OFFLINE_GIVING,
	createOfflineGivingSuccess,
	createOfflineGivingError,
	DELETE_OFFLINE_GIVING,
	deleteOfflineGivingSuccess,
	getGivingsHistory,
	deleteOfflineGivingError,
} from "./actions";
import pageSizes from "../../../../../common/constants/pageSizes";
import ApiBase from "../../../../../common/api/api.base";
import { toast } from "react-toastify";

function* workerCreateGivingType(action) {
	try {
		const result = yield call(
			ApiBase.post,
			`/api/givingTypes`,
			action.payload
		);
		if (!result.error) {
			yield put(createGivingTypeSuccess(result));
			toast.success("Successfully added!");
			yield put(
				getGivingTypes({ skip: 0, take: pageSizes.GIVING_TYPES_TAKE })
			);
		} else {
			yield put(createGivingTypeError("Failed to create giving type!"));
		}
	} catch (e) {
		yield put(createGivingTypeError(e.message));
	}
}

function* workerUpdateGivingType(action) {
	try {
		const { id, name } = action.payload;
		const result = yield call(ApiBase.put, `/api/givingTypes/${id}`, {
			name,
		});
		if (!result.error) {
			yield put(updateGivingTypeSuccess(result));
			toast.success("Successfully updated!");
		} else {
			yield put(updateGivingTypeError("Failed to update giving type!"));
		}
	} catch (e) {
		yield put(updateGivingTypeError(e.message));
	}
}

function* workerDeleteGivingType(action) {
	try {
		const result = yield call(
			ApiBase.delete,
			`/api/givingTypes/${action.payload}`
		);
		if (!result.error) {
			yield put(deleteGivingTypeSuccess(result));
			yield put(
				getGivingTypes({ skip: 0, take: pageSizes.GIVING_TYPES_TAKE })
			);
		} else {
			yield put(deleteGivingTypeError("Failed to delete giving type!"));
		}
	} catch (e) {
		yield put(deleteGivingTypeError(e.message));
	}
}

function* workerGetGivingTypes(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/paginated-givingTypes`,
			action.payload
		);
		if (!result.error) {
			yield put(getGivingTypesSuccess(result));
		} else {
			yield put(getGivingTypesError("Failed to get giving types!"));
		}
	} catch (e) {
		yield put(getGivingTypesError(e.message));
	}
}

function* workerGetGivings(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/givings/history`,
			action.payload
		);
		if (!result.error) {
			yield put(getGivingsHistorySuccess(result));
		} else {
			yield put(getGivingsHistoryError(`Failed to get givings history!`));
		}
	} catch (e) {
		yield put(getGivingsHistoryError(e.message));
	}
}

function* workerCreateOfflineGiving(action) {	
	try {
		const result = yield call(
			ApiBase.post,
			`/api/givings/offline`,
			action.payload
		);
		if (!result.error) {
			yield put(createOfflineGivingSuccess(result));
			yield workerGetGivings({
				payload: {
					skip: 0,
					take: pageSizes.GIVINGS_HISTORY_TAKE,
				},
			});
			toast.success("Successfully added!");
		} else {
			yield put(
				createOfflineGivingError("Failed to create offline giving!")
			);
		}
	} catch (e) {
		yield put(createOfflineGivingError(e.message));
	}
}

function* workerDeleteOfflineGiving(action){
	try {
		const result = yield call(
			ApiBase.delete,
			`/api/givings/offline/${action.payload}`
		);
		if (!result.error) {
			yield put(deleteOfflineGivingSuccess(result));
			yield put(
				getGivingsHistory({ skip: 0, take: pageSizes.GIVINGS_HISTORY_TAKE })
			);
		} else {
			yield put(deleteOfflineGivingError("Failed to delete offline giving!"));
		}
	} catch (e) {
		yield put(deleteOfflineGivingError(e.message));
	}
}

export default function* watchDashboardGivingTypesSaga() {
	yield takeEvery(DELETE_GIVING_TYPE, workerDeleteGivingType);
	yield takeEvery(GET_GIVING_TYPES, workerGetGivingTypes);
	yield takeEvery(CREATE_GIVING_TYPE, workerCreateGivingType);
	yield takeEvery(UPDATE_GIVING_TYPE, workerUpdateGivingType);
	yield takeEvery(GET_GIVINGS_HISTORY, workerGetGivings);
	yield takeEvery(CREATE_OFFLINE_GIVING, workerCreateOfflineGiving);
	yield takeEvery(DELETE_OFFLINE_GIVING, workerDeleteOfflineGiving);
}
