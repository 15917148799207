import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { ReactComponent as CloseIcon } from "../../../../assets/img/icons/close.svg";
import { useDispatch, useSelector } from "react-redux";
import {
	closeAllGivingModals,
	openGivingModal,
} from "../../store/modals/actions";
import GiftDesignations from "./components/GiftDesignations";
import PaymentSuccess from "./components/PaymentSuccess";
import CheckoutForm from "./components/CheckoutForm";
import GiftConfirmation from "./components/GiftConfirmation";

const AnonymousGift = ({
	givingTypes,
	giving,
	createOneTimeGiving,
	givingCreationLoading,
	createPaymentIntent,
	paymentIntentData,
	resetPaymentIntent
}) => {
	const dispatch = useDispatch();

	const [currentContent, setCurrentContent] = useState("designations");
	const [designations, setDesignations] = useState([]);
	const [rawDesignationsData, setRawDesignationsData] = useState([]);

	const currentModal = useSelector((x) => x.givingModals.currentModal);
	const show = currentModal === "anonymous-gift";

	const closeModal = () => {
		dispatch(closeAllGivingModals());
		setTimeout(() => {
			setCurrentContent("designations");
		}, 200);
	};

	const goBack = () => {
		dispatch(openGivingModal("giving"));
	};

	const goToConfirmation = (data, fields) => {
		setCurrentContent("confirmation");

		setRawDesignationsData(data);
		setDesignations(fields);
	};

	const goToDesignations = () => {
		setCurrentContent("designations");
	};

	const mapFields = (data) => {
		const result = [];
		for (let i = 0; i < data.length; i += 2) {
			const amount = data[i][Object.keys(data[i])[0]];
			const givingTypeId = data[i + 1][Object.keys(data[i + 1])[0]];

			result.push({
				amount,
				givingTypeId,
			});
		}

		return result;
	};

	const submitPayment = (donor) => {
		const givingData = mapFields(rawDesignationsData);		
		createOneTimeGiving({...donor, designations:givingData});
	};

	const onPaymentSuccess = () => {
		setCurrentContent("payment-success");
	};

	const goToPaymentMethod = () => {
		resetPaymentIntent();
		setCurrentContent("payment-method");
	};

	useEffect(() => {
		if (!show) {
			setDesignations([]);
			setRawDesignationsData([]);
		}
	}, [show]);

	useEffect(() => {
		if (giving && currentContent === "confirmation") {		
			setCurrentContent("card");
		}
	}, [giving]);

	return (
		<Modal
			isOpen={show}
			toggle={closeModal}
			centered
			className="modal-compact">
			<div className="modal-header / align-items-center">
				<h5
					className="modal-title / title  title_size_h2"
					id="login_modal_title">
					Giving
				</h5>
				<button
					className="close"
					type="button"
					aria-label="Close"
					onClick={closeModal}>
					<CloseIcon />
				</button>
			</div>
			{currentContent === "designations" && (
				<GiftDesignations
					formFields={designations}
					fieldsData={rawDesignationsData}
					resetTrigger={show}
					goBack={goBack}
					goNext={goToConfirmation}
					givingTypes={givingTypes}
				/>
			)}

			{currentContent === "confirmation" && (
				<GiftConfirmation
					designations={mapFields(rawDesignationsData)}
					givingTypes={givingTypes}
					goBack={goToDesignations}
					submitPayment={submitPayment}
					loading={givingCreationLoading}
				/>
			)}

			{currentContent === "card" && giving && (
				<CheckoutForm
					createPaymentIntent={createPaymentIntent}
					paymentIntentData={paymentIntentData}
					giving={giving}
					onSuccess={onPaymentSuccess}
					goToPaymentMethod={goToPaymentMethod}
				/>
			)}

			{currentContent === "payment-success" && (
				<PaymentSuccess closeModal={closeModal} />
			)}
		</Modal>
	);
};

export default AnonymousGift;
