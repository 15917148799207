import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_MESSAGES,
    getMessagesSuccess,
    getMessagesError
} from './actions';
import ApiBase from '../../api/api.base';

function* workerGetMessages(action) {
    try {
        const result = yield call(ApiBase.get, `/api/messages`, action.payload);
        if (!result.error) {
            yield put(getMessagesSuccess(result));
        } else {
            yield put(getMessagesError(`Failed to get requests!`));
        }
    } catch (e) {
        yield put(getMessagesError(e.message));
    }
}

export default function* watchMessagesSaga() {
    yield takeEvery(GET_MESSAGES, workerGetMessages);    
}