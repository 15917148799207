import React, { Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getCurrentUser } from "../../common/helpers/auth.helper";
import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import AccountLayout from "./components/AccountLayout";
import AccountRouting from "./Account.routing";

const Account = ({ onLogout }) => {
	if (getCurrentUser().roles.includes("Admin")) {
		return (
			<Redirect
				to={{
					pathname: "/",
				}}
			/>
		);
	}

	return (
		<>
			<Helmet>
				<title>Account – Peter's Rock Ministries</title>				
			</Helmet>			
			<AccountLayout onLogout={onLogout}>
				<Suspense
					fallback={
						<div className="center-loading">
							<LoadingSpinner />
						</div>
					}>
					<AccountRouting />
				</Suspense>
			</AccountLayout>
		</>
	);
};

export default Account;
