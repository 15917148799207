import {
	GET_ROLES,
	GET_ROLES_SUCCESS,
	GET_ROLES_ERROR,
	CREATE_ROLE,
	CREATE_ROLE_SUCCESS,
	CREATE_ROLE_ERROR,
	UPDATE_ROLE,
	UPDATE_ROLE_SUCCESS,
	UPDATE_ROLE_ERROR,
	DELETE_ROLE,
	DELETE_ROLE_ERROR,
	DELETE_ROLE_SUCCESS,
	UPDATE_ROLES_RANGE,
	UPDATE_ROLES_RANGE_SUCCESS,
	UPDATE_ROLES_RANGE_ERROR,
	GET_ALLOWED_ACTIONS,
	GET_ALLOWED_ACTIONS_SUCCESS,
	GET_ALLOWED_ACTIONS_ERROR,
	UPDATE_ROLE_ACTION,
} from "./actions";

const initialState = {
	roles: {
		loading: false,
		error: null,
		loaded: false,
		entities: [],
	},
	allowedActions: {
		loading: false,
		error: null,
		loaded: false,
		entities: [],
	},
	rolesActions: {
		loading: false,
		error: null,
		loaded: false,
	},
	rolesUpdateRange: {
		loading: false,
		error: null,
		loaded: false,
	},
	changedRoles: [],
};

export default function dashboardRolesReducer(state = initialState, action) {
	switch (action.type) {
		case GET_ROLES:
			return {
				...state,
				roles: {
					...state.roles,
					loading: true,
					loaded: false,
				},
			};
		case GET_ROLES_SUCCESS:
			return {
				...state,
				roles: {
					...state.roles,
					loading: false,
					loaded: true,
					error: null,
					entities: action.payload,
				},
			};
		case GET_ROLES_ERROR:
			return {
				...state,
				roles: {
					...state.roles,
					loading: false,
					loaded: false,
					error: action.error,
				},
			};
		case CREATE_ROLE:
			return {
				...state,
				rolesActions: {
					...state.rolesActions,
					loading: true,
					loaded: false,
				},
			};
		case CREATE_ROLE_SUCCESS:
			return {
				...state,
				rolesActions: {
					...state.rolesActions,
					loading: false,
					loaded: true,
					error: null,
				},
				roles: {
					...state.roles,
					entities: orderByName(
						state.roles.entities.concat(action.payload)
					),
				},
			};
		case CREATE_ROLE_ERROR:
			return {
				...state,
				rolesActions: {
					...state.rolesActions,
					loading: false,
					loaded: false,
					error: action.error,
				},
			};
		case UPDATE_ROLE:
			return {
				...state,
				rolesActions: {
					...state.rolesActions,
					loading: true,
					loaded: false,
				},
			};
		case UPDATE_ROLE_SUCCESS:
			return {
				...state,
				rolesActions: {
					...state.rolesActions,
					loading: false,
					loaded: true,
					error: null,
				},
				roles: {
					...state.roles,
					entities: updateRole(
						state.roles.entities,
						action.payload.id
					),
				},
				changedRoles: removeFromChangedRoles(
					state.changedRoles,
					action.payload.id
				),
			};
		case UPDATE_ROLE_ERROR:
			return {
				...state,
				rolesActions: {
					...state.rolesActions,
					loading: false,
					loaded: false,
					error: action.error,
				},
			};
		case DELETE_ROLE:
			return {
				...state,
				rolesActions: {
					...state.rolesActions,
					loading: true,
					loaded: false,
				},
			};
		case DELETE_ROLE_SUCCESS:
			return {
				...state,
				rolesActions: {
					...state.rolesActions,
					loading: false,
					loaded: true,
					error: null,
				},
				roles: {
					...state.roles,
					entities: removeRole(
						state.roles.entities,
						action.payload.roleId
					),
				},
				changedRoles: removeFromChangedRoles(
					state.changedRoles,
					action.payload.roleId
				),
			};
		case DELETE_ROLE_ERROR:
			return {
				...state,
				rolesActions: {
					...state.rolesActions,
					loading: false,
					loaded: false,
					error: action.error,
				},
			};
		case UPDATE_ROLES_RANGE:
			return {
				...state,
				rolesUpdateRange: {
					...state.rolesUpdateRange,
					loading: true,
					loaded: false,
				},
			};
		case UPDATE_ROLES_RANGE_SUCCESS:
			return {
				...state,
				rolesUpdateRange: {
					...state.rolesUpdateRange,
					loading: false,
					loaded: true,
					error: null,
				},
				roles: {
					...state.roles,
					entities: updateRoles(state.roles.entities, action.payload),
				},
				changedRoles: removeMultipleFromChangedRoles(
					state.changedRoles,
					action.payload.map((x) => x.id)
				),
			};
		case UPDATE_ROLES_RANGE_ERROR:
			return {
				...state,
				rolesUpdateRange: {
					...state.rolesUpdateRange,
					loading: false,
					loaded: false,
					error: action.error,
				},
			};
		case GET_ALLOWED_ACTIONS:
			return {
				...state,
				allowedActions: {
					...state.allowedActions,
					loading: true,
					loaded: false,
				},
			};
		case GET_ALLOWED_ACTIONS_SUCCESS:
			return {
				...state,
				allowedActions: {
					...state.allowedActions,
					loading: false,
					loaded: true,
					error: null,
					entities: action.payload,
				},
			};
		case GET_ALLOWED_ACTIONS_ERROR:
			return {
				...state,
				allowedActions: {
					...state.allowedActions,
					loading: false,
					loaded: false,
					error: action.error,
				},
			};
		case UPDATE_ROLE_ACTION:
			return {
				...state,
				changedRoles: updateChangedRoles(state, action.payload),
				roles: {
					...state.roles,
					entities: updateRoleActionsList(state, action.payload),
				},
			};
		default:
			return state;
	}
}

function updateRole(roles, updatedRole) {
	const roleIdx = roles.findIndex((x) => x.id === updatedRole.id);
	if (roleIdx !== -1) {
		return [
			...roles.slice(0, roleIdx),
			updatedRole,
			...roles.slice(roleIdx + 1),
		];
	}

	return orderByName(roles);
}

function updateRoles(roles, updatedRoles) {
	let newRoles = roles;

	for (const role of updatedRoles) {
		newRoles = updateRole(newRoles, role);
	}

	return newRoles;
}

function removeRole(roles, toRemoveRoleId) {
	const roleIdx = roles.findIndex((x) => x.id === toRemoveRoleId);
	return [...roles.slice(0, roleIdx), ...roles.slice(roleIdx + 1)];
}

function removeFromChangedRoles(changedRoles, toRemoveRoleId) {
	return changedRoles.filter((x) => x.id !== toRemoveRoleId);
}

function removeMultipleFromChangedRoles(changedRoles, toRemoveRoleIds) {
	return changedRoles.filter((x) => !toRemoveRoleIds.some((i) => i === x.id));
}

function updateRoleActionsList(state, payload) {
	const { roleId, actionId } = payload;
	const roles = state.roles.entities;
	const allowedActions = state.allowedActions.entities;

	const role = roles.find((x) => x.id === roleId);

	let newAllowedActions = [];

	if (role.allowedActions.some((x) => x.id === actionId)) {
		newAllowedActions = role.allowedActions.filter(
			(x) => x.id !== actionId
		);
	} else {
		const action = allowedActions.find((x) => x.id === actionId);

		newAllowedActions = [...role.allowedActions, action];
	}

	const roleIdx = roles.findIndex((x) => x.id === roleId);
	role.allowedActions = newAllowedActions;

	return [...roles.slice(0, roleIdx), role, ...roles.slice(roleIdx + 1)];
}

function updateChangedRoles(state, payload) {
	const { roleId, actionId } = payload;
	const changedRoles = state.changedRoles;

	const role = changedRoles.find((x) => x.id === roleId);
	if (!role) {
		return changedRoles.concat({
			id: roleId,
			allowedActionIds: [actionId],
		});
	}

	if (role.allowedActionIds.some((x) => x === actionId)) {
		role.allowedActionIds = role.allowedActionIds.filter(
			(x) => x !== actionId
		);
	} else {
		role.allowedActionIds = role.allowedActionIds.concat(actionId);
	}

	const roleIdx = changedRoles.findIndex((x) => x.id === roleId);

	return [
		...changedRoles.slice(0, roleIdx),
		role,
		...changedRoles.slice(roleIdx + 1),
	];
}

function orderByName(roles) {
	return roles.sort((a, b) => {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	});
}
