export const GET_RECURRING_GIVINGS = "GET_RECURRING_GIVINGS";
export const GET_RECURRING_GIVINGS_SUCCESS = "GET_RECURRING_GIVINGS_SUCCESS";
export const GET_RECURRING_GIVINGS_ERROR = "GET_RECURRING_GIVINGS_ERROR";

export function getRecurringGivings(payload={}){
    return{
        type:GET_RECURRING_GIVINGS,
        payload
    }
}

export function getRecurringGivingsSuccess(payload){
    return{
        type:GET_RECURRING_GIVINGS_SUCCESS,
        payload
    }
}

export function getRecurringGivingsError(error){
    return{
        type:GET_RECURRING_GIVINGS_ERROR,
        error
    }
}

export const CANCEL_RECURRING_GIVING = "CANCEL_RECURRING_GIVING";
export const CANCEL_RECURRING_GIVING_SUCCESS = "CANCEL_RECURRING_GIVING_SUCCESS";
export const CANCEL_RECURRING_GIVING_ERROR = "CANCEL_RECURRING_GIVING_ERROR";

export function cancelRecurringGiving(payload){
    return{
        type:CANCEL_RECURRING_GIVING,
        payload
    }
}

export function cancelRecurringGivingSuccess(payload){
    return{
        type:CANCEL_RECURRING_GIVING_SUCCESS,
        payload
    }
}

export function cancelRecurringGivingError(error){
    return{
        type:CANCEL_RECURRING_GIVING,
        error
    }
}