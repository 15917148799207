import {
	GET_CURRENT_USER_INFO,
	GET_CURRENT_USER_INFO_SUCCESS,
	GET_CURRENT_USER_INFO_ERROR,
	UPDATE_CURRENT_USER_INFO,
	UPDATE_CURRENT_USER_INFO_SUCCESS,
	UPDATE_CURRENT_USER_INFO_ERROR,
	CLEAR_CURRENT_USER_INFO,
	UPDATE_CURRENT_USER_AVATAR,
	UPDATE_CURRENT_USER_AVATAR_SUCCESS,
	UPDATE_CURRENT_USER_AVATAR_ERROR,
} from "./actions";

const initialAccountUserInfo = {
	error: null,
	user: {
		id: "",
		firstName: "",
		lastName: "",
		address: "",
	},
	loading: false,
	loaded: true,
};

function accountUserInfoReducer(state = initialAccountUserInfo, action) {
	switch (action.type) {
		case GET_CURRENT_USER_INFO:
			return { ...state, loading: true, loaded: false, error: null };
		case GET_CURRENT_USER_INFO_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				user: action.payload,
			};
		case GET_CURRENT_USER_INFO_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case UPDATE_CURRENT_USER_INFO:
			return {
				...state,
				loading: true,
				loaded: false,
				error: null,
			};
		case UPDATE_CURRENT_USER_INFO_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
			};
		case UPDATE_CURRENT_USER_INFO_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
			};
		case UPDATE_CURRENT_USER_AVATAR:
			return {
				...state,
				loading: true,
				loaded: false,
			};
		case UPDATE_CURRENT_USER_AVATAR_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
			};
		case UPDATE_CURRENT_USER_AVATAR_ERROR:
			return {
				...state,
				loading: false,
				loaded: true,
			};
		case CLEAR_CURRENT_USER_INFO:
			return initialAccountUserInfo;
		default:
			return state;
	}
}

export default accountUserInfoReducer;
