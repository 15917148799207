import { toast } from "react-toastify";
import axios from "axios";
import {
	getCurrentUser,
	isTokenExpired,
	updateToken,
} from "../helpers/auth.helper";
import { dispatch } from "../../redux/root.store";
import {
	logoutUser,
	logoutUserSuccess,
	refreshTokenSuccess,
} from "../../redux/actions/auth.actions";

String.prototype.capitalize = function () {
	return this.charAt(0).toUpperCase() + this.slice(1);
};

export const axiosBase = axios.create();

axiosBase.interceptors.request.use(function (request) {
	const currentUser = getCurrentUser();

	request.headers.Authorization = currentUser.isSignedIn
		? `Bearer ${currentUser.accessToken}`
		: "";

	return request;
});

let isAlreadyFetchingAccessToken = false;

let subscribers = [];

axiosBase.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const originalRequest = error.config;
		// if (!error.response) {
		// 	return Promise.reject(error);
		// }
		if (
			(error.response.status === 401 ||
				error.response.status === 400 ||
				error.response.status === 409) &&
			originalRequest.url.includes("/api/account/logout")
		) {
			dispatch(logoutUserSuccess());
			return Promise.resolve({ data: "Logout because token expires" });
		}

		if (originalRequest.url.includes("/api/account/refresh-access-token")) {
			dispatch(logoutUserSuccess());
			return Promise.resolve({
				data: "Logout because refresh token expires",
			});
		}

		if (error.response.status === 401 && isTokenExpired()) {
			return resetTokenAndReattemptRequest(error);
		} else if (error.response.status === 500) {
			toast.error("500 - Network Error");
		} else if (error.response.data?.errors) {
			error.response.data.errors.forEach(({ message }) => {
				toast.error(message);
			});
		}

		return Promise.reject(error);
	}
);

async function resetTokenAndReattemptRequest(error) {
	try {
		const { response: errorResponse } = error;
		const { accessToken, refreshToken } = getCurrentUser();
		if (!refreshToken) {
			return Promise.reject(error);
		}

		const retryOriginalRequest = new Promise((resolve) => {
			addSubscriber((access_token) => {
				errorResponse.config.headers.Authorization =
					"Bearer " + access_token;
				resolve(axios(errorResponse.config));
			});
		});
		if (!isAlreadyFetchingAccessToken) {
			isAlreadyFetchingAccessToken = true;
			const response = await axiosBase.post(
				"/api/account/refresh-access-token",
				{ accessToken, refreshToken }
			);

			if (!response.data) {
				return Promise.reject(error);
			}
			updateToken(response.data);
			refreshTokenSuccess();
			isAlreadyFetchingAccessToken = false;
			onAccessTokenFetched(getCurrentUser().accessToken);
		}
		return retryOriginalRequest;
	} catch (err) {
		return Promise.reject(err);
	}
}

function onAccessTokenFetched(access_token) {
	subscribers.forEach((callback) => callback(access_token));
	subscribers = [];
}

function addSubscriber(callback) {
	subscribers.push(callback);
}

function extractData(response) {
	/*response.data.messages.forEach(message => {
    toast.success(message.messageText);
  });*/
	return response.data;
}

function extractCancelableResponse(response) {
	return response.data ? response.data : response;
}

class ApiBase {
	get(url, params, headers = {}) {
		return axiosBase
			.get(url, {
				params,
				headers,
			})
			.then((res) => {
				return extractData(res);
			});
	}	

	post(url, body = null, headers = {}) {
		return axiosBase.post(url, body, { headers }).then((res) => {
			return extractData(res);
		});
	}

	put(url, body = null, headers = {}) {
		return axiosBase.put(url, body, { headers }).then((res) => {
			return extractData(res);
		});
	}

	delete(url, params, data, headers = {}) {
		return axiosBase.delete(url, { params, headers, data }).then((res) => {
			return extractData(res);
		});
	}

	file(url, body = null, headers = {}, config) {
		const formData = new FormData();
		formData.append("file", body);

		return axiosBase
			.post(url, formData, {
				// responseType: "blob",
				headers: headers,
				...config,
			})
			.then((res) => {
				return extractCancelableResponse(res);
			});
	}

	downloadFile(url, body = null, headers = {}) {
		return axiosBase
			.post(url, body, { headers, responseType: "blob" })
			.then((res) => {
				return res;
			});
	}
}

export default new ApiBase();
