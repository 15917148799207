import configureStore from "./config/configureStore";

const configuredStore = configureStore({});
export const { store, persistor } = configuredStore;
store.subscribe(() => {
	// const { auth } = store.getState();
	// localStorage.setItem("session", auth);
});

export const { dispatch } = store;
