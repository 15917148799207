import { call, put, takeEvery } from "redux-saga/effects";
import ApiBase from "../../../api/api.base";
import {
	SEARCH_VIDEOS,
	SEARCH_POSTS,
	searchPostsSuccess,
	searchPostsError,
	searchVideosSuccess,
	searchVideosError,
} from "./actions";

function* workerSearchPosts(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/posts/by-heading`,
			action.payload
		);
		if (!result.error) {
			yield put(searchPostsSuccess(result));
		} else {
			yield put(searchPostsError(`Failed to search`));
		}
	} catch (e) {
		yield put(searchPostsError(e.message));
	}
}

function* workerSearchVideos(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/videos/by-name`,
			action.payload
		);
		if (!result.error) {
			yield put(searchVideosSuccess(result));
		} else {
			yield put(searchVideosError(`Failed to search`));
		}
	} catch (e) {
		yield put(searchVideosError(e.message));
	}
}

export default function* watchSearchSaga() {
	yield takeEvery(SEARCH_VIDEOS, workerSearchVideos);
	yield takeEvery(SEARCH_POSTS, workerSearchPosts);
}
