export const CLOSE_ALL_GIVING_MODALS = "CLOSE_ALL_GIVING_MODALS";
export const OPEN_GIVING_MODAL="OPEN_GIVING_MODAL";

export function closeAllGivingModals(payload={}){
    return{
        type:CLOSE_ALL_GIVING_MODALS,
        payload
    }
}

export function openGivingModal(payload){
    return{
        type:OPEN_GIVING_MODAL,
        payload
    }
}