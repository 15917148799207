import {
	GET_DASHBOARD_VIDEOS,
	GET_DASHBOARD_VIDEOS_ERROR,
	GET_DASHBOARD_VIDEOS_SUCCESS,
	UPLOAD_VIDEO,
	UPLOAD_VIDEO_ERROR,
	UPLOAD_VIDEO_SUCCESS,
	DELETE_VIDEO,
	DELETE_VIDEO_SUCCESS,
	DELETE_VIDEO_ERROR,
	SET_DASHBOARD_VIDEO_UPLOAD_PROGRESS,
	UPLOAD_EMBEDED_VIDEO_REQUEST,
	UPLOAD_EMBEDED_VIDEO_SUCCESS,
	UPLOAD_EMBEDED_VIDEO_ERROR,
	TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE,
	TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE_SUCCESS,
	TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE_ERROR
} from "./actions";

const initialDashboardVideosState = {
	error: null,
	videos: {
		count: 0,
		entities: [],
	},
	loading: false,
	actionLoading:false,
	loaded: false,
	progress: 0,
	toggleVideoVisibility: {
		loading: false,
		loaded: true,
		error: null
	}
};

function dashboardVideosReducer(state = initialDashboardVideosState, action) {
	switch (action.type) {
		case GET_DASHBOARD_VIDEOS:
			return { ...state, loading: true, loaded: false };
		case GET_DASHBOARD_VIDEOS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				videos: action.payload,
			};
		case GET_DASHBOARD_VIDEOS_ERROR:
			return {
				...state,
				videos: { count: 0, entities: [] },
				loaded: false,
				loading: false,
				error: action.error,
			};
		case UPLOAD_VIDEO:
		case UPLOAD_EMBEDED_VIDEO_REQUEST:
			return { ...state, actionLoading: true, loaded: false };
		case UPLOAD_VIDEO_SUCCESS:
		case UPLOAD_EMBEDED_VIDEO_SUCCESS:
			return {
				...state,
				error: false,
				actionLoading: false,
				loaded: true,
				progress: 0,
			};
		case UPLOAD_VIDEO_ERROR:
		case UPLOAD_EMBEDED_VIDEO_ERROR:
			return {
				...state,
				error: action.error,
				actionLoading: false,
				loaded: true,
				progress: 0,
			};
		case DELETE_VIDEO:
			return { ...state, actionLoading: true, loaded: false };
		case DELETE_VIDEO_SUCCESS:
			return { ...state, error: false, actionLoading: false, loaded: true };
		case DELETE_VIDEO_ERROR:
			return {
				...state,
				error: action.error,
				actionLoading: false,
				loaded: true,
			};
		case SET_DASHBOARD_VIDEO_UPLOAD_PROGRESS:
			return { ...state, progress: action.payload };
		case TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE:
			return {
				...state,
				toggleVideoVisibility: {
					...state.toggleVideoVisibility,
					loading: true,
					loaded: false
				}
			};
		case TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE_SUCCESS:
			return {
				...state,
				toggleVideoVisibility: {
					...state.toggleVideoVisibility,
					loading: false,
					loaded: true,
					error: null
				},
				videos:updateVideoVisibilityFlagInList(state, action.payload)
			};
		case TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE_ERROR:
			return {
				...state,
				toggleVideoVisibility: {
					...state.toggleVideoVisibility,
					loading: false,
					loaded: false,
					error: action.error
				}
			};
		default:
			return state;
	}
}

export default dashboardVideosReducer;

function updateVideoVisibilityFlagInList(state, video) {
	const videos = state.videos;

	const videoIdx = videos.entities.findIndex(v => v.id == video.id);
	if (videoIdx === -1) {
		return videos;
	}

	const videoInList = videos.entities[videoIdx];
	videoInList.isOnHomePage = video.isOnHomePage;

	return {
		count: videos.count,
		entities: [...videos.entities.slice(0, videoIdx),
				   videoInList,
				   ...videos.entities.slice(videoIdx + 1)]
	};
}
