import React, { Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import { logoutUser } from "../redux/actions/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import Global404 from "./NotFound/Global404";

import LoadingSpinner from "../common/components/LoadingSpinner/LoadingSpinner";
import CommonLayout from "../common/blocks/CommonLayout/CommonLayout";

import Home from "./Home/Home.container";

import { getCurrentUser } from "../common/helpers/auth.helper";
import AppRouting from "./App.routing";
import { Helmet } from "react-helmet";

function AppContainer() {
	const dispatch = useDispatch();
	const onLogout = (payload) => dispatch(logoutUser(payload));
	useSelector((x) => x.auth.user);

	const currentUser = getCurrentUser();
	const isSignedIn = currentUser.isSignedIn;
	const isAdmin = currentUser.roles.some((r) => r === "Admin");

	return (
		<>
			<Helmet>
				<meta
					name="description"
					content="The mission of Peter’s Rock Ministries is to glorify God,
    edify the saints, and evangelize the lost. More specifically,
    the purpose and mission of Peter’s Rock Church is to effectively minister to
    ALL people regardless of gender, color, creed, or religious persuasion.
    We MUST minister through the Word of God and be committed to leadership in
    order to enhance spiritual growth and victorious living"
				/>
				<meta name="keywords" content="Peter's Rock, petersrock, Salina church, Ministries, petersrock, petersrock ministries"></meta>
			</Helmet>
			<Route
				render={({ location }) =>
					location.state && location.state.is404 ? (
						<Global404 />
					) : (
						<Switch>
							<Route
								path={["/home", "/"]}
								exact
								component={(props) => (
									<Home
										isAdmin={isAdmin}
										isSignedIn={isSignedIn}
										{...props}
									/>
								)}
							/>
							<CommonLayout
								isAdmin={isAdmin}
								isSignedIn={isSignedIn}>
								<Suspense
									fallback={
										<div className="center-loading">
											<LoadingSpinner />
										</div>
									}>
									{/* <Switch> */}
									<AppRouting onLogout={onLogout} />
									{/* </Switch> */}
								</Suspense>
							</CommonLayout>
							<Redirect to="/" />
						</Switch>
					)
				}
			/>
			{/* <Switch>
				<Route
					path={["/home", "/"]}
					exact
					component={(props) => (
						<Home
							isAdmin={isAdmin}
							isSignedIn={isSignedIn}
							{...props}
						/>
					)}
				/>
				<CommonLayout isAdmin={isAdmin} isSignedIn={isSignedIn}>
					<Suspense
						fallback={
							<div className="center-loading">
								<LoadingSpinner />
							</div>
						}>
						<Switch>
							<AppRouting onLogout={onLogout} />
						</Switch>
					</Suspense>
				</CommonLayout>
				<Redirect to="/" />
			</Switch> */}
		</>
	);
}

export default AppContainer;
