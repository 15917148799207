import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { ModalBody, ModalFooter } from "reactstrap";
import ApiBase from "../../../../../common/api/api.base";

const CheckoutForm = ({
	giving,
	onSuccess,
	createPaymentIntent,
	paymentIntentData,
	goToPaymentMethod,
}) => {
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState("");
	const [disabled, setDisabled] = useState(true);
	const [savePaymentMethod, setSavePaymentMethod] = useState(false);

	const stripe = useStripe();
	const elements = useElements();

	const cardStyle = {
		style: {
			base: {
				color: "#32325d",
				fontFamily: "Arial, sans-serif",
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#32325d",
				},
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a",
			},
		},
	};

	useEffect(() => {
		if (paymentIntentData) {
			async function confirmPayment() {				
				const card = elements.getElement(CardElement);

				const payload = await stripe.confirmCardPayment(
					paymentIntentData.clientSecret,
					{
						payment_method: {
							card: card,
						}
					}
				);

				if (payload.error) {
					setError(`Payment failed ${payload.error.message}`);
					setProcessing(false);
				} else {
					setError(null);
					setProcessing(false);
					setSucceeded(true);
					onSuccess();
				}
			}

			confirmPayment();
		}
	}, [paymentIntentData]);

	const handleChange = async (event) => {
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};

	const handleSubmit = async (ev) => {
		ev.preventDefault();
		setProcessing(true);
		createPaymentIntent({ givingId: giving.id, amount: giving.amount });
	};

	return (
		<>
			<ModalBody className="wide-modal-body-without-shadow">
				<p className="modal-subtitle / text-center / mb-4">
					Give: ${giving.amount}
				</p>
				<form
					id="payment-form"
					className="stripe-payment-form"
					onSubmit={handleSubmit}>						
					<CardElement
						id="card-element"
						options={cardStyle}
						onChange={handleChange}
					/>
					<button
						disabled={processing || disabled || succeeded}
						id="submit">
						<span id="button-text">
							{processing ? (
								<div className="spinner" id="spinner"></div>
							) : (
									"Pay"
								)}
						</span>
					</button>
					{error && (
						<div className="card-error" role="alert">
							{error}
						</div>
					)}
				</form>
			</ModalBody>
		</>
	);
};

export default CheckoutForm;
