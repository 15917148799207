import { call, put, takeEvery } from "redux-saga/effects";
import {
	CREATE_REQUEST,
	createRequestSuccess,
	createRequestError,
} from "./actions";
import {
	getRequestsStealth as getDashboardRequests,
	resetDashboardRequests,
} from "../../../pages/Dashboard/pages/DashboardPrayerRequests/store/actions";
import {
	getRequestsStealth as getAccountRequests,
	resetAccountRequestsStealth,
} from "../../../pages/Account/pages/AccountRequests/store/actions";
import ApiBase from "../../../common/api/api.base";
import { toast } from "react-toastify";
import pageSizes from "../../../common/constants/pageSizes";

function* workerCreateRequest(action) {
	try {
		const result = yield call(
			ApiBase.post,
			"/api/requests",
			action.payload
		);
		if (!result.error) {
			yield put(createRequestSuccess(result));
			toast.success("Your request has been sent");
			if (action.payload.page) {
				if (action.payload.page === "dashboard") {
					yield put(resetDashboardRequests());
					yield put(
						getDashboardRequests({
							skip: 0,
							take: pageSizes.DASHBOARD_REQUESTS_TAKE,
						})
					);
				} else {
					// yield put(resetAccountRequestsStealth());
					yield put(
						getAccountRequests({
							skip: 0,
							take: pageSizes.ACCOUNT_REQUESTS_TAKE,
						})
					);
				}
			}
		} else {
			yield put(createRequestError("Failer to create request!"));
		}
	} catch (e) {
		yield put(createRequestError(e.message));
	}
}

export default function* watchRequestsSaga() {
	yield takeEvery(CREATE_REQUEST, workerCreateRequest);
}
