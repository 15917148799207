import React, { useEffect } from "react";
import HomePosts from "./HomePosts";
import { useDispatch, useSelector } from "react-redux";
import { getHomePosts } from "./store/actions";
import pageSizes from "../../../../common/constants/pageSizes";

const HomePostsContainer = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getHomePosts({ skip: 0, take: pageSizes.HOME_POSTS_TAKE }));
	}, []);

	const posts = useSelector((x) => x.homePosts.posts);

	return <HomePosts posts={posts} />;
};

export default HomePostsContainer;
