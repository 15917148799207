import { call, put, takeEvery } from "redux-saga/effects";
import {
	GET_ACCOUNT_REQUESTS,
	getRequestsSuccess,
	getRequestsError,
	SEARCH_USER_PRAYER_REQUESTS,
	searchUserPrayerRequestsSuccess,
	searchUserPrayerRequestsError,
	moveAccountRequestToTop,
	GET_ACCOUNT_REQUESTS_STEALTH,
	getRequestsSuccessStealth,
	getRequestsErrorStealth,
} from "./actions";
import ApiBase from "../../../../../common/api/api.base";
import { RECEIVE_MESSAGE } from "../../../../../common/Messages/store/actions";

function* workerGetRequests(action) {
	try {
		const result = yield call(ApiBase.get, `/api/requests`, action.payload);
		if (!result.error) {
			yield put(getRequestsSuccess(result));
		} else {
			yield put(getRequestsError(`Failed to get requests!`));
		}
	} catch (e) {
		yield put(getRequestsError(e.message));
	}
}

function* workerSearchUserRequests(action) {
	try {
		const result = yield call(ApiBase.get, `/api/requests`, action.payload);
		if (!result.error) {
			yield put(searchUserPrayerRequestsSuccess(result));
		} else {
			yield put(searchUserPrayerRequestsError(`Failed to get requests!`));
		}
	} catch (e) {
		yield put(searchUserPrayerRequestsError(e.message));
	}
}

function* workerGetRequestsStealth(action) {
	try {
		const result = yield call(ApiBase.get, `/api/requests`, action.payload);
		if (!result.error) {
			yield put(getRequestsSuccessStealth(result));
		} else {
			yield put(getRequestsErrorStealth(`Failed to get requests!`));
		}
	} catch (e) {
		yield put(getRequestsErrorStealth(e.message));
	}
}

function* workerReceiveAccountMessage(action) {
	yield put(moveAccountRequestToTop(action.payload));
}

export default function* watchAccountRequestsSaga() {
	yield takeEvery(GET_ACCOUNT_REQUESTS, workerGetRequests);
	yield takeEvery(SEARCH_USER_PRAYER_REQUESTS, workerSearchUserRequests);
	yield takeEvery(RECEIVE_MESSAGE, workerReceiveAccountMessage);
	yield takeEvery(GET_ACCOUNT_REQUESTS_STEALTH, workerGetRequestsStealth);
}
