import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ReactComponent as CloseIcon } from "../../assets/img/icons/close.svg";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import ForgotPasswordModal from "./components/ForgotPasswordModal";
import ResendConfirmationModal from "./components/ResendConfirmationModal";
import validator from "validator";

const LoginModal = ({
	loading,
	emailSending,
	emailSent,
	loginUser,
	sendResetPasswordEmail,
	sendConfirmationEmail,
	show,
	handleClose,
	setShowSignUp,
}) => {
	const { register, handleSubmit, errors } = useForm();

	const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
	const toggleForgotPassword = () => {
		setForgotPasswordModal(!forgotPasswordModal);
	};

	const [resendConfirmationModal, setResendConfirmationModal] = useState(
		false
	);
	const toggleResetConfirmation = () => {
		setResendConfirmationModal(!resendConfirmationModal);
	};

	const onSubmit = (data) => loginUser(data);

	const closeModal = () => {
		handleClose();
	};

	useEffect(() => {
		if (emailSent) {
			setForgotPasswordModal(false);
			setResendConfirmationModal(false);
		}
	}, [emailSent]);

	return (
		<>
			<Modal
				isOpen={show}
				toggle={closeModal}
				centered
				className="modal-compact">
				<div className="modal-header / align-items-center">
					<h5
						className="modal-title / title  title_size_h2"
						id="login_modal_title">
						Sign In
					</h5>
					<button
						className="close"
						type="button"
						aria-label="Close"
						onClick={closeModal}>
						<CloseIcon />
					</button>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<ModalBody className="wide-modal-body-without-shadow">
						<div className="form-group">
							<label
								className="form-label  form-label_required"
								htmlFor="login_modal_email">
								Email
							</label>
							<input
								className="form-control"
								type="email"
								id="login_modal_email"
								name="email"
								placeholder="Email"
								ref={register({
									required: true,
									validate: {
										isEmail: (value) =>
											validator.isEmail(value),
									},
								})}
							/>
							{errors.email?.type === "required" && (
								<span className="form-validation-error">
									Email is required
								</span>
							)}
							{errors.email?.type === "isEmail" && (
								<span className="form-validation-error text-left">
									Email is invalid
								</span>
							)}
						</div>

						<div className="form-group">
							<label
								className="form-label  form-label_required"
								htmlFor="login_modal_password">
								Password
							</label>
							<input
								className="form-control"
								type="password"
								id="login_modal_password"
								name="password"
								placeholder="Password"
								ref={register({ required: true })}
							/>
							{errors.password && (
								<span className="form-validation-error">
									Password is required
								</span>
							)}
						</div>
						<p
							className="login-modal-action"
							onClick={toggleForgotPassword}>
							Forgot password?
						</p>
						<p
							className="login-modal-action"
							onClick={toggleResetConfirmation}>
							Resend confirmation email
						</p>
						<p
							className="auth_modal_switch_p"
							onClick={() => {
								handleClose();
								setShowSignUp(true);
							}}>
							Don`t have an account?
						</p>
					</ModalBody>
					<ModalFooter className="wide-modal-body-without-shadow">
						<button
							disabled={loading}
							className="btn"
							type="submit">
							Sign In
							{loading && <Spinner size="sm" />}
						</button>
					</ModalFooter>
				</form>
			</Modal>

			<ForgotPasswordModal
				loading={emailSending}
				show={forgotPasswordModal}
				handleClose={toggleForgotPassword}
				sendForgotPasswordEmail={sendResetPasswordEmail}
			/>

			<ResendConfirmationModal
				loading={emailSending}
				show={resendConfirmationModal}
				handleClose={toggleResetConfirmation}
				sendConfirmationEmail={sendConfirmationEmail}
			/>
		</>
	);
};

export default LoginModal;
