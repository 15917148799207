import { call, put, takeEvery, select } from "redux-saga/effects";
import {
	GET_CURRENT_USER_INFO,
	getCurrentUserInfoSuccess,
	getCurrentUserInfoError,
	UPDATE_CURRENT_USER_INFO,
	updateCurrentUserInfoSuccess,
	updateCurrentUserInfoError,
	UPDATE_CURRENT_USER_AVATAR,
	updateCurrentUserAvatarSuccess,
	updateCurrentUserAvatarError,
} from "./actions";

import ApiBase from "../../../../../common/api/api.base";
import { toast } from "react-toastify";

function* workerGetCurrentUserInfo(action) {
	try {
		const result = yield call(ApiBase.get, "/api/users/current");
		if (!result.error) {
			yield put(getCurrentUserInfoSuccess(result));
		} else {
			yield put(getCurrentUserInfoError("Failed to fetch user info!"));
		}
	} catch (e) {
		yield put(getCurrentUserInfoError("Failed to fetch user info!"));
	}
}

function* workerUpdateCurrentUserInfo(action) {
	try {
		const result = yield call(
			ApiBase.put,
			"/api/users/current",
			action.payload
		);
		if (!result.error) {
			yield put(updateCurrentUserInfoSuccess(result));
			toast.success("Changes have been saved");
		} else {
			yield put(
				updateCurrentUserInfoError("Failed to update user info!")
			);
		}
	} catch (e) {
		yield put(updateCurrentUserInfoError("Failed to update user info!"));
	}
}

function* workerUpdateCurrentUserAvatar(action) {
	try {
		const fileUploadResult = yield call(
			ApiBase.file,
			`/api/files`,
			action.payload.photoUrl
		);
		if (!fileUploadResult.error) {
			yield put(updateCurrentUserAvatarSuccess());
			const accountUserInfo = yield select(
				(state) => state.accountUserInfo.user
			);
			const result = yield call(ApiBase.put, "/api/users/current", {
				...accountUserInfo,
				address: {
					...accountUserInfo.address,
					stateId: accountUserInfo.address.state.id,
				},
				photoUrl: fileUploadResult,
				oldPassword: "",
				password: "",
			});
			if (!result.error) {
				yield put(updateCurrentUserInfoSuccess(result));
				toast.success("Avatar have been updated");
				yield workerGetCurrentUserInfo();
			} else {
				yield put(
					updateCurrentUserInfoError("Failed to update user info!")
				);
			}
		} else {
			yield put(
				updateCurrentUserAvatarError("Failed to upload new avatar!")
			);
		}
	} catch (error) {
		yield put(updateCurrentUserAvatarError("Failed to upload new avatar!"));
	}
}

export default function* watchAccountUserInfoSaga() {
	yield takeEvery(GET_CURRENT_USER_INFO, workerGetCurrentUserInfo);
	yield takeEvery(UPDATE_CURRENT_USER_INFO, workerUpdateCurrentUserInfo);
	yield takeEvery(UPDATE_CURRENT_USER_AVATAR, workerUpdateCurrentUserAvatar);
}
