export const GET_ALL_GIVING_TYPES = "GET_ALL_GIVING_TYPES";
export const GET_ALL_GIVING_TYPES_SUCCESS = "GET_ALL_GIVING_TYPES_SUCCESS";
export const GET_ALL_GIVING_TYPES_ERROR = "GET_ALL_GIVING_TYPES_ERROR";

export function getAllGivingTypes(payload = {}) {
    return {
        type: GET_ALL_GIVING_TYPES,
        payload
    }
}

export function getAllGivingTypesSuccess(payload) {
    return {
        type: GET_ALL_GIVING_TYPES_SUCCESS,
        payload
    }
}

export function getAllGivingTypesError(error) {
    return {
        type: GET_ALL_GIVING_TYPES_ERROR,
        error
    }
}

export const CREATE_ONE_TIME_GIVING = "CREATE_ONE_TIME_GIVING";
export const CREATE_ONE_TIME_GIVING_SUCCESS = "CREATE_ONE_TIME_GIVING_SUCCESS";
export const CREATE_ONE_TIME_GIVING_ERROR = "CREATE_ONE_TIME_GIVING_ERROR";

export function createOneTimeGiving(payload) {
    return {
        type: CREATE_ONE_TIME_GIVING,
        payload
    }
}

export function createOneTimeGivingSuccess(payload) {
    return {
        type: CREATE_ONE_TIME_GIVING_SUCCESS,
        payload
    }
}

export function createOneTimeGivingError(error) {
    return {
        type: CREATE_ONE_TIME_GIVING_ERROR,
        error
    }
}

export const CREATE_ANONYMOUS_GIVING = "CREATE_ANONYMOUS_GIVING";
export const CREATE_ANONYMOUS_GIVING_SUCCESS = "CREATE_ANONYMOUS_GIVING_SUCCESS";
export const CREATE_ANONYMOUS_GIVING_ERROR = "CREATE_ANONYMOUS_GIVING_ERROR";

export function createAnonymousGiving(payload) {
    return {
        type: CREATE_ANONYMOUS_GIVING,
        payload
    }
}

export function createAnonymousGivingSuccess(payload) {
    return {
        type: CREATE_ANONYMOUS_GIVING_SUCCESS,
        payload
    }
}

export function createAnonymousGivingError(error) {
    return {
        type: CREATE_ANONYMOUS_GIVING_ERROR,
        error
    }
}

export const GET_ALL_GIVING_PLANS = "GET_ALL_GIVING_PLANS";
export const GET_ALL_GIVING_PLANS_SUCCESS = "GET_ALL_GIVING_PLANS_SUCCESS";
export const GET_ALL_GIVING_PLANS_ERROR = "GET_ALL_GIVING_PLANS_ERROR";

export function getAllGivingPlans(payload = {}) {
    return {
        type: GET_ALL_GIVING_PLANS,
        payload
    }
}

export function getAllGivingPlansSuccess(payload) {
    return {
        type: GET_ALL_GIVING_PLANS_SUCCESS,
        payload
    }
}

export function getAllGivingPlansError(error) {
    return {
        type: GET_ALL_GIVING_PLANS_ERROR,
        error
    }
}

export const GET_DATA_FOR_SUBSCRIPTION = "GET_DATA_FOR_SUBSCRIPTION";
export const GET_DATA_FOR_SUBSCRIPTION_SUCCESS = "GET_DATA_FOR_SUBSCRIPTION_SUCCESS";
export const GET_DATA_FOR_SUBSCRIPTION_ERROR = "GET_DATA_FOR_SUBSCRIPTION_ERROR";

export function getDataForSubscription(payload) {
    return {
        type: GET_DATA_FOR_SUBSCRIPTION,
        payload
    }
}

export function getDataForSubscriptionSuccess(payload) {
    return {
        type: GET_DATA_FOR_SUBSCRIPTION_SUCCESS,
        payload
    }
}

export function getDataForSubscriptionError(error) {
    return {
        type: GET_DATA_FOR_SUBSCRIPTION_ERROR,
        error
    }
}