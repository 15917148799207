import React from "react";
import HomeVideosListItem from "./HomeVideosListItem";

const HomeVideosList = ({ videos, showRequestModal }) => {
	return (
		<section className="videos">
			<ul className="videos__list">
				{videos.map((video, idx) => {
					return (
						<li key={idx} className="videos__item / video">
							<HomeVideosListItem
								video={video}
								showRequestModal={showRequestModal}
							/>
						</li>
					);
				})}
			</ul>
		</section>
	);
};

export default HomeVideosList;
