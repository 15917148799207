import {
    GET_MORE_VIDEOS,
    GET_MORE_VIDEOS_ERROR,
    GET_MORE_VIDEOS_SUCCESS
} from './actions';

const initialMoreVideosState = {
    error: null,
    videos: [],
    loading: false,
    loaded: false
};

function moreVideosReducer(state = initialMoreVideosState, action) {
    switch (action.type) {
        case GET_MORE_VIDEOS:
            return { ...state, loading: true, loaded: false };
        case GET_MORE_VIDEOS_SUCCESS:            
            return { ...state, loading: false, loaded: true, videos: action.payload };
        case GET_MORE_VIDEOS_ERROR:
            return { ...state, videos:[], loaded: false, loading: false, error: action.error };       
        default:
            return state;
    }
}

export default moreVideosReducer;