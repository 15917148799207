import React from "react";
import HeroSwiper from "./components/HeroSwiper";
import HomeArticles from "./modules/HomeArticles/HomeArticles.container";
import HomeVideos from "./modules/HomeVideos/HomeVideos.container";
import HomePosts from "./modules/HomePosts/HomePosts.container";
import CommonLayout from "../../common/blocks/CommonLayout/CommonLayout";

const Home = ({ isSignedIn, isAdmin }) => (
	<>
		<CommonLayout
			isAdmin={isAdmin}
			isSignedIn={isSignedIn}
			mainClasses="main-content_index"
			headerClasses="main-header_index">
			<HeroSwiper />
			<HomeArticles />
			<div className="main-content__inner / container">
				<div className="row">
					<div className="col-md-7">
						<HomeVideos />
					</div>
					<div className="col-md-4 / offset-md-1">
						<HomePosts />
					</div>
				</div>
			</div>
		</CommonLayout>
	</>
);

export default Home;
