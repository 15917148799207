export const GET_HOME_HIGHLIGHTED_POSTS = "GET_HOME_HIGHLIGHTED_POSTS";
export const GET_HOME_HIGHLIGHTED_POSTS_SUCCESS="GET_HOME_HIGHLIGHTED_POSTS_SUCCESS";
export const GET_HOME_HIGHLIGHTED_POSTS_ERROR = "GET_HOME_HIGHLIGHTED_POSTS_ERROR";

export function getHomeHighlightedPosts(payload){
    return{
        type:GET_HOME_HIGHLIGHTED_POSTS,
        payload
    }
}

export function getHomeHighlightedPostsSuccess(payload){
    return{
        type:GET_HOME_HIGHLIGHTED_POSTS_SUCCESS,
        payload
    }
}

export function getHomeHighlightedPostsError(error){
    return{
        type:GET_HOME_HIGHLIGHTED_POSTS_ERROR,
        error
    }
}