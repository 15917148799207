import {
    GET_ALL_GIVING_TYPES,
    GET_ALL_GIVING_TYPES_SUCCESS,
    GET_ALL_GIVING_TYPES_ERROR,
    GET_ALL_GIVING_PLANS,
    GET_ALL_GIVING_PLANS_SUCCESS,
    GET_ALL_GIVING_PLANS_ERROR,
    CREATE_ONE_TIME_GIVING,
    CREATE_ONE_TIME_GIVING_SUCCESS,
    CREATE_ONE_TIME_GIVING_ERROR,
    CREATE_ANONYMOUS_GIVING,
    CREATE_ANONYMOUS_GIVING_SUCCESS,
    CREATE_ANONYMOUS_GIVING_ERROR,
    GET_DATA_FOR_SUBSCRIPTION,
    GET_DATA_FOR_SUBSCRIPTION_SUCCESS,
    GET_DATA_FOR_SUBSCRIPTION_ERROR
} from "./actions";

const initialGivingsState = {
    loading: false,
    loaded: false,
    givingTypes: [],
    givingPlans: [],
    error: null,
    givingCreationLoading: false,
    oneTimeGiving: null,
    dataForSubscription: null,
    subscriptionLoading: false
}

function givingsReducer(state = initialGivingsState, action) {
    switch (action.type) {
        case GET_ALL_GIVING_TYPES:
            return { ...state, loading: true, loaded: false, error: null };
        case GET_ALL_GIVING_TYPES_SUCCESS:
            return { ...state, loading: false, loaded: true, givingTypes: action.payload };
        case GET_ALL_GIVING_TYPES_ERROR:
            return { ...state, loading: false, loaded: false, givingTypes: [], error: action.error };
        case GET_ALL_GIVING_PLANS:
            return { ...state, loading: true, loaded: false, error: null };
        case GET_ALL_GIVING_PLANS_SUCCESS:
            return { ...state, loading: false, loaded: true, givingPlans: action.payload };
        case GET_ALL_GIVING_PLANS_ERROR:
            return { ...state, loading: false, loaded: false, givingPlans: [], error: action.error };
        case CREATE_ONE_TIME_GIVING:
            return { ...state, givingCreationLoading: true, loaded: false, error: null };
        case CREATE_ONE_TIME_GIVING_SUCCESS:
            return { ...state, givingCreationLoading: false, loaded: true, oneTimeGiving: action.payload };
        case CREATE_ONE_TIME_GIVING_ERROR:
            return { ...state, givingCreationLoading: false, loaded: false, oneTimeGiving: null, error: action.error };
        case CREATE_ANONYMOUS_GIVING:
            return { ...state, givingCreationLoading: true, loaded: false, error: null };
        case CREATE_ANONYMOUS_GIVING_SUCCESS:
            return { ...state, givingCreationLoading: false, loaded: true, oneTimeGiving: action.payload };
        case CREATE_ANONYMOUS_GIVING_ERROR:
            return { ...state, givingCreationLoading: false, loaded: false, oneTimeGiving: null, error: action.error };
        case GET_DATA_FOR_SUBSCRIPTION:
            return { ...state, subscriptionLoading: true, loaded: false, dataForSubscription: null, error: null };
        case GET_DATA_FOR_SUBSCRIPTION_SUCCESS:
            return { ...state, subscriptionLoading: false, loaded: true, dataForSubscription: action.payload };
        case GET_DATA_FOR_SUBSCRIPTION_ERROR:
            return { ...state, subscriptionLoading: false, loaded: false, dataForSubscription: null, error: action.error }
        default:
            return state;
    }
}

export default givingsReducer;