import {
    GET_EVENT_TEMPLATES,
    GET_EVENT_TEMPLATES_SUCCESS,
    GET_EVENT_TEMPLATES_ERROR,
    DELETE_EVENT_TEMPLATE,
    DELETE_EVENT_TEMPLATE_ERROR,
    DELETE_EVENT_TEMPLATE_SUCCESS
} from './actions';

const initialEventTemplatesState = {
    loading: false,
    loaded: false,
    error: null,
    eventTemplates: {
        count: 0,
        entities: []
    },
    deleteEventTemplate: {
        loading: false,
        loaded: false,
        error: null
    }
};

function eventTemplatesReducer(state = initialEventTemplatesState, action) {
    switch (action.type) {
        case GET_EVENT_TEMPLATES:
            return { ...state, loading: true, loaded: false };
        case GET_EVENT_TEMPLATES_SUCCESS:
            return { ...state, loading: false, loaded: true, error: null, eventTemplates: action.payload };
        case GET_EVENT_TEMPLATES_ERROR:
            return { ...state, loading: false, loaded: false, error: action.error };
        case DELETE_EVENT_TEMPLATE:
            return {
                ...state,
                deleteEventTemplate: {
                    ...state.deleteEventTemplate,
                    loading: true,
                    loaded: false
                }
            };
        case DELETE_EVENT_TEMPLATE_SUCCESS:
            return {
                ...state,
                deleteEventTemplate: {
                    ...state.deleteEventTemplate,
                    loading: false,
                    loaded: true,
                    error: null                   
                }
            };
        case DELETE_EVENT_TEMPLATE_ERROR:
            return {
                ...state,
                deleteEventTemplate: {
                    ...state.deleteEventTemplate,
                    loading: false,
                    loaded: false,
                    error: action.error
                }
            };

        default:
            return state;
    }
}

export default eventTemplatesReducer;