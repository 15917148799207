import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { getCurrentUser } from "../../common/helpers/auth.helper";
import NotFound from "../NotFound/NotFound";

const GivingsHistory = React.lazy(() =>
	import("./pages/AccountGivingsHistory/AccountGivingsHistory.container")
);
const Settings = React.lazy(() =>
	import("./pages/AccountSettings/AccountSettings.container")
);
const Requests = React.lazy(() =>
	import("./pages/AccountRequests/AccountRequests.container")
);
const PaymentSettings = React.lazy(() =>
	import("./pages/AccountPaymentSettings/AccountPaymentSettings.container")
);
// optional pages
const VideoSettings = React.lazy(() =>
	import(
		"../Dashboard/pages/DashboardVideoSettings/DashboardVideoSettings.container"
	)
);

const Posts = React.lazy(() =>
	import("../Dashboard/pages/DashboardPosts/DashboardPosts.container")
);

const Comments = React.lazy(() =>
	import("../Dashboard/pages/DashboardComments/DashboardComments.container")
);

const GivingsSettings = React.lazy(() =>
	import("../Dashboard/pages/DashboardGivings/DashboardGivings.container")
);

const Events = React.lazy(() =>
	import("../Dashboard/pages/DashboardEvents/DashboardEvents.container")
);

const Roles = React.lazy(() =>
	import("../Dashboard/pages/DashboardRoles/DashboardRoles.Container")
);

const Reports = React.lazy(() =>
	import("../Dashboard/pages/DashboardReports/DashboardReports.Container")
);

const Members = React.lazy(() =>
	import("../Dashboard/pages/DashboardMembers/DashboardMembers.Container")
);

// Plans
const Plans = React.lazy(() =>
	import("../Dashboard/pages/DashboardPlans/DashboardPlans.Container")
);

const MasterCalendar = React.lazy(() =>
	import(
		"../Dashboard/pages/DashboardPlans/components/MasterCalendar/MasterCalendar.Container"
	)
);

const PlanPage = React.lazy(() =>
	import("../Dashboard/pages/DashboardPlanPage/PlanPage.Container")
);

const ALLOWED_ACTIONS = {
	"Video upload": {
		path: "/account/video-settings",
		component: VideoSettings,
	},
	"Post upload": {
		path: "/account/posts",
		component: Posts,
	},
	"Service plans": {
		path: "/account/event-plans",
		component: Plans,
	},
	Comments: {
		path: "/account/comments",
		component: Comments,
	},
	Givings: {
		path: "/account/givings-settings",
		component: GivingsSettings,
	},
	Events: {
		path: "/account/events",
		component: Events,
	},
	Roles: {
		path: "/account/roles",
		component: Roles,
	},
	Reports: {
		path: "/account/Reports",
		component: Reports,
	},
	Members: {
		path: "/account/members",
		component: Members,
	},
};

export default function AccountRouting() {
	return (
		<Switch>
			<Route
				path={`/account/givings-history`}
				component={GivingsHistory}
			/>
			<Route path={`/account/settings`} component={Settings} />
			<Route path={`/account/prayer-requests`} component={Requests} />
			<Route
				path={`/account/payment-settings`}
				component={PaymentSettings}
			/>
			<Route
				exact
				path="/account"
				render={() => <Redirect to="/account/settings" />}
			/>
			{getCurrentUser().allowedActions.map((elem) => (
				<Route
					key={elem}
					exact
					path={ALLOWED_ACTIONS[elem].path}
					component={ALLOWED_ACTIONS[elem].component}
				/>
			))}
			{getCurrentUser().allowedActions.includes("Service plans") && (
				<>
					<Route
						path={`/account/plan-page/:id`}
						component={PlanPage}
					/>
					<Route
						path={`/account/events-master-calendar`}
						component={MasterCalendar}
					/>
				</>
			)}
			<Route component={NotFound} />
		</Switch>
	);
}
