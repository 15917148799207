export const GET_EVENT_TEMPLATES = "GET_EVENT_TEMPLATES";
export const GET_EVENT_TEMPLATES_SUCCESS = "GET_EVENT_TEMPLATES_SUCCESS";
export const GET_EVENT_TEMPLATES_ERROR = "GET_EVENT_TEMPLATES_ERROR";

export function getEventTemplates(payload){
    return{
        type:GET_EVENT_TEMPLATES,
        payload
    };
}

export function getEventTemplatesSuccess(payload){
    return{
        type:GET_EVENT_TEMPLATES_SUCCESS,
        payload
    };
}

export function getEventTemplatesError(error){
    return{
        type:GET_EVENT_TEMPLATES_ERROR,
        error
    };
}

export const DELETE_EVENT_TEMPLATE = "DELETE_EVENT_TEMPLATE";
export const DELETE_EVENT_TEMPLATE_SUCCESS = "DELETE_EVENT_TEMPLATE_SUCCESS";
export const DELETE_EVENT_TEMPLATE_ERROR = "DELETE_EVENT_TEMPLATE_ERROR";

export function deleteEventTemplate(payload){
    return{
        type:DELETE_EVENT_TEMPLATE,
        payload
    };
}

export function deleteEventTemplateSuccess(payload){
    return{
        type:DELETE_EVENT_TEMPLATE_SUCCESS,
        payload
    };
}

export function deleteEventTemplateError(error){
    return{
        type:DELETE_EVENT_TEMPLATE_ERROR,
        error
    };
}
