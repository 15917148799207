import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import { ReactComponent as GivingsIcon } from "../../../assets/img/icons/new_givings_history.svg";
import { ReactComponent as PrayerRequestsIcon } from "../../../assets/img/icons/prayer_requests.svg";
import { ReactComponent as UserIcon } from "../../../assets/img/icons/men.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/img/icons/settings.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/img/icons/logout.svg";
import MobileNav from "./MobileNav";
import { ReactComponent as ChevronRight } from "../../../assets/img/icons/chevron_right-24px.svg";
import { ReactComponent as ChevronLeft } from "../../../assets/img/icons/chevron_left-24px.svg";
import { ReactComponent as VideoIcon } from "../../../assets/img/icons/video_settings.svg";
import { ReactComponent as PostsIcon } from "../../../assets/img/icons/new_posts.svg";
import { ReactComponent as CommentsIcon } from "../../../assets/img/icons/new_comments.svg";
import { ReactComponent as EventsIcon } from "../../../assets/img/icons/events.svg";
import { ReactComponent as ReportsIcon } from "../../../assets/img/icons/reports.svg";
import { ReactComponent as PlansIcon } from "../../../assets/img/icons/plans.svg";

import { getCurrentUser } from "../../../common/helpers/auth.helper";

export const ALLOWED_ACTIONS = {
	"Video upload": {
		path: "/account/video-settings",
		name: "Video Settings",
		label: "Video Settings",
		icon: <VideoIcon />,
	},
	"Post upload": {
		path: "/account/posts",
		name: "Posts",
		label: "Posts",
		icon: <PostsIcon />,
	},
	"Service plans": {
		path: "/account/event-plans",
		name: "Plans",
		label: "Plans",
		icon: <PlansIcon />,
	},
	Comments: {
		path: "/account/comments",
		name: "Comments",
		label: "Comments",
		icon: <CommentsIcon />,
	},
	Givings: {
		path: "/account/givings-settings",
		name: "Givings Settings",
		label: "Givings Settings",
		icon: <GivingsIcon />,
	},
	Events: {
		path: "/account/events",
		name: "Events",
		label: "Events",
		icon: <EventsIcon />,
	},
	Reports: {
		path: "/account/Reports",
		name: "Reports",
		label: "Reports",
		icon: <ReportsIcon />,
	},
};

const AccountLayout = ({ onLogout, children }) => {
	const logoutClick = (e) => {
		e.preventDefault();
		onLogout();
	};

	const [showMenu, setShowMenu] = useState("76px");

	return (
		<>
			<div className="dashboard__mobile_nav">
				<MobileNav logoutClick={logoutClick} />
			</div>
			<div className="dashboard__all_container">
				<div className="dashboard__tabs account">
					<div
						className="dashboard__desktop_menu"
						style={{
							width: showMenu,
						}}>
						<div
							className="nav-link-alt"
							onClick={() => {
								if (showMenu === "76px") {
									setShowMenu("250px");
								} else {
									setShowMenu("76px");
								}
							}}>
							<span className="nav-icon">
								{showMenu === "76px" ? (
									<ChevronRight />
								) : (
									<ChevronLeft />
								)}
							</span>
						</div>

						<NavLink
							className="nav-link-alt"
							to={"/account/settings"}>
							<span className="nav-icon  nav-icon_sm">
								<label title="Account Settings">
									<UserIcon />
								</label>
							</span>
							<span>Account Settings</span>
						</NavLink>

						<NavLink
							className="nav-link-alt"
							to={"/account/givings-history"}>
							<span className="nav-icon nav-icon_stroke">
								<label title="Givings History">
									<GivingsIcon />
								</label>
							</span>
							<span>Givings History</span>
						</NavLink>
						<NavLink
							className="nav-link-alt"
							to={"/account/prayer-requests"}>
							<span className="nav-icon  nav-icon_sm nav-icon_stroke">
								<label title="My Prayer Requests">
									<PrayerRequestsIcon />
								</label>
							</span>
							<span>My Prayer Requests</span>
						</NavLink>

						<NavLink
							className="nav-link-alt"
							to={"/account/payment-settings"}>
							<span className="nav-icon  nav-icon_sm nav-icon_stroke">
								<label title="Payment Settings">
									<SettingsIcon />
								</label>
							</span>
							<span>Payment Settings</span>
						</NavLink>

						{getCurrentUser().allowedActions.map((elem) => {
							if (ALLOWED_ACTIONS[elem]) {
								return (
									<NavLink
										key={elem}
										className="nav-link-alt"
										to={ALLOWED_ACTIONS[elem].path}>
										<span className="nav-icon  nav-icon_sm nav-icon_stroke">
											<label
												title={
													ALLOWED_ACTIONS[elem].label
												}>
												{ALLOWED_ACTIONS[elem].icon}
											</label>
										</span>
										<span>
											{ALLOWED_ACTIONS[elem].name}
										</span>
									</NavLink>
								);
							} else {
								return null;
							}
						})}
						<div className="logout_link">
							<a
								onClick={logoutClick}
								href="#!"
								className="nav-link-alt">
								<span className="nav-icon nav-icon_stroke nav-icon_sm">
									<label title="Log out">
										<LogoutIcon />
									</label>
								</span>

								<span>Log out</span>
							</a>
						</div>
					</div>
				</div>
				<section className="dashboard">
					<div className="dashboard__content_alt">{children}</div>
				</section>
			</div>
		</>
	);
};

export default AccountLayout;
