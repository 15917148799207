export const GET_ACCOUNT_REQUESTS = "GET_ACCOUNT_REQUESTS";
export const GET_ACCOUNT_REQUESTS_SUCCESS = "GET_ACCOUNT_REQUESTS_SUCCESS";
export const GET_ACCOUNT_REQUESTS_ERROR = "GET_ACCOUNT_REQUESTS_ERROR";

export function getRequests(payload) {
	return {
		type: GET_ACCOUNT_REQUESTS,
		payload,
	};
}

export function getRequestsSuccess(payload) {
	return {
		type: GET_ACCOUNT_REQUESTS_SUCCESS,
		payload,
	};
}

export function getRequestsError(error) {
	return {
		type: GET_ACCOUNT_REQUESTS_ERROR,
		error,
	};
}

export const GET_ACCOUNT_REQUESTS_STEALTH = "GET_ACCOUNT_REQUESTS_STEALTH";
export const GET_ACCOUNT_REQUESTS_SUCCESS_STEALTH =
	"GET_ACCOUNT_REQUESTS_SUCCESS_STEALTH";
export const GET_ACCOUNT_REQUESTS_ERROR_STEALTH =
	"GET_ACCOUNT_REQUESTS_ERROR_STEALTH";

export function getRequestsStealth(payload) {
	return {
		type: GET_ACCOUNT_REQUESTS_STEALTH,
		payload,
	};
}

export function getRequestsSuccessStealth(payload) {
	return {
		type: GET_ACCOUNT_REQUESTS_SUCCESS_STEALTH,
		payload,
	};
}

export function getRequestsErrorStealth(error) {
	return {
		type: GET_ACCOUNT_REQUESTS_ERROR_STEALTH,
		error,
	};
}

export const RESET_ACCOUNT_REQUESTS = "RESET_ACCOUNT_REQUESTS";
export const RESET_ACCOUNT_REQUESTS_STEALTH = "RESET_ACCOUNT_REQUESTS_STEALTH";

export function resetAccountRequests() {
	return {
		type: RESET_ACCOUNT_REQUESTS,
	};
}

export function resetAccountRequestsStealth() {
	return {
		type: RESET_ACCOUNT_REQUESTS_STEALTH,
	};
}

export const RESET_ACCOUNT_NEW_MESSAGES_COUNTER =
	"RESET_ACCOUNT_NEW_MESSAGES_COUNTER";
export function resetAccountNewMessagesCounter(payload) {
	return {
		type: RESET_ACCOUNT_NEW_MESSAGES_COUNTER,
		payload,
	};
}

export const MOVE_ACCOUNT_REQUEST_TO_TOP = "MOVE_ACCOUNT_REQUEST_TO_TOP";

export function moveAccountRequestToTop(payload) {
	return {
		type: MOVE_ACCOUNT_REQUEST_TO_TOP,
		payload,
	};
}

export const SEARCH_USER_PRAYER_REQUESTS = "SEARCH_USER_PRAYER_REQUESTS";
export const SEARCH_USER_PRAYER_REQUESTS_SUCCESS =
	"SEARCH_USER_PRAYER_REQUESTS_SUCCESS";
export const SEARCH_USER_PRAYER_REQUESTS_ERROR =
	"SEARCH_USER_PRAYER_REQUESTS_ERROR";

export function searchUserPrayerRequests(payload) {
	return {
		type: SEARCH_USER_PRAYER_REQUESTS,
		payload,
	};
}

export function searchUserPrayerRequestsSuccess(payload) {
	return {
		type: SEARCH_USER_PRAYER_REQUESTS_SUCCESS,
		payload,
	};
}

export function searchUserPrayerRequestsError(error) {
	return {
		type: SEARCH_USER_PRAYER_REQUESTS_ERROR,
		payload: error,
	};
}
