import { axiosBase } from "../api/api.base";
import { refreshTokenSuccess } from "../../redux/actions/auth.actions";

const SESSION = "session";

const getUser = (user) => {
	return {
		userId: user.userId,
		accessToken: user.accessToken,
		accessTokenExpirationDateTimeOffset:
			user.accessTokenExpirationDateTimeOffset,
		refreshToken: user.refreshToken,
		roles: user.roles,
		isSignedIn: true,
		allowedActions: user.allowedActions,
	};
};

export const setCurrentUser = (user) => {
	localStorage.setItem(SESSION, JSON.stringify(user));
};

export const updateToken = (tokenData) => {
	const json = localStorage.getItem(SESSION);
	const user = JSON.parse(json);

	user.accessToken = tokenData.accessToken;
	user.accessTokenExpirationDateTimeOffset =
		tokenData.accessTokenExpirationDateTimeOffset;
	user.refreshToken = tokenData.refreshToken;

	setCurrentUser(user);
};

export const clearCurrentUser = () => {
	localStorage.removeItem(SESSION);
};

export const getCurrentUser = () => {
	const json = localStorage.getItem(SESSION);
	const user = JSON.parse(json);
	if (user) return getUser(user);
	return {
		userId: "",
		accessToken: "",
		expiresIn: "",
		refreshToken: "",
		roles: [],
		isSignedIn: false,
		allowedActions: [],
	};
};

export const isTokenExpired = () => {
	const { accessTokenExpirationDateTimeOffset } = getCurrentUser();
	const getTime = new Date().getTime() / 1000;
	const expTime =
		new Date(accessTokenExpirationDateTimeOffset).getTime() / 1000;
	return expTime <= getTime;
};

export const getValidToken = async () => {
	const currentUser = getCurrentUser();
	if (!isTokenExpired()) {
		return currentUser.accessToken;
	} else {
		const { accessToken, refreshToken } = currentUser;
		const response = await axiosBase.post(
			"/api/account/refresh-access-token",
			{ accessToken, refreshToken }
		);
		if (!response.data) {
			throw Error("Can't get refresh token");
		}
		updateToken(response.data);
		refreshTokenSuccess();
		return getCurrentUser().accessToken;
	}
};
