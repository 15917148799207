export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_ERROR = "SEARCH_USERS_ERROR";

export function searchUsers(payload){
    return {
        type:SEARCH_USERS,
        payload
    }
}

export function searchUsersSuccess(payload){
    return{
        type:SEARCH_USERS_SUCCESS,
        payload
    }
}

export function searchUsersError(error){
    return{
        type:SEARCH_USERS_ERROR,
        error
    }
}

export const CLEAR_FINDED_USERS = "CLEAR_FINDED_USERS";
export function clearFindedUsers(payload){
    return{
        type:CLEAR_FINDED_USERS,
        payload
    }
}