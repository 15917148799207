import React from "react";
import { ModalBody, ModalFooter } from "reactstrap";

const PaymentSuccess = ({ closeModal }) => {
	return (
		<>
			<ModalBody className="wide-modal-body-without-shadow">
				<div className="modal-inner modal-inner_step_2 / text-center">
					<p className="modal-subtitle">Thank you!</p>
					<p className="m-0">Your gift has been sent successfully.</p>
				</div>
			</ModalBody>
			<ModalFooter className="wide-modal-body-without-shadow">
				<button
					className="btn  btn_theme_white"
					type="button"
					onClick={closeModal}>
					Close
				</button>
			</ModalFooter>
		</>
	);
};

export default PaymentSuccess;
