export const SEARCH_FOR_SONGS_REQUEST = "SEARCH_FOR_SONGS_REQUEST";
export const SEARCH_FOR_SONGS_SUCCESS = "SEARCH_FOR_SONGS_SUCCESS";
export const SEARCH_FOR_SONGS_ERROR = "SEARCH_FOR_SONGS_ERROR";

export const searchForSongsRequest = (payload) => ({
	type: SEARCH_FOR_SONGS_REQUEST,
	payload,
});

export const searchForSongsSuccess = (payload) => ({
	type: SEARCH_FOR_SONGS_SUCCESS,
	payload,
});

export const searchForSongsError = (error) => ({
	type: SEARCH_FOR_SONGS_ERROR,
	payload: error,
});

export const SELECT_SONG_REQUEST = "SELECT_SONG_REQUEST";
export const SELECT_SONG_SUCCESS = "SELECT_SONG_SUCCESS";
export const SELECT_SONG_ERROR = "SELECT_SONG_ERROR";

export const selectSongRequest = (payload) => ({
	type: SELECT_SONG_REQUEST,
	payload,
});

export const selectSongSuccess = (payload) => ({
	type: SELECT_SONG_SUCCESS,
	payload,
});

export const selectSongError = (error) => ({
	type: SELECT_SONG_ERROR,
	payload: error,
});

export const GET_POPULAR_SONGS_REQUEST = "GET_POPULAR_SONGS_REQUEST";
export const GET_POPULAR_SONGS_SUCCESS = "GET_POPULAR_SONGS_SUCCESS";
export const GET_POPULAR_SONGS_ERROR = "GET_POPULAR_SONGS_ERROR";

export const getPopularSongsRequest = (payload) => ({
	type: GET_POPULAR_SONGS_REQUEST,
	payload,
});

export const getPopularSongsSuccess = (payload) => ({
	type: GET_POPULAR_SONGS_SUCCESS,
	payload,
});

export const getPopularSongsError = (error) => ({
	type: GET_POPULAR_SONGS_ERROR,
	payload: error,
});

export const CLEAR_SONGS_SEARCH_RESULT = "CLEAR_SONGS_SEARCH_RESULT";

export const clearSongsSearchResult = () => ({
	type: CLEAR_SONGS_SEARCH_RESULT,
});

export const SEARCH_FOR_POPULAR_SONGS_REQUEST =
	"SEARCH_FOR_POPULAR_SONGS_REQUEST";
export const SEARCH_FOR_POPULAR_SONGS_SUCCESS =
	"SEARCH_FOR_POPULAR_SONGS_SUCCESS";
export const SEARCH_FOR_POPULAR_SONGS_ERROR = "SEARCH_FOR_POPULAR_SONGS_ERROR";

export const searchForPopularSongsRequest = (payload) => ({
	type: SEARCH_FOR_POPULAR_SONGS_REQUEST,
	payload,
});

export const searchForPopularSongsSuccess = (payload) => ({
	type: SEARCH_FOR_POPULAR_SONGS_SUCCESS,
	payload,
});

export const searchForPopularSongsError = (error) => ({
	type: SEARCH_FOR_POPULAR_SONGS_ERROR,
	payload: error,
});
