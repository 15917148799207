import {
    CREATE_COMMENT,
    CREATE_COMMENT_SUCCESS,
    CREATE_COMMENT_ERROR,
    GET_VIDEO_COMMENTS,
    GET_VIDEO_COMMENTS_SUCCESS,
    GET_VIDEO_COMMENTS_ERROR
} from './actions';

const initialCommentsState = {
    error: null,
    loading: false,
    loaded: true,
    comments: {
        count: 0,
        entities: []
    }
}

function videoCommentsReducer(state = initialCommentsState, action) {
    switch (action.type) {
        case CREATE_COMMENT:
            return { ...state, loading: true, loaded: false, error: null };
        case CREATE_COMMENT_SUCCESS:
            return { ...state, loading: false, loaded: true, error: null };
        case CREATE_COMMENT_ERROR:
            return { ...state, loading: false, loaded: false, error: action.error }
        case GET_VIDEO_COMMENTS:           
            return { ...state, loading: true, loaded: false, error: null };
        case GET_VIDEO_COMMENTS_SUCCESS:
            return { ...state, loading: false, loaded: true, error: null, comments: action.payload }
        case GET_VIDEO_COMMENTS_ERROR:
            return { ...state, loading: false, loaded: false, error: action.error, comments: { count: 0, entities: [] } };
        default:
            return state
    }
}

export default videoCommentsReducer;