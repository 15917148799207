import React, { useState, useEffect } from "react";
import { ModalBody, ModalFooter, Spinner } from "reactstrap";
import { getCardBrandFullName } from "../../../../../common/helpers/card.utils";
import { useStripe } from "@stripe/react-stripe-js";

const PaymentMethods = ({
	goToCheckoutForm,
	paymentMethods,
	giving,
	onSuccess,
	createPaymentIntent,
	paymentIntentData,
}) => {
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
	const [error, setError] = useState(null);
	const [succeeded, setSucceeded] = useState(false);
	const [processing, setProcessing] = useState("");
	const stripe = useStripe();

	const handlePay = async (ev) => {
		ev.preventDefault();
		setProcessing(true);
		createPaymentIntent({ givingId: giving.id, amount: giving.amount });
	};

	useEffect(() => {
		if (paymentIntentData) {
			async function confirmPayment() {
				const payload = await stripe.confirmCardPayment(
					paymentIntentData.clientSecret,
					{
						payment_method:
							selectedPaymentMethod.stripePaymentMethodId,
					}
				);

				if (payload.error) {
					setError(`Payment failed ${payload.error.message}`);
					setProcessing(false);
				} else {
					setError(null);
					setProcessing(false);
					setSucceeded(true);
					onSuccess();
				}
			}

			confirmPayment();
		}
	}, [paymentIntentData]);

	const renderPaymentMethods = () => {
		return (
			<ul className="giving-payment-methods">
				{paymentMethods.map((pm) => {
					return (
						<li
							key={pm.id}
							className={
								"row giving-payment-methods__item " +
								(pm.id === selectedPaymentMethod.id
									? "active"
									: "")
							}
							onClick={() => setSelectedPaymentMethod(pm)}>
							<span className="col-6">
								{pm.card.brand !== "unknown" ? (
									<img
										src={require(`../../../../../assets/img/cards/${pm.card.brand}.png`)}
										alt={getCardBrandFullName(
											pm.card.brand
										)}
									/>
								) : (
									<>Unknown card brand</>
								)}
							</span>
							<span className="col-6">
								**** **** **** {pm.card.last4}
							</span>
						</li>
					);
				})}
			</ul>
		);
	};

	useEffect(() => {
		const defaultPaymentMethod = paymentMethods.find((pm) => pm.isDefault);
		if (defaultPaymentMethod) {
			setSelectedPaymentMethod(defaultPaymentMethod);
		}
	}, [paymentMethods]);

	return (
		<>
			<ModalBody className="wide-modal-body-without-shadow">
				<p className="modal-subtitle / text-center / mb-4">
					Select payment method
				</p>
				{renderPaymentMethods()}
				{error && (
					<div className="card-error" role="alert">
						{error}
					</div>
				)}
			</ModalBody>
			<ModalFooter className="wide-modal-body-without-shadow">
				<button
					className="btn  btn_theme_transparent / mr-auto"
					type="button"
					disabled={processing}
					onClick={goToCheckoutForm}>
					Use another card
				</button>

				<button
					className="btn  btn_theme_white"
					disabled={processing || !selectedPaymentMethod.id}
					onClick={handlePay}
					type="button">
					Pay
					{processing && <Spinner size="sm" />}
				</button>
			</ModalFooter>
		</>
	);
};

export default PaymentMethods;
