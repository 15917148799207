import React, { useEffect } from "react";
import { Modal } from "reactstrap";
import { ReactComponent as CloseIcon } from "../../assets/img/icons/close.svg";
import { ReactComponent as OneTimeGiftIcon } from "../../assets/img/icons/one_time_gift.svg";
import { ReactComponent as OnlineRecurringIcon } from "../../assets/img/icons/online_recurring.svg";
import { ReactComponent as InPersonIcon } from "../../assets/img/icons/in_person.svg";
import { ReactComponent as ByMailIcon } from "../../assets/img/icons/by_mail.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeAllGivingModals, openGivingModal } from "./store/modals/actions";
import OneTimeGift from "./modules/OneTimeGift/OneTimeGift";
import OnlineRecurring from "./modules/OnlineRecurring/OnlineRecurring";
import { toast } from "react-toastify";
import AnonymousGift from "./modules/AnonymousGift/AnonymousGift";

const Giving = ({
	isSignedIn,
	fetchSelectsData,
	givingPlans,
	givingTypes,
	createOneTimeGiving,
	createAnonymousGiving,
	oneTimeGiving,
	givingCreationLoading,
	createOneTimeGivingPaymentIntent,
	createAnonymousGivingPaymentIntent,
	resetPaymentIntent,
	oneTimeGivingPaymentIntentData,
	dataForSubscription,
	subscriptionLoading,
	getDataForSubscription,
	getPaymentMethods,
	paymentMethods,
}) => {
	const dispatch = useDispatch();
	const currentModal = useSelector((x) => x.givingModals.currentModal);
	const show = currentModal === "giving";

	useEffect(() => {
		if (show) {
			resetPaymentIntent();
			fetchSelectsData();
			getPaymentMethods();
		}
	}, [show]);

	const closeModal = () => {
		dispatch(closeAllGivingModals());
	};

	const handleOneTimeModals = (e) => {
		e.preventDefault();
		if (isSignedIn) {
			dispatch(openGivingModal("one-time-gift"));
		} else {
			dispatch(openGivingModal("anonymous-gift"));
		}
	};

	const openOnlineRecurringModal = (e) => {
		e.preventDefault();
		if (isSignedIn) {
			dispatch(openGivingModal("online-recurring"));
		} else {
			toast.info("Please, sign in to make a recurring donation", {
				autoClose: 3500,
			});
		}
	};

	return (
		<>
			<Modal isOpen={show} toggle={closeModal} centered>
				<div className="modal-header / align-items-center">
					<h5
						className="modal-title / title  title_size_h2"
						id="login_modal_title">
						Giving
					</h5>
					<button
						className="close"
						type="button"
						aria-label="Close"
						onClick={closeModal}>
						<CloseIcon />
					</button>
				</div>
				<div className="modal-list / row / no-gutters">
					<div className="modal-item  modal-item_shadow / col-md-6">
						<OneTimeGiftIcon className="modal-icon" />

						<h6 className="modal-subtitle">Online-one time gift</h6>

						<p className="modal-text">
							Click on the link above to give a one-time gift to
							<br /> Peter's Rock Ministries.
						</p>

						<a
							className="btn  btn_theme_white"
							href="#replace"
							onClick={handleOneTimeModals}>
							Give
						</a>
					</div>

					<div className="modal-item  modal-item_shadow / col-md-6">
						<OnlineRecurringIcon className="modal-icon" />

						<h6 className="modal-subtitle">Online-recurring</h6>

						<p className="modal-text">
							You can set up scheduled giving to the church click
							on the link above and sign in to your Peter's Rock
							Ministries and then select the Financial tab on your
							profile.
						</p>

						<a
							className="btn  btn_theme_white"
							href="#replace"
							onClick={openOnlineRecurringModal}>
							Give
						</a>
					</div>

					<div className="modal-item / col-md-6">
						<InPersonIcon className="modal-icon" />

						<h6 className="modal-subtitle">In person</h6>

						<p className="modal-text">
							Cash or check can be given during our Sunday
							gatherings. If you'd like to receive a tax deduction
							for cash gifts, the gift should be placed in an
							offering envelope with your name and address on it.
							Offering envelopes are available at all of our
							services.
						</p>
					</div>

					<div className="modal-item / col-md-6">
						<ByMailIcon className="modal-icon" />

						<h6 className="modal-subtitle">By mail</h6>

						<p className="modal-text">
							You can mail a check, made out to Peter's Rock
							Ministries, to our office at 256 S 8th Street,
							Salina, KS 67401.
						</p>
					</div>
				</div>
			</Modal>
			<OneTimeGift
				paymentIntentData={oneTimeGivingPaymentIntentData}
				createPaymentIntent={createOneTimeGivingPaymentIntent}
				givingTypes={givingTypes}
				createOneTimeGiving={createOneTimeGiving}
				giving={oneTimeGiving}
				givingCreationLoading={givingCreationLoading}
				paymentMethods={paymentMethods}
				resetPaymentIntent={resetPaymentIntent}
			/>
			<AnonymousGift
				paymentIntentData={oneTimeGivingPaymentIntentData}
				createPaymentIntent={createAnonymousGivingPaymentIntent}
				givingTypes={givingTypes}
				createOneTimeGiving={createAnonymousGiving}
				giving={oneTimeGiving}
				givingCreationLoading={givingCreationLoading}
				paymentMethods={paymentMethods}
				resetPaymentIntent={resetPaymentIntent}
			/>
			<OnlineRecurring
				getPaymentMethods={getPaymentMethods}
				paymentMethods={paymentMethods}
				givingTypes={givingTypes}
				givingPlans={givingPlans}
				dataForSubscription={dataForSubscription}
				subscriptionLoading={subscriptionLoading}
				getDataForSubscription={getDataForSubscription}
				resetPaymentIntent={resetPaymentIntent}
			/>
		</>
	);
};

export default Giving;
