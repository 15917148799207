import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";

export const formatStringDateToMMDDYYYY = (dateStr) => {
	const date = new Date(dateStr);

	return (
		("0" + (date.getMonth() + 1)).slice(-2) +
		"." +
		("0" + date.getDate()).slice(-2) +
		"." +
		date.getFullYear()
	);
};

export const formatStringDateToMMDDYYYYHHMM = (dateStr) => {
	const date = new Date(dateStr);

	return (
		("0" + (date.getMonth() + 1)).slice(-2) +
		"." +
		("0" + date.getDate()).slice(-2) +
		"." +
		date.getFullYear() +
		" " +
		("0" + date.getHours()).slice(-2) +
		":" +
		("0" + date.getMinutes()).slice(-2)
	);
};

export const formatStringDateToAMPM = (dateStr) => {
	return moment(dateStr).format("MM.DD.YYYY hh:mm A");
};

export const formatStringDateToTimeAgo = (dateStr) => {
	TimeAgo.addLocale(en);
	const timeAgo = new TimeAgo("en-Us");

	const date = new Date(dateStr);
	return timeAgo.format(date);
};
