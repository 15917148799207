import { call, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
    GET_POST,
    getPostSuccess,
    getPostError
} from './actions';

import ApiBase from '../../../common/api/api.base';
import { toast } from "react-toastify";

function* workerGetPost(action) {
    try {
        const result = yield call(ApiBase.get, `/api/posts/${action.payload}`);
        if (!result.error) {
            yield put(getPostSuccess(result));
        } else {
            yield put(getPostError(`Failed to get post!`));
        }
    } catch (e) {
        const { status } = e.response;
        if (status === 404) {
            yield put(getPostError({status}));
        }
        else{
            yield put(getPostError(`Failed to get post!`));
            yield put(push("/posts"));
            toast.error(`Failed to get post!`);  
        }
    }
}

export default function* watchPostSaga() {
    yield takeEvery(GET_POST, workerGetPost);
}