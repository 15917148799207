import{
    SEARCH_USERS,
    SEARCH_USERS_SUCCESS,   
    SEARCH_USERS_ERROR,
    CLEAR_FINDED_USERS
} from '../actions/users.actions';

const initialUsersState = {
    users:[],
    loading:false,
    error:null,
    loaded:false
};

function usersReducer(state=initialUsersState, action){
    switch(action.type){
        case SEARCH_USERS:
            return {...state, loading:true, loaded:false};
        case SEARCH_USERS_SUCCESS:
            return {...state, loading:false, loaded:true, users:state.users.concat(action.payload)};
        case SEARCH_USERS_ERROR:
            return {...state, loading:false, loaded:false, error:action.error, users:[]};
        case CLEAR_FINDED_USERS:
            return initialUsersState;
        default:
            return state;
    }
}

export default usersReducer;