import { immutableSwap } from "./common.utils";

export function concatRequests(state, payload) {
	const { entities } = state.requests;
	return {
		hasMore: payload.hasMore,
		entities: [...entities, ...payload.entities],
	};
}

export function resetMessagesCounter(state, request) {
	const { hasMore, entities } = state.requests;

	const requestIdx = entities.findIndex((r) => r.id === request.id);
	request.numberOfNewMessages = 0;

	return {
		hasMore,
		entities: [
			...entities.slice(0, requestIdx),
			request,
			...entities.slice(requestIdx + 1),
		],
	};
}

export function moveToTop(state, action) {
	const { hasMore, entities } = state.requests;
	const requestIdx = entities.findIndex((r) => r.id == action.requestId);
	// const test = immutableSwap(entities, 0, requestIdx);/
	return {
		hasMore,
		entities: [
			{
				...entities.find((elem) => elem.id === action.requestId),
				creationDate: action.creationDate,
				requestText: action.messageText,
				creator: {
					firstName: action.creatorName.split(" ")[0],
					id: action.creatorId,
					lastName: action.creatorName.split(" ")[1],
					photoUrl: action.creatorPhotoUrl,
				},
			},
			...entities.filter((item) => item.id !== action.requestId),
		],
		// entities: immutableSwap(entities, 0, requestIdx),
	};
}

export function replaceCreator(state, payload) {
	return state.requests.entities.map((elem) => {
		if (elem.id === payload.requestId) {
			return {
				...elem,
				id: payload.requestId,
				requestText: payload.message.messageText,
				creator: {
					...elem.creator,
					firstName: payload.message.creatorName.split(" ")[1],
					lastName: payload.message.creatorName.split(" ")[1],
					photoUrl: payload.message.creatorPhotoUrl,
				},
				creationDate: payload.message.creationDate,
			};
		} else {
			return elem;
		}
	});
}
