import React from "react";
import { ModalBody, ModalFooter, Spinner } from "reactstrap";
import { STRIPE_MAX_AMOUNT } from "../../../../../common/constants/stripe.constants";

const RecurringPaymentConfirmation = ({
	goBack,
	submitPayment,
	givingTypes,
	givingPlans,
	loading,
	selectedPlan,
}) => {
	const plan = givingPlans.find((x) => x.id == selectedPlan.recurringAmount);
	const designation = givingTypes.find(
		(x) => x.id === selectedPlan.recurringDesignation
	);

	const exceedLimit = plan.amount > STRIPE_MAX_AMOUNT;
	return (
		<>
			<ModalBody className="wide-modal-body-without-shadow">
				<p className="modal-subtitle / text-center / mb-4">
					Confirm recurring giving
				</p>
				<div>
					<p>
						You wish to give ${plan.amount} for{" "}
						<b>{designation.name}</b> every month.
						<br />
						<span style={{ fontSize: "0.9rem" }}>
							(The recurring giving can be cancelled any time)
						</span>
					</p>
				</div>

				{exceedLimit && (
					<div className="alert alert-warning" role="alert">
						Sum of the giving can't be more than $
						{STRIPE_MAX_AMOUNT}.
					</div>
				)}
			</ModalBody>
			<ModalFooter className="wide-modal-body-without-shadow">
				<button
					className="btn  btn_theme_transparent / mr-auto"
					type="button"
					disabled={loading}
					onClick={goBack}>
					&lt;Back
				</button>

				<button
					onClick={submitPayment}
					className="btn  btn_theme_white"
					disabled={loading || exceedLimit}
					type="submit">
					Confirm and checkout
					{loading && <Spinner size="sm" />}
				</button>
			</ModalFooter>
		</>
	);
};

export default RecurringPaymentConfirmation;
