export const CREATE_COMMENT = "CREATE_COMMENT";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_ERROR = "CREATE_COMMENT_ERROR";

export function createComment(payload) {
    return {
        type: CREATE_COMMENT,
        payload
    }
}

export function createCommentSuccess(payload) {
    return {
        type: CREATE_COMMENT_SUCCESS,
        payload
    }
}

export function createCommentError(error) {
    return {
        type: CREATE_COMMENT_ERROR,
        error
    }
}


export const GET_VIDEO_COMMENTS = "GET_VIDEO_COMMENTS";
export const GET_VIDEO_COMMENTS_SUCCESS = "GET_VIDEO_COMMENTSS_SUCCESS";
export const GET_VIDEO_COMMENTS_ERROR = "GET_VIDEO_COMMENTS_ERROR";

export function getVideoComments(payload) {
    return {
        type: GET_VIDEO_COMMENTS,
        payload
    }
}

export function getVideoCommentsSuccess(payload) {
    return {
        type: GET_VIDEO_COMMENTS_SUCCESS,
        payload
    }
}

export function getVideoCommentsError(error) {
    return {
        type: GET_VIDEO_COMMENTS_ERROR,
        error
    }
}