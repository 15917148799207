import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useScrollHandler } from "../../../hooks/useScrollHandler";
import { Collapse } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { openGivingModal } from "../../../../pages/Giving/store/modals/actions";
import LoginModal from "../../../../modals/LoginModal/LoginModal.container";
import SignUpModal from "../../../../modals/SignUpModal/SignUpModal.container";
import Avatar from "react-avatar";
import classnames from "classnames";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";

const JoinUsDropdown = ({ onSignIn, onSignUp }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	return (
		<Dropdown direction="down" isOpen={dropdownOpen} toggle={toggle}>
			<DropdownToggle tag="a" className="nav-link">
				Join Us
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem
					className="join-us-dropdown-item"
					onClick={onSignUp}>
					Sign Up
				</DropdownItem>
				<DropdownItem
					className="join-us-dropdown-item"
					onClick={onSignIn}>
					Sign In
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const CommomLayoutHeader = ({ isSignedIn, isAdmin, additionClasses }) => {
	const dispatch = useDispatch();

	const onGivingClick = (e) => {
		e.preventDefault();
		dispatch(openGivingModal("giving"));
	};

	const [collapsed, setCollapsed] = useState(true);

	const toggleNavbar = () => setCollapsed(!collapsed);

	const scroll = useScrollHandler(50);

	const [showLogin, setShowLogin] = useState(false);
	const [showSignUp, setShowSignUp] = useState(false);

	const handleCloseLogin = () => setShowLogin(false);
	const handleCloseSignUp = () => setShowSignUp(false);

	const userFirstName = useSelector(
		(state) => state.accountUserInfo.user.firstName
	);
	const userLastName = useSelector(
		(state) => state.accountUserInfo.user.lastName
	);
	const userPhotoUrl = useSelector(
		(state) => state.accountUserInfo.user.photoUrl
	);

	const onSignUp = (e) => {
		e.preventDefault();
		setShowSignUp(true);
	};

	const onSignIn = (e) => {
		e.preventDefault();
		setShowLogin(true);
	};

	return (
		<>
			<header
				className={classnames(
					"main-header",
					"fixed-top",
					additionClasses,
					{ "main-header_scrolled": scroll || !collapsed }
				)}>
				<div className="container">
					<nav className="navbar navbar-dark / navbar-expand-md / p-0">
						<h1 className="main-header__logo / navbar-brand">
							<Link to={"/"}>Peter's Rock Ministries</Link>
						</h1>

						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							onClick={toggleNavbar}
							aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="nav_bar_header_avatar_collapsed">
							{isSignedIn && (
								<div className="nav_bar_header_avatar">
									<Avatar
										size={40}
										name={`${
											userFirstName
												? userFirstName
												: "Not"
										} ${
											userLastName
												? userLastName
												: "available"
										}`}
										round
										src={userPhotoUrl ? userPhotoUrl : null}
									/>
								</div>
							)}
						</div>
						<Collapse
							isOpen={!collapsed}
							className="collapse / navbar-collapse"
							id="navbar_menu">
							<ul className="navbar-nav ml-auto">
								<Link className="nav-link" to={"/"}>
									Home
								</Link>
								<Link className="nav-link" to={"/videos"}>
									Watch Live
								</Link>
								<Link className="nav-link" to={"/events"}>
									Events
								</Link>
								{isSignedIn ? (
									<>
										{isAdmin ? (
											<Link
												className="nav-link"
												to={"/dashboard"}>
												Dashboard
											</Link>
										) : (
											<Link
												className="nav-link"
												to={"/account"}>
												My Account
											</Link>
										)}
									</>
								) : (
									<>
										<JoinUsDropdown
											onSignIn={onSignIn}
											onSignUp={onSignUp}
										/>
										{/* <a
											className="nav-link"
											href="#"
											onClick={onJoinUsClick}>
											Join Us
										</a> */}
									</>
								)}
								<a
									className="nav-link"
									href="#!"
									onClick={onGivingClick}>
									Give
								</a>
								<Link className="nav-link" to={"/about-us"}>
									About Us
								</Link>
							</ul>
						</Collapse>
						<div className="nav_bar_header_avatar_full">
							{isSignedIn && (
								<div className="nav_bar_header_avatar">
									<Avatar
										size={40}
										name={`${
											userFirstName
												? userFirstName
												: "Not"
										} ${
											userLastName
												? userLastName
												: "available"
										}`}
										round
										src={userPhotoUrl ? userPhotoUrl : null}
									/>
								</div>
							)}
						</div>
					</nav>
				</div>
			</header>
			<LoginModal
				show={showLogin}
				handleClose={handleCloseLogin}
				setShowSignUp={setShowSignUp}
			/>
			<SignUpModal
				show={showSignUp}
				handleClose={handleCloseSignUp}
				setShowLogin={setShowLogin}
			/>
		</>
	);
};

export default CommomLayoutHeader;
