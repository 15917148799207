import { call, put, takeEvery } from "redux-saga/effects";
import{
    GET_HOME_HIGHLIGHTED_POSTS,
    getHomeHighlightedPostsSuccess,
    getHomeHighlightedPostsError
} from './actions';

import ApiBase from '../../../../../common/api/api.base';

function* workerGetHomeHighlightedPosts(action) {
    try {
        const result = yield call(ApiBase.get, `/api/posts/highlighted`, action.payload);
        if (!result.error) {
            yield put(getHomeHighlightedPostsSuccess(result));
        } else {
            yield put(getHomeHighlightedPostsError(`Failed to get posts!`));
        }
    } catch (e) {
        yield put(getHomeHighlightedPostsError(e.message));
    }
}

export default function* watchHomeHighlightedPostsSaga(){
    yield takeEvery(GET_HOME_HIGHLIGHTED_POSTS, workerGetHomeHighlightedPosts);
}