import React, { useState } from "react";
import HomeVideosList from "./components/HomeVideosList";
import RequestModal from "../../../../modals/RequestModal/RequestModal.container";
import { toast } from "react-toastify";

const HomeVideos = ({ videos, isAdmin, isSignedIn }) => {
	const [showRequest, setShowRequest] = useState(false);
	const handleCloseShowRequest = () => setShowRequest(false);

	const handleShowRequestModal = () => {
		if (!isSignedIn) {
			toast.error("Login to make Prayer Requests", {
				autoClose: 3500,
			});
		} else if (isAdmin) {
			toast.warn("Admin is not allowed to Prayer Requests", {
				autoClose: 3500,
			});
		} else {
			setShowRequest(true);
		}
	};

	return (
		<>
			<HomeVideosList
				videos={videos}
				showRequestModal={handleShowRequestModal}
			/>
			<RequestModal
				show={showRequest}
				handleClose={handleCloseShowRequest}
			/>
		</>
	);
};

export default HomeVideos;
