import {
	GET_ACCOUNT_REQUESTS,
	GET_ACCOUNT_REQUESTS_ERROR,
	GET_ACCOUNT_REQUESTS_SUCCESS,
	RESET_ACCOUNT_REQUESTS,
	RESET_ACCOUNT_NEW_MESSAGES_COUNTER,
	MOVE_ACCOUNT_REQUEST_TO_TOP,
	SEARCH_USER_PRAYER_REQUESTS,
	SEARCH_USER_PRAYER_REQUESTS_SUCCESS,
	SEARCH_USER_PRAYER_REQUESTS_ERROR,
	GET_ACCOUNT_REQUESTS_STEALTH,
	GET_ACCOUNT_REQUESTS_SUCCESS_STEALTH,
	GET_ACCOUNT_REQUESTS_ERROR_STEALTH,
	RESET_ACCOUNT_REQUESTS_STEALTH,
} from "./actions";
import { SET_LAST_MESSAGE_EMITTER } from "../../../../../common/Messages/store/actions";
import {
	concatRequests,
	resetMessagesCounter,
	moveToTop,
	replaceCreator,
} from "../../../../../common/helpers/prayer.requests.reducer.utils";

const initialAccountRequestsState = {
	error: null,
	requests: {
		entities: [],
		hasMore: false,
	},
	loading: false,
	loaded: false,
	searchLoading: false,
	page: 0,
};

function accountRequestsReducer(state = initialAccountRequestsState, action) {
	switch (action.type) {
		case RESET_ACCOUNT_REQUESTS:
			return { ...initialAccountRequestsState, loading: true };
		case RESET_ACCOUNT_REQUESTS_STEALTH:
			return {
				...initialAccountRequestsState,
			};
		case GET_ACCOUNT_REQUESTS:
			return { ...state, loading: true, loaded: false };
		case GET_ACCOUNT_REQUESTS_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				// requests: action.payload,
				page: state.page + 1,
				requests: concatRequests(state, action.payload),
			};
		case GET_ACCOUNT_REQUESTS_ERROR:
			return {
				...state,
				requests: { hasMore: false, entities: [] },
				loaded: false,
				loading: false,
				error: action.error,
			};
		case RESET_ACCOUNT_NEW_MESSAGES_COUNTER:
			return {
				...state,
				requests: resetMessagesCounter(state, action.payload),
			};
		case MOVE_ACCOUNT_REQUEST_TO_TOP:
			return { ...state, requests: moveToTop(state, action.payload) };
		case SET_LAST_MESSAGE_EMITTER:
			return {
				...state,
				requests: {
					...state.requests,
					entities: replaceCreator(state, action.payload),
				},
			};
		case SEARCH_USER_PRAYER_REQUESTS:
			return {
				...state,
				searchLoading: true,
			};
		case SEARCH_USER_PRAYER_REQUESTS_SUCCESS:
			return {
				...state,
				searchLoading: false,
				requests: { ...action.payload },
			};
		case SEARCH_USER_PRAYER_REQUESTS_ERROR:
			return {
				...state,
				searchLoading: false,
				error: action.payload,
			};
		case GET_ACCOUNT_REQUESTS_SUCCESS_STEALTH:
			return {
				...state,
				requests: action.payload,
				page: 1,
				// requests: concatRequests(state, action.payload),
			};
		case GET_ACCOUNT_REQUESTS_ERROR_STEALTH:
			return {
				...state,
				requests: { hasMore: false, entities: [] },
				error: action.error,
			};
		default:
			return state;
	}
}

export default accountRequestsReducer;
