import { call, put, takeEvery } from "redux-saga/effects";
import {
	CREATE_COMMENT,
	createCommentSuccess,
	createCommentError,
	GET_VIDEO_COMMENTS,
	getVideoComments,
	getVideoCommentsSuccess,
	getVideoCommentsError,
} from "./actions";
import pageSizes from "../../../../../common/constants/pageSizes";

import ApiBase from "../../../../../common/api/api.base";

function* workerCreateComment(action) {
	try {
		const result = yield call(
			ApiBase.post,
			"/api/comments",
			action.payload
		);
		if (!result.error) {
			yield put(createCommentSuccess(result));
			yield put(
				getVideoComments({
					videoId: action.payload.videoId,
					skip: 0,
					take: pageSizes.VIDEO_COMMENTS_TAKE,
				})
			);
		} else {
			yield put(createCommentError("Failer to create comment!"));
		}
	} catch (e) {
		yield put(createCommentError(e.message));
	}
}

function* workerGetVideoComments(action) {
	try {
		const { videoId, skip, take } = action.payload;
		const result = yield call(
			ApiBase.get,
			`/api/paginated-comments/${videoId}`,
			{ skip, take }
		);
		if (!result.error) {
			yield put(getVideoCommentsSuccess(result));
		} else {
			yield put(getVideoCommentsError("Failed to get comments!"));
		}
	} catch (e) {
		yield put(getVideoCommentsError(e.message));
	}
}

export default function* watchVideoCommentsSaga() {
	yield takeEvery(CREATE_COMMENT, workerCreateComment);
	yield takeEvery(GET_VIDEO_COMMENTS, workerGetVideoComments);
}
