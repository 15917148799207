export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

// action creators
export function loginUser(payload) {
  return {
    type: LOGIN_USER,
    payload
  };
}

export function loginUserSuccess(payload) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload
  };
}

export function loginUserError(error) {
  return {
    type: LOGIN_USER_ERROR,
    error
  };
}

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

//action creators
export function logoutUser() {
  return {
    type: LOGOUT_USER
  };
}

export function logoutUserSuccess() {
  return {
    type: LOGOUT_USER_SUCCESS
  };
}

export function logoutUserError(error) {
  return {
    type: LOGOUT_USER_ERROR,
    error
  };
}

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

//action creators
export function refreshToken() {
  return {
    type: REFRESH_TOKEN
  }
}

export function refreshTokenSuccess(payload) {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload
  }
}

export function refreshTokenError(error) {
  return {
    type: REFRESH_TOKEN_ERROR,
    error
  }
}

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export function resetPassword(payload) {
  return {
    type: RESET_PASSWORD,
    payload
  }
}

export function resetPasswordSuccess(payload) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload
  }
}

export function resetPasswordError(error) {
  return {
    type: RESET_PASSWORD_ERROR,
    error
  }
}

export const SEND_RESET_PASSWORD = "SEND_RESET_PASSWORD";
export const SEND_RESET_PASSWORD_SUCCESS = "SEND_RESET_PASSWORD_SUCCESS";
export const SEND_RESET_PASSWORD_ERROR = "SEND_RESET_PASSWORD_ERROR";

export function sendResetPassword(payload) {
  return {
    type: SEND_RESET_PASSWORD,
    payload
  }
}

export function sendResetPasswordSuccess(payload) {
  return {
    type: SEND_RESET_PASSWORD_SUCCESS,
    payload
  }
}

export function sendResetPasswordError(error) {
  return {
    type: SEND_RESET_PASSWORD_ERROR,
    error
  }
}

export const SEND_CONFIRMATION_EMAIL = "SEND_CONFIRMATION_EMAIL";
export const SEND_CONFIRMATION_EMAIL_SUCCESS = "SEND_CONFIRMATION_EMAIL_SUCCESS";
export const SEND_CONFIRMATION_EMAIL_ERROR = "SEND_CONFIRMATION_EMAIL_SUCCESS";

export function sendConfirmationEmail(payload) {
  return {
    type: SEND_CONFIRMATION_EMAIL,
    payload
  }
}

export function sendConfirmationEmailSuccess(payload) {
  return {
    type: SEND_CONFIRMATION_EMAIL_SUCCESS,
    payload
  }
}

export function sendConfirmationEmailError(error) {
  return {
    type: SEND_CONFIRMATION_EMAIL_ERROR,
    error
  }
}

