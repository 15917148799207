export const GET_MORE_POSTS = "GET_MORE_POSTS";
export const GET_MORE_POSTS_SUCCESS="GET_MORE_POSTS_SUCCESS";
export const GET_MORE_POSTS_ERROR = "GET_MORE_POSTS_ERROR";

export function getMorePosts(payload){
    return{
        type:GET_MORE_POSTS,
        payload
    }
}

export function getMorePostsSuccess(payload){
    return{
        type:GET_MORE_POSTS_SUCCESS,
        payload
    }
}

export function getMorePostsError(error){
    return{
        type:GET_MORE_POSTS_ERROR,
        error
    }
}