export const isEmpty = obj => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const immutableSwap = (items, firstIndex, secondIndex) => {    
    const result = [...items];
    [result[firstIndex], result[secondIndex]] = [result[secondIndex], result[firstIndex]];
    return result;  
}

export const hasNumber= str => {
    return /\d/.test(str);
  }