import {
	GET_SERVICE_TYPE_BY_ID,
	GET_SERVICE_TYPE_BY_ID_SUCCESS,
	GET_SERVICE_TYPE_BY_ID_ERROR,
	CREATE_SERVICE_PLAN_ITEM,
	CREATE_SERVICE_PLAN_ITEM_SUCCES,
	CREATE_SERVICE_PLAN_ITEM_ERROR,
	UPDATE_SERVICE_PLAN_ITEM,
	UPDATE_SERVICE_PLAN_ITEM_SUCCES,
	UPDATE_SERVICE_PLAN_ITEM_ERROR,
	DELETE_SERVICE_PLAN_ITEM,
	DELETE_SERVICE_PLAN_ITEM_SUCCES,
	DELETE_SERVICE_PLAN_ITEM_ERROR,
	GET_SERVICE_PLAN_ITEM,
	GET_SERVICE_PLAN_ITEM_SUCCES,
	GET_SERVICE_PLAN_ITEM_ERROR,
	CREATE_SERVICE_TYPE_NOTE,
	CREATE_SERVICE_TYPE_NOTE_SUCCES,
	CREATE_SERVICE_TYPE_NOTE_ERROR,
	UPDATE_SERVICE_TYPE_NOTE,
	UPDATE_SERVICE_TYPE_NOTE_SUCCES,
	UPDATE_SERVICE_TYPE_NOTE_ERROR,
	DELETE_SERVICE_TYPE_NOTE,
	DELETE_SERVICE_TYPE_NOTE_SUCCES,
	DELETE_SERVICE_TYPE_NOTE_ERROR,
	CREATE_SERVICE_TYPE_ATTACHMENT,
	CREATE_SERVICE_TYPE_ATTACHMENT_SUCCES,
	CREATE_SERVICE_TYPE_ATTACHMENT_ERROR,
	UPDATE_SERVICE_TYPE_ATTACHMENT,
	UPDATE_SERVICE_TYPE_ATTACHMENT_SUCCES,
	UPDATE_SERVICE_TYPE_ATTACHMENT_ERROR,
	DELETE_SERVICE_TYPE_ATTACHMENT,
	DELETE_SERVICE_TYPE_ATTACHMENT_SUCCES,
	DELETE_SERVICE_TYPE_ATTACHMENT_ERROR,
	CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE,
	CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE_SUCCESS,
	CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE_ERROR,
	CLEAR_PLAN,
	PRINT_SERVICE_PLAN_PDF,
	PRINT_SERVICE_PLAN_PDF_SUCCESS,
	PRINT_SERVICE_PLAN_PDF_ERROR,
	PRINT_SERVICE_PLAN_HTML,
	PRINT_SERVICE_PLAN_HTML_SUCCESS,
	PRINT_SERVICE_PLAN_HTML_ERROR,
} from "./actions";

const initialState = {
	loading: false,
	loaded: false,
	error: null,
	name: "",
	id: "",
	servicePlan: {
		id: "",
		servicePlanItems: [],
	},
	notes: [],
	attachments: [],
	createdAt: "",
	print: {
		loading: false,
		loaded: false,
		error: null,
		pdfData: null,
		htmlData: null
	}
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_SERVICE_TYPE_BY_ID:
			return {
				...state,
				loading: true,
				loaded: false,
			};
		case GET_SERVICE_TYPE_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				error: null,
				attachments: action.payload.attachments,
				servicePlan: action.payload.servicePlan,
				notes: action.payload.notes,
				name: action.payload.name,
				id: action.payload.id,
				createdAt: action.payload.createdAt,
			};
		case GET_SERVICE_TYPE_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case CREATE_SERVICE_PLAN_ITEM:
		case UPDATE_SERVICE_PLAN_ITEM:
		case CREATE_SERVICE_TYPE_NOTE:
		case UPDATE_SERVICE_TYPE_NOTE:
		case CREATE_SERVICE_TYPE_ATTACHMENT:
		case CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE:
		case UPDATE_SERVICE_TYPE_ATTACHMENT:
			return {
				...state,
				loading: true,
				loaded: false,
			};
		case UPDATE_SERVICE_PLAN_ITEM_SUCCES:
		case CREATE_SERVICE_PLAN_ITEM_SUCCES:
			return {
				...state,
				loading: false,
				loaded: true,
			};
		case UPDATE_SERVICE_PLAN_ITEM_ERROR:
		case CREATE_SERVICE_PLAN_ITEM_ERROR:
		case CREATE_SERVICE_TYPE_NOTE_ERROR:
		case UPDATE_SERVICE_TYPE_NOTE_ERROR:
		case CREATE_SERVICE_TYPE_ATTACHMENT_ERROR:
		case CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE_ERROR:
		case UPDATE_SERVICE_TYPE_ATTACHMENT_ERROR:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};
		case CREATE_SERVICE_TYPE_NOTE_SUCCES:
			return {
				...state,
				loading: false,
				loaded: true,
				notes: [...state.notes, action.payload],
			};
		// case DELETE_SERVICE_TYPE_NOTE,
		case DELETE_SERVICE_TYPE_NOTE_SUCCES:
			return {
				...state,
				notes: state.notes.filter(
					(elem) => elem.id !== action.payload.id
				),
			};
		case UPDATE_SERVICE_TYPE_NOTE_SUCCES:
			return {
				...state,
				loading: false,
				loaded: true,
				notes: state.notes.map((elem) => {
					if (elem.id === action.payload.id) {
						return action.payload;
					} else {
						return elem;
					}
				}),
			};
		case CREATE_SERVICE_TYPE_ATTACHMENT_SUCCES:
			return {
				...state,
				loading: false,
				loaded: true,
				attachments: [...state.attachments, action.payload],
			};
		case DELETE_SERVICE_TYPE_ATTACHMENT_SUCCES:
			return {
				...state,
				attachments: state.attachments.filter(
					(elem) => elem.id !== action.payload.id
				),
			};
		case CREATE_SERVICE_TYPE_ATTACHMENT_MULTIPLE_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				attachments: [...state.attachments, ...action.payload],
			};
		case UPDATE_SERVICE_TYPE_ATTACHMENT_SUCCES:
			return {
				...state,
				loading: false,
				loaded: true,
				attachments: state.attachments.map((elem) => {
					if (elem.id === action.payload.id) {
						return action.payload;
					} else {
						return elem;
					}
				}),
			};
		case CLEAR_PLAN:
			return initialState;
		// CREATE_SERVICE_TYPE_ATTACHMENT_SUCCES,
		// CREATE_SERVICE_TYPE_ATTACHMENT_ERROR,

		// DELETE_SERVICE_TYPE_NOTE_ERROR,
		case PRINT_SERVICE_PLAN_PDF:
			return {
				...state,
				print: {
					...state.print,
					loading: true,
					loaded: false
				}
			};
		case PRINT_SERVICE_PLAN_PDF_SUCCESS:
			return {
				...state,
				print: {
					...state.print,
					loading: false,
					loaded: true,
					error: null,
					pdfData: action.payload
				}
			};
		case PRINT_SERVICE_PLAN_PDF_ERROR:
			return {
				...state,
				print: {
					...state.print,
					loading: false,
					loaded: false,
					error: action.error
				}
			};
		case PRINT_SERVICE_PLAN_HTML:
			return {
				...state,
				print: {
					...state.print,
					loading: true,
					loaded: false
				}
			};
		case PRINT_SERVICE_PLAN_HTML_SUCCESS:
			return {
				...state,
				print: {
					...state.print,
					loading: false,
					loaded: true,
					error: null,
					htmlData: action.payload
				}
			};
		case PRINT_SERVICE_PLAN_HTML_ERROR:
			return {
				...state,
				print: {
					...state.print,
					loading: false,
					loaded: false,
					error: action.error
				}
			};
		default:
			return state;
	}
}
