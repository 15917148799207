import { toast } from "react-toastify";
import { call, put, takeEvery, take } from "redux-saga/effects";
import {
	GET_DASHBOARD_VIDEOS,
	getDashboardVideosSuccess,
	getDashboardVideosError,
	UPLOAD_VIDEO,
	uploadVideoSuccess,
	uploadVideoError,
	DELETE_VIDEO,
	deleteVideoSuccess,
	deleteVideoError,
	getDashboardVideos,
	setDashboardVideoUploadProgress,
	uploadEmbededVideoSuccess,
	uploadEmbededVideoError,
	UPLOAD_EMBEDED_VIDEO_REQUEST,
	CANCEL_VIDEO_UPLOAD,
	toggleVisibilityOnHomePageSuccess,
	toggleVisibilityOnHomePageError,
	TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE,
} from "./actions";
import ApiBase from "../../../../../common/api/api.base";
import axios from "axios";
import pageSizes from "../../../../../common/constants/pageSizes";

const CancelToken = axios.CancelToken;
let cancel;

function* workerGetDashboardVideos(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/paginated-videos`,
			action.payload
		);
		if (!result.error) {
			yield put(getDashboardVideosSuccess(result));
		} else {
			yield put(getDashboardVideosError(`Failed to get videos!`));
		}
	} catch (e) {
		yield put(getDashboardVideosError(e.message));
	}
}

function* workerUploadVideo(action) {
	try {
		// const formData = new FormData();
		// formData.append("file", action.payload.video);

		const fileUploadResult = yield call(
			ApiBase.file,
			"/api/files",
			action.payload.video,
			{},
			{
				onUploadProgress: action.payload.progressFunc,
				cancelToken: new CancelToken(function executor(c) {
					// An executor function receives a cancel function as a parameter
					cancel = c;
				}),
			}
		);
		if (!fileUploadResult.error) {
			const fileUrl = fileUploadResult;
			const videoSaveResult = yield call(ApiBase.post, "/api/videos", {
				name: action.payload.name,
				videoUrl: fileUrl,
			});

			if (!videoSaveResult.error) {
				toast.success("Video successfully uploaded!");
				yield put(uploadVideoSuccess(videoSaveResult));
				yield put(setDashboardVideoUploadProgress(100));
				yield put(
					getDashboardVideos({
						skip: 0,
						offset: pageSizes.DAHSBOARD_VIDEO_SETTINGS_TAKE,
					})
				);
			} else {
				yield put(uploadVideoError(videoSaveResult.error));
			}
		} else {
			yield put(uploadVideoError(fileUploadResult.error));
		}
	} catch (e) {
		yield put(uploadVideoError("Failed to upload video!"));
	}
}

function* workerUploadEmbededVideo(action) {
	try {
		const result = yield call(ApiBase.post, "/api/videos", {
			name: action.payload.name,
			videoUrl: action.payload.videoUrl,
		});

		if (!result.error) {
			toast.success("Video successfully uploaded!");
			yield put(uploadVideoSuccess(result));
			// yield put(setDashboardVideoUploadProgress(100));
			yield put(
				getDashboardVideos({
					skip: 0,
					offset: pageSizes.DAHSBOARD_VIDEO_SETTINGS_TAKE,
				})
			);
		} else {
			yield put(uploadVideoError(result.error));
		}
	} catch (error) {
		yield put(uploadVideoError("Failed to upload video!"));
	}
}

function* workerDeleteVideo(action) {
	try {
		const result = yield call(
			ApiBase.delete,
			"/api/videos/" + action.payload
		);
		if (!result.error) {
			yield put(deleteVideoSuccess(action.payload));
			toast.success("Video successfully deleted!");
			yield put(
				getDashboardVideos({
					skip: 0,
					offset: pageSizes.DAHSBOARD_VIDEO_SETTINGS_TAKE,
				})
			);
		} else {
			yield put(deleteVideoError(result.error));
		}
	} catch (e) {
		yield put(deleteVideoError("Failed to upload video!"));
	}
}

function* workerCancelVideoUpload(action) {
	yield call(cancel);
}

function* workerToggleVideoVisibilityOnHomePage(action) {
	try {
		const result = yield call(
			ApiBase.put,
			"/api/videos/toggle-home-visibility/" + action.payload
		);
		if (!result.error) {
			yield put(toggleVisibilityOnHomePageSuccess(result));			
		} else {
			yield put(toggleVisibilityOnHomePageError(result.error));
		}
	} catch (e) {
		yield put(toggleVisibilityOnHomePageError("Failed to toggle video visibility on home page!"));
	}
}

export default function* watchDashboardVideosSaga() {
	yield takeEvery(GET_DASHBOARD_VIDEOS, workerGetDashboardVideos);
	yield takeEvery(UPLOAD_VIDEO, workerUploadVideo);
	yield takeEvery(DELETE_VIDEO, workerDeleteVideo);
	yield takeEvery(UPLOAD_EMBEDED_VIDEO_REQUEST, workerUploadEmbededVideo);
	yield takeEvery(CANCEL_VIDEO_UPLOAD, workerCancelVideoUpload);
	yield takeEvery(TOGGLE_VIDEO_VISIBILITY_ON_HOME_PAGE, workerToggleVideoVisibilityOnHomePage);
}
