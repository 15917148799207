export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_ERROR = "GET_MESSAGES_ERROR";

export function getMessages(payload) {
	return {
		type: GET_MESSAGES,
		payload,
	};
}

export function getMessagesSuccess(payload) {
	return {
		type: GET_MESSAGES_SUCCESS,
		payload,
	};
}

export function getMessagesError(error) {
	return {
		type: GET_MESSAGES_ERROR,
		error,
	};
}

export const RESET_MESSAGES = "RESET_MESSAGES";

export function resetMessages() {
	return {
		type: RESET_MESSAGES,
	};
}

export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";

export function receiveMessage(payload) {
	return {
		type: RECEIVE_MESSAGE,
		payload,
	};
}

export const SET_LAST_MESSAGE_EMITTER = "SET_LAST_MESSAGE_EMITTER";

export function setLastMessageEmitter(payload) {
	return {
		type: SET_LAST_MESSAGE_EMITTER,
		payload,
	};
}
