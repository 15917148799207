export const GET_VIDEOS = "GET_VIDEOS";
export const GET_VIDEOS_SUCCESS="GET_VIDEOS_SUCCESS";
export const GET_VIDEOS_ERROR = "GET_VIDEOS_ERROR";

export function getVideos(payload){
    return{
        type:GET_VIDEOS,
        payload
    }
}

export function getVideosSuccess(payload){
    return{
        type:GET_VIDEOS_SUCCESS,
        payload
    }
}

export function getVideosError(error){
    return{
        type:GET_VIDEOS_ERROR,
        error
    }
}