export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";

export function deleteComment(payload) {
    return {
        type: DELETE_COMMENT,
        payload
    }
}

export function deleteCommentSuccess(payload) {
    return {
        type: DELETE_COMMENT_SUCCESS,
        payload
    }
}

export function deleteCommentError(error) {
    return {
        type: DELETE_COMMENT_ERROR,
        error
    }
}

export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const GET_ALL_COMMENTS_SUCCESS = "GET_ALL_COMMENTSS_SUCCESS";
export const GET_ALL_COMMENTS_ERROR = "GET_ALL_COMMENTS_ERROR";

export function getAllComments(payload) {
    return {
        type: GET_ALL_COMMENTS,
        payload
    }
}

export function getAllCommentsSuccess(payload) {
    return {
        type: GET_ALL_COMMENTS_SUCCESS,
        payload
    }
}

export function getAllCommentsError(error) {
    return {
        type: GET_ALL_COMMENTS_ERROR,
        error
    }
}