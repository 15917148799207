export const GET_STATES_DIRECTORY_REQUEST = "GET_STATES_DIRECTORY_REQUEST";
export const GET_STATES_DIRECTORY_SUCCESS = "GET_STATES_DIRECTORY_SUCCESS";
export const GET_STATES_DIRECTORY_ERROR = "GET_STATES_DIRECTORY_ERROR";

export const getStatesDirectory = () => ({
	type: GET_STATES_DIRECTORY_REQUEST,
});

export const getStatesDirectorySuccess = (payload) => {
	return {
		type: GET_STATES_DIRECTORY_SUCCESS,
		payload,
	};
};

export const getStatesDirectoryError = (error) => ({
	type: GET_STATES_DIRECTORY_ERROR,
	payload: error,
});
