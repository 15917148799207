import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { ReactComponent as CloseIcon } from "../../../../assets/img/icons/close.svg";
import { useDispatch, useSelector } from "react-redux";
import {
	closeAllGivingModals,
	openGivingModal,
} from "../../store/modals/actions";
import RecurringPlan from "./components/RecurringPlan";
import PaymentSuccess from "./components/PaymentSuccess";
import CheckoutForm from "./components/CheckoutForm";
import RecurringPaymentConfirmation from "./components/RecurringPaymentConfirmation";
import PaymentMethods from "./components/PaymentMethods";

const OnlineRecurring = ({
	givingTypes,
	givingPlans,
	dataForSubscription,
	subscriptionLoading,
	getDataForSubscription,
	paymentMethods,
	getPaymentMethods,
	resetPaymentIntent
}) => {
	const dispatch = useDispatch();

	const [currentContent, setCurrentContent] = useState("recurring-plan");
	const [selectedPlan, setSelectedPlan] = useState(null);

	const currentModal = useSelector((x) => x.givingModals.currentModal);
	const show = currentModal === "online-recurring";

	const closeModal = () => {
		dispatch(closeAllGivingModals());
		setTimeout(() => {
			setCurrentContent("recurring-plan");
		}, 200);
	};

	const goToStart = () => {
		setSelectedPlan(null);
		goToPlan();
		getPaymentMethods();
	};

	const goToPlan = () => {
		setCurrentContent("recurring-plan");
	};

	const goBack = () => {
		dispatch(openGivingModal("giving"));
	};

	const toConfirmation = (data) => {
		setSelectedPlan(data);
		setCurrentContent("confirmation");
	};

	const goToCard = () => {
		resetPaymentIntent();
		setCurrentContent("card");
	};

	const goToPaymentMethod = () => {
		resetPaymentIntent();
		setCurrentContent("payment-method");
	};

	const submitPayment = () => {
		getDataForSubscription({
			givingPlanId: selectedPlan.recurringAmount,
			givingTypeId: selectedPlan.recurringDesignation,
		});
	};

	const onPaymentSuccess = () => {
		setCurrentContent("payment-success");
	};

	useEffect(() => {
		if (!show) {
			setSelectedPlan(null);
		}
	}, [show]);

	useEffect(() => {
		if (dataForSubscription && currentContent === "confirmation") {
			if (paymentMethods.length === 0) {
				setCurrentContent("card");
			} else {
				setCurrentContent("payment-method");
			}
		}
	}, [dataForSubscription]);

	return (
		<Modal
			isOpen={show}
			toggle={closeModal}
			centered
			className="modal-compact">
			<div className="modal-header / align-items-center">
				<h5
					className="modal-title / title  title_size_h2"
					id="login_modal_title">
					Giving
				</h5>
				<button
					className="close"
					type="button"
					aria-label="Close"
					onClick={closeModal}>
					<CloseIcon />
				</button>
			</div>

			{currentContent === "recurring-plan" && (
				<RecurringPlan
					selectedPlan={selectedPlan}
					goBack={goBack}
					goNext={toConfirmation}
					givingPlans={givingPlans}
					givingTypes={givingTypes}
				/>
			)}

			{currentContent === "confirmation" && selectedPlan && (
				<RecurringPaymentConfirmation
					givingPlans={givingPlans}
					givingTypes={givingTypes}
					selectedPlan={selectedPlan}
					loading={subscriptionLoading}
					goBack={goToPlan}
					submitPayment={submitPayment}
				/>
			)}

			{currentContent === "payment-method" && dataForSubscription && (
				<PaymentMethods
					goToCheckoutForm={goToCard}
					paymentMethods={paymentMethods}
					dataForSubscription={dataForSubscription}
					onSuccess={onPaymentSuccess}
				/>
			)}

			{currentContent === "card" && dataForSubscription && (
				<CheckoutForm
					dataForSubscription={dataForSubscription}
					onSuccess={onPaymentSuccess}
					goToPaymentMethod={goToPaymentMethod}
				/>
			)}

			{currentContent === "payment-success" && (
				<PaymentSuccess
					closeModal={closeModal}
					makeMoreGiving={goToStart}
				/>
			)}
		</Modal>
	);
};

export default OnlineRecurring;
