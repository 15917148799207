import React, { useState } from "react";
import { ReactComponent as GivingsIcon } from "../../../assets/img/icons/new_givings_history.svg";
import { ReactComponent as PrayerRequestsIcon } from "../../../assets/img/icons/prayer_requests.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/img/icons/logout.svg";
import { ReactComponent as UserIcon } from "../../../assets/img/icons/men.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/img/icons/settings.svg";
import { NavLink } from "react-router-dom";
import { ALLOWED_ACTIONS } from "./AccountLayout";
import { getCurrentUser } from "../../../common/helpers/auth.helper";
import { Collapse } from "reactstrap";

// http://localhost:3000/account/givings-history

const MobileNav = ({ logoutClick }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => setIsOpen(!isOpen);

	return (
		<>
			<div className="filter_pane_bg d-flex justify-content-between align-items-center">
				<p className="m-0 ml-3">Menu</p>
				<button
					className="btn_theme_transparent"
					type="button"
					onClick={toggle}>
					<img
						style={{
							transform: `rotate(${isOpen ? "180" : "0"}deg)`,
						}}
						src="https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png"
						className="mx-3"
					/>
				</button>
			</div>
			<Collapse isOpen={isOpen}>
				<ul className="dashboard__nav / nav / flex-md-column">
					<li className="nav-item">
						<NavLink to={`/account/settings`} className="nav-link">
							<span className="nav-icon  nav-icon_sm">
								<UserIcon />
							</span>
							<span>Account Settings</span>
						</NavLink>
					</li>
					<li className="nav-item">
						<NavLink
							to={`/account/givings-history`}
							className="nav-link">
							<span className="nav-icon nav-icon_stroke">
								<GivingsIcon />
							</span>
							<span>Givings History</span>
						</NavLink>
					</li>

					<li className="nav-item">
						<NavLink
							to={`/account/prayer-requests`}
							className="nav-link">
							<span className="nav-icon  nav-icon_sm nav-icon_stroke">
								<PrayerRequestsIcon />
							</span>
							<span>Prayer requests</span>
						</NavLink>
					</li>

					<li className="nav-item">
						<NavLink
							to={`/account/payment-settings`}
							className="nav-link">
							<span className="nav-icon  nav-icon_stroke  nav-icon_sm">
								<SettingsIcon />
							</span>
							<span>Payment Settings</span>
						</NavLink>
					</li>

					{getCurrentUser().allowedActions.map((elem) => {
						if (ALLOWED_ACTIONS[elem]) {
							return (
								<li className="nav-item" key={elem}>
									<NavLink
										to={ALLOWED_ACTIONS[elem].path}
										className="nav-link">
										<span className="nav-icon  nav-icon_stroke  nav-icon_sm">
											{ALLOWED_ACTIONS[elem].icon}
										</span>
										<span>
											{ALLOWED_ACTIONS[elem].name}
										</span>
									</NavLink>
								</li>
							);
						} else {
							return null;
						}
					})}
				</ul>
			</Collapse>
			<div className="dashboard__logout_mobile / row">
				<a onClick={logoutClick}>
					<span>
						<LogoutIcon />
					</span>

					<span>Log out</span>
				</a>
			</div>
		</>
	);
};

export default MobileNav;
