import React from "react";
import CommonLayout from "../../common/blocks/CommonLayout/CommonLayout";
import { getCurrentUser } from "../../common/helpers/auth.helper";

const Global404 = () => {
	const currentUser = getCurrentUser();
	const isSignedIn = currentUser.isSignedIn;
	const isAdmin = currentUser.roles.some((r) => r === "Admin");
	return (
		<CommonLayout isSignedIn={isSignedIn} isAdmin={isAdmin}>
			<div className="container">
				<h1>404</h1>
				<p>Sorry, the page you are looking for doesn`t exist</p>
			</div>
		</CommonLayout>
	);
};

export default Global404;
