import { call, put, takeEvery } from "redux-saga/effects";

import {
    GET_RECURRING_GIVINGS,
    CANCEL_RECURRING_GIVING,
    getRecurringGivingsSuccess,
    getRecurringGivingsError,
    getRecurringGivings,
    cancelRecurringGivingSuccess,
    cancelRecurringGivingError
} from "./actions";

import ApiBase from '../../../../../common/api/api.base';


function* workerGetRecurringGivings(action) {
    try {
        const result = yield call(ApiBase.get, '/api/recurring-givings/current');
        if (!result.error) {
            yield put(getRecurringGivingsSuccess(result));
        }
        else {
            yield put(getRecurringGivingsError("Failed to fetch recurring givings"));
        }
    }
    catch (e) {
        yield put(getRecurringGivingsError(e.message));
    }
}

function* workerCancelRecurringGiving(action) {
    try {
        const result = yield call(ApiBase.delete, `/api/recurring-givings/current/${action.payload}`);
        if (!result.error) {
            yield put(cancelRecurringGivingSuccess(result));
            yield put(getRecurringGivings());
        }
        else {
            yield put(cancelRecurringGivingError("Failed to delete selected recurring giving"));
        }
    }
    catch (e) {
        yield put(cancelRecurringGivingError(e.message));
    }
}

export default function* watchPaymentSettingsSaga() {
    yield takeEvery(GET_RECURRING_GIVINGS, workerGetRecurringGivings);
    yield takeEvery(CANCEL_RECURRING_GIVING, workerCancelRecurringGiving);
}