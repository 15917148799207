import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import {
	GET_DASHBOARD_POSTS,
	getDashboardPostsSuccess,
	getDashboardPostsError,
	CREATE_POST,
	createPostSuccess,
	createPostError,
	DELETE_POST,
	deletePostSuccess,
	deletePostError,
	getDashboardPosts,
	GET_EDITABLE_POST,
	getEditablePostError,
	getEditablePostSuccess,
	UPDATE_POST,
	updatePostSuccess,
	updatePostError,
} from "./actions";
import ApiBase from "../../../../../common/api/api.base";
import { push } from "connected-react-router";
import pageSizes from "../../../../../common/constants/pageSizes";

function* workerGetEditablePost(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/posts/edit/${action.payload}`
		);
		if (!result.error) {
			yield put(getEditablePostSuccess(result));
		} else {
			yield put(getEditablePostError(`Failed to get post for edit!`));
		}
	} catch (e) {
		const { status } = e.response;
		yield put(getDashboardPostsError(e.message));
	}
}

function* workerGetDashboardPosts(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/paginated-posts`,
			action.payload
		);
		if (!result.error) {
			yield put(getDashboardPostsSuccess(result));
		} else {
			yield put(getDashboardPostsError(`Failed to get posts!`));
		}
	} catch (e) {
		yield put(getDashboardPostsError(e.message));
	}
}

function getFormData(file) {
	const formData = new FormData();
	formData.append("file", file);
	return formData;
}

function* workerCreatePost(action) {
	try {
		const {
			heading,
			text,
			titlePicture,
			postItems,
			rowPosition,
		} = action.payload;
		let titlePictureUrl = "";

		if (titlePicture) {
			const titlePictureUploadResult = yield call(
				ApiBase.post,
				"/api/files",
				getFormData(titlePicture)
			);
			if (!titlePictureUploadResult.error) {
				titlePictureUrl = titlePictureUploadResult;
			} else {
				yield put(
					createPostError("Error while uploading media to cloud")
				);
				toast.error("Error while uploading media to cloud");
				return;
			}
		}

		const postItemsWithUrl = [];
		for (let postItem of postItems) {
			const { picture } = postItem;
			if (!picture) {
				postItemsWithUrl.push({
					orderNumber: postItem.orderNumber,
					pictureUrl: null,
					text: postItem.text,
				});
			} else {
				const pictureUploadResult = yield call(
					ApiBase.post,
					"/api/files",
					getFormData(picture)
				);
				if (!pictureUploadResult.error) {
					postItemsWithUrl.push({
						orderNumber: postItem.orderNumber,
						pictureUrl: pictureUploadResult,
						text: postItem.text,
					});
				} else {
					yield put(
						createPostError("Error while uploading media to cloud")
					);
					toast.error("Error while uploading media to cloud");
					return;
				}
			}
		}

		const post = {
			heading,
			text,
			titlePictureUrl,
			postItems: postItemsWithUrl,
			rowPosition,
		};

		const result = yield call(ApiBase.post, "/api/posts", post);
		if (!result.error) {
			yield put(createPostSuccess(result));
			yield put(push(`/posts/${result.id}`));
		} else {
			yield put(createPostError("Failed to create post!"));
		}
	} catch (e) {
		yield put(createPostError("Failed to create post!"));
	}
}

function* workerDeletePost(action) {
	try {
		const result = yield call(
			ApiBase.delete,
			"/api/posts/" + action.payload
		);
		if (!result.error) {
			yield put(deletePostSuccess(action.payload));
			yield put(
				getDashboardPosts({
					skip: 0,
					offset: pageSizes.DASHBOARD_POSTS_TAKE,
				})
			);
		} else {
			yield put(deletePostError(result.error));
		}
	} catch (e) {
		yield put(deletePostError("Failed to create post!"));
	}
}

function* workerUpdatePost(action) {
	try {
		const {
			id,
			heading,
			text,
			initTitlePictureUrl,
			titlePicture,
			titlePictureChanged,
			postItems,
			rowPosition,
		} = action.payload;
		let titlePictureUrl = initTitlePictureUrl;

		if (titlePictureChanged && titlePicture) {
			const titlePictureUploadResult = yield call(
				ApiBase.post,
				"/api/files",
				getFormData(titlePicture)
			);
			if (!titlePictureUploadResult.error) {
				titlePictureUrl = titlePictureUploadResult;
			} else {
				yield put(
					createPostError("Error while uploading media to cloud")
				);
				toast.error("Error while uploading media to cloud");
				return;
			}
		}

		const postItemsWithUrl = [];
		for (let postItem of postItems) {
			const { picture, pictureChanged, initPicture } = postItem;

			let pictureUrl = initPicture;

			if (picture && pictureChanged) {
				const pictureUploadResult = yield call(
					ApiBase.post,
					"/api/files",
					getFormData(picture)
				);
				pictureUrl = pictureUploadResult;
				if (pictureUploadResult.error) {
					yield put(
						createPostError("Error while uploading media to cloud")
					);
					toast.error("Error while uploading media to cloud");
					return;
				}
			}

			postItemsWithUrl.push({
				id: postItem.id,
				orderNumber: postItem.orderNumber,
				pictureUrl: pictureUrl,
				text: postItem.text,
			});
		}

		const post = {
			heading,
			text,
			titlePictureUrl,
			postItems: postItemsWithUrl,
			rowPosition,
		};

		const result = yield call(ApiBase.put, "/api/posts/" + id, post);
		if (!result.error) {
			yield put(updatePostSuccess(result));
			yield put(
				getDashboardPosts({
					skip: 0,
					take: pageSizes.DASHBOARD_POSTS_TAKE,
				})
			);
			toast.success("Post successfully updated!");
		} else {
			yield put(updatePostError("Failed to update post!"));
		}
	} catch (e) {
		yield put(updatePostError("Failed to update post!"));
	}
}

export default function* watchDashboardPostsSaga() {
	yield takeEvery(GET_DASHBOARD_POSTS, workerGetDashboardPosts);
	yield takeEvery(CREATE_POST, workerCreatePost);
	yield takeEvery(DELETE_POST, workerDeletePost);
	yield takeEvery(GET_EDITABLE_POST, workerGetEditablePost);
	yield takeEvery(UPDATE_POST, workerUpdatePost);
}
