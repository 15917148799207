import { call, put, takeEvery } from "redux-saga/effects";
import {
    GET_USER_GIVINGS_HISTORY,
    getUserGivingsHistorySuccess,
    getUserGivingsHistoryError,
    refundError,
    refundSuccess,
    REFUND
} from './actions';

import ApiBase from "../../../../../common/api/api.base";
import { toast } from "react-toastify";

function* workerGetUserGivings(action){
    try {
        const result = yield call(ApiBase.get, `/api/givings/history/current`, action.payload);
        if (!result.error) {
            yield put(getUserGivingsHistorySuccess(result));
        } else {
            yield put(getUserGivingsHistoryError(`Failed to get givings history!`));
        }
    } catch (e) {
        yield put(getUserGivingsHistoryError(e.message));
    }
}

function* workerRefund(action){
    try{
        const result = yield call(ApiBase.put, `/api/givings/refund`,{givingDetailId:action.payload});
        if(!result.error){
            yield put(refundSuccess(result));
            toast.success("Refund process ussualy takes 5-10 business days")
        }
        else{
            yield put(refundError('Failed to request refund'));
        }
    }
    catch(e){
        yield put(refundError(e.message));
    }
}

export default function* watchUserGivingsSaga(){
    yield takeEvery(GET_USER_GIVINGS_HISTORY, workerGetUserGivings);
    yield takeEvery(REFUND,workerRefund);
}