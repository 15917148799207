import React from "react";
import { Spinner } from "reactstrap";

const LoadingSpinner = () => {
	return (
		<div className="center-spinner">
			<Spinner className="spinner-style" />
			<p>Loading...</p>
		</div>
	);
};

export default LoadingSpinner;
