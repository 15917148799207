import React from "react";
import NotFound from "../NotFound/NotFound";
import { Switch, Route, Redirect } from "react-router-dom";

const Givings = React.lazy(() =>
	import("./pages/DashboardGivings/DashboardGivings.container")
);
const Posts = React.lazy(() =>
	import("./pages/DashboardPosts/DashboardPosts.container")
);
const Comments = React.lazy(() =>
	import("./pages/DashboardComments/DashboardComments.container")
);
const PrayerRequests = React.lazy(() =>
	import("./pages/DashboardPrayerRequests/DashboardPrayerRequests.container")
);
const VideoSettings = React.lazy(() =>
	import("./pages/DashboardVideoSettings/DashboardVideoSettings.container")
);
const Charts = React.lazy(() => import("./pages/Charts/Charts.Container"));

const Members = React.lazy(() =>
	import("./pages/DashboardMembers/DashboardMembers.Container")
);

const Reports = React.lazy(() =>
	import("./pages/DashboardReports/DashboardReports.Container")
);

const AccountSettings = React.lazy(() =>
	import("../Account/pages/AccountSettings/AccountSettings.container")
);

const Events = React.lazy(() =>
	import("./pages/DashboardEvents/DashboardEvents.container")
);

const Roles = React.lazy(() =>
	import("./pages/DashboardRoles/DashboardRoles.Container")
);

const Plans = React.lazy(() =>
	import("./pages/DashboardPlans/DashboardPlans.Container")
);

const MasterCalendar = React.lazy(() =>
	import(
		"./pages/DashboardPlans/components/MasterCalendar/MasterCalendar.Container"
	)
);

const PlanPage = React.lazy(() =>
	import("./pages/DashboardPlanPage/PlanPage.Container")
);

const Scheduling = React.lazy(() =>
	import("./pages/DashboardScheduling/DashboardScheduling.Container")
);

const DashboardGroupPage = React.lazy(() =>
	import("./pages/DashboardGroupPage/DashboardGroup.Container")
);

const GroupTypes = React.lazy(() =>
	import("./pages/DashboardGroupTypes/DashboardGroupTypes.container")
);

const PaymentSettings = React.lazy(() =>
	import(
		"../Account/pages/AccountPaymentSettings/AccountPaymentSettings.container"
	)
);

const DashboardRouting = () => {
	return (
		<Switch>
			{/* <Route path={"/dashboard/group-types"} component={GroupTypes} />
			<Route
				path={`/dashboard/group/:id`}
				component={DashboardGroupPage}
			/>
			{
				<Route
					path={`/dashboard/volunteer-scheduling`}
					component={Scheduling}
				/>
			} */}
			<Route path={`/dashboard/plan-page/:id`} component={PlanPage} />
			<Route
				path={`/dashboard/events-master-calendar`}
				component={MasterCalendar}
			/>
			<Route path={`/dashboard/event-plans`} component={Plans} />
			<Route path={`/dashboard/roles`} component={Roles} />
			<Route path={`/dashboard/events`} component={Events} />
			<Route path={`/dashboard/reports`} component={Reports} />
			<Route path={`/dashboard/members`} component={Members} />
			<Route path={`/dashboard/charts`} component={Charts} />
			<Route path={`/dashboard/givings`} component={Givings} />
			<Route path={`/dashboard/posts`} component={Posts} />
			<Route path={`/dashboard/comments`} component={Comments} />
			<Route
				path={`/dashboard/account-settings`}
				component={AccountSettings}
			/>
			<Route
				path={`/dashboard/prayer-requests`}
				component={PrayerRequests}
			/>
			<Route
				path={`/dashboard/video-settings`}
				component={VideoSettings}
			/>
			<Route
				exact
				path="/dashboard"
				render={() => <Redirect to="/dashboard/charts" />}
			/>
			<Route
				path={`/dashboard/payment-settings`}
				component={PaymentSettings}
			/>
			<Route component={NotFound} />
		</Switch>
	);
};

export default DashboardRouting;
