export const GET_HOME_POSTS = "GET_HOME_POSTS";
export const GET_HOME_POSTS_SUCCESS="GET_HOME_POSTS_SUCCESS";
export const GET_HOME_POSTS_ERROR = "GET_HOME_POSTS_ERROR";

export function getHomePosts(payload){
    return{
        type:GET_HOME_POSTS,
        payload
    }
}

export function getHomePostsSuccess(payload){
    return{
        type:GET_HOME_POSTS_SUCCESS,
        payload
    }
}

export function getHomePostsError(error){
    return{
        type:GET_HOME_POSTS_ERROR,
        error
    }
}