import React from "react";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as CloseIcon } from "../../assets/img/icons/close.svg";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";
import CompleteSignUpInfo from "./components/CompleteSignUpInfo";
import { hasNumber } from "../../common/helpers/common.utils";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import validator from "validator";

const SignUpModal = ({
	loading,
	registerUser,
	signUpComplete,
	show,
	handleClose,
	setShowLogin,
	states,
}) => {
	const { register, handleSubmit, errors, getValues, control } = useForm();
	const onSubmit = (data) =>
		registerUser({
			firstName: data.firstName,
			lastName: data.lastName,
			address: {
				streetAddress: data.address,
				city: data.city,
				stateId: data.stateId,
				zipCode: data.zipCode,
			},
			email: data.email,
			password: data.password,
			confirmPassword: data.confirmPassword,
			phoneNumber: data.phoneNumber,
		});

	const closeModal = () => {
		handleClose();
	};

	const passwordMatchValidation = (confirmPassword) => {
		return getValues("password") === confirmPassword;
	};

	const passwordFormatValidation = (password) => {
		return hasNumber(password);
	};

	return (
		<Modal
			isOpen={show}
			toggle={closeModal}
			centered
			className="modal-compact">
			<div className="modal-header / align-items-center">
				<h5
					className="modal-title / title  title_size_h2"
					id="login_modal_title">
					Sign up
				</h5>
				<button
					className="close"
					type="button"
					aria-label="Close"
					onClick={closeModal}>
					<CloseIcon />
				</button>
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody className="wide-modal-body-without-shadow">
					{signUpComplete ? (
						<CompleteSignUpInfo />
					) : (
						<>
							<div className="row">
								<div className="form-group / col-md-6">
									<label
										className="form-label form-label_required"
										htmlFor="sign_up_modal_firstname">
										First name
									</label>
									<input
										className="form-control"
										type="text"
										name="firstName"
										placeholder="First Name"
										id="sign_up_modal_firstname"
										ref={register({
											required: true,
											maxLength: 100,
											validate: {
												minTwo: (value) =>
													value.length >= 2,
											},
										})}
									/>
									{errors.firstName?.type === "required" && (
										<span className="form-validation-error text-left">
											First name is required
										</span>
									)}
									{errors.firstName?.type === "maxLength" && (
										<span className="form-validation-error text-left">
											First name max length is 100
											characters
										</span>
									)}
									{errors.firstName?.type === "minTwo" && (
										<span className="form-validation-error text-left">
											The length of First name must be at
											least 2 characters
										</span>
									)}
								</div>

								<div className="form-group / col-md-6 / mb-4">
									<label
										className="form-label form-label_required"
										htmlFor="sign_up_modal_lastname">
										Last name
									</label>
									<input
										className="form-control"
										type="text"
										name="lastName"
										id="sign_up_modal_lastname"
										placeholder="Last Name"
										ref={register({
											required: true,
											maxLength: 100,
											validate: {
												minTwo: (value) =>
													value.length >= 2,
											},
										})}
									/>
									{errors.lastName?.type === "required" && (
										<span className="form-validation-error text-left">
											Last name is required
										</span>
									)}
									{errors.lastName?.type === "maxLength" && (
										<span className="form-validation-error text-left">
											Last name max length is 100
											characters
										</span>
									)}
									{errors.lastName?.type === "minTwo" && (
										<span className="form-validation-error text-left">
											The length of Last name must be at
											least 2 characters
										</span>
									)}
								</div>
							</div>

							<div className="form-group">
								<label
									className="form-label  form-label_required"
									htmlFor="sign_up_modal_email">
									Email
								</label>
								<input
									className="form-control"
									type="email"
									name="email"
									id="sign_up_modal_email"
									placeholder="Email"
									ref={register({
										required: true,
										validate: {
											isEmail: (value) =>
												validator.isEmail(value),
										},
									})}
								/>
								{errors.email?.type === "required" && (
									<span className="form-validation-error text-left">
										Email is required
									</span>
								)}
								{errors.email?.type === "isEmail" && (
									<span className="form-validation-error text-left">
										Email is invalid
									</span>
								)}
							</div>

							<div className="form-group">
								<label
									className="form-label  form-label_required"
									htmlFor="sign_up_modal_password">
									Password
								</label>
								<input
									className="form-control"
									type="password"
									name="password"
									placeholder="Password"
									id="sign_up_modal_password"
									ref={register({
										required: true,
										minLength: 6,
										validate: {
											passwordFormat: (value) =>
												passwordFormatValidation(value),
										},
									})}
								/>
								{errors.password?.type === "required" && (
									<span className="form-validation-error text-left">
										Password is required
									</span>
								)}
								{errors.password?.type === "minLength" && (
									<span className="form-validation-error text-left">
										Password must have at least 6 characters
									</span>
								)}
								{errors.password?.type === "passwordFormat" && (
									<span className="form-validation-error text-left">
										Password must have at least one digit
										('0'-'9')
									</span>
								)}
							</div>

							<div className="form-group">
								<label
									className="form-label  form-label_required"
									htmlFor="sign_up_modal_password_repeat">
									Repeat Password
								</label>
								<input
									className="form-control"
									type="password"
									name="confirmPassword"
									placeholder="Repeat Password"
									id="sign_up_modal_password_repeat"
									ref={register({
										required: true,
										validate: {
											passwordMatch: (value) =>
												passwordMatchValidation(value),
										},
									})}
								/>
								{errors.confirmPassword?.type ===
									"required" && (
									<span className="form-validation-error text-left">
										Repeat your password
									</span>
								)}
								{errors.confirmPassword?.type ===
									"passwordMatch" && (
									<span className="form-validation-error text-left">
										Passwords don't match
									</span>
								)}
							</div>

							<div className="form-group">
								<label
									className="form-label form-label_required"
									htmlFor="sign_up_modal_address">
									Street Address
								</label>
								<input
									className="form-control"
									type="text"
									name="address"
									id="sign_up_modal_address"
									placeholder="Street Address"
									ref={register({
										required: true,
										maxLength: 150,
									})}
								/>
								{errors.address?.type === "required" && (
									<span className="form-validation-error text-left">
										Street Address is required
									</span>
								)}
								{errors.address?.type === "maxLength" && (
									<span className="form-validation-error text-left">
										Street Address max length is 150
										characters
									</span>
								)}
							</div>

							<div className="form-group">
								<label
									className="form-label  form-label_required"
									htmlFor="sign_up_modal_city">
									City
								</label>
								<input
									className="form-control"
									type="text"
									name="city"
									placeholder="City"
									id="sign_up_modal_city"
									ref={register({
										required: true,
										// minLength: 6,
										// validate: {
										// 	passwordFormat: (value) =>
										// 		passwordFormatValidation(value),
										// },
									})}
								/>
								{errors.city?.type === "required" && (
									<span className="form-validation-error text-left">
										City is required
									</span>
								)}
							</div>

							<div className="form-group">
								<label
									className="form-label  form-label_required"
									htmlFor="sign_up_modal_state">
									State
								</label>
								<select
									className="form-control"
									name="stateId"
									id="sign_up_modal_state"
									ref={register({
										required: true,
										// minLength: 6,
										// validate: {
										// 	passwordFormat: (value) =>
										// 		passwordFormatValidation(value),
										// },
									})}
									defaultValue="">
									<option value="" disabled hidden>
										State
									</option>
									{states.map((elem) => (
										<option value={elem.id} key={elem.id}>
											{elem.name}
										</option>
									))}
								</select>
								{errors.stateId?.type === "required" && (
									<span className="form-validation-error text-left">
										State is required
									</span>
								)}
							</div>

							<div className="form-group">
								<label
									className="form-label  form-label_required"
									htmlFor="sign_up_modal_zip">
									Zip-Code
								</label>
								<input
									className="form-control"
									type="text"
									name="zipCode"
									placeholder="Zip-Code"
									id="sign_up_modal_zip"
									ref={register({
										required: true,
										validate: {
											codeFormat: (value) =>
												/^\d{5}(-\d{4})?$/.test(value),
										},
									})}
								/>
								{errors.zipCode?.type === "required" && (
									<span className="form-validation-error text-left">
										Zip Code is required
									</span>
								)}
								{errors.zipCode?.type === "codeFormat" && (
									<span className="form-validation-error text-left">
										Zip Code is invalid
									</span>
								)}
							</div>

							<div className="form-group">
								<label
									className="form-label  form-label_required"
									htmlFor="sign_up_modal_phone">
									Phone
								</label>
								<Controller
									as={
										<PhoneInput
											country={"us"}
											inputClass="phone_input_config"
											inputProps={{
												name: "phoneNumber",
												id: "sign_up_modal_phone",
											}}
											placeholder="Phone"
										/>
									}
									name="phoneNumber"
									control={control}
									rules={{ required: true }}
								/>
								{errors.phoneNumber?.type === "required" && (
									<span className="form-validation-error text-left">
										Phone is required
									</span>
								)}
							</div>

							{Boolean(setShowLogin) && (
								<p
									className="auth_modal_switch_p"
									onClick={() => {
										handleClose();
										setShowLogin(true);
									}}>
									Already have an account?
								</p>
							)}
						</>
					)}
				</ModalBody>
				<ModalFooter className="wide-modal-body-without-shadow">
					{signUpComplete ? (
						<button
							className="btn btn_theme_white"
							type="button"
							onClick={closeModal}>
							Close
						</button>
					) : (
						<button
							disabled={loading}
							className="btn"
							type="submit">
							Sign up
							{loading && <Spinner size="sm" />}
						</button>
					)}
				</ModalFooter>
			</form>
		</Modal>
	);
};

export default SignUpModal;
