export const GET_SERVICE_TYPES = "GET_SERVICE_TYPES";
export const GET_SERVICE_TYPES_SUCCESS = "GET_SERVICE_TYPES_SUCCESS";
export const GET_SERVICE_TYPES_ERROR = "GET_SERVICE_TYPES_ERROR";

export function getServiceTypes(payload) {
	return {
		type: GET_SERVICE_TYPES,
		payload,
	};
}

export function getServiceTypesSuccess(payload) {
	return {
		type: GET_SERVICE_TYPES_SUCCESS,
		payload,
	};
}

export function getServiceTypesError(error) {
	return {
		type: GET_SERVICE_TYPES_ERROR,
		error,
	};
}

export const UPDATE_SERVICE_TYPE = "UPDATE_SERVICE_TYPE";
export const UPDATE_SERVICE_TYPE_SUCCESS = "UPDATE_SERVICE_TYPE_SUCCESS";
export const UPDATE_SERVICE_TYPE_ERROR = "UPDATE_SERVICE_TYPE_ERROR";

export function updateServiceType(payload) {
	return {
		type: UPDATE_SERVICE_TYPE,
		payload,
	};
}

export function updateServiceTypeSuccess(payload) {
	return {
		type: UPDATE_SERVICE_TYPE_SUCCESS,
		payload,
	};
}

export function updateServiceTypeError(error) {
	return {
		type: UPDATE_SERVICE_TYPE,
		error,
	};
}

export const CREATE_SERVICE_TYPE = "CREATE_SERVICE_TYPE";
export const CREATE_SERVICE_TYPE_SUCCESS = "CREATE_SERVICE_TYPE_SUCCESS";
export const CREATE_SERVICE_TYPE_ERROR = "CREATE_SERVICE_TYPE_ERROR";

export function createServiceType(payload) {
	return {
		type: CREATE_SERVICE_TYPE,
		payload,
	};
}

export function createServiceTypeSuccess(payload) {
	return {
		type: CREATE_SERVICE_TYPE_SUCCESS,
		payload,
	};
}

export function createServiceTypeError(error) {
	return {
		type: CREATE_SERVICE_TYPE,
		error,
	};
}

export const DELETE_SERVICE_TYPE = "DELETE_SERVICE_TYPE";
export const DELETE_SERVICE_TYPE_SUCCESS = "DELETE_SERVICE_TYPE_SUCCESS";
export const DELETE_SERVICE_TYPE_ERROR = "DELETE_SERVICE_TYPE_ERROR";

export function deleteServiceType(payload) {
	return {
		type: DELETE_SERVICE_TYPE,
		payload,
	};
}

export function deleteServiceTypeSuccess(payload) {
	return {
		type: DELETE_SERVICE_TYPE_SUCCESS,
		payload,
	};
}

export function deleteServiceTypeError(error) {
	return {
		type: DELETE_SERVICE_TYPE_ERROR,
		error,
	};
}

export const GET_MASTER_CALENDAR_EVENTS_REQUEST =
	"GET_MASTER_CALENDAR_EVENTS_REQUEST";
export const GET_MASTER_CALENDAR_EVENTS_SUCCESS =
	"GET_MASTER_CALENDAR_EVENTS_SUCCESS";
export const GET_MASTER_CALENDAR_EVENTS_ERROR =
	"GET_MASTER_CALENDAR_EVENTS_ERROR";

export const getMasterCalendarEventsRequest = (payload) => ({
	type: GET_MASTER_CALENDAR_EVENTS_REQUEST,
	payload,
});

export const getMasterCalendarEventsSuccess = (payload) => ({
	type: GET_MASTER_CALENDAR_EVENTS_SUCCESS,
	payload,
});

export const getMasterCalendarEventsError = (error) => ({
	type: GET_MASTER_CALENDAR_EVENTS_ERROR,
	payload: error,
});
