import { call, put, takeEvery } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
	LOGIN_USER,
	loginUserError,
	loginUserSuccess,
	LOGOUT_USER,
	logoutUserError,
	logoutUserSuccess,
	refreshTokenSuccess,
	refreshTokenError,
	REFRESH_TOKEN,
	RESET_PASSWORD,
	resetPasswordSuccess,
	resetPasswordError,
	sendResetPasswordSuccess,
	sendResetPasswordError,
	sendConfirmationEmailSuccess,
	sendConfirmationEmailError,
	SEND_CONFIRMATION_EMAIL,
	SEND_RESET_PASSWORD,
} from "../actions/auth.actions";
import {
	getCurrentUserInfo,
	clearCurrentUserInfo,
} from "../../pages/Account/pages/AccountSettings/store/actions";

import ApiBase from "../../common/api/api.base";
import {
	clearCurrentUser,
	getCurrentUser,
} from "../../common/helpers/auth.helper";
import { toast } from "react-toastify";

function* login(action) {
	try {
		const result = yield call(
			ApiBase.post,
			"/api/account/login",
			action.payload
		);
		if (!result.error) {
			yield put(loginUserSuccess(result));
			yield put(getCurrentUserInfo());
			// yield put(window.location.pathname);
			if (result.roles[0] === "Admin") {
				yield put(push("/dashboard"));
			} else {
				yield put(window.location.pathname);
			}
		} else {
			yield put(loginUserError("Failed to sign in!"));
		}
	} catch (e) {
		yield put(loginUserError("Failed to sign in!"));
	}
}

function* logout() {
	try {
		const result = yield call(ApiBase.post, "/api/account/logout");
		if (!result.error) {
			yield put(logoutUserSuccess(result));
			yield put(clearCurrentUserInfo());
			clearCurrentUser();
			yield put(push("/"));
			window.scrollTo(0);
			window.location.reload();
		} else {
			yield put(logoutUserError("Failed to logout"));
		}
	} catch (e) {
		yield put(logoutUserError("Failed to sign in!"));
	}
}

function* refreshToken() {
	try {
		const { accessToken, refreshToken } = getCurrentUser();
		const result = yield call(
			ApiBase.post,
			"/api/account/refresh-access-token",
			{ accessToken, refreshToken }
		);
		if (!result.error) {
			yield put(refreshTokenSuccess(result));
		} else {
			yield put(refreshTokenError("Failed to refresh access token"));
		}
	} catch (e) {
		yield put(refreshTokenError(e.message));
	}
}

function* resetPassword(action) {
	try {
		const result = yield call(
			ApiBase.post,
			"/api/account/reset-password",
			action.payload
		);
		if (!result.error) {
			yield put(resetPasswordSuccess(result));

			toast.success("Your password has been successfully reset");
			yield put(push("/"));
			window.location.reload();
		} else {
			yield put(resetPasswordError("Failed to reset password"));
		}
	} catch (e) {
		yield put(resetPasswordError(e.message));
	}
}

function* sendResetPasswordEmail(action) {
	try {
		const result = yield call(
			ApiBase.post,
			`/api/account/send-forgot-password-email`,{email:action.payload.email}
		);
		if (!result.error) {
			yield put(sendResetPasswordSuccess(result));

			toast.success("Forgot password instructions has been sent");
		} else {
			yield put(
				sendResetPasswordError("Failed to send forgot password email")
			);
		}
	} catch (e) {
		yield put(sendResetPasswordError(e.message));
	}
}

function* sendConfirmationEmail(action) {
	try {
		const result = yield call(
			ApiBase.post,
			`/api/account/resend-confirm-email?email=${action.payload.email}`
		);
		if (!result.error) {
			yield put(sendConfirmationEmailSuccess(result));

			toast.success("Confirmation email has been sent");
		} else {
			yield put(
				sendConfirmationEmailError(
					"Failed to resend confirmation email"
				)
			);
		}
	} catch (e) {
		yield put(sendConfirmationEmailError(e.message));
	}
}

export default function* watchAuthSaga() {
	yield takeEvery(LOGIN_USER, login);
	yield takeEvery(LOGOUT_USER, logout);
	yield takeEvery(REFRESH_TOKEN, refreshToken);
	yield takeEvery(RESET_PASSWORD, resetPassword);
	yield takeEvery(SEND_CONFIRMATION_EMAIL, sendConfirmationEmail);
	yield takeEvery(SEND_RESET_PASSWORD, sendResetPasswordEmail);
}
