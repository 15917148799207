import {
	SEARCH_FOR_SONGS_REQUEST,
	SEARCH_FOR_SONGS_SUCCESS,
	SEARCH_FOR_SONGS_ERROR,
	SELECT_SONG_REQUEST,
	SELECT_SONG_SUCCESS,
	SELECT_SONG_ERROR,
	GET_POPULAR_SONGS_REQUEST,
	GET_POPULAR_SONGS_SUCCESS,
	GET_POPULAR_SONGS_ERROR,
	CLEAR_SONGS_SEARCH_RESULT,
	SEARCH_FOR_POPULAR_SONGS_REQUEST,
	SEARCH_FOR_POPULAR_SONGS_SUCCESS,
	SEARCH_FOR_POPULAR_SONGS_ERROR,
} from "./actions";

const initialState = {
	searchLoading: false,
	selectLoading: false,
	popularLoading: false,
	searchResult: {
		total: 0,
		items: [],
	},
	error: null,
	selectedSong: null,
	popularSongs: {
		total: 0,
		items: [],
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SEARCH_FOR_SONGS_REQUEST:
			return {
				...state,
				searchLoading: true,
			};
		case SEARCH_FOR_SONGS_SUCCESS:
			return {
				...state,
				searchLoading: false,
				searchResult: action.payload,
				error: null,
			};
		case SEARCH_FOR_SONGS_ERROR:
			return {
				...state,
				error: action.payload,
				searchLoading: false,
			};
		case SELECT_SONG_REQUEST:
			return {
				...state,
				selectLoading: true,
			};
		case SELECT_SONG_SUCCESS:
			return {
				...state,
				selectLoading: false,
				selectedSong: action.payload,
			};
		case SELECT_SONG_ERROR:
			return {
				...state,
				selectLoading: false,
				error: action.payload,
			};
		case GET_POPULAR_SONGS_REQUEST:
			return {
				...state,
				popularLoading: true,
			};
		case GET_POPULAR_SONGS_SUCCESS:
			return {
				...state,
				popularLoading: false,
				popularSongs: action.payload,
				error: null,
			};
		case GET_POPULAR_SONGS_ERROR:
			return {
				...state,
				popularLoading: false,
				error: action.payload,
			};
		case CLEAR_SONGS_SEARCH_RESULT:
			return {
				...state,
				searchResult: {
					total: 0,
					items: [],
				},
				searchLoading: false,
			};
		case SEARCH_FOR_POPULAR_SONGS_REQUEST:
			return {
				...state,
				popularLoading: true,
			};
		case SEARCH_FOR_POPULAR_SONGS_SUCCESS:
			return {
				...state,
				popularLoading: false,
				popularSongs: action.payload,
			};
		case SEARCH_FOR_POPULAR_SONGS_ERROR:
			return {
				...state,
				popularLoading: false,
				error: action.payload,
			};
		default:
			return state;
	}
}
