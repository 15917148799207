import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import SignUpModal from "../../../modals/SignUpModal/SignUpModal.container";

const HeroSwiper = () => {
	const [showSignUp, setShowSignUp] = useState(false);
	const handleCloseSignUp = () => setShowSignUp(false);

	const swiperParams = {
		loop: true,
		autoplay: {
			delay: 5000,
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	};

	return (
		<>
			<section className="hero">
				<Swiper {...swiperParams}>
					<div className="hero__item  hero__item_welcome">
						<div className="container">
							<div className="row">
								<div className="col-12">
									<p className="hero__notice">Welcome to</p>

									<h2 className="hero__title">
										Peter's Rock Ministries
									</h2>

									<Link
										to={"/videos"}
										className="hero__link / btn">
										Watch Sermons
									</Link>
								</div>
							</div>
						</div>
					</div>

					<div className="hero__item  hero__item_help">
						<div className="container">
							<div className="row">
								<div className="col-12">
									<h2 className="hero__title">
										Our Highest Ambition is to Help People
									</h2>

									<Link
										to={"/videos"}
										className="hero__link / btn">
										Watch Sermons
									</Link>
								</div>
							</div>
						</div>
					</div>

					<div className="hero__item  hero__item_join">
						<div className="container">
							<div className="row">
								<div className="col-12">
									<h2 className="hero__title">
										Join The Prayer
									</h2>

									<a
										className="hero__link / btn"
										onClick={(e) => {
											e.preventDefault();
											setShowSignUp(true);
										}}
										href="#!">
										Register
									</a>
								</div>
							</div>
						</div>
					</div>
				</Swiper>
			</section>
			<SignUpModal show={showSignUp} handleClose={handleCloseSignUp} />
		</>
	);
};

export default HeroSwiper;
