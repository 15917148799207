import { call, put, takeEvery, take } from "redux-saga/effects";
import {
	GET_DASHBOARD_MEMBERS,
	SEARCH_DASHBOARD_MEMBERS,
	getDashboardMembersSuccess,
	getDashboardMembersError,
	searchDashboardMembersSuccess,
	searchDashboardMembersError,
	getRolesShortInfoError,
	getRolesShortInfoSuccess,
	GET_ROLES_SHORT_INFO,
	updateMemberRoleSuccess,
	updateMemberRoleError,
	UPDATE_MEMBER_ROLE,
	deleteMembersError,
	deleteMembersSuccess,
	getDashboardMembers,
	DELETE_MEMBERS,
} from "./actions";

import ApiBase from "../../../../../common/api/api.base";
import { toast } from "react-toastify";
import pageSizes from "../../../../../common/constants/pageSizes";

function* workerGetDashboardMembers(action) {
	try {
		var result = yield call(ApiBase.get, '/api/users/members', action.payload);
		if (!result.error) {
			yield put(getDashboardMembersSuccess(result));
		}
		else {
			yield put(getDashboardMembersError(result.error));
		}
	}
	catch (e) {
		yield put(getDashboardMembersError(e.message));
		toast.error("An error occured while fetching members");
	}
}

function* workerSearchDashboardMembers(action) {
	try {
		var result = yield call(ApiBase.get, '/api/users/members', action.payload);
		if (!result.error) {
			yield put(searchDashboardMembersSuccess(result));
		}
		else {
			yield put(searchDashboardMembersError(result.error));
		}
	}
	catch (e) {
		yield put(searchDashboardMembersError(e.message));
		toast.error("An error occured while fetching members");
	}
}

function* workerGetRolesShortInfo(_) {
	try {
		var result = yield call(ApiBase.get, '/api/roles/short');
		if (!result.error) {
			yield put(getRolesShortInfoSuccess(result));
		}
		else {
			yield put(getRolesShortInfoError(result.error));
		}
	}
	catch (e) {
		yield put(getRolesShortInfoError(e.message));
	}
}

function* workerUpdateMemberRole(action) {
	try {
		const result = yield call(ApiBase.put, '/api/users/role', action.payload);
		if (!result.error) {
			yield put(updateMemberRoleSuccess(result));
		}
		else {
			yield put(updateMemberRoleError(result.error));
		}
	}
	catch (e) {
		yield put(updateMemberRoleError(e.message));
	}
}

function* workerDeleteMembers(action) {
	try {
		const result = yield call(ApiBase.delete, '/api/users/multiple', {}, action.payload.body);
		if (!result.error) {
			yield put(deleteMembersSuccess(result));
			yield put(getDashboardMembers({ fullName: action.payload.query, skip: 0, take: pageSizes.DASHBOARD_MEMBERS_TAKE }));
		}
		else {
			yield put(deleteMembersError(result.error));
		}
	}
	catch (e) {
		yield put(deleteMembersError(e.message));
	}
}

export default function* watchDashboardMembersSaga() {
	yield takeEvery(GET_DASHBOARD_MEMBERS, workerGetDashboardMembers);
	yield takeEvery(SEARCH_DASHBOARD_MEMBERS, workerSearchDashboardMembers);
	yield takeEvery(GET_ROLES_SHORT_INFO, workerGetRolesShortInfo);
	yield takeEvery(UPDATE_MEMBER_ROLE, workerUpdateMemberRole);
	yield takeEvery(DELETE_MEMBERS, workerDeleteMembers);
}
