import { call, put, takeEvery } from "redux-saga/effects";
import {
	deleteGroupTypeSuccess,
	deleteGroupTypeError,
	getGroupTypes,
	getGroupTypesSuccess,
	getGroupTypesError,
	CREATE_GROUP_TYPE,
	createGroupTypeError,
	createGroupTypeSuccess,
	UPDATE_GROUP_TYPE,
	updateGroupTypeSuccess,
	updateGroupTypeError,
	DELETE_GROUP_TYPE,
	GET_GROUP_TYPES
} from "./actions";
import pageSizes from "../../../../../common/constants/pageSizes";
import ApiBase from "../../../../../common/api/api.base";
import { toast } from "react-toastify";

function* workerCreateGroupType(action) {
	try {
		const result = yield call(
			ApiBase.post,
			`/api/group-types`,
			action.payload
		);
		if (!result.error) {
			yield put(createGroupTypeSuccess(result));
			toast.success("Successfully added!");
			yield put(
				getGroupTypes({ skip: 0, take: pageSizes.GROUP_TYPES_TAKE })
			);
		} else {
			yield put(createGroupTypeError("Failed to create group type!"));
		}
	} catch (e) {
		yield put(createGroupTypeError(e.message));
	}
}

function* workerUpdateGroupType(action) {
	try {
		const { id, name, description, colorTheme } = action.payload;
		const result = yield call(ApiBase.put, `/api/group-types/${id}`, {
			name,
			description,
			colorTheme
		});
		if (!result.error) {
			yield put(updateGroupTypeSuccess(result));
			toast.success("Successfully updated!");
		} else {
			yield put(updateGroupTypeError("Failed to update group type!"));
		}
	} catch (e) {
		yield put(updateGroupTypeError(e.message));
	}
}

function* workerDeleteGroupType(action) {
	try {
		const result = yield call(
			ApiBase.delete,
			`/api/group-types/${action.payload}`
		);
		if (!result.error) {
			yield put(deleteGroupTypeSuccess(result));
			yield put(
				getGroupTypes({ skip: 0, take: pageSizes.GROUP_TYPES_TAKE })
			);
		} else {
			yield put(deleteGroupTypeError("Failed to delete group type!"));
		}
	} catch (e) {
		yield put(deleteGroupTypeError(e.message));
	}
}

function* workerGetGroupTypes(action) {
	try {
		const result = yield call(
			ApiBase.get,
			`/api/group-types`,
			action.payload
		);
		if (!result.error) {
			yield put(getGroupTypesSuccess(result));
		} else {
			yield put(getGroupTypesError("Failed to get group types!"));
		}
	} catch (e) {
		yield put(getGroupTypesError(e.message));
	}
}

export default function* watchDashboardGroupTypesSaga() {
	yield takeEvery(DELETE_GROUP_TYPE, workerDeleteGroupType);
	yield takeEvery(GET_GROUP_TYPES, workerGetGroupTypes);
	yield takeEvery(CREATE_GROUP_TYPE, workerCreateGroupType);
	yield takeEvery(UPDATE_GROUP_TYPE, workerUpdateGroupType);
}
