import {
    REGISTER_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS
} from './actions';

const initialRegistrationState = {
    error: null,
    loading: false,
    loaded: false
};

function registrationReducer(state = initialRegistrationState, action) {
    switch (action.type) {
        case REGISTER_USER:
            return { ...state, loading: true, loaded: false, error: null };
        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false, loaded: true, error: null };
        case REGISTER_USER_ERROR:
            return { ...state, loading: false, loaded: false, error: action.error };
        default:
            return state;
    }
}

export default registrationReducer;