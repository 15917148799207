import React, { useEffect } from "react";
import { ModalBody, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";

const RecurringPlan = ({
	goBack,
	goNext,
	givingTypes,
	givingPlans,
	selectedPlan,
}) => {
	const onSubmit = (data) => {
		goNext(data);
	};

	const { register, handleSubmit, reset } = useForm();
	useEffect(() => {
		if (selectedPlan) {
			reset(selectedPlan);
		}
	}, [selectedPlan]);

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalBody className="wide-modal-body-without-shadow">
					<p className="modal-subtitle / text-center / mb-4">
						The giving will repeat every month
					</p>
					<div className="modal-inner  modal-inner_step_0">
						<div className="form-group">
							<label
								className="form-label"
								htmlFor="recurringAmount">
								Amount $
							</label>
							<select
								className="form-control"
								id="recurringAmount"
								name="recurringAmount"
								ref={register({ required: true })}>
								{givingPlans.map((type) => {
									return (
										<option value={type.id} key={type.id}>
											{type.amount}
										</option>
									);
								})}
							</select>
						</div>

						<div className="form-group">
							<label
								className="form-label"
								htmlFor="recurringDesignation">
								Giving type
							</label>
							<select
								className="form-control"
								id="recurringDesignation"
								name="recurringDesignation"
								ref={register({ required: true })}>
								{givingTypes.map((type) => {
									return (
										<option value={type.id} key={type.id}>
											{type.name}
										</option>
									);
								})}
							</select>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className="wide-modal-body-without-shadow">
					<button
						className="btn  btn_theme_transparent / mr-auto"
						type="button"
						onClick={goBack}>
						&lt;Back
					</button>

					<button className="btn  btn_theme_white" type="submit">
						Next
					</button>
				</ModalFooter>
			</form>
		</>
	);
};

export default RecurringPlan;
